import React, { MouseEvent } from "react";
import {
    useRef,
    useCallback,
    useState,
} from "react";
import {
    Box,
    Zoom,
    Fab,
    useScrollTrigger,
    Menu,
    MenuItem,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { UserRequest } from "api/requests/Requests";
import {
    UserModel,
    GroupModel,
    JoinGroupModel,
    HistoryUserList,
} from "models/Models";
import { useLongPress } from "use-long-press";
import { useDispatch, useSelector } from "react-redux";
import { UserListItem } from "components/Components";
import { block } from "functions/Functions";
import {
    ProcessAction,
    HistoryAction,
    UserAction,
    SelectedItemAction,
    UnreadCountAction,
} from "redux/Actions";
import {
    getUserListHistory,
    getTimeDifference,
    getLanguageCode,
    getFollowUserList,
    getBlockUserList,
    getOwnGroupList,
    getUserInviteList,
} from "redux/Selectors";
import { KeyboardArrowUp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AppConstants } from "constants/AppConstants";
import Utility from "utils/Utility";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/user/UserList.scss";
import { FormattedMessage } from "react-intl";

type Props = {
    loginUser: UserModel | null;
};

const UserList: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ UserList IN");
        /***** 定数、変数 */
        const dispatch = useDispatch();
        const selector = useSelector((state) => state);
        const navigate = useNavigate();
        const theme = useTheme();
        const isSizeXS = useMediaQuery(theme.breakpoints.down("sm"));
        const langCd = getLanguageCode(selector);

        /***** useRef */
        const refContainer = React.useRef<HTMLDivElement>();
        const refTimeDifference = React.useRef<number>(
            getTimeDifference(selector)
        );
        // 処理中フラグ
        const isUnderProcess = React.useRef<boolean>(false);
        // 読込中フラグ
        const nowFetching = React.useRef<boolean>(false);
        // タイマーID
        const timeoutId = React.useRef<number>(0);
        // 次レコード有無
        const hasNext = React.useRef<boolean>(true);
        // 読み込みインデックス
        const currentIndex = React.useRef<number>(0);
        // 読み込みサイズ
        const readSize = React.useRef<number>(100);
        // ユーザリスト参照
        const refUserList = React.useRef<UserModel[]>();
        // フォローユーザリスト参照
        const refFollowUserList = React.useRef<UserModel[]>();
        // ブロックユーザリスト参照
        const refBlockUserList = React.useRef<UserModel[]>();
        // マイグループリスト参照
        const refOwnGroupList = React.useRef<GroupModel[]>([]);
        // 招待中JoinGroupリスト参照
        const refInviteList = React.useRef<JoinGroupModel[]>();
        // コンテキストメニュー表示フラグ
        const showingContextMenu = React.useRef<boolean>(false);
        // 右クリックor長押し選択ユーザ参照
        const refSelectedUser = React.useRef<UserModel>();

        /***** useState */
        // 時差
        const [timeDifference, setTimeDifference] = React.useState<number>();
        // ユーザリスト
        const [lstUser, setUserList] = React.useState<UserModel[]>([]);
        const [selectedUser, setSelectedUser] = React.useState<UserModel>();
        // コンテクストメニュー
        const [contextMenu, setContextMenu] = React.useState<{
            mouseX: number;
            mouseY: number;
        } | null>(null);
        // // フォローユーザリスト
        // const [lstFollowUser, setFollowUserList] =
        //     React.useState<UserModel[]>();
        // // ブロックユーザリスト
        // const [lstBlockUser, setBlockUserList] = React.useState<UserModel[]>();
        // // マイグループリスト
        // const [lstOwnGroup, setOwnGroupList] = React.useState<GroupModel[]>([]);
        // // 招待中JoinGroupリスト
        // const [lstInvite, setInviteList] = React.useState<JoinGroupModel[]>();
        const [enabled] = React.useState(true);

        // const callback = React.useCallback((event: any) => {
        //     event.preventDefault();
        //     return false;
        // }, []);

        const trigger = useScrollTrigger({
            target: refContainer.current,
            disableHysteresis: true,
            threshold: 300,
        });

        const scrollToTop = React.useCallback(() => {
            if (refContainer.current != null) {
                refContainer.current.scrollTo({ top: 0 });
            }
        }, []);

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "ユーザ一覧",
                path: window.location.pathname,
            })
        }, []);
        React.useEffect(() => {
            if (refTimeDifference.current != null) {
                setTimeDifference(refTimeDifference.current);
            }
        }, [refTimeDifference.current]);
        React.useEffect(() => {
            if (refContainer != null && refContainer.current != null) {
                refContainer.current.addEventListener("scroll", onScroll);
            }
            return () => {
                if (refContainer != null && refContainer.current != null) {
                    refContainer.current.removeEventListener("scroll", onScroll);
                }
            };
        }, [lstUser]);
        React.useEffect(() => {
            (async () => {
                dispatch(
                    ProcessAction({
                        processing: true,
                        message: "msg_loading",
                    })
                );
                if (props.loginUser != null) {
                    const lstFollowUser = getFollowUserList(selector);
                    if (lstFollowUser != null) {
                        refFollowUserList.current = lstFollowUser;
                    }
                    const lstBlockUser = getBlockUserList(selector);
                    if (lstBlockUser != null) {
                        refBlockUserList.current = lstBlockUser;
                    }
                    const lstOwnGroup = getOwnGroupList(selector);
                    if (lstOwnGroup != null) {
                        refOwnGroupList.current = [...lstOwnGroup];
                    }
                    const lstInvite = getUserInviteList(selector);
                    if (lstInvite != null) {
                        refInviteList.current = lstInvite;
                    }
                    await getUserList(0);
                } else {
                    await getUserList(0);
                }
                dispatch(ProcessAction({ processing: false, message: "" }));
            })();
        }, [props.loginUser]);

        /**
         * スクロール位置取得
         * @returns
         */
        const getScrollPosition = (): number => {
            if (refContainer != null && refContainer.current != null) {
                return Math.max(refContainer.current.scrollTop);
            } else {
                return 0;
            }
        };
        //     const getScrollPosition = (): number => {
        //     return Math.max(
        //         window.pageYOffset,
        //         document.documentElement.scrollTop,
        //         document.body.scrollTop
        //     );
        // };

        /**
         * スクロール時
         * @param event
         * @returns
         */
        async function onScroll(event: any) {
            if (timeoutId.current !== 0) {
                return;
            }
            timeoutId.current = window.setTimeout(async function () {
                const el = refContainer.current;
                if (el == null) {
                    timeoutId.current = 0;
                    return;
                }
                const scrollHeight = el.scrollHeight;
                const scrollY = getScrollPosition();
                const windowHeight = window.innerHeight;
                if (scrollHeight - 50 < scrollY + windowHeight) {
                    if (!hasNext.current) {
                        return;
                    }
                    if (nowFetching.current) {
                        return;
                    }
                    nowFetching.current = true;
                    currentIndex.current =
                        currentIndex.current + readSize.current;
                    await getUserList(currentIndex.current);
                    nowFetching.current = false;
                }
                timeoutId.current = 0;
            }, 100);
        }

        /**
         * アイテム選択時
         * @param userId
         */
        async function onClickUser(user: UserModel) {
            if (showingContextMenu.current) {
                return;
            }
            const key = Utility.getUserKey(user);
            if (key.length > 0) {
                dispatch(SelectedItemAction({ userId: user.id }));
                const scrollPos = getScrollPosition();
                const history = getUserListHistory(selector) as HistoryUserList;
                if (history != null) {
                    history.scrollPos = scrollPos;
                    dispatch(HistoryAction({ userListHistory: history }));
                }
                window.setTimeout(function () {
                    navigate(`/users/${key}/profile`);
                });
            }
        }
        function onMouseDownHandler(user: UserModel) {
            refSelectedUser.current = user;
            setSelectedUser(user);
        }
        // const onLongPressBind = useLongPress(onLongPress, {
        const onLongPressBind = useLongPress(enabled ? onLongPress : null, {
            onStart: (event, params) => {
                if (params != null && params.context != null) {
                    const user = new UserModel(params.context);
                    refSelectedUser.current = user;
                    setSelectedUser(user);
                }
            },
            onFinish: (event, user) => {},
            onCancel: (event, user) => {
                onCloseContextMenu();
            },
            // onMove: (event) => Utility.log("Detected mouse or touch movement"),
            filterEvents: (event) => true, // All events can potentially trigger long press (same as 'undefined')
            threshold: 300, // In milliseconds
            captureEvent: false, // Event won't get cleared after React finish processing it
            cancelOnMovement: 50, // Square side size (in pixels) inside which movement won't cancel long press
            cancelOutsideElement: true, // Cancel long press when moved mouse / pointer outside element while pressing
            // detect: "pointer", // Default option
        });
        function onLongPress(event: any) {
            // event.preventDefault();
            if (showingContextMenu.current) {
                return;
            }
            event.preventDefault();
            // alert("onLongPress");
            setContextMenu({
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 100,
            });
            showingContextMenu.current = true;
        }
        function onOpenContextMenu(
            event: MouseEvent<HTMLDivElement>,
            user: UserModel
        ) {
            // event.preventDefault();
            if (showingContextMenu.current) {
                return;
            }
            // alert("onContextMenuHandler");
            setContextMenu({
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
            });
            refSelectedUser.current = user;
            setSelectedUser(user);
            showingContextMenu.current = true;
        }

        /**
         * コンテクストメニュー閉じる
         */
        function onCloseContextMenu() {
            setContextMenu(null);
            refSelectedUser.current = undefined;
            setSelectedUser(undefined);
            showingContextMenu.current = false;
        }
        /**
         * ブロック
         * @param _user
         */
        async function onBlock(_user: UserModel) {
            if (props.loginUser == null) {
                return;
            }
            const rtnCd = await block(props.loginUser, _user);
            if (rtnCd === 0) {
                // ローカルとReduxデータのブロックユーザリスト更新
                updateBlockUserList(_user);
                // ユーザリスト履歴を更新
                const wkUserList = updateUserFromUserListHistory(_user);
                setUserList(wkUserList);
                refUserList.current = wkUserList;
                // メール未読件数取得
                const p1 = Utility.getUnreadMailCount(props.loginUser);
                // トーク未読件数取得
                const p2 = Utility.getUnreadTalkCount(props.loginUser);
                const [unreadMailCount, unreadTalkCount] =
                    await Promise.all([p1, p2]);
                dispatch(
                    UnreadCountAction({
                        mail:
                            unreadMailCount != null
                                ? unreadMailCount
                                : 0,
                        talk:
                            unreadTalkCount != null
                                ? unreadTalkCount
                                : 0,
                    })
                );
            }
            onCloseContextMenu();
        }
        /**
         * ローカルとReduxデータのブロックユーザリスト更新
         * @param target
         */
        function updateBlockUserList(target: UserModel) {
            if (
                refBlockUserList.current == null ||
                refBlockUserList.current == null
            ) {
                const lstBlockUser = [target];
                dispatch(
                    UserAction({
                        lstBlockUser: lstBlockUser,
                    })
                );
                refBlockUserList.current = lstBlockUser;
            } else {
                const lstBlockUser = refBlockUserList.current;
                lstBlockUser.push(target);
                dispatch(
                    UserAction({
                        lstBlockUser: lstBlockUser,
                    })
                );
            }
        }
        /**
         * ユーザリスト履歴を更新
         * @param target
         * @returns
         */
        function updateUserFromUserListHistory(target: UserModel): UserModel[] {
            let wkUserList = lstUser;
            if (wkUserList != null && wkUserList.length > 0) {
                for (let i = 0; i < wkUserList.length; i++) {
                    const user = wkUserList[i];
                    if (target.id === user.id) {
                        user.blocking = true;
                        break;
                    }
                }
                saveHistory(
                    currentIndex.current,
                    hasNext.current,
                    readSize.current,
                    wkUserList,
                    true,
                    true
                );
            }
            return wkUserList;
        }
        /**
         * ユーザの付加情報(フォロー中、ブロック中、招待中)セット
         * @param loginUser
         * @param user_list
         * @returns
         */
        function processUserList(
            loginUser: UserModel | null,
            user_list: UserModel[]
        ): UserModel[] {
            if (loginUser == null) {
                let wkUserList: UserModel[] = [];
                for (let i = 0; i < user_list.length; i++) {
                    user_list[i].following = false;
                    user_list[i].blocking = false;
                    user_list[i].inviting = false;
                    const wkUser = Object.assign({}, user_list[i]) as UserModel;
                    wkUserList.push(wkUser);
                }
                return wkUserList;
            }
            let wkUserList: UserModel[] = [];
            for (let i in user_list) {
                let user = user_list[i];
                // フォロー中判定
                let following = false;
                if (
                    refFollowUserList != null &&
                    refFollowUserList.current != null
                ) {
                    for (let j in refFollowUserList.current) {
                        const followUser = refFollowUserList.current[j];
                        if (followUser.id === user.id) {
                            following = true;
                            break;
                        }
                    }
                    if (following) {
                        user.following = true;
                    } else {
                        user.following = false;
                    }
                }
                // ブロック中判定
                let blocking = false;
                if (
                    refBlockUserList != null &&
                    refBlockUserList.current != null
                ) {
                    for (let j in refBlockUserList.current) {
                        const blockUser = refBlockUserList.current[j];
                        if (blockUser.id === user.id) {
                            blocking = true;
                            break;
                        }
                    }
                    if (blocking) {
                        user.blocking = true;
                    } else {
                        user.blocking = false;
                    }
                }
                // 招待中判定
                let inviting = false;
                if (refInviteList != null && refInviteList.current != null) {
                    for (let j in refInviteList.current) {
                        const joinGroup = refInviteList.current[j];
                        if (joinGroup.userId === user.id) {
                            inviting = true;
                            break;
                        }
                    }
                    if (inviting) {
                        user.inviting = true;
                    } else {
                        user.inviting = false;
                    }
                }
                wkUserList.push(user);
            }
            return wkUserList;
        }
        /**
         * ユーザリスト取得
         * @param index
         */
        async function getUserList(index: number) {
            if (!hasNext.current) {
                return;
            }
            if (index === 0) {
                setUserList([]);
                refUserList.current = undefined;
                const success = loadHistory();
                if (success) {
                    return;
                }
            }
            if (isUnderProcess.current) {
                return;
            }
            isUnderProcess.current = true;
            await getUserListExecute(index);
            isUnderProcess.current = false;
        }
        /**
         * ユーザリスト取得実行
         * @param index
         * @returns
         */
        async function getUserListExecute(index: number) {
            if (!hasNext.current) {
                return;
            }
            let res = await UserRequest.list(0, index);
            if (res == null) {
                return;
            }
            let wkHasNext = false;
            if (res.hasNext != null) {
                wkHasNext = res.hasNext;
            }
            let wkReadSize = readSize.current;
            // 次レコード有無
            hasNext.current = res.hasNext;
            // 初回読み込み時
            if (index === 0) {
                // 読み込みサイズ
                if (res.readSize != null) {
                    wkReadSize = res.readSize;
                    readSize.current = res.readSize;
                }
            }
            // ユーザリスト
            if (res.users != null) {
                let wkUserList = processUserList(props.loginUser, res.users);
                if (index !== 0) {
                    wkUserList = lstUser.concat(wkUserList);
                }
                refUserList.current = wkUserList;
                setUserList(wkUserList);
                saveHistory(
                    index,
                    wkHasNext,
                    wkReadSize,
                    wkUserList,
                    true,
                    true
                );
            }
        }

        /**
         * 履歴からユーザリスト取得
         * @returns
         */
        function loadHistory(): boolean {
            const history = getUserListHistory(selector) as HistoryUserList;
            if (history == null) {
                return false;
            }
            try {
                const expiredTime = Number(history.expiredTime);
                const now = new Date().getTime() / 1000;
                // セッションデータが10分経過してる場合は取得し直し
                if (isNaN(expiredTime) || now - expiredTime > 0) {
                    return false;
                }
            } catch (e) {
                console.error(e);
                return false;
            }
            currentIndex.current = Number(history.index);
            hasNext.current = Boolean(history.hasNext);
            readSize.current = Number(history.readSize);
            let wkUserList = history.list;
            if (wkUserList.length > 0) {
                wkUserList = processUserList(props.loginUser, wkUserList);
                setUserList(wkUserList);
                refUserList.current = wkUserList;
                const scrollPos = Number(history.scrollPos);
                window.setTimeout(() => {
                    if (refContainer != null && refContainer.current != null) {
                        refContainer.current.scrollTo({
                            top: scrollPos,
                            behavior: "auto",
                        });
                    }
                }, 500);
            } else {
                return false;
            }
            return true;
        }
        /**
         * 履歴保存
         * @param saveScrollPos
         */
        async function saveHistory(
            _index: number,
            _hasNext: boolean,
            _readSize: number,
            _list: UserModel[],
            _refresh_expired_time: boolean,
            _saveScrollPos: boolean
        ) {
            let expiredTime: number | null =
                new Date().getTime() / 1000 +
                AppConstants.STORAGE_EXPIRE_TIME_USER_LIST;
            if (!_refresh_expired_time) {
                expiredTime = getExpiredTime();
                if (expiredTime == null) {
                    expiredTime =
                        new Date().getTime() / 1000 +
                        AppConstants.STORAGE_EXPIRE_TIME_USER_LIST;
                }
            }
            let history = new HistoryUserList();
            history.expiredTime = expiredTime;
            history.index = _index;
            history.hasNext = _hasNext;
            history.list = _list;
            history.readSize = _readSize;
            history.scrollPos =
                _saveScrollPos === false ? 0 : getScrollPosition();
            dispatch(HistoryAction({ userListHistory: history }));
        }

        /**
         * 有効期限取得
         * @returns
         */
        function getExpiredTime(): number | null {
            const history = getUserListHistory(selector);
            if (history == null || history.userListHistory == null) {
                return null;
            }
            const historyUserList = history.userListHistory as HistoryUserList;
            try {
                const expiredTime = Number(historyUserList.expiredTime);
                return expiredTime;
            } catch (e) {
                return null;
            }
        }

        /**
         * ユーザHtml取得
         * @param user
         * @returns
         */
        function getItemWrapperHtml(user: UserModel) {
            if (props.loginUser == null || user.id === props.loginUser.id) {
                return (
                    <div
                        className="list-item-wrapper"
                        key={user.id}
                        onClick={(e) => {
                            if (e.nativeEvent.buttons === 0) {
                                onClickUser(user);
                            }
                        }}
                    >
                        {langCd != null && timeDifference != null && (
                            <UserListItem
                                user={user}
                                langCd={langCd}
                                timeDifference={timeDifference}
                            />
                        )}
                    </div>
                );
            } else {
                if (isSizeXS) {
                    return (
                        <div
                            className="list-item-wrapper"
                            key={user.id}
                            onClick={(e) => {
                                if (e.nativeEvent.buttons === 0) {
                                    onClickUser(user);
                                }
                            }}
                            onTouchStart={() => {
                                onMouseDownHandler(user);
                            }}
                            {...onLongPressBind(user)}
                        >
                            {langCd != null && timeDifference != null && (
                                <UserListItem
                                    user={user}
                                    langCd={langCd}
                                    timeDifference={timeDifference}
                                />
                            )}
                        </div>
                    );
                } else {
                    return (
                        <div
                            className="list-item-wrapper"
                            key={user.id}
                            onClick={(e) => {
                                if (e.nativeEvent.buttons === 0) {
                                    onClickUser(user);
                                }
                            }}
                            onMouseDown={(e) => {
                                if (e.nativeEvent.buttons === 1) {
                                    onMouseDownHandler(user);
                                }
                            }}
                            onTouchStart={() => {
                                onMouseDownHandler(user);
                            }}
                            onContextMenu={(e) => {
                                e.preventDefault();
                                onOpenContextMenu(e, user);
                            }}
                        >
                            {langCd != null && timeDifference != null && (
                                <UserListItem
                                    user={user}
                                    langCd={langCd}
                                    timeDifference={timeDifference}
                                />
                            )}
                        </div>
                    );
                }
            }
        }

        /**
         * レンダリング
         */
        return (
            <Box className="pageWrapper UserList" ref={refContainer}>
                <Box className="list-container">
                    {lstUser.map((user: UserModel) => {
                        if (
                            user.name != null &&
                            user.name.length > 0 &&
                            !user.blocking
                        ) {
                            return (
                                <React.Fragment key={user.id}>
                                    {getItemWrapperHtml(user)}
                                    {contextMenu != null &&
                                        selectedUser != null &&
                                        selectedUser.id === user.id && (
                                            <Menu
                                                sx={{ zIndex: 10 }}
                                                open={
                                                    selectedUser.id === user.id
                                                }
                                                onClose={onCloseContextMenu}
                                                anchorReference="anchorPosition"
                                                anchorPosition={
                                                    contextMenu !== null
                                                        ? {
                                                              top: contextMenu.mouseY,
                                                              left: contextMenu.mouseX,
                                                          }
                                                        : undefined
                                                }
                                            >
                                                <MenuItem
                                                    className="text-danger"
                                                    onClick={() => {
                                                        onBlock(user);
                                                    }}
                                                >
                                                    <FormattedMessage id="btn_block" />
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={onCloseContextMenu}
                                                >
                                                    <FormattedMessage id="btn_cancel" />
                                                </MenuItem>
                                            </Menu>
                                        )}
                                </React.Fragment>
                            );
                        } else {
                            return (
                                <React.Fragment key={user.id}></React.Fragment>
                            );
                        }
                    })}
                    <div className="list-item-wrapper"></div>
                    <div className="list-item-wrapper"></div>
                    <div className="list-item-wrapper"></div>
                    <div className="list-item-wrapper"></div>
                    <div className="list-item-wrapper"></div>
                </Box>
                <Zoom in={trigger}>
                    <div className="scroll-to-top" role="presentation">
                        <Fab
                            onClick={scrollToTop}
                            color="primary"
                            size="small"
                            aria-label="scroll back to top"
                        >
                            <KeyboardArrowUp />
                        </Fab>
                    </div>
                </Zoom>
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default UserList;
