import React from "react";
import {
    Box,
    Alert,
    FormControl,
    OutlinedInput,
    Button,
    TextField,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ThreadRequest } from "api/requests/Requests";
import { CategoryModel, UserModel } from "models/Models";
import { useDispatch } from "react-redux";
import { NavBar } from "components/Components";
import { ProcessAction } from "redux/Actions";
import "styles/components/ThreadPostComponent.scss";

type Props = {
    loginUser: UserModel | null;
    category: CategoryModel;
    langCd: string;
};

export const ThreadPostComponent: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ ThreadPostComponent IN");
        /***** 定数、変数 */
        const intl = useIntl();
        const navigate = useNavigate();
        const dispatch = useDispatch();

        /***** useRef */
        const isUnderProcessing = React.useRef<boolean>(false);
        const refBody = React.useRef<HTMLInputElement | null>(null);

        /***** useState */
        // タイトル
        const [title, setTitle] = React.useState<string>("");
        // 本文
        const [body, setBody] = React.useState<string>("");
        // エラーメッセージ
        const [errMessage, setErrMessage] = React.useState<string>("");
        // ボタンラベル
        const [btnPostLabel, setBtnPostLabel] = React.useState<string>("");
        // ボタン活性化フラグ
        const [btnPostDisabled, setBtnPostDisabled] =
            React.useState<boolean>(false);

        /**
         * useEffect
         */
        React.useEffect(() => {
            setPostButtonLabel("btn_create_thread");
            return () => {};
        }, [props.langCd]);

        /**
         * ログインボタンのラベル設定
         * @param labelId
         */
        const setPostButtonLabel = (labelId: string) => {
            const btnLabel = intl.formatMessage({
                id: labelId,
            });
            setBtnPostLabel(btnLabel);
        };

        /**
         * スレッド作成ボタンクリック時
         * @returns
         */
        const onClickCreateThread = async () => {
            if (validate()) {
                createThread();
            }
        };

        function validate(): boolean {
            if (title.trim().length === 0) {
                const errMessage = intl.formatMessage({
                    id: "err_empty_title",
                });
                setErrMessage(errMessage);
                return false;
            }
            if (body.trim().length === 0) {
                const errMessage = intl.formatMessage({
                    id: "err_empty_body",
                });
                setErrMessage(errMessage);
                return false;
            }
            return true;
        }
        /**
         * スレッド作成
         * @returns
         */
        async function createThread() {
            if (props.loginUser == null || props.category.id == null) {
                return;
            }
            if (isUnderProcessing.current) {
                return;
            }
            isUnderProcessing.current = true;

            setBtnPostDisabled(true);
            setPostButtonLabel("msg_processing");
            dispatch(
                ProcessAction({
                    processing: true,
                    message: "msg_processing",
                })
            );
            try {
                const res = await ThreadRequest.create(
                    props.category.id,
                    title,
                    body,
                    props.loginUser
                );
                if (res == null) {
                    setBtnPostDisabled(true);
                    setPostButtonLabel("msg_processing");
                    return;
                }
                if (res.rtnCd == null || res.rtnCd < 0) {
                    const errMessage = intl.formatMessage({
                        id: "err_failed_to_post_message_board",
                    });
                    setErrMessage(errMessage);
                    return;
                }
                setTimeout(function () {
                    navigate(-1);
                });
            } catch (e) {
            } finally {
                setBtnPostDisabled(false);
                setPostButtonLabel("btn_create_thread");
                dispatch(ProcessAction({ processing: false, message: "" }));
                isUnderProcessing.current = false;
            }
        }
        return (
            <Box className="component ThreadPostComponent">
                {/* ページタイトル */}
                <NavBar
                    showBack={true}
                    title={intl.formatMessage({ id: "title_thread_post" })}
                />
                {/* エラーメッセージエリア */}
                {errMessage.length > 0 && (
                    <Alert
                        className="error-message"
                        severity="error"
                        onClose={() => {
                            setErrMessage("");
                        }}
                    >
                        {errMessage}
                    </Alert>
                )}
                <h1 className="page-title">
                    {props.langCd === "ja" && (
                        props.category.name
                    )}
                    {props.langCd === "cn" && (
                        props.category.nameCn
                    )}
                    {props.langCd === "tw" && (
                        props.category.nameTw
                    )}
                    {props.langCd === "es" && (
                        props.category.nameEs
                    )}
                    {props.langCd === "de" && (
                        props.category.nameDe
                    )}
                    {props.langCd === "it" && (
                        props.category.nameIt
                    )}
                    {props.langCd === "ru" && (
                        props.category.nameRu
                    )}
                    {props.langCd !== "ja" &&
                    props.langCd !== "cn" &&
                    props.langCd !== "tw" &&
                    props.langCd !== "es" &&
                    props.langCd !== "de" &&
                    props.langCd !== "it" &&
                    props.langCd !== "ru" && (
                        props.category.nameEn
                    )}
                </h1>
                {
                    // 入力エリア
                }
                <form>
                    {
                        // タイトル
                    }
                    <FormControl className="title-area" variant="standard">
                        <label className="title-label" htmlFor="txtTitle">
                            <FormattedMessage id={"title"} />
                        </label>
                        <OutlinedInput
                            className="title-value"
                            fullWidth
                            id="txtTitle"
                            label=""
                            type="text"
                            inputProps={{
                                maxLength: 100,
                            }}
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    setTimeout(() => {
                                        if (
                                            refBody != null &&
                                            refBody.current != null
                                        ) {
                                            refBody.current.focus();
                                        }
                                    });
                                }
                            }}
                        />
                    </FormControl>
                    {
                        // 本文
                    }
                    <FormControl className="body-area" variant="standard">
                        <label className="body-label" htmlFor="txtBody">
                            <FormattedMessage id={"message"} />
                        </label>
                        <TextField
                            className="body-value"
                            fullWidth
                            variant="outlined"
                            id="txtBody"
                            label=""
                            type="text"
                            multiline={true}
                            maxRows={30}
                            minRows={6}
                            inputProps={{
                                maxLength: 2000,
                            }}
                            inputRef={(node) => {
                                refBody.current = node;
                            }}
                            value={body}
                            onChange={(e) => {
                                setBody(e.target.value);
                            }}
                        />
                    </FormControl>
                    {
                        // ボタンエリア
                    }
                    <div className="button-area">
                        <Button
                            className={
                                btnPostDisabled ? "app-button button disabled" : "app-button button"
                            }
                            variant="text"
                            disabled={btnPostDisabled}
                            onClick={onClickCreateThread}
                        >
                            {btnPostLabel}
                        </Button>
                    </div>
                </form>
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.category !== nextProps.category) {
            return false;
        }
        if (prevProps.category != null && nextProps.category != null) {
            if (prevProps.category.id !== nextProps.category.id) {
                return false;
            }
        }
        if (prevProps.langCd !== nextProps.langCd) {
            return false;
        }

        return true;
    }
);
