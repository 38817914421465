import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { UserModel } from "models/Models";
import { SelectedItemAction } from "redux/Actions";
import ReceivedParentMail from "./ReceivedParentMail";
import SentParentMail from "./SentParentMail";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/mail/Mail.scss";

type Props = {
    loginUser: UserModel;
};

const Mail: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ Mail");
        /***** 定数、変数 */
        const intl = useIntl();
        const dispatch = useDispatch();

        /***** useState */
        const [selectedTab, setSelectedTab] = React.useState(0);
        const [activateTab, setActivateTab] = React.useState(false);

        /***** useEffect */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "メール",
                path: window.location.pathname,
            });

            const hash = window.location.hash;
            if (hash == null || hash.length === 0) {
                window.location.hash = "#inbox";
            } else if (hash === "#outbox") {
                setSelectedTab(1);
            }
            setTimeout(() => {
                setActivateTab(true);
            }, 500);
            dispatch(
                SelectedItemAction({
                    lstUser: null,
                })
            );
        }, []);

        function handleTabChange(
            event: React.SyntheticEvent,
            newValue: number
        ) {
            if (newValue === 0) {
                window.location.hash = "#inbox";
            } else if (newValue === 1) {
                window.location.hash = "#outbox";
            }
            setSelectedTab(newValue);
        }

        /**
         * レンダリング
         */
        return (
            <Box className="pageWrapper Mail">
                {props.loginUser != null && (
                    <>
                        <div className="tab-area">
                            {activateTab && (
                                <Tabs
                                    className="tab-wrapper"
                                    value={selectedTab}
                                    onChange={handleTabChange}
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "transparent",
                                        },
                                    }}
                                >
                                    <Tab
                                        className={
                                            selectedTab === 0
                                                ? "tab selected"
                                                : "tab"
                                        }
                                        id="simple-tab-0"
                                        aria-controls="simple-tabpanel-0"
                                        label={intl.formatMessage({
                                            id: "inbox",
                                        })}
                                        value={0}
                                    />
                                    <Tab
                                        className={
                                            selectedTab === 1
                                                ? "tab selected"
                                                : "tab"
                                        }
                                        id="simple-tab-1"
                                        aria-controls="simple-tabpanel-1"
                                        label={intl.formatMessage({
                                            id: "outbox",
                                        })}
                                        value={1}
                                    />
                                </Tabs>
                            )}
                        </div>
                        <div
                            className={
                                selectedTab === 0 ? "tab-panel inbox" : "d-none"
                            }
                            role="tabpanel"
                            id={"simple-tabpanel-0"}
                            aria-labelledby={"simple-tab-0"}
                        >
                            <ReceivedParentMail 
                                loginUser={props.loginUser}
                                selectedTab={selectedTab}
                            />
                        </div>
                        <div
                            className={
                                selectedTab === 1
                                    ? "tab-panel outbox"
                                    : "d-none"
                            }
                            role="tabpanel"
                            id={"simple-tabpanel-1"}
                            aria-labelledby={"simple-tab-1"}
                        >
                            <SentParentMail
                                loginUser={props.loginUser} 
                                selectedTab={selectedTab}
                            />
                        </div>
                    </>
                )}
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }

        return true;
    }
);

export default Mail;
