import React from "react";
import {
    Alert,
    FormControl,
    OutlinedInput,
    Button,
    TextField,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { ReportRequest } from "api/requests/Requests";
import { UserModel } from "models/Models";
import { useDispatch } from "react-redux";
import { ProcessAction } from "redux/Actions";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/report/Report.scss";

type Props = {
    loginUser: UserModel | null;
};

const Report: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ Report IN");
        /***** 定数、変数 */
        const intl = useIntl();
        const dispatch = useDispatch();

        /***** useRef */
        const isUnderProcessing = React.useRef<boolean>(false);

        /***** useState */
        // タイトル
        const [title, setTitle] = React.useState<string>("");
        // 本文
        const [body, setBody] = React.useState<string>("");
        // エラーメッセージ
        const [errorMessage, setErrorMessage] = React.useState<string>("");
        // 成功メッセージ
        const [successMessage, setSuccessMessage] = React.useState<string>("");
        // ボタンラベル
        const [btnPostLabel, setBtnPostLabel] = React.useState<string>("");
        // ボタン活性化フラグ
        const [btnPostDisabled, setBtnPostDisabled] =
            React.useState<boolean>(false);

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "レポート",
                path: window.location.pathname,
            });

            setPostButtonLabel("btn_send");
            return () => {};
        }, []);

        /**
         * ログインボタンのラベル設定
         * @param labelId
         */
        const setPostButtonLabel = (labelId: string) => {
            const btnLabel = intl.formatMessage({
                id: labelId,
            });
            setBtnPostLabel(btnLabel);
        };

        /**
         * 投稿ボタンクリック時
         * @returns
         */
        const onClickPost = async () => {
            if (validate()) {
                post();
            }
        };

        function validate(): boolean {
            if (title.trim().length === 0) {
                const errorMessage = intl.formatMessage({
                    id: "err_empty_title",
                });
                setErrorMessage(errorMessage);
                return false;
            }
            if (body.trim().length === 0) {
                const errorMessage = intl.formatMessage({
                    id: "err_empty_body",
                });
                setErrorMessage(errorMessage);
                return false;
            }
            return true;
        }
        /**
         * 投稿
         * @returns
         */
        async function post() {
            if (isUnderProcessing.current) {
                return;
            }
            isUnderProcessing.current = true;

            setBtnPostDisabled(true);
            setPostButtonLabel("msg_processing");
            dispatch(
                ProcessAction({
                    processing: true,
                    message: "msg_processing",
                })
            );
            let userId = 0;
            if (props.loginUser != null && props.loginUser.id != null) {
                userId = props.loginUser.id;
            }
            const res = await ReportRequest.send(userId, title, body);
            try {
                if (res == null || res.rtnCd == null || res.rtnCd < 0) {
                    const errorMessage = intl.formatMessage({
                        id: "err_failed_to_send",
                    });
                    setErrorMessage(errorMessage);
                    return;
                }
                const successMessage = intl.formatMessage({
                    id: "msg_complete_send",
                });
                setSuccessMessage(successMessage);
                setTitle("");
                setBody("");
            } catch (e) {
            } finally {
                setBtnPostDisabled(false);
                setPostButtonLabel("btn_send");
                dispatch(ProcessAction({ processing: false, message: "" }));
                isUnderProcessing.current = false;
            }
        }

        return (
            <div className="pageWrapper report">
                {
                    // エラーメッセージエリア
                }
                {errorMessage.length > 0 && (
                    <Alert
                        className="alert"
                        severity="error"
                        onClose={() => {
                            setErrorMessage("");
                        }}
                    >
                        {errorMessage}
                    </Alert>
                )}
                {
                    // サクセスメッセージエリア
                }
                {successMessage.length > 0 && (
                    <Alert
                        className="alert"
                        severity="success"
                        onClose={() => {
                            setSuccessMessage("");
                        }}
                    >
                        {successMessage}
                    </Alert>
                )}
                {
                    // 入力エリア
                }
                <form>
                    {
                        // ガイド文言
                    }
                    <div className="guide">
                        <FormattedMessage id={"guide_request"} />
                    </div>
                    {
                        // タイトル
                    }
                    <FormControl className="title-area" variant="standard">
                        <label className="title-label" htmlFor="txtTitle">
                            <FormattedMessage id={"title"} />
                        </label>
                        <OutlinedInput
                            className="title-value"
                            fullWidth
                            id="txtTitle"
                            label=""
                            type="text"
                            inputProps={{
                                maxLength: 100,
                            }}
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                        />
                    </FormControl>
                    {
                        // 本文
                    }
                    <FormControl className="body-area" variant="standard">
                        <label className="body-label" htmlFor="txtBody">
                            <FormattedMessage id={"message"} />
                        </label>
                        <TextField
                            className="body-value"
                            fullWidth
                            variant="outlined"
                            id="txtBody"
                            label=""
                            type="text"
                            multiline={true}
                            maxRows={50}
                            minRows={12}
                            inputProps={{
                                maxLength: 2000,
                            }}
                            value={body}
                            onChange={(e) => {
                                setBody(e.target.value);
                            }}
                        />
                    </FormControl>
                    {
                        // ボタンエリア
                    }
                    <div className="button-area">
                        <Button
                            className={
                                btnPostDisabled ? "app-button button disabled" : "app-button button"
                            }
                            variant="text"
                            disabled={btnPostDisabled}
                            onClick={onClickPost}
                        >
                            {btnPostLabel}
                        </Button>
                    </div>
                </form>
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default Report;
