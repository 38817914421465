import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Box, Divider, Button } from "@mui/material";
import { UserModel, MailModel, ParentMailModel } from "models/Models";
import { AppConstants } from "constants/Constants";
import { AttachedFileListComponent } from "components/Components";
import Utility from "utils/Utility";

type Props = {
    loginUser: UserModel | null;
    parentMail: ParentMailModel | undefined;
    mail: MailModel;
    sender: UserModel | undefined;
    receiver: UserModel | undefined;
    timeDifference: number;
    langCd: string;
    onRead: () => void;
    onDelete: () => void;
};

export const ReceivedChildMailListItem: React.FC<Props> = (props) => {
    // Utility.log("===== ReceivedChildMailListItem IN");
    /***** 定数、変数 */

    /***** useState */
    const [createdAt, setCreatedAt] = React.useState<string>("");

    React.useEffect(() => {
        if (
            props.mail != null &&
            props.mail.createdAt != null &&
            props.timeDifference != null &&
            props.langCd != null
        ) {
            const unixTime = new Date(props.mail.createdAt).getTime() / 1000;
            const wkCreatedAt = Utility.getFormattedDateTime2(
                unixTime,
                props.timeDifference,
                props.langCd
            );
            setCreatedAt(wkCreatedAt);
        }
    }, [
        props.langCd,
        props.mail,
        props.timeDifference,
    ]);

    /**
     * メールメッセージ組み立て
     * @returns
     */
    function getMailMessage(): string {
        if (props.mail == null || props.mail.message == null) {
            return "";
        }
        let message = Utility.escape(props.mail.message).replaceAll(
            "\n",
            "<br />"
        );
        const linkInfo = props.mail.linkInfo2;
        if (linkInfo == null) {
            return message;
        } else {
            const userId = linkInfo.userId;
            const userName = linkInfo.userName;
            const groupId = linkInfo.groupId;
            const groupName = linkInfo.groupName;
            const urlList = linkInfo.urlList;

            // ユーザ名置換
            if (
                userName != null &&
                userName.length > 0 &&
                userId != null &&
                userId > 0
            ) {
                message = message.replaceAll(
                    "[" + userName + "]",
                    `[<a target="_blank" href="/users/012-39111508-${userId}/profile">${userName}</a>]`
                );
            }
            // グループ名置換
            if (
                groupName != null &&
                groupName.length > 0 &&
                groupId != null &&
                groupId > 0
            ) {
                message = message.replaceAll(
                    "[" + groupName + "]",
                    `[<a target="_blank" href="/groups/${groupId}">${groupName}</a>]`
                );
            }
            // URL置換
            if (urlList != null && urlList.length > 0) {
                const lstUrl = urlList.split(",");
                for (let i = 0; i < lstUrl.length; i++) {
                    let url = lstUrl[i];
                    message = message.replaceAll(
                        url,
                        `<a href="${url}" target="_blank">${url}</a>`
                    );
                }
            }
            return message;
        }
    }
    /**
     * レンダー
     */
    return (
        <>
            {props.loginUser != null &&
                props.parentMail != null &&
                props.mail.sender != null &&
                props.mail.receiver != null && (
                    <Box
                        className="component receivedChildMailListItem"
                        sx={{
                            position: "relative",
                            width: "100%",
                            // width: {
                            //     xs: "90%",
                            //     sm: "80%",
                            // },
                            height: "auto",
                            // minHeight: "100%",
                            margin: "0 auto",
                            padding: {
                                xs: "5px",
                                sm: "10px",
                            },
                            backgroundColor:
                                props.loginUser.id !== props.mail.receiverId
                                    ? "#dddddd"
                                    : props.mail.read === 1
                                    ? "#ffffff"
                                    : AppConstants.BG_COLOR_UNREAD,
                            boxSizing: "border-box",
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            {
                                // 送信者名、作成日時
                            }
                            <Box
                                sx={{
                                    position: "relative",
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                {props.sender != null && (
                                    <>
                                        <Box
                                            sx={{
                                                fontWeight: "bold",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                color: "#000000",
                                            }}
                                        >
                                            {props.sender.id !== 1 && (
                                                <>
                                                    <span>From:&nbsp;</span>
                                                    <Link to={`/users/${Utility.getUserKey(props.sender)}/profile`}>
                                                        {props.mail.sender.name}
                                                    </Link>
                                                </>
                                            )}
                                            {props.sender.id === 1 && (
                                                <>
                                                    <span>From:&nbsp;</span>
                                                    {props.sender.name}
                                                </>
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                fontSize: "0.8rem",
                                                color: AppConstants.COLOR_GRAY_DATE,
                                            }}
                                        >
                                            {createdAt}
                                        </Box>
                                    </>
                                )}
                            </Box>
                            {
                                // 受信者名
                            }
                            <Box
                                sx={{
                                    textAlign: "left",
                                }}
                            >
                                To:{" " + props.mail.receiver.name}
                            </Box>
                            {
                                // タイトル
                            }
                            <Box
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: "1.2rem",
                                    textAlign: "left",
                                    wordBreak: "break-all",
                                }}
                            >
                                {props.mail.title}
                            </Box>
                            <Divider
                                sx={{
                                    width: "100%",
                                    marginBottom: {
                                        xs: "5px",
                                        sm: "10px",
                                    },
                                    opacity: "1.0",
                                    backgroundColor: "rgba(0,0,0,1.0)",
                                }}
                            />
                            {
                                // 本文
                            }
                            <Box
                                sx={{
                                    textAlign: "left",
                                    wordBreak: "break-all",
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: getMailMessage(),
                                }}
                            ></Box>
                            {
                                // 添付ファイル
                            }
                            {props.mail.existsAttachedFiles === 1 &&
                                props.mail.lstAttachedFile != null && (
                                    <AttachedFileListComponent
                                        loginUser={props.loginUser}
                                        lstAttachedFile={
                                            props.mail.lstAttachedFile
                                        }
                                        needDummy={true}
                                    />
                                )}
                            {
                                // ボタンエリア
                            }
                            {props.loginUser.id === props.mail.receiverId && (
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        margin: {
                                            xs: "10px 0 5px 0",
                                            sm: "20px 0 10px 0",
                                        },
                                    }}
                                >
                                    <Button
                                        variant="text"
                                        sx={{
                                            backgroundColor:
                                                AppConstants.BTN_DELETE_BG_COLOR,
                                            color: "#ffffff",
                                            "&:hover": {
                                                backgroundColor:
                                                    AppConstants.BTN_DELETE_BG_COLOR_HOVER,
                                            },
                                        }}
                                        onClick={props.onDelete}
                                    >
                                        <FormattedMessage id={"btn_delete"} />
                                    </Button>
                                    {props.loginUser.id ===
                                        props.mail.receiverId &&
                                        props.mail.read !== 1 && (
                                            <Button
                                                variant="text"
                                                sx={{
                                                    backgroundColor:
                                                        AppConstants.BTN_READ_BG_COLOR_INBOX,
                                                    color: "#ffffff",
                                                    "&:hover": {
                                                        backgroundColor:
                                                            AppConstants.BTN_READ_BG_COLOR_HOVER_INBOX,
                                                    },
                                                }}
                                                onClick={props.onRead}
                                            >
                                                <FormattedMessage
                                                    id={"btn_read"}
                                                />
                                            </Button>
                                        )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
        </>
    );
};
