import React from "react";
import Draggable from "react-draggable";
import { Box, Dialog, DialogContent, DialogTitle, Paper } from "@mui/material";
import ReactPlayer from "react-player";
import { PaperProps } from "@mui/material/Paper";
import { UserModel, GalleryModel, AttachedFileModel } from "models/Models";
import { AppConstants } from "constants/Constants";
import CloseButton from "assets/images/cross_mark.png";
import Utility from "utils/Utility";

type Props = {
    user: UserModel | null;
    gallery?: GalleryModel;
    attachedFile?: AttachedFileModel;
    partnerId?: number;
    file?: File;
    onClose: () => void;
    open: boolean;
};

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper
                {...props}
                style={{
                    margin: 0,
                }}
            />
        </Draggable>
    );
}

export const VideoComponent: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("===== VideoComponent");
        /***** 定数、変数 */

        /***** useState */
        // URL
        const [url, setUrl] = React.useState<string>();
        // 再生中フラグ
        const [isPlaying, setIsPlaying] = React.useState<boolean>(false);

        /***** useRef */
        const videoRef = React.useRef<ReactPlayer>(null);

        React.useEffect(() => {
            // const video = videoRef.current;
            if (props.open) {
                setVideoUrl();
                // onOpenDialog();
            } else {
                onCloseDialog();
            }
        }, [props.open]);
        React.useEffect(() => {
            onOpenDialog();
        }, [videoRef, videoRef.current]);

        /**
         * 本ダイアログを表示
         */
        function onOpenDialog() {
            if (videoRef != null && videoRef.current != null) {
            }
        }
        /**
         * 本ダイアログを閉じる
         */
        function onCloseDialog() {
            props.onClose();
            if (videoRef != null && videoRef.current != null) {
                // videoRef.current.pause();
            }
            setIsPlaying(false);
        }
        /**
         * 動画URLをセット
         */
        async function setVideoUrl() {
            const url = getVideoUrl();
            if (url != null) {
                setUrl(url);
            }
        }

        function onStart() {
            setIsPlaying(true);
        }
        function onPlay() {
            setIsPlaying(true);
        }
        function onPause() {
            setIsPlaying(false);
        }
        function onEnded() {
            setIsPlaying(false);
        }
        function onError() {
            setIsPlaying(false);
        }

        /**
         * 動画URL取得
         * @returns
         */
        function getVideoUrl() {
            const attachedFile = props.attachedFile;
            const gallery = props.gallery;
            const file = props.file;
            if (gallery !== undefined) {
                return Utility.getGalleryOriginalUrl(gallery);
            } else if (attachedFile !== undefined) {
                return Utility.getAttachedFileOriginalUrl(
                    attachedFile,
                    props.partnerId
                );
            } else if (file != null) {
                return window.URL.createObjectURL(file);
            }
        }

        /**
         * レンダー
         */
        return (
            <Box
                className="component videoComponent"
                sx={{
                    display: props.open ? "block" : "none",
                    position: "fixed",
                    top: AppConstants.HEADER_HEIGHT,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: {
                        xs: "100vw",
                        md: "70vw",
                    },
                    height: {
                        xs: `calc(100vh - ${AppConstants.HEADER_HEIGHT} - ${AppConstants.FOOTER_HEIGHT})`,
                        md: "auto",
                    },
                    margin: {
                        xs: 0,
                        md: "auto",
                    },
                    zIndex: 2000,
                    overflow: "auto",
                }}
            >
                {((props.gallery != null && props.gallery.fileName != null) ||
                    (props.attachedFile != null &&
                        props.attachedFile.fileName != null) ||
                    props.file != null) && (
                    <>
                        {
                            // スマホ用
                        }
                        <Box
                            component="div"
                            sx={{
                                position: "relative",
                                width: "100%",
                                height: "100%",
                                display: {
                                    xs: "flex",
                                    md: "none",
                                },
                                flexDirection: "column",
                            }}
                        >
                            <Box
                                component="div"
                                sx={{
                                    position: "fixed",
                                    top: AppConstants.HEADER_HEIGHT,
                                    left: 0,
                                    width: "100%",
                                    height: AppConstants.NAVBAR_HEIGHT,
                                    lineHeight: AppConstants.NAVBAR_HEIGHT,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "1.2rem",
                                    color: "#FFFFFF",
                                    backgroundColor: "#000000",
                                    paddinx: 0,
                                    overflow: "hidden",
                                    zIndex: 2010,
                                }}
                                onClick={() => {
                                    if (
                                        videoRef != null &&
                                        videoRef.current != null
                                    ) {
                                        setIsPlaying(false);
                                    }
                                    onCloseDialog();
                                }}
                            >
                                CLOSE
                            </Box>
                            <div
                                className="player-wrapper"
                                style={{
                                    position: "relative",
                                    marginTop: AppConstants.NAVBAR_HEIGHT,
                                    width: "100%",
                                    height: "100%",
                                    flexGrow: 1,
                                    flexShrink: 1,
                                    backgroundColor: "#000000",
                                }}
                            >
                                <ReactPlayer
                                    ref={videoRef}
                                    playing={isPlaying}
                                    className="react-player"
                                    controls={true}
                                    url={url}
                                    width="100%"
                                    height="100%"
                                    style={{
                                        backgroundColor: "#000000",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                    }}
                                    onStart={onStart}
                                    onPlay={onPlay}
                                    onPause={onPause}
                                    onEnded={onEnded}
                                    onError={onError}
                                />
                            </div>
                        </Box>
                        {
                            // スマホ以外用
                        }
                        <Dialog
                            className="dialog VideoDialog"
                            open={props.open}
                            onClose={() => {
                                onCloseDialog();
                            }}
                            PaperProps={{
                                sx: {
                                    // maxWidth: "none",
                                    maxWidth: {
                                        xs: "none",
                                    }
                                },
                            }}
                            PaperComponent={PaperComponent}
                            aria-labelledby="draggable-dialog-title"
                            sx={{
                                width: "100%",
                                height: "100%",
                                display: {
                                    xs: "none",
                                    md: "block",
                                },
                                zIndex: 2000,
                            }}
                        >
                            <DialogTitle
                                id="draggable-dialog-title"
                                className="dialog-title"
                                style={{
                                    cursor: "move",
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    padding: 0,
                                }}
                            >
                                <Box
                                    component="img"
                                    src={CloseButton}
                                    sx={{
                                        objectFit: "contain",
                                        width: "30px",
                                        height: "30px",
                                        marginRight: "10px",
                                        cursor: "default",
                                    }}
                                    onClick={() => {
                                        onCloseDialog();
                                    }}
                                />
                            </DialogTitle>
                            <DialogContent
                                style={{
                                    padding: 0,
                                    position: "relative",
                                    lineHeight: 0,
                                    textAlign: "center",
                                    backgroundColor: "#000000",
                                    height: "70vh",
                                    width: "100%",
                                    maxWidth: "800px",
                                }}
                            >
                                <ReactPlayer
                                    ref={videoRef}
                                    className="react-player"
                                    controls={true}
                                    url={url}
                                    width="100%"
                                    height="100%"
                                />
                            </DialogContent>
                        </Dialog>
                    </>
                )}
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.user !== nextProps.user) {
            return false;
        }
        if (prevProps.gallery !== nextProps.gallery) {
            return false;
        }
        if (prevProps.gallery != null && nextProps.gallery != null) {
            if (prevProps.gallery.id !== nextProps.gallery.id) {
                return false;
            }
        }
        if (prevProps.attachedFile !== nextProps.attachedFile) {
            return false;
        }
        if (prevProps.attachedFile != null && nextProps.attachedFile != null) {
            if (prevProps.attachedFile.id !== nextProps.attachedFile.id) {
                return false;
            }
        }
        if (prevProps.partnerId !== nextProps.partnerId) {
            return false;
        }
        if (prevProps.file !== nextProps.file) {
            return false;
        }
        if (prevProps.open !== nextProps.open) {
            return false;
        }
        if (prevProps.onClose !== nextProps.onClose) {
            return false;
        }

        return false;
    }
);
