import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { UserModel, CategoryModel } from "models/Models";
import { CategoryRequest } from "api/requests/Requests";
import {
    NavBar,
    CategoryListComponent,
    ThreadListComponent,
    ThreadPostComponent,
    ResponseListComponent,
} from "components/Components";
import { SelectedItemAction, CategoryListAction } from "redux/Actions";
import {
    getCategoryList,
    getLanguageCode,
    getTimeDifference,
} from "redux/Selectors";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/board/Board.scss";

type Props = {
    loginUser: UserModel | null;
};

const Board: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ Board IN");
        /***** 定数、変数 */
        const intl = useIntl();
        const dispatch = useDispatch();
        const selector = useSelector((state) => state);
        const navigate = useNavigate();
        const theme = useTheme();
        const isSizeXS = useMediaQuery(theme.breakpoints.down("sm"));
        const { categoryId } = useParams<{ categoryId: string }>();
        const { threadId } = useParams<{ threadId: string }>();
        const langCd = getLanguageCode(selector);

        /***** useRef */
        const refTimeDifference = React.useRef<number>(
            getTimeDifference(selector)
        );

        /***** useState */
        // 時差
        const [timeDifference, setTimeDifference] = React.useState<number>();
        // 読み込み完了フラグ
        const [loaded, setLoaded] = React.useState<boolean>(false);
        // カテゴリーリスト
        const [lstCategory, setCategoryList] =
            React.useState<CategoryModel[]>();
        // 選択カテゴリー
        const [selectedCategory, setSelectedCategory] =
            React.useState<CategoryModel>();

        const [showCategoryList, setShowCategoryList] =
            React.useState<boolean>(false);
        const [showThreadList, setShowThreadList] =
            React.useState<boolean>(false);
        const [showThreadPost, setShowThreadPost] =
            React.useState<boolean>(false);
        const [showResponseList, setShowResponseList] =
            React.useState<boolean>(false);

        /**
         * useEffect
         */
        React.useEffect(() => {
            init();
        });
        React.useEffect(() => {
            let lstCategory = getCategoryList(selector);
            if (lstCategory != null) {
                setCategoryList(lstCategory);
                setSelectedCategory(lstCategory[0]);
                setLoaded(true);
                return;
            }
            fetchCategoryList();
        }, []);
        React.useEffect(() => {
            // fetchCategoryList();
        }, [langCd]);
        React.useEffect(() => {
            if (refTimeDifference.current != null) {
                setTimeDifference(refTimeDifference.current);
            }
        }, [refTimeDifference.current]);
        React.useEffect(() => {
            if (categoryId != null) {
                if (lstCategory == null) {
                    return;
                }
                for (let i = 0; i < lstCategory.length; i++) {
                    const tmpCategory = lstCategory[i];
                    if (tmpCategory.id === Number(categoryId)) {
                        setSelectedCategory(tmpCategory);
                        break;
                    }
                }
            }
        }, [categoryId]);

        function init() {
            setShowCategoryList(false);
            setShowThreadList(false);
            setShowThreadPost(false);
            setShowResponseList(false);

            const pathname = window.location.pathname;
            if (!isSizeXS) {
                setShowCategoryList(true);
            }
            if (pathname === "/board") {
                if (isSizeXS) {
                    setShowCategoryList(true);
                } else {
                    navigate(`/board/1/thread/list`, { replace: true });
                    setShowThreadList(true);
                }
            } else if (pathname.indexOf("/thread/list") > -1) {
                setShowThreadList(true);
            } else if (pathname.indexOf("/thread/new") > -1) {
                setShowThreadPost(true);
            } else if (pathname.indexOf("/list") > -1) {
                if (categoryId != null && threadId != null) {
                    setShowResponseList(true);
                }
            }
        }

        /**
         * カテゴリークリック時
         * @param category
         */
        async function onClickCategory(category: CategoryModel) {
            if (category.id != null) {
                // dispatch(
                //     SelectedItemAction({ category: selectedCategory })
                // );
                // window.setTimeout(function () {
                //     navigate(`/board/${category.id}/list`);
                // });
                if (isSizeXS) {
                    dispatch(
                        SelectedItemAction({ category: selectedCategory })
                    );
                    window.setTimeout(function () {
                        navigate(`/board/${category.id}/thread/list`);
                    });
                } else {
                    dispatch(
                        SelectedItemAction({ category: selectedCategory })
                    );
                    window.setTimeout(function () {
                        navigate(`/board/${category.id}/thread/list`);
                    });
                }
            }
        }

        /**
         * カテゴリーリスト取得実行
         * @returns
         */
        async function fetchCategoryList() {
            const res = await CategoryRequest.getList();
            try {
                if (res == null || res.rtnCd == null || res.rtnCd < 0) {
                    throw new Error();
                }
                if (res.lstCategory == null) {
                    return;
                }
                const lstCategory = res.lstCategory;
                setCategoryList(lstCategory);
                if (lstCategory.length > 0) {
                    setSelectedCategory(lstCategory[0]);
                    dispatch(
                        CategoryListAction({
                            list: lstCategory,
                        })
                    );
                }
            } catch (e) {
            } finally {
                setLoaded(true);
            }
        }

        /**
         * レンダリング
         */
        return (
            <Box className="pageWrapper Board">
                {/* ページタイトル */}
                <NavBar
                    showBack={false}
                    title={intl.formatMessage({ id: "title_message_board" })}
                />
                {loaded && (
                    <div className="contents-wrapper">
                        {
                            // カテゴリー一覧
                        }
                        {langCd != null &&
                            showCategoryList &&
                            lstCategory != null && (
                                <div className="category-list-wrapper">
                                    <CategoryListComponent
                                        lstCategory={lstCategory}
                                        selectedCategory={selectedCategory}
                                        langCd={langCd}
                                        onClick={onClickCategory}
                                    />
                                </div>
                            )}
                        {
                            // スレッドリスト
                        }
                        {langCd != null &&
                            showThreadList &&
                            selectedCategory != null &&
                            selectedCategory.id != null && (
                                <div className="thread-list-wrapper">
                                    <ThreadListComponent
                                        loginUser={props.loginUser}
                                        category={selectedCategory}
                                        langCd={langCd}
                                    />
                                </div>
                            )}
                        {
                            // スレッド投稿
                        }
                        {langCd != null &&
                            showThreadPost &&
                            selectedCategory != null &&
                            selectedCategory.id != null && (
                                <div className="thread-post-wrapper">
                                    <ThreadPostComponent
                                        loginUser={props.loginUser}
                                        category={selectedCategory}
                                        langCd={langCd}
                                    />
                                </div>
                            )}
                        {
                            // レスポンスリスト
                        }
                        {langCd != null &&
                            timeDifference != null &&
                            showResponseList &&
                            selectedCategory != null &&
                            selectedCategory.id != null && (
                                <div className="response-list-wrapper">
                                    <ResponseListComponent
                                        loginUser={props.loginUser}
                                        category={selectedCategory}
                                        langCd={langCd}
                                        timeDifference={timeDifference}
                                    />
                                </div>
                            )}
                    </div>
                )}
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        const prevLoginUser = prevProps.loginUser;
        const nextLoginUser = nextProps.loginUser;
        if (prevLoginUser !== nextLoginUser) {
            return false;
        }
        return true;
    }
);

export default Board;
