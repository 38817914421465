import React from "react";
import { Box } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { UserModel, AttachedFileModel } from "models/Models";
import { ImageComponent, VideoComponent } from "components/Components";

import Utility from "utils/Utility";

type Props = {
    loginUser: UserModel | null;
    lstAttachedFile: AttachedFileModel[];
    needDummy: boolean | null;
    partnerId?: number | null;
    sx?: SxProps<Theme>;
};

export const AttachedFileListComponent: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("===== AttachedFileListComponent");
        /***** useState */
        const [selectedAttachedFile, setSelectedAttachedFile] =
            React.useState<AttachedFileModel>();
        const [showImage, setShowImage] = React.useState<boolean>(false);
        const [showVideo, setShowVideo] = React.useState<boolean>(false);

        /**
         * 画像クリック時
         * @param attachedFile
         * @returns
         */
        function onClickImage(attachedFile: AttachedFileModel) {
            if (attachedFile.fileName == null) {
                return;
            }
            setSelectedAttachedFile(attachedFile);
            setShowImage(true);
        }
        /**
         * 動画サムネイルクリック時
         * @param attachedFile
         * @returns
         */
        function onClickVideo(attachedFile: AttachedFileModel) {
            if (attachedFile.fileName == null) {
                return;
            }
            setSelectedAttachedFile(attachedFile);
            setShowVideo(true);
        }
        /**
         * 添付ファイルの受信者ID取得
         * @param attachedFile
         * @returns
         */
        function getRceeiverId(
            attachedFile: AttachedFileModel
        ): number | undefined {
            if (props.loginUser == null || props.loginUser.id == null) {
                return undefined;
            }
            if (attachedFile.userId === props.loginUser.id) {
                if (props.partnerId == null) {
                    return undefined;
                } else {
                    return props.partnerId;
                }
            } else {
                return props.loginUser.id;
            }
        }
        /**
         * インナーコンポーネント取得
         * @returns
         */
        const InnerContents = () => {
            if (props.loginUser == null || props.loginUser.id == null) {
                return <></>;
            }
            const loginUserId = props.loginUser.id;
            return (
                <>
                    {props.lstAttachedFile.map((attachedFile) => {
                        const fileName = attachedFile.fileName;
                        let isImage = true;
                        if (fileName != null) {
                            isImage = Utility.isImage(fileName);
                        }
                        let url = null;
                        if (attachedFile.userId === props.partnerId) {
                            url = Utility.getAttachedFileSampleUrl(
                                attachedFile,
                                loginUserId
                            );
                        } else {
                            url = Utility.getAttachedFileSampleUrl(
                                attachedFile,
                                props.partnerId
                            );
                        }
                        return (
                            <React.Fragment key={attachedFile.id}>
                                {fileName != null && url != null && (
                                    <>
                                        {
                                            // 画像の場合
                                        }
                                        {isImage && (
                                            <Box
                                                component="img"
                                                src={url}
                                                sx={{
                                                    objectFit: "contain",
                                                    width: {
                                                        sm: "45%",
                                                    },
                                                    maxWidth: {
                                                        xs: "80%",
                                                        sm: "250px",
                                                    },
                                                    maxHeight: "250px",
                                                    minWidth: {
                                                        xs: "50%",
                                                        sm: "200px",
                                                    },
                                                    minHeight: {
                                                        sm: "200px",
                                                    },
                                                    height: "auto",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    onClickImage(attachedFile);
                                                }}
                                            />
                                        )}
                                        {
                                            // 動画の場合
                                        }
                                        {!isImage && (
                                            <Box
                                                component="div"
                                                className="videoThumbnailWrapper"
                                                sx={{
                                                    position: "relative",
                                                    width: {
                                                        sm: "45%",
                                                    },
                                                    maxWidth: {
                                                        xs: "80%",
                                                        sm: "250px",
                                                    },
                                                    maxHeight: "100%",
                                                    minWidth: {
                                                        xs: "50%",
                                                        sm: "200px",
                                                    },
                                                    minHeight: {
                                                        sm: "200px",
                                                    },
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    onClickVideo(attachedFile);
                                                }}
                                            >
                                                <Box
                                                    component="img"
                                                    src={url}
                                                    sx={{
                                                        objectFit: "contain",
                                                        width: "100%",
                                                        height: "100%",
                                                        maxHeight: "250px",
                                                        minWidth: "200px",
                                                        minHeight: "200px",
                                                    }}
                                                />
                                                <Box
                                                    component="img"
                                                    src="/images/btn_play.png"
                                                    sx={{
                                                        position: "absolute",
                                                        top: 0,
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0,
                                                        margin: "auto",
                                                        width: "30%",
                                                        height: "30%",
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </>
                                )}
                            </React.Fragment>
                        );
                    })}
                    {props.needDummy && (
                        <>
                            <Box
                                className="dummy"
                                sx={{
                                    display: {
                                        xs: "none",
                                        sm: "block",
                                    },
                                    width: {
                                        xs: "100%",
                                        sm: "45%",
                                    },
                                    maxWidth: {
                                        sm: "250px",
                                    },
                                    height: "1px",
                                    flexGrow: 1,
                                    flexShrink: 1,
                                }}
                            />
                            <Box
                                className="dummy"
                                sx={{
                                    display: {
                                        xs: "none",
                                        sm: "block",
                                    },
                                    width: {
                                        xs: "100%",
                                        sm: "45%",
                                    },
                                    maxWidth: {
                                        sm: "250px",
                                    },
                                    height: "1px",
                                    flexGrow: 1,
                                    flexShrink: 1,
                                }}
                            />
                            <Box
                                className="dummy"
                                sx={{
                                    display: {
                                        xs: "none",
                                        sm: "block",
                                    },
                                    width: {
                                        xs: "100%",
                                        sm: "45%",
                                    },
                                    maxWidth: {
                                        sm: "250px",
                                    },
                                    height: "1px",
                                    flexGrow: 1,
                                    flexShrink: 1,
                                }}
                            />
                            <Box
                                className="dummy"
                                sx={{
                                    display: {
                                        xs: "none",
                                        sm: "block",
                                    },
                                    width: {
                                        xs: "100%",
                                        sm: "45%",
                                    },
                                    maxWidth: {
                                        sm: "250px",
                                    },
                                    height: "1px",
                                    flexGrow: 1,
                                    flexShrink: 1,
                                }}
                            />
                            <Box
                                className="dummy"
                                sx={{
                                    display: {
                                        xs: "none",
                                        sm: "block",
                                    },
                                    width: {
                                        xs: "100%",
                                        sm: "45%",
                                    },
                                    maxWidth: {
                                        sm: "250px",
                                    },
                                    height: "1px",
                                    flexGrow: 1,
                                    flexShrink: 1,
                                }}
                            />
                            <Box
                                className="dummy"
                                sx={{
                                    display: {
                                        xs: "none",
                                        sm: "block",
                                    },
                                    width: {
                                        xs: "100%",
                                        sm: "45%",
                                    },
                                    maxWidth: {
                                        sm: "250px",
                                    },
                                    height: "1px",
                                    flexGrow: 1,
                                    flexShrink: 1,
                                }}
                            />
                        </>
                    )}
                    {selectedAttachedFile != null &&
                        selectedAttachedFile.fileName != null &&
                        Utility.isImage(selectedAttachedFile.fileName) && (
                            <ImageComponent
                                user={props.loginUser}
                                attachedFile={selectedAttachedFile}
                                onClose={() => {
                                    setShowImage(false);
                                }}
                                open={showImage}
                                partnerId={getRceeiverId(selectedAttachedFile)}
                            />
                        )}
                    {selectedAttachedFile != null &&
                        selectedAttachedFile.fileName != null &&
                        !Utility.isImage(selectedAttachedFile.fileName) && (
                            <VideoComponent
                                user={props.loginUser}
                                attachedFile={selectedAttachedFile}
                                onClose={() => {
                                    setShowVideo(false);
                                }}
                                open={showVideo}
                                partnerId={getRceeiverId(selectedAttachedFile)}
                            />
                        )}
                </>
            );
        };
        /**
         * レンダー
         */
        return (
            <>
                {props.sx == null && (
                    <Box
                        className="component AttachedFileListComponent"
                        sx={{
                            position: "relative",
                            display: "flex",
                            flexDirection: {
                                xs: "column",
                                sm: "row",
                            },
                            flexWrap: "wrap",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            width: "100%",
                            columnGap: {
                                xs: 0,
                                sm: "20px",
                            },
                            rowGap: {
                                xs: "10px",
                                sm: "20px",
                            },
                            margin: {
                                xs: "10px 0",
                                sm: "20px 0",
                            },
                        }}
                    >
                        <InnerContents />
                    </Box>
                )}
                {props.sx != null && (
                    <Box sx={props.sx}>
                        <InnerContents />
                    </Box>
                )}
            </>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return true;
        }
        const prevAttachedFileList = prevProps.lstAttachedFile;
        const nextAttachedFileList = nextProps.lstAttachedFile;
        if (prevAttachedFileList != null && nextAttachedFileList != null) {
            if (prevAttachedFileList.length !== nextAttachedFileList.length) {
                return false;
            }
            for (let i = 0; i < prevAttachedFileList.length; i++) {
                for (let j = 0; j < nextAttachedFileList.length; j++) {
                    if (
                        prevAttachedFileList[i].id !==
                        nextAttachedFileList[j].id
                    ) {
                        return false;
                    }
                }
            }
        }
        if (prevProps.needDummy !== nextProps.needDummy) {
            return false;
        }
        if (prevProps.partnerId !== nextProps.partnerId) {
            return false;
        }
        if (prevProps.sx !== nextProps.sx) {
            return false;
        }
        return true;
    }
);
