import { CustomTagModel, TagModel } from "models/Models";
import { UrlConstants } from "constants/Constants";

export class UserModel {
    id: number | null = null;
    deviceType: number | null = null;
    majorVersion: number | null = null;
    minorVersion: number | null = null;
    buildNo: number | null = null;
    countryCd: string | null = null;
    languageCd: string | null = null;
    name: string | null = null;
    region: string | null = null;
    regionLatitude: number | null = null;
    regionLongitude: number | null = null;
    age: number | null = null;
    gender: number | null = null;
    profile: string | null = null;
    profileText: string | null = null;
    iconName: string | null = null;
    iconResult: number | null = null;
    tag: string | null = null;
    attribute: number | null = null;
    point: number | null = null;
    followUserCnt: number | null = null;
    followerCnt: number | null = null;
    notification: number | null = null;
    adOff: number | null = null;
    plan: number | null = null;
    provideLocation: number | null = null;
    deviceToken: string | null = null;
    online: number | null = null;
    background: number | null = null;
    invalid: number | null = null;
    admin: number | null = null;
    createdAt: string | null = null;
    updatedAt: string | null = null;

    lstSelectedTag: TagModel[] | null = null;
    lstCustomTag: CustomTagModel[] | null = null;
    following: boolean = false;
    blocking: boolean = false;
    inviting: boolean = false;
    // lstOwnGroup: GroupModel[] | null = null;
    // lstBlockUser: UserModel[] | null = null;
    // lstFollowUser: UserModel[] | null = null;
    bearerToken: string | null = null;
    profileImage: File | null = null;
    // lstInvite: JoinGroupModel[] | null = null;
    selected: boolean = false;
    tmpSelected: boolean = false;
    hidden: boolean = false;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // deviceType
        if (obj.hasOwnProperty("deviceType")) {
            this.deviceType = obj.deviceType;
        } else if (obj.hasOwnProperty("DeviceType")) {
            this.deviceType = obj.DeviceType;
        }
        // majorVersion
        if (obj.hasOwnProperty("majorVersion")) {
            this.majorVersion = obj.majorVersion;
        } else if (obj.hasOwnProperty("MajorVersion")) {
            this.majorVersion = obj.MajorVersion;
        }
        // minorVersion
        if (obj.hasOwnProperty("minorVersion")) {
            this.minorVersion = obj.minorVersion;
        } else if (obj.hasOwnProperty("MinorVersion")) {
            this.minorVersion = obj.MinorVersion;
        }
        // buildNo
        if (obj.hasOwnProperty("buildNo")) {
            this.buildNo = obj.buildNo;
        } else if (obj.hasOwnProperty("BuildNo")) {
            this.buildNo = obj.BuildNo;
        }
        // countryCd
        if (obj.hasOwnProperty("countryCd")) {
            this.countryCd = obj.countryCd;
        } else if (obj.hasOwnProperty("CountryCd")) {
            this.countryCd = obj.CountryCd;
        }
        // languageCd
        if (obj.hasOwnProperty("languageCd")) {
            this.languageCd = obj.languageCd;
        } else if (obj.hasOwnProperty("LanguageCd")) {
            this.languageCd = obj.LanguageCd;
        }
        // name
        if (obj.hasOwnProperty("name")) {
            this.name = obj.name;
        } else if (obj.hasOwnProperty("Name")) {
            this.name = obj.Name;
        }
        // region
        if (obj.hasOwnProperty("region")) {
            this.region = obj.region;
        } else if (obj.hasOwnProperty("Region")) {
            this.region = obj.Region;
        }
        // regionLatitude
        if (obj.hasOwnProperty("regionLatitude")) {
            this.regionLatitude = obj.regionLatitude;
        } else if (obj.hasOwnProperty("RegionLatitude")) {
            this.regionLatitude = obj.RegionLatitude;
        }
        // regionLongitude
        if (obj.hasOwnProperty("regionLongitude")) {
            this.regionLongitude = obj.regionLongitude;
        } else if (obj.hasOwnProperty("RegionLongitude")) {
            this.regionLongitude = obj.RegionLongitude;
        }
        // age
        if (obj.hasOwnProperty("age")) {
            this.age = obj.age;
        } else if (obj.hasOwnProperty("Age")) {
            this.age = obj.Age;
        }
        // gender
        if (obj.hasOwnProperty("gender")) {
            this.gender = obj.gender;
        } else if (obj.hasOwnProperty("Gender")) {
            this.gender = obj.Gender;
        }
        // profile
        if (obj.hasOwnProperty("profile")) {
            this.profile = obj.profile;
        } else if (obj.hasOwnProperty("Profile")) {
            this.profile = obj.Profile;
        }
        // profileText
        if (obj.hasOwnProperty("profileText")) {
            this.profileText = obj.profileText;
        } else if (obj.hasOwnProperty("ProfileText")) {
            this.profileText = obj.ProfileText;
        }
        // iconName
        if (obj.hasOwnProperty("iconName")) {
            this.iconName = obj.iconName;
        } else if (obj.hasOwnProperty("IconName")) {
            this.iconName = obj.IconName;
        }
        // iconResult
        if (obj.hasOwnProperty("iconResult")) {
            this.iconResult = obj.iconResult;
        } else if (obj.hasOwnProperty("IconResult")) {
            this.iconResult = obj.IconResult;
        }
        // tag
        if (obj.hasOwnProperty("tag")) {
            this.tag = obj.tag;
        } else if (obj.hasOwnProperty("Tag")) {
            this.tag = obj.Tag;
        }
        // attribute
        if (obj.hasOwnProperty("attribute")) {
            this.attribute = obj.attribute;
        } else if (obj.hasOwnProperty("Attribute")) {
            this.attribute = obj.Attribute;
        }
        // point
        if (obj.hasOwnProperty("point")) {
            this.point = obj.point;
        } else if (obj.hasOwnProperty("Point")) {
            this.point = obj.Point;
        }
        // followUserCnt
        if (obj.hasOwnProperty("followUserCnt")) {
            this.followUserCnt = obj.followUserCnt;
        } else if (obj.hasOwnProperty("FollowUserCnt")) {
            this.followUserCnt = obj.FollowUserCnt;
        }
        // followerCnt
        if (obj.hasOwnProperty("followerCnt")) {
            this.followerCnt = obj.followerCnt;
        } else if (obj.hasOwnProperty("FollowerCnt")) {
            this.followerCnt = obj.FollowerCnt;
        }
        // notification
        if (obj.hasOwnProperty("notification")) {
            this.notification = obj.notification;
        } else if (obj.hasOwnProperty("Notification")) {
            this.notification = obj.Notification;
        }
        // adOff
        if (obj.hasOwnProperty("adOff")) {
            this.adOff = obj.adOff;
        } else if (obj.hasOwnProperty("AdOff")) {
            this.adOff = obj.AdOff;
        }
        // plan
        if (obj.hasOwnProperty("plan")) {
            this.plan = obj.plan;
        } else if (obj.hasOwnProperty("Plan")) {
            this.plan = obj.Plan;
        }
        // provideLocation
        if (obj.hasOwnProperty("provideLocation")) {
            this.provideLocation = obj.provideLocation;
        } else if (obj.hasOwnProperty("ProvideLocation")) {
            this.provideLocation = obj.ProvideLocation;
        }
        // deviceToken
        if (obj.hasOwnProperty("deviceToken")) {
            this.deviceToken = obj.deviceToken;
        } else if (obj.hasOwnProperty("DeviceToken")) {
            this.deviceToken = obj.DeviceToken;
        }
        // online
        if (obj.hasOwnProperty("online")) {
            this.online = obj.online;
        } else if (obj.hasOwnProperty("Online")) {
            this.online = obj.Online;
        }
        // background
        if (obj.hasOwnProperty("background")) {
            this.background = obj.background;
        } else if (obj.hasOwnProperty("Background")) {
            this.background = obj.Background;
        }
        // invalid
        if (obj.hasOwnProperty("invalid")) {
            this.invalid = obj.invalid;
        } else if (obj.hasOwnProperty("Invalid")) {
            this.invalid = obj.Invalid;
        }
        // admin
        if (obj.hasOwnProperty("admin")) {
            this.admin = obj.admin;
        } else if (obj.hasOwnProperty("Admin")) {
            this.admin = obj.Admin;
        }
        // createdAt
        if (obj.hasOwnProperty("createdAt")) {
            this.createdAt = obj.createdAt;
        } else if (obj.hasOwnProperty("CreatedAt")) {
            this.createdAt = obj.CreatedAt;
        }
        // updatedAt
        if (obj.hasOwnProperty("updatedAt")) {
            this.updatedAt = obj.updatedAt;
        } else if (obj.hasOwnProperty("UpdatedAt")) {
            this.updatedAt = obj.UpdatedAt;
        }
        // lstSelectedTag
        if (obj.hasOwnProperty("lstSelectedTag")) {
            this.lstSelectedTag = obj.lstSelectedTag;
        } else if (obj.hasOwnProperty("LstSelectedTag")) {
            this.lstSelectedTag = obj.LstSelectedTag;
        }
        // lstCustomTag
        if (obj.hasOwnProperty("lstCustomTag")) {
            this.lstCustomTag = obj.lstCustomTag;
        } else if (obj.hasOwnProperty("LstCustomTag")) {
            this.lstCustomTag = obj.LstCustomTag;
        }
    }

    /**
     * プロフィール画像URL取得
     * @returns
     */
    getIconUrl(): string {
        if (this.iconName == null || this.iconName.length === 0) {
            return "/images/no_image.png";
        } else {
            return (
                UrlConstants.URL_S3_USER_ICON +
                this.iconName +
                "?" +
                new Date().getTime()
            );
        }
    }
}
