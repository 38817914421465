import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSentMailListHistory } from "redux/Selectors";
import { HistoryAction } from "redux/Actions";

import { UserModel, MailModel, HistorySentMailList } from "models/Models";
import { SentChildMailComponent } from "components/Components";

type Props = {
    loginUser: UserModel | null;
};

const SentChildMail: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ SentChildMail IN");
        /***** 定数、変数 */
        const selector = useSelector((state) => state);
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const { parentId } = useParams<{ parentId: string }>();

        /***** useState */
        const [selectedParentId, setSelectedParentId] =
            React.useState<string>("");

        /**
         * useEffect
         */
        React.useEffect(() => {
            if (parentId != null) {
                setSelectedParentId(parentId);
            }
        }, [parentId]);

        /**
         * 子メール削除
         * @returns
         */
        async function deleteMail(child_mail: MailModel) {
            if (props.loginUser == null || props.loginUser.id == null) {
                return;
            }
            if (parentId == null) {
                return;
            }
            if (child_mail.receiverId == null || child_mail.senderId == null) {
                return;
            }
            let history = getSentMailListHistory(
                selector
            ) as HistorySentMailList;
            if (history == null || history.list == null) {
                return;
            }
            let lstParentMail = history.list;
            let goBack = false;
            for (let i = 0; i < lstParentMail.length; i++) {
                let parentMail = lstParentMail[i];
                if (parentMail.id === parseInt(parentId)) {
                    if (props.loginUser.id === parentMail.receiverId) {
                        if (props.loginUser.id === child_mail.receiverId) {
                            if (
                                parentMail.receiverReceiveCnt != null &&
                                parentMail.receiverReceiveCnt > 0
                            ) {
                                parentMail.receiverReceiveCnt -= 1;
                            }
                            if (parentMail.receiverReceiveCnt === 0) {
                                parentMail.hidden = true;
                                goBack = true;
                            }
                        }
                    } else if (props.loginUser.id === parentMail.senderId) {
                        if (props.loginUser.id === child_mail.receiverId) {
                            if (
                                parentMail.senderReceiveCnt != null &&
                                parentMail.senderReceiveCnt > 0
                            ) {
                                parentMail.senderReceiveCnt -= 1;
                            }
                            if (parentMail.senderReceiveCnt === 0) {
                                parentMail.hidden = true;
                                goBack = true;
                            }
                        }
                    }
                }
            }
            dispatch(HistoryAction({ sentMailListHistory: history }));
            if (goBack) {
                window.setTimeout(() => {
                    navigate(-1);
                });
            }
        }
        /**
         * レンダリング
         */
        return (
            <>
                {props.loginUser != null && parentId != null && (
                    <SentChildMailComponent
                        loginUser={props.loginUser}
                        parentId={selectedParentId}
                        onDelete={(childMail: MailModel) =>
                            deleteMail(childMail)
                        }
                    />
                )}
            </>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default SentChildMail;
