import { DbConstants, AppConstants, UrlConstants } from "constants/Constants";
import {
    UserModel,
    GroupChatMemberData,
    AttachedFileModel,
    GalleryModel,
} from "models/Models";
import {
    ActionKeyRequest,
    MailRequest,
    TalkMessageRequest,
} from "api/requests/Requests";
import { MODE } from "constants/AppConstants";

export default class Utility {
    static log(message: any) {
        if (AppConstants.mode === MODE.DEVELOPMENT) {
            console.log(message);
        }
    }
    static error(error: unknown) {
        if (AppConstants.mode !== MODE.PRODUCTION) {
            console.error(error);
        }
    }
    /**
     * 属性ラベルのリソースキー取得
     * @param gender
     * @param attribute
     * @returns
     */
    static getAttributeLabelKey(user: UserModel): string {
        let label = "";
        const gender = user.gender;
        const attribute = user.attribute;
        if (attribute === DbConstants.ATTRIBUTE_SADO) {
            if (gender === DbConstants.GENDER_MALE) {
                label = "s_male";
            } else if (gender === DbConstants.GENDER_FEMALE) {
                label = "s_female";
            } else {
                label = "sado";
            }
        } else if (attribute === DbConstants.ATTRIBUTE_MASO) {
            if (gender === DbConstants.GENDER_MALE) {
                label = "m_male";
            } else if (gender === DbConstants.GENDER_FEMALE) {
                label = "m_female";
            } else {
                label = "maso";
            }
        }
        return label;
    }
    static getAttributeLabelKey2(gender: number, attribute: number): string {
        let label = "";
        if (attribute === DbConstants.ATTRIBUTE_SADO) {
            if (gender === DbConstants.GENDER_MALE) {
                label = "s_male";
            } else if (gender === DbConstants.GENDER_FEMALE) {
                label = "s_female";
            } else {
                label = "sado";
            }
        } else if (attribute === DbConstants.ATTRIBUTE_MASO) {
            if (gender === DbConstants.GENDER_MALE) {
                label = "m_male";
            } else if (gender === DbConstants.GENDER_FEMALE) {
                label = "m_female";
            } else {
                label = "maso";
            }
        }
        return label;
    }
    /**
     * 年代ラベルのリソースキー取得
     * @param age
     * @returns
     */
    static getAgeLabelKey(age: number): string {
        let label = "";
        switch (age) {
            case DbConstants.AGE_10:
                label = "age_10";
                break;
            case DbConstants.AGE_20:
                label = "age_20";
                break;
            case DbConstants.AGE_30:
                label = "age_30";
                break;
            case DbConstants.AGE_40:
                label = "age_40";
                break;
            case DbConstants.AGE_50:
                label = "age_50";
                break;
            case DbConstants.AGE_60:
                label = "age_60";
                break;
        }
        return label;
    }
    /**
     * 性別ラベルのリソースキー取得
     * @param gender
     * @returns
     */
    static getGenderLabelKey(gender: number | null): string {
        let label = "";
        switch (gender) {
            case DbConstants.GENDER_MALE:
                label = "gender_male";
                break;
            case DbConstants.GENDER_FEMALE:
                label = "gender_female";
                break;
            case DbConstants.GENDER_OTHER:
                label = "gender_other";
                break;
        }
        return label;
    }
    /**
     * 通知ラベルのリソースキー取得
     * @param notification
     * @returns
     */
    static getNotificationLabelKey(notification: number | null): string {
        let label = "";
        switch (notification) {
            case DbConstants.NOTIFICATION_OFF:
                label = "notification_off";
                break;
            case DbConstants.NOTIFICATION_ON:
                label = "notification_on";
                break;
        }
        return label;
    }
    /**
     * 国名ラベルのリソースキー取得
     * @param countryCd
     * @returns
     */
    static getCountryLabelKey(countryCd: string | null): string {
        let label = "";
        if (countryCd != null && countryCd.length > 0) {
            label = "country_name_" + countryCd.toLocaleUpperCase();
        }
        return label;
    }
    /**
     * 時差を反映したフォーマット済み日付を取得
     * @param unixTime
     * @param difference
     * @param lang
     * @returns
     */
    static getFormattedDate(
        unixTime: number,
        difference: number,
        lang: string
    ): string {
        let time = unixTime - difference;
        let date = new Date(time * 1000);
        if (lang === "ja" || lang === "cn" || lang === "tw") {
            const strYear = date.getFullYear().toString();
            const m = date.getMonth() + 1;
            let strMonth = m.toString();
            if (m < 10) {
                strMonth = "0" + strMonth;
            }
            const d = date.getDate();
            let strDate = d.toString();
            if (d < 10) {
                strDate = "0" + strDate;
            }
            return strYear + "/" + strMonth + "/" + strDate;
        } else if (lang === "es") {
            const strYear = date.getFullYear().toString();
            const m = date.getMonth();
            const month_array: Array<string> = [
                "enero",
                "febrero",
                "marzo",
                "abril",
                "mayo",
                "junio",
                "julio",
                "agosto",
                "septiembre",
                "octubre",
                "noviembre",
                "diciembre",
            ];
            const d = date.getDate();
            let strDate = d.toString();
            if (d < 10) {
                strDate = "0" + strDate;
            }
            return strDate + " de " + month_array[m] + " de " +  strYear;
        } else if (lang === "de") {
            const strYear = date.getFullYear().toString();
            const m = date.getMonth();
            const month_array: Array<string> = [
                "Januar",
                "Februar",
                "März",
                "April",
                "Mai",
                "Juni",
                "Juli",
                "August",
                "September",
                "Oktober",
                "November",
                "Dezember",
            ];
            const d = date.getDate();
            let strDate = d.toString();
            if (d < 10) {
                strDate = "0" + strDate;
            }
            return strDate + ". " + month_array[m] + " " + strYear;
        } else if (lang === "it") {
            const strYear = date.getFullYear().toString();
            const m = date.getMonth();
            const month_array: Array<string> = [
                "gennaio",
                "febbraio",
                "marzo",
                "aprile",
                "maggio",
                "giugno",
                "luglio",
                "agosto",
                "settembre",
                "ottobre",
                "novembre",
                "dicembre",
            ];
            const d = date.getDate();
            let strDate = d.toString();
            if (d < 10) {
                strDate = "0" + strDate;
            }
            return strDate + " " + month_array[m] + " " + strYear;
        } else if (lang === "ru") {
            const strYear = date.getFullYear().toString();
            const m = date.getMonth();
            const month_array: Array<string> = [
                "янв",
                "февраля",
                "марта",
                "апр",
                "мая",
                "июня",
                "июля",
                "августа",
                "сентября",
                "октября",
                "ноября",
                "декабря",
            ];
            const d = date.getDate();
            let strDate = d.toString();
            if (d < 10) {
                strDate = "0" + strDate;
            }
            return strDate + " " + month_array[m] + " " + strYear + " года";
        } else {
            const strYear = date.getFullYear().toString();
            const m = date.getMonth();
            const month_array: Array<string> = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];
            const d = date.getDate();
            let strDate = d.toString();
            if (d < 10) {
                strDate = "0" + strDate;
            }
            return month_array[m] + " " + strDate + ", " + strYear;
        }
    }
    /**
     * 時差を反映した時刻を取得
     * @param unixTime
     * @param difference
     * @param lang
     * @returns
     */
    static getFormattedTime(
        unixTime: number,
        difference: number,
        lang: string
    ): string {
        let time = unixTime - difference;
        let date = new Date(time * 1000);
        if (lang === "en") {
            const hour24 = date.getHours();
            const hour12 = hour24 % 12;
            let strHour12 = String(hour12);
            if (hour12 < 10) {
                strHour12 = "0" + strHour12;
            }
            const minute = date.getMinutes();
            let strMinute = String(minute);
            if (minute < 10) {
                strMinute = "0" + strMinute;
            }
            const ampm = hour24 < 12 ? "a.m." : "p.m.";
            return strHour12 + ":" + strMinute + " " + ampm;
        } else {
            const hour = date.getHours();
            let strHour = String(hour);
            if (hour < 10) {
                strHour = "0" + strHour;
            }
            const minute = date.getMinutes();
            let strMinute = String(minute);
            if (minute < 10) {
                strMinute = "0" + strMinute;
            }
            return strHour + ":" + strMinute;
        }
    }
    /**
     * 時差を反映した時刻を取得
     * @param unixTime
     * @param difference
     * @returns
     */
    static getFormattedTime2(
        unixTime: number,
        difference: number,
    ): string {
        let time = unixTime - difference;
        let date = new Date(time * 1000);
        const hour = date.getHours();
        let strHour = hour.toString();
        if (hour < 10) {
            strHour = "0" + strHour;
        }
        const minute = date.getMinutes();
        let strMinute = minute.toString();
        if (minute < 10) {
            strMinute = "0" + strMinute;
        }

        return `${strHour}:${strMinute}`;
    }
    /**
     * 時差を反映した時刻を取得
     * @param unixTime
     * @param difference
     * @returns
     */
    static getFormattedTime3(
        unixTime: number,
        difference: number,
    ): string {
        let time = unixTime - difference;
        let date = new Date(time * 1000);
        const hour = date.getHours();
        let strHour = hour.toString();
        if (hour < 10) {
            strHour = "0" + strHour;
        }
        const minute = date.getMinutes();
        let strMinute = minute.toString();
        if (minute < 10) {
            strMinute = "0" + strMinute;
        }
        const second = date.getSeconds();
        let strSecond = second.toString();
        if (second < 10) {
            strSecond = "0" + strSecond;
        }

        return `${strHour}:${strMinute}:${strSecond}`;
    }
    static getWeekDay(
        unixTime: number,
        difference: number,
        lang: string
    ): string {
        let time = unixTime - difference;
        let date = new Date(time * 1000);
   
        const dayOfWeek = date.getDay();
        switch (lang) {
            case "ja":
                return ["日", "月", "火", "水", "木", "金", "土"][
                    dayOfWeek
                ];
            case "cn":
                return [
                    "星期天", 
                    "星期一", 
                    "星期二", 
                    "星期三", 
                    "星期四", 
                    "星期五", 
                    "星期六"
                ][dayOfWeek];
            case "tw":
                return [
                    "星期天", 
                    "星期一", 
                    "星期二", 
                    "星期三", 
                    "星期四", 
                    "星期五", 
                    "星期六"
                ][dayOfWeek];
            case "es":
                return [
                    "Domingo", 
                    "Lunes", 
                    "Martes", 
                    "Miércoles", 
                    "Jueves", 
                    "Viernes", 
                    "Sábado"
                ][dayOfWeek];
            case "de":
                return [
                    "Sonntag", 
                    "Montag", 
                    "Dienstag", 
                    "Mittwoch", 
                    "Donnerstag", 
                    "Freitag", 
                    "Samstag"
                ][dayOfWeek];
            case "it":
                return [
                    "Domenica", 
                    "Lunedì", 
                    "Martedì", 
                    "Mercoledì", 
                    "Giovedì", 
                    "Venerdì", 
                    "Sabato"
                ][dayOfWeek];
            case "ru":
                return [
                    "Воскресенье ", 
                    "Понедельник ", 
                    "Вторник ", 
                    "Среда ", 
                    "Четверг ", 
                    "Пятница ", 
                    "Суббота "
                ][dayOfWeek];
            default:
                return [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                ][dayOfWeek];    
        }
    }

    /**
     * 時差を反映した日時を配列で取得
     * @param unixTime
     * @param difference
     * @param lang
     * @returns
     */
    static getFormattedDateTimeArray(
        unixTime: number,
        difference: number,
        lang: string
    ): string[] {
        const formattedDate = Utility.getFormattedDate(unixTime, difference, lang);
        const formattedTime = Utility.getFormattedTime(unixTime, difference, lang);
        return [formattedDate, formattedTime];
    }
    /**
     * 時差を反映したフォーマット済み日時を取得
     * @param unixTime
     * @param difference
     * @param lang
     * @returns
     */
    static getFormattedDateTime(
        unixTime: number,
        difference: number,
        lang: string
    ): string {
        const formattedDate = Utility.getFormattedDate(unixTime, difference, lang);
        const formattedTime = Utility.getFormattedTime2(unixTime, difference);

        if (lang === "ja" || lang === "cn" || lang === "tw") {
            return `${formattedDate} ${formattedTime}`;
        } else {
            return `${formattedDate}, ${formattedTime}`;
        }
        // let time = unixTime - difference;
        // let date = new Date(time * 1000);
        // if (lang === "ja") {
        //     const strYear = date.getFullYear().toString();
        //     const m = date.getMonth() + 1;
        //     let strMonth = m.toString();
        //     if (m < 10) {
        //         strMonth = "0" + strMonth;
        //     }
        //     const d = date.getDate();
        //     let strDate = d.toString();
        //     if (d < 10) {
        //         strDate = "0" + strDate;
        //     }
        //     const hour = date.getHours();
        //     let strHour = hour.toString();
        //     if (hour < 10) {
        //         strHour = "0" + strHour;
        //     }
        //     const minute = date.getMinutes();
        //     let strMinute = minute.toString();
        //     if (minute < 10) {
        //         strMinute = "0" + strMinute;
        //     }
        //     return `${strYear}/${strMonth}/${strDate} ${strHour}:${strMinute}`;
        // } else {
        //     const strYear = date.getFullYear().toString();
        //     const m = date.getMonth();
        //     const month_array: Array<string> = [
        //         "Jan",
        //         "Feb",
        //         "Mar",
        //         "Apr",
        //         "May",
        //         "Jun",
        //         "Jul",
        //         "Aug",
        //         "Sep",
        //         "Oct",
        //         "Nov",
        //         "Dec",
        //     ];
        //     const d = date.getDate();
        //     let strDate = d.toString();
        //     if (d < 10) {
        //         strDate = "0" + strDate;
        //     }
        //     const hour = date.getHours();
        //     let strHour = hour.toString();
        //     if (hour < 10) {
        //         strHour = "0" + strHour;
        //     }
        //     const minute = date.getMinutes();
        //     let strMinute = minute.toString();
        //     if (minute < 10) {
        //         strMinute = "0" + strMinute;
        //     }
        //     return `${month_array[m]} ${strDate}, ${strYear}, at ${strHour}:${strMinute}`;
        // }
    }
    /**
     * 時差を反映したフォーマット済み日時を取得
     * @param unixTime
     * @param difference
     * @param lang
     * @returns
     */
    static getFormattedDateTime2(
        unixTime: number,
        difference: number,
        lang: string
    ): string {
        const formattedDate = Utility.getFormattedDate(unixTime, difference, lang);
        const formattedTime = Utility.getFormattedTime3(unixTime, difference);

        if (lang === "ja" || lang === "cn" || lang === "tw") {
            return `${formattedDate} ${formattedTime}`;
        } else {
            return `${formattedDate}, ${formattedTime}`;
        }

        // let time = unixTime - difference;
        // let date = new Date(time * 1000);
        // if (lang === "ja") {
        //     const strYear = date.getFullYear().toString();
        //     const m = date.getMonth() + 1;
        //     let strMonth = m.toString();
        //     if (m < 10) {
        //         strMonth = "0" + strMonth;
        //     }
        //     const d = date.getDate();
        //     let strDate = d.toString();
        //     if (d < 10) {
        //         strDate = "0" + strDate;
        //     }
        //     const hour = date.getHours();
        //     let strHour = hour.toString();
        //     if (hour < 10) {
        //         strHour = "0" + strHour;
        //     }
        //     const minute = date.getMinutes();
        //     let strMinute = minute.toString();
        //     if (minute < 10) {
        //         strMinute = "0" + strMinute;
        //     }
        //     const second = date.getSeconds();
        //     let strSecond = second.toString();
        //     if (second < 10) {
        //         strSecond = "0" + strSecond;
        //     }
        //     return `${strYear}/${strMonth}/${strDate} ${strHour}:${strMinute}:${strSecond}`;
        // } else {
        //     const strYear = date.getFullYear().toString();
        //     const m = date.getMonth();
        //     const month_array: Array<string> = [
        //         "Jan",
        //         "Feb",
        //         "Mar",
        //         "Apr",
        //         "May",
        //         "Jun",
        //         "Jul",
        //         "Aug",
        //         "Sep",
        //         "Oct",
        //         "Nov",
        //         "Dec",
        //     ];
        //     const d = date.getDate();
        //     let strDate = d.toString();
        //     if (d < 10) {
        //         strDate = "0" + strDate;
        //     }
        //     const hour = date.getHours();
        //     let strHour = hour.toString();
        //     if (hour < 10) {
        //         strHour = "0" + strHour;
        //     }
        //     const minute = date.getMinutes();
        //     let strMinute = minute.toString();
        //     if (minute < 10) {
        //         strMinute = "0" + strMinute;
        //     }
        //     const second = date.getSeconds();
        //     let strSecond = second.toString();
        //     if (second < 10) {
        //         strSecond = "0" + strSecond;
        //     }
        //     return `${month_array[m]} ${strDate}, ${strYear}, at ${strHour}:${strMinute}:${strSecond}`;
        // }
    }
    /**
     * 時差を反映したフォーマット済み日時を取得
     * @param unixTime
     * @param difference
     * @param lang
     * @returns
     */
    static getFormattedDateTimeWithDay(
        unixTime: number,
        difference: number,
        lang: string
    ): string {
        const formattedDate = Utility.getFormattedDate(unixTime, difference, lang);
        const formattedTime = Utility.getFormattedTime3(unixTime, difference);
        const weekDay = Utility.getWeekDay(unixTime, difference, lang);

        if (lang === "ja" || lang === "cn" || lang === "tw") {
            return `${formattedDate}(${weekDay}) ${formattedTime}`;
        } else {
            return `${weekDay}, ${formattedDate}, ${formattedTime}`;
        }

        // let time = unixTime - difference;
        // let date = new Date(time * 1000);
        // if (lang === "ja") {
        //     const strYear = date.getFullYear().toString();
        //     const m = date.getMonth() + 1;
        //     let strMonth = m.toString();
        //     if (m < 10) {
        //         strMonth = "0" + strMonth;
        //     }
        //     const d = date.getDate();
        //     let strDate = d.toString();
        //     if (d < 10) {
        //         strDate = "0" + strDate;
        //     }
        //     const dayOfWeek = date.getDay();
        //     const dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][
        //         dayOfWeek
        //     ];
        //     const hour = date.getHours();
        //     let strHour = hour.toString();
        //     if (hour < 10) {
        //         strHour = "0" + strHour;
        //     }
        //     const minute = date.getMinutes();
        //     let strMinute = minute.toString();
        //     if (minute < 10) {
        //         strMinute = "0" + strMinute;
        //     }
        //     return `${strYear}/${strMonth}/${strDate}(${dayOfWeekStr}) ${strHour}:${strMinute}`;
        // } else {
        //     const strYear = date.getFullYear().toString();
        //     const m = date.getMonth();
        //     const month_array: Array<string> = [
        //         "Jan",
        //         "Feb",
        //         "Mar",
        //         "Apr",
        //         "May",
        //         "Jun",
        //         "Jul",
        //         "Aug",
        //         "Sep",
        //         "Oct",
        //         "Nov",
        //         "Dec",
        //     ];
        //     const d = date.getDate();
        //     let strDate = d.toString();
        //     if (d < 10) {
        //         strDate = "0" + strDate;
        //     }
        //     const dayOfWeek = date.getDay();
        //     const dayOfWeekStr = [
        //         "Sunday",
        //         "Monday",
        //         "Tuesday",
        //         "Wednesday",
        //         "Thursday",
        //         "Friday",
        //         "Saturday",
        //     ][dayOfWeek];
        //     const hour = date.getHours();
        //     let strHour = hour.toString();
        //     if (hour < 10) {
        //         strHour = "0" + strHour;
        //     }
        //     const minute = date.getMinutes();
        //     let strMinute = minute.toString();
        //     if (minute < 10) {
        //         strMinute = "0" + strMinute;
        //     }
        //     return `${dayOfWeekStr}, ${month_array[m]} ${strDate}, ${strYear}, at ${strHour}:${strMinute}`;
        // }
    }
    /**
     * 画像ファイル判定
     * @param fileName
     * @returns
     */
    static isImage(fileName: string): boolean {
        return !Utility.isVideo(fileName);
    }
    /**
     * 動画ファイル判定
     * @param fileName
     * @returns
     */
    static isVideo(fileName: string): boolean {
        if (
            fileName.toLowerCase().endsWith(".flv") ||
            fileName.toLowerCase().endsWith(".mpg") ||
            fileName.toLowerCase().endsWith(".mpeg") ||
            fileName.toLowerCase().endsWith(".mp4") ||
            fileName.toLowerCase().endsWith(".avi") ||
            fileName.toLowerCase().endsWith(".mov") ||
            fileName.toLowerCase().endsWith(".wmv")
        ) {
            return true;
        } else {
            return false;
        }
    }
    /**
     * 動画ファイル名からサムネイルファイル名を取得
     * @param fileName
     * @returns
     */
    static getThumbnailFileName(fileName: string): string {
        let value = fileName.replace(".flv", "_thumbnail.jpg");
        value = value.replace(".FLV", "_thumbnail.jpg");
        value = value.replace(".mpg", "_thumbnail.jpg");
        value = value.replace(".MPG", "_thumbnail.jpg");
        value = value.replace(".mpeg", "_thumbnail.jpg");
        value = value.replace(".MPEG", "_thumbnail.jpg");
        value = value.replace(".mp4", "_thumbnail.jpg");
        value = value.replace(".MP4", "_thumbnail.jpg");
        value = value.replace(".avi", "_thumbnail.jpg");
        value = value.replace(".AVI", "_thumbnail.jpg");
        value = value.replace(".mov", "_thumbnail.jpg");
        value = value.replace(".MOV", "_thumbnail.jpg");
        value = value.replace(".wmv", "_thumbnail.jpg");
        value = value.replace(".WMV", "_thumbnail.jpg");
        return value;
    }
    static getUserKey(user: UserModel | null): string {
        let value = "";
        if (user != null) {
            let createdAtDate = new Date();
            if (user.createdAt != null) {
                createdAtDate = new Date(user.createdAt);
            }
            const month = createdAtDate.getMonth();
            const date = createdAtDate.getDate();
            const hour = createdAtDate.getHours();
            const minute = createdAtDate.getMinutes();
            const deviceType = user.deviceType;
            const gender = user.gender;
            const age = user.age;
            const id = user.id;
            value = `${deviceType}${gender}${age}-${minute}${hour}${date}${month}-${id}`;
        }
        return value;
    }
    static getUserKey2(user: GroupChatMemberData): string {
        let value = "";
        if (user != null) {
            let createdAtDate = new Date();
            const month = createdAtDate.getMonth();
            const date = createdAtDate.getDate();
            const hour = createdAtDate.getHours();
            const minute = createdAtDate.getMinutes();
            const deviceType = 3;
            const gender = user.gender;
            const age = 2;
            const id = user.id;
            value = `${deviceType}${gender}${age}-${minute}${hour}${date}${month}-${id}`;
        }
        return value;
    }
    static getLanguageCode(): string {
        Utility.log("getLanguageCode IN")
        let language =
            (window.navigator.languages && window.navigator.languages[0]) ||
            window.navigator.language;
            Utility.log("language1:" + language)
            language = language.replace("-", "").toLowerCase();
            Utility.log("language2:" + language)
        if (language == null || language.length === 0) {
            language = "en";
        }
        if (language === "zh" || language === "zhcn" || language === "zhhans") {
            language = "cn";
        } else if (language === "zhhk" || language === "zhtw" || language === "zhhant") {
            language = "tw";
        }
        Utility.log("language3:" + language)
        if (language.length > 2) {
            language = language.substring(0, 2);
        }
        Utility.log("language4:" + language)
        Utility.log("getLanguageCode OUT")
        return language;
    }
    static async getActionKey(user?: UserModel) {
        let actionKey = "";
        let res = await ActionKeyRequest.getActionKey(user);
        if (
            res?.rtnCd != null &&
            res.rtnCd === 0 &&
            res.actionKey != null &&
            res.date != null
        ) {
            let baseKey = res.actionKey;
            let datetime = res.date;
            let array = datetime.split(" ");
            if (array.length === 2) {
                let arrayYMD = array[0].split("-");
                let arrayHMD = array[1].split(":");
                if (arrayYMD.length === 3 && arrayHMD.length === 3) {
                    let year = Number(arrayYMD[0]);
                    let month = Number(arrayYMD[1]);
                    let day = Number(arrayYMD[2]);
                    let hour = Number(arrayHMD[0]);
                    let minute = Number(arrayHMD[1]);
                    let second = Number(arrayHMD[2]);
                    let sum = year + month + day + hour + minute + second;
                    let len = baseKey.length;
                    let sublen = sum % len;
                    let key1 = baseKey.substr(0, sublen);
                    let key2 = baseKey.substr(sublen, len - sublen);
                    actionKey = key2 + key1;
                }
            }
        }
        return actionKey;
    }
    /**
     * 性別による背景色を返却
     * @param gender
     * @returns
     */
    static getGenderBgColor(gender: number | null | undefined): string {
        let color = AppConstants.BG_COLOR_GENDER_OTHER;
        if (gender === DbConstants.GENDER_MALE) {
            color = AppConstants.BG_COLOR_GENDER_MALE;
        } else if (gender === DbConstants.GENDER_FEMALE) {
            color = AppConstants.BG_COLOR_GENDER_FEMALE;
        }
        return color;
    }
    /**
     * 性別によるフォント色を返却
     * @param gender
     * @returns
     */
    static getGenderFontColor(gender: number | null | undefined): string {
        let color = AppConstants.FONT_COLOR_GENDER_OTHER;
        if (gender === DbConstants.GENDER_MALE) {
            color = AppConstants.FONT_COLOR_GENDER_MALE;
        } else if (gender === DbConstants.GENDER_FEMALE) {
            color = AppConstants.FONT_COLOR_GENDER_FEMALE;
        }
        return color;
    }
    // /**
    //  * ユーザリスト履歴をクリア
    //  */
    // static clearUserListHistory() {
    //     dispatch = useDispatch();
    //     dispatch(HistoryAction({ userListHistory: null }));
    // }
    // /**
    //  * ユーザリスト履歴のスクロール位置を更新
    //  * @param pos
    //  * @returns
    //  */
    // static updateScrollPosInUserListHistory(pos: number) {
    //     dispatch = useDispatch();
    //     selector = useSelector((state) => state);
    //     const history = getHistory(selector) as HistoryUserList;
    //     if (history == null) {
    //         return;
    //     }
    //     history.scrollPos = pos;
    //     dispatch(HistoryAction({ userListHistory: history }));
    // }

    /**
     * 画像URL判定
     * @param url
     * @returns
     */
    static isImageUrl(url: string): boolean {
        url = url.toLocaleLowerCase();
        url = url.replace("jpeg", "jpg");
        if (url.endsWith(".jpg")) {
            return true;
        }
        if (url.endsWith(".png")) {
            return true;
        }
        if (url.endsWith(".bmp")) {
            return true;
        }
        if (url.endsWith(".gif")) {
            return true;
        }
        if (url.endsWith(".svg")) {
            return true;
        }

        if (url.indexOf(".jpg?") > -1) {
            return true;
        }
        if (url.indexOf(".png?") > -1) {
            return true;
        }
        if (url.indexOf(".bmp?") > -1) {
            return true;
        }
        if (url.indexOf(".gif?") > -1) {
            return true;
        }
        if (url.indexOf(".svg?") > -1) {
            return true;
        }
        return false;
    }
    /**
     * 動画URL判定
     * @param url
     * @returns
     */
    static isVideoUrl(url: string): boolean {
        url = url.toLocaleLowerCase();
        if (url.endsWith(".mp4")) {
            return true;
        }
        if (url.endsWith(".mov")) {
            return true;
        }
        if (url.endsWith(".wmv")) {
            return true;
        }
        if (url.endsWith(".avi")) {
            return true;
        }
        if (url.endsWith(".flv")) {
            return true;
        }
        if (url.endsWith(".f4v")) {
            return true;
        }
        if (url.endsWith(".swf")) {
            return true;
        }
        if (url.endsWith(".webm")) {
            return true;
        }

        if (url.indexOf(".mp4?") > -1) {
            return true;
        }
        if (url.indexOf(".mov?") > -1) {
            return true;
        }
        if (url.indexOf(".wmv?") > -1) {
            return true;
        }
        if (url.indexOf(".avi?") > -1) {
            return true;
        }
        if (url.indexOf(".flv?") > -1) {
            return true;
        }
        if (url.indexOf(".f4v?") > -1) {
            return true;
        }
        if (url.indexOf(".swf?") > -1) {
            return true;
        }
        if (url.indexOf(".webm?") > -1) {
            return true;
        }
        return false;
    }
    static getHlsFileName(fileName: string | null): string {
        if (fileName == null) {
            return "";
        }
        if (!Utility.isVideo(fileName)) {
            return fileName;
        }
        let index = -1;
        let wkFileName = fileName.toLowerCase();
        if (wkFileName.lastIndexOf(".flv") > -1) {
            index = wkFileName.lastIndexOf(".flv");
        } else if (wkFileName.lastIndexOf(".mpg") > -1) {
            index = wkFileName.lastIndexOf(".mpg");
        } else if (wkFileName.lastIndexOf(".mpeg") > -1) {
            index = wkFileName.lastIndexOf(".mpeg");
        } else if (wkFileName.lastIndexOf(".mp4") > -1) {
            index = wkFileName.lastIndexOf(".mp4");
        } else if (wkFileName.lastIndexOf(".avi") > -1) {
            index = wkFileName.lastIndexOf(".avi");
        } else if (wkFileName.lastIndexOf(".mov") > -1) {
            index = wkFileName.lastIndexOf(".mov");
        } else if (wkFileName.lastIndexOf(".m4v") > -1) {
            index = wkFileName.lastIndexOf(".m4v");
        } else if (wkFileName.lastIndexOf(".wmv") > -1) {
            index = wkFileName.lastIndexOf(".wmv");
        }
        if (index === -1) {
            return fileName;
        }
        wkFileName = fileName.substring(0, index);
        wkFileName = wkFileName + ".m3u8";
        return wkFileName;
    }
    static escape(text: string): string {
        if (text.length === 0) {
            return "";
        }
        let result = text.replace(/[&'`"<>]/g, function (match: string) {
            const escapeTable: { [key: string]: string } = {
                "&": "&amp;",
                "'": "&#x27;",
                "`": "&#x60;",
                '"': "&quot;",
                "<": "&lt;",
                ">": "&gt;",
            };
            return escapeTable[match];
        });
        if (result == null) {
            return text;
        } else {
            return result;
        }
    }
    static unescape(text: string): string {
        if (text.length === 0) {
            return "";
        }
        return text.replace(
            /(&amp; | &#x27; | &#x60; | &quot; | &lt; | &gt;)/g,
            function (match: string) {
                const escapeTable: { [key: string]: string } = {
                    "&amp;": "&",
                    "&#x27;": "'",
                    "&#x60;": "`",
                    "&quot;": '"',
                    "&lt;": "<",
                    "&gt;": ">",
                };
                return escapeTable[match];
            }
        );
    }
    /**
     * メール未読件数取得
     * @returns
     */
    static async getUnreadMailCount(
        user: UserModel | undefined | null
    ): Promise<number> {
        if (user == null || user.id == null || user.bearerToken == null) {
            return 0;
        }
        const result = await MailRequest.getUnreadCount(user);
        if (result == null) {
            return 0;
        }
        if (result.rtnCd == null || result.rtnCd < 0) {
            return 0;
        }
        if (result.unreadCount == null) {
            return 0;
        }
        return result.unreadCount != null ? result.unreadCount : 0;
    }
    /**
     * トーク未読件数取得
     * @returns
     */
    static async getUnreadTalkCount(
        user: UserModel | undefined | null
    ): Promise<number> {
        if (user == null || user.id == null || user.bearerToken == null) {
            return 0;
        }
        const result = await TalkMessageRequest.getUnreadCount(user);
        if (result == null) {
            return 0;
        }
        if (result.rtnCd == null || result.rtnCd < 0) {
            return 0;
        }
        if (result.unreadCount == null) {
            return 0;
        }
        return result.unreadCount != null ? result.unreadCount : 0;
    }
    static addDateTimeToFileName(fileName: string) {
        const date = new Date();
        const strYear = String(date.getFullYear());
        const month = date.getMonth() + 1;
        let strMonth = String(month);
        if (month < 10) {
            strMonth = "0" + String(month);
        }
        const day = date.getDate();
        let strDay = String(day);
        if (day < 10) {
            strDay = "0" + String(day);
        }
        const hour = date.getHours();
        let strHour = String(hour);
        if (hour < 10) {
            strHour = "0" + String(hour);
        }
        const minute = date.getMinutes();
        let strMinute = String(minute);
        if (minute < 10) {
            strMinute = "0" + String(minute);
        }
        const second = date.getSeconds();
        let strSecond = String(second);
        if (second < 10) {
            strSecond = "0" + String(second);
        }
        let ext = "";
        let wkFileName = fileName;
        let index = fileName.lastIndexOf(".");
        if (index > -1) {
            wkFileName = fileName.substring(0, index);
            ext = fileName.substring(index + 1, fileName.length);
            fileName =
                wkFileName +
                "_" +
                strYear +
                strMonth +
                strDay +
                "_" +
                strHour +
                strMinute +
                strSecond +
                "." +
                ext;
        }
        return fileName;
    }
    /**
     * 添付ファイルURL(サンプル画像、サムネイル画像)取得
     * @param attachedFile
     * @param partnerId
     * @returns
     */
    static getAttachedFileSampleUrl(
        attachedFile: AttachedFileModel,
        partnerId: number | null = null
    ): string | null {
        const fileName = attachedFile.fileName;
        if (fileName == null) {
            return null;
        }
        // 画像の場合
        if (Utility.isImage(fileName)) {
            // 掲示板
            if (
                attachedFile.messageBoardId != null &&
                attachedFile.messageBoardId > 0
            ) {
                return `${UrlConstants.URL_S3_ATTACHED_FILES}message_board/${attachedFile.userId}/${attachedFile.fileName}`;
            }
            // メール
            else if (attachedFile.mailId != null && attachedFile.mailId > 0) {
                return `${UrlConstants.URL_S3_ATTACHED_FILES}mail/${attachedFile.userId}/${attachedFile.fileName}`;
            }
            // ツイート
            else if (attachedFile.tweetId != null && attachedFile.tweetId > 0) {
                return `${UrlConstants.URL_S3_ATTACHED_FILES}tweet/${attachedFile.userId}/${attachedFile.fileName}`;
            }
            // トーク
            else if (
                attachedFile.talkMessageId != null &&
                attachedFile.talkMessageId > 0
            ) {
                return `${UrlConstants.URL_S3_ATTACHED_FILES}talk/${attachedFile.userId}/${partnerId}/${attachedFile.fileName}`;
            } else {
                return null;
            }
        } else {
            const thumbnailFileName = Utility.getThumbnailFileName(fileName);
            // 掲示板
            if (
                attachedFile.messageBoardId != null &&
                attachedFile.messageBoardId > 0
            ) {
                return `${UrlConstants.URL_S3_ORIGINAL_ATTACHED_FILES}message_board/${attachedFile.userId}/${thumbnailFileName}`;
            }
            // メール
            else if (attachedFile.mailId != null && attachedFile.mailId > 0) {
                return `${UrlConstants.URL_S3_ORIGINAL_ATTACHED_FILES}mail/${attachedFile.userId}/${thumbnailFileName}`;
            }
            // ツイート
            else if (attachedFile.tweetId != null && attachedFile.tweetId > 0) {
                return `${UrlConstants.URL_S3_ORIGINAL_ATTACHED_FILES}tweet/${attachedFile.userId}/${thumbnailFileName}`;
            }
            // トーク
            else if (
                attachedFile.talkMessageId != null &&
                attachedFile.talkMessageId > 0
            ) {
                return `${UrlConstants.URL_S3_ORIGINAL_ATTACHED_FILES}talk/${attachedFile.userId}/${partnerId}/${thumbnailFileName}`;
            } else {
                return null;
            }
        }
    }
    /**
     * 添付ファイルオリジナルURL取得
     * @param attachedFile
     * @param partnerId
     * @returns
     */
    static getAttachedFileOriginalUrl(
        attachedFile: AttachedFileModel,
        partnerId: number | null = null
    ): string | null {
        const fileName = attachedFile.fileName;
        if (fileName == null) {
            return null;
        }
        // 画像の場合
        if (Utility.isImage(fileName)) {
            // 掲示板
            if (
                attachedFile.messageBoardId != null &&
                attachedFile.messageBoardId > 0
            ) {
                return `${UrlConstants.URL_S3_ORIGINAL_ATTACHED_FILES}message_board/${attachedFile.userId}/${attachedFile.fileName}`;
            }
            // メール
            else if (attachedFile.mailId != null && attachedFile.mailId > 0) {
                return `${UrlConstants.URL_S3_ORIGINAL_ATTACHED_FILES}mail/${attachedFile.userId}/${attachedFile.fileName}`;
            }
            // ツイート
            else if (attachedFile.tweetId != null && attachedFile.tweetId > 0) {
                return `${UrlConstants.URL_S3_ORIGINAL_ATTACHED_FILES}tweet/${attachedFile.userId}/${attachedFile.fileName}`;
            }
            // トーク
            else if (
                attachedFile.talkMessageId != null &&
                attachedFile.talkMessageId > 0
            ) {
                return `${UrlConstants.URL_S3_ORIGINAL_ATTACHED_FILES}talk/${attachedFile.userId}/${partnerId}/${attachedFile.fileName}`;
            } else {
                return null;
            }
        }
        // 動画の場合
        else {
            if (attachedFile.uploadedVideoHls360 === 1) {
                return `${UrlConstants.URL_S3_360HLS_ATTACHED_FILE}/${
                    attachedFile.userId
                }/${attachedFile.id}/${Utility.getHlsFileName(
                    attachedFile.fileName
                )}`;
            } else {
                // 掲示板
                if (
                    attachedFile.messageBoardId != null &&
                    attachedFile.messageBoardId > 0
                ) {
                    return `${UrlConstants.URL_S3_ORIGINAL_ATTACHED_FILES}message_board/${attachedFile.userId}/${attachedFile.fileName}`;
                }
                // メール
                else if (
                    attachedFile.mailId != null &&
                    attachedFile.mailId > 0
                ) {
                    return `${UrlConstants.URL_S3_ORIGINAL_ATTACHED_FILES}mail/${attachedFile.userId}/${attachedFile.fileName}`;
                }
                // ツイート
                else if (
                    attachedFile.tweetId != null &&
                    attachedFile.tweetId > 0
                ) {
                    return `${UrlConstants.URL_S3_ORIGINAL_ATTACHED_FILES}tweet/${attachedFile.userId}/${attachedFile.fileName}`;
                }
                // トーク
                else if (
                    attachedFile.talkMessageId != null &&
                    attachedFile.talkMessageId > 0
                ) {
                    return `${UrlConstants.URL_S3_ORIGINAL_ATTACHED_FILES}talk/${attachedFile.userId}/${partnerId}/${attachedFile.fileName}`;
                } else {
                    return null;
                }
            }
        }
    }
    /**
     * ギャラリーファイルURL(サンプル、サムネイル)取得
     * @param gallery
     * @returns
     */
    static getGallerySampleUrl(gallery: GalleryModel): string | null {
        const fileName = gallery.fileName;
        if (fileName == null) {
            return null;
        }
        // 画像の場合
        if (Utility.isImage(fileName)) {
            return `${UrlConstants.URL_S3_ATTACHED_FILES}${gallery.userId}/${gallery.albumId}/${gallery.fileName}`;
        }
        // 動画の場合
        else {
            const thumbnailFileName = Utility.getThumbnailFileName(fileName);
            return `${UrlConstants.URL_S3_ORIGINAL_GALLERY_FILES}${gallery.userId}/${gallery.albumId}/${thumbnailFileName}`;
        }
    }
    /**
     * ギャラリーファイルオリジナルURL取得
     * @param gallery
     * @returns
     */
    static getGalleryOriginalUrl(gallery: GalleryModel): string | null {
        const fileName = gallery.fileName;
        if (fileName == null) {
            return null;
        }
        // 画像の場合
        if (Utility.isImage(fileName)) {
            return `${UrlConstants.URL_S3_ORIGINAL_GALLERY_FILES}${gallery.userId}/${gallery.albumId}/${gallery.fileName}`;
        }
        // 動画の場合
        else {
            if (gallery.uploadedVideoHls360 === 1) {
                return `${UrlConstants.URL_S3_360HLS_GALLERY}/${
                    gallery.userId
                }/${gallery.id}/${Utility.getHlsFileName(gallery.fileName)}`;
            } else {
                return `${UrlConstants.URL_S3_ORIGINAL_GALLERY_FILES}${gallery.userId}/${gallery.albumId}/${gallery.fileName}`;
            }
        }
    }

    /**
     * 端末やアプリバージョンを検証し、ユーザチャット可能かどうかを判定
     * @param target
     * @returns
     */
    static isChatAvailable(target: UserModel | undefined | null): boolean {
        if (target == null) {
            return false;
        }
        const deviceType = target.deviceType;
        const majorVersion = target.majorVersion;
        const minorVersion = target.minorVersion;
        const buildNo = target.buildNo;
        if (
            deviceType == null ||
            majorVersion == null ||
            minorVersion == null ||
            buildNo == null
        ) {
            return false;
        }
        if (
            deviceType !== DbConstants.DEVICE_TYPE_ANDROID &&
            deviceType !== DbConstants.DEVICE_TYPE_BROWSER
        ) {
            return false;
        }
        if (deviceType === DbConstants.DEVICE_TYPE_ANDROID) {
            if (majorVersion < AppConstants.TALK_POSSIBLE_MAJOR_VERSION) {
                return false;
            } else if (
                majorVersion === AppConstants.TALK_POSSIBLE_MAJOR_VERSION
            ) {
                if (minorVersion < AppConstants.TALK_POSSIBLE_MINOR_VERSION) {
                    return false;
                }
            }
        }
        return true;
    }

    /**
     * ブロックしてるユーザかどうか
     * @param lstBlockUser 
     * @param targetId 
     * @returns 
     */
    static isBlocked(lstBlockUser: [UserModel] | null | undefined, targetId: number | null | undefined) {
        if (lstBlockUser == null || targetId == null) {
            return false;
        }
        for (let i=0; i<lstBlockUser.length; i++) {
            const blockUser = lstBlockUser[i];
            if (blockUser.id === targetId) {
                return true;
            }
        }
        return false;
    }
    /**
     * フォローしてるユーザかどうか
     * @param lstFollowUser 
     * @param targetId 
     * @returns 
     */
    static isFollowing(lstFollowUser: [UserModel] | null | undefined, targetId: number | null | undefined) {
        if (lstFollowUser == null || targetId == null) {
            return false;
        }
        for (let i=0; i<lstFollowUser.length; i++) {
            const followUser = lstFollowUser[i];
            if (followUser.id === targetId) {
                return true;
            }
        }
        return false;
    }
    /**
     * ファイル名に日時を追加
     * @param fileName
     * @returns
     */
    // static addDateTimeToFileName(fileName: string): string {
    //     const date = new Date();
    //     const year = date.getFullYear();
    //     const month = ("00" + (date.getMonth() + 1)).slice(-2);
    //     const day = ("00" + date.getDate()).slice(-2);
    //     const hour = ("00" + date.getHours()).slice(-2);
    //     const minute = ("00" + date.getMinutes()).slice(-2);
    //     const second = ("00" + date.getSeconds()).slice(-2);
    //     return year + month + day + "_" + hour + minute + second;
    // }
}
