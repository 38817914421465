import React from "react";
import { Dialog, DialogTitle, DialogContent, Paper } from "@mui/material";
import Draggable from "react-draggable";
import { PaperProps } from "@mui/material/Paper";
import { FormattedMessage } from "react-intl";
import { UserModel, ParentMailModel } from "models/Models";
import { useDispatch } from "react-redux";
import { SendMessageComponent } from "components/Components";
import { SelectedItemAction } from "redux/Actions";
import CloseButton from "assets/images/cross_mark.png";
import "styles/components/SendMessageDialog.scss";

type Props = {
    open: boolean;
    close: () => void;
    onSendCompleted: () => void;
    loginUser: UserModel | null;
    parentMail: ParentMailModel | undefined;
    target?: UserModel | null;
};

export const SendMessageDialog: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("===== SendMessageDialog");
        /***** 定数、変数 */
        const dispatch = useDispatch();

        function PaperComponent(props: PaperProps) {
            return (
                <Draggable
                    handle="#draggable-dialog-title"
                    cancel={'[class*="MuiDialogContent-root"]'}
                >
                    <Paper
                        {...props}
                        style={{
                            margin: 0,
                            maxWidth: "none",
                            width: "70vw",
                        }}
                    />
                </Draggable>
            );
        }

        /**
         * useEffect
         */
        React.useEffect(() => {
            return () => {
                dispatch(SelectedItemAction({ lstUser: null }));
            };
        }, []);

        /**
         * レンダリング
         */
        return (
            <>
                {props.loginUser != null && (
                    <>
                        <Dialog
                            className="dialog SendMessageDialog"
                            open={props.open}
                            onClose={props.close}
                            PaperComponent={PaperComponent}
                            aria-labelledby="draggable-dialog-title"
                            PaperProps={{
                                sx: {
                                    position: "relative",
                                    width: "100%",
                                    maxWidth: "none",
                                    display: {
                                        xs: "none",
                                        md: "block",
                                    },
                                    margin: "auto !important",
                                },
                            }}
                        >
                            <DialogTitle
                                id="draggable-dialog-title"
                                className="dialog-title"
                            >
                                <img
                                    className="icon-close"
                                    src={CloseButton}
                                    alt="Close Button"
                                    onClick={() => {
                                        props.close();
                                    }}
                                />
                                <div className="title">
                                    {props.parentMail == null && (
                                        <FormattedMessage
                                            id={"btn_send_message2"}
                                        />
                                    )}
                                    {props.parentMail != null && (
                                        <FormattedMessage
                                            id={"btn_reply_message2"}
                                        />
                                    )}
                                </div>
                            </DialogTitle>
                            <DialogContent className="dialog-content">
                                <SendMessageComponent
                                    loginUser={props.loginUser}
                                    // parentMail={props.parentMail}
                                    target={props.target}
                                    onSendCompleted={props.onSendCompleted}
                                />
                            </DialogContent>
                        </Dialog>
                    </>
                )}
            </>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.open !== nextProps.open) {
            return false;
        }
        if (prevProps.parentMail !== nextProps.parentMail) {
            return false;
        }
        if (prevProps.parentMail != null && nextProps.parentMail != null) {
            if (prevProps.parentMail.id !== nextProps.parentMail.id) {
                return false;
            }
        }
        if (prevProps.target !== nextProps.target) {
            return false;
        }
        if (prevProps.target != null && nextProps.target != null) {
            if (prevProps.target.id !== nextProps.target.id) {
                return false;
            }
        }
        if (prevProps.onSendCompleted !== nextProps.onSendCompleted) {
            return false;
        }
        if (prevProps.close !== nextProps.close) {
            return false;
        }

        return true;
    }
);
