import React, { ChangeEvent, MouseEvent } from "react";
import Draggable from "react-draggable";
import {
    SkyWayAuthToken,
} from "@skyway-sdk/token";
import {
    SkyWayStreamFactory,
    LocalAudioStream,
    LocalVideoStream,
    AudioMediaTrackConstraints,
    LocalMediaStreamOptions,
    VideoMediaTrackConstraints,
    LocalStream,
    RemoteDataStream,
    RemoteAudioStream,
    RemoteVideoStream,
    EventDisposer,
    MemberLeftEvent,
    PersonInit,
} from "@skyway-sdk/core";
import {
    SkyWayContext,
    SkyWayRoom,
    RoomPublication,
    LocalP2PRoomMember,
    RoomSubscription,
    uuidV4,
    nowInSec,
    P2PRoom,
    Event,
    RemoteRoomMember,
} from "@skyway-sdk/room";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    Divider,
    IconButton,
    Paper
} from "@mui/material";
import { PaperProps } from "@mui/material/Paper";
import { EventEmitter, EventSubscription } from "fbemitter";
import { UserModel, TalkUserModel, TalkMessageModel, TalkPermissionModel, TalkHistoryModel } from "models/Models";
import {
    getTimeDifference,
    getLanguageCode,
    getEmitter,
    getLoginUser,
    getRtcInfo,
} from "redux/Selectors";
import {
    ProcessAction,
    DecrementTalkUnreadCountAction,
    RtcInfoAction,
} from "redux/Actions";

import {
    UserChatListItemComponent,
    NavBar,
    AttachedFileNameComponent,
    ImageComponent,
    VideoComponent,
    OkDialog,
} from "components/Components";
import Utility from "utils/Utility";
import {
    TalkMessageRequest,
    TalkUserRequest,
    UserRequest,
    TalkPermissionRequest,
} from "api/requests/Requests";
import { AppServer } from "socket/AppServer";
import AttachFile from "@mui/icons-material/AttachFile";
import SendButton from "@mui/icons-material/Send";
import { DbConstants, UrlConstants } from "constants/Constants";
import { TALK_TYPE, TALK_STATUS } from "constants/Enum";
import VoiceCalling from "assets/images/voice_calling.png";
import VideoCalling from "assets/images/video_calling.png";
import CallingImage from "assets/images/calling_white.png";
import CallingGif from "assets/images/calling.gif";
import { TalkHistoryRequest } from "api/requests/TalkHistoryRequest";
import { pushDataLayer } from "gtm/gtm"

import "styles/components/UserTalkComponent.scss";

type Props = {
    loginUser: UserModel;
    talkUser: TalkUserModel;
    pathname: string;
};

export const UserTalkComponent: React.FC<Props> = React.memo(
    (props) => {
        Utility.log("@@@@@ UserTalkComponent IN");
        /***** 定数、変数 */
        // const MAX_WAITING_SECONDS = 40000;
        const MAX_WAITING_SECONDS = 40;
        const intl = useIntl();
        const dispatch = useDispatch();
        const selector = useSelector((state) => state);
        // セッションストレージ
        const sessSenderTalkStarted = window.sessionStorage.getItem("SENDER_TALK_STARTED");
        const sessSenderId = window.sessionStorage.getItem("SENDER_ID");
        const sessReceiverId = window.sessionStorage.getItem("RECEIVER_ID");
        const sessSenderTalkType = window.sessionStorage.getItem("SENDER_TALK_TYPE");
        const sessReceiverTalkType = window.sessionStorage.getItem("RECEIVER_TALK_TYPE");
        Utility.log("sessSenderTalkStarted:" + sessSenderTalkStarted)
    
        /***** useRef */
        const audioDisabled = React.useRef<boolean>(false);
        // EventEmitter
        const emitter = React.useRef<EventEmitter>(getEmitter(selector));
        // EventEmitterの購読
        const lstSubscription = React.useRef<EventSubscription[]>();
        // チャットサーバー
        const appServer = React.useRef<AppServer>(AppServer.instance);
        // トーク相手
        const refPartner = React.useRef<UserModel>();
        // トークステータス
        const refTalkStatus = React.useRef<TALK_STATUS>(TALK_STATUS.NONE);
        // 通話タイプ
        const refUserTalkType = React.useRef<TALK_TYPE>(TALK_TYPE.NONE);
        const refPartnerTalkType = React.useRef<TALK_TYPE>(TALK_TYPE.NONE);
        // ブロックされているかどうか
        const refIsBlocked = React.useRef<boolean>();
        // 自分が発信者かどうか
        const refIsSender = React.useRef<boolean>(false);
        // SkyWayトークン
        const refSkyWayToken = React.useRef<string>();
        // VIDEO
        const refVideoElementMe = React.useRef<HTMLVideoElement>(null);
        const refVideoElementPartner = React.useRef<HTMLVideoElement>(null);
        // AUDIO
        const refAudioElementMe = React.useRef<HTMLAudioElement>(null);
        const refAudioElementPartner = React.useRef<HTMLAudioElement>(null);
        // トークスクリーン要素
        const refTalkScreenElement = React.useRef<HTMLDivElement>(null);
        // ルーム参照
        const refRoom = React.useRef<P2PRoom>();
        // ルームメンバー(自分)参照
        const refRoomMemberMe = React.useRef<LocalP2PRoomMember>();
        // PublicationId参照
        const refPublicationAudio = React.useRef<RoomPublication<LocalAudioStream>>();
        const refPublicationVideo = React.useRef<RoomPublication<LocalVideoStream>>();
        // メディアストリーム
        const refMediaStream = React.useRef<MediaStream>();
        // SkyWayストリーム
        const refAudioStream = React.useRef<LocalAudioStream>();
        const refVideoStream = React.useRef<LocalVideoStream>();
        // タイマー
        const refTimerId = React.useRef<number>(0);
        // トーク開始インターバルID
        const refIntervalId = React.useRef<number>(0);
        // トーク履歴
        const refTalkHistory = React.useRef<TalkHistoryModel>();

        /***** useState */
        const [partner, setPartner] = React.useState<UserModel>();
        const [talkStatus, setTalkStatus] = React.useState<TALK_STATUS>(
            TALK_STATUS.NONE
        );
        const [talkTypeMe, setTalkTypeMe] = React.useState<TALK_TYPE>(TALK_TYPE.NONE);
        const [talkTypePartner, setTalkTypePartner] = React.useState<TALK_TYPE>(TALK_TYPE.NONE);
        // ダイアログメッセージ表示フラグ
        const [openDialog, setOpenDialog] = React.useState<boolean>(false);
        // ダイアログメッセージ
        const [dialogMessage, setDialogMessage] = React.useState<string>("");

        /**
         * useEffect
         */
        React.useEffect(() => {
            Utility.log(">>>>>UserTalkComponent useEffect 1")
            pushDataLayer({
                event: 'page_view',
                screen: "ユーザトーク",
                path: window.location.pathname,
            });
            
            // EventEmitterを登録
            registerEmitterListener();

            // デストラクタ
            return () => {
                // EventEmitterを解除
                removeEmitterListener();

                if (refTimerId != null && refTimerId.current != null && refTimerId.current !== 0) {
                    clearTimeout(refTimerId.current);
                }
                Utility.log("removeSessionStorage 1")
                removeSessionStorage();

                terminateRtc(true);
            };
        }, []);

        React.useEffect(() => {
            Utility.log(">>>>> UserTalkComponent useEffect 2");
            Utility.log("partner name:" + props.talkUser.partner?.name)
            if (props.talkUser == null || props.talkUser.partner == null) {
                return;
            }
            Utility.log("pathname:" + props.pathname)
            // トーク要求受信者がトーク開始する場合
            Utility.log(">>>>>UserTalkComponent sessSenderTalkStarted:" + sessSenderTalkStarted)

            if (sessSenderTalkStarted !== "1" ||
            sessReceiverId !== String(props.loginUser.id) ||
            sessReceiverTalkType == null ||
            sessReceiverTalkType === "0" ||
            sessSenderTalkType == null ||
            sessSenderTalkType === "0") {
                
                Utility.log("return")
                return;
            }

            (async () => {
                refIsSender.current = false;
                
                // 自分のトークタイプをセット
                if (sessReceiverTalkType === "1") {
                    refUserTalkType.current = TALK_TYPE.VOICE;
                } else if (sessReceiverTalkType === "2") {
                    refUserTalkType.current = TALK_TYPE.VIDEO;
                }
                setTalkTypeMe(refUserTalkType.current);

                // パートナーのトークタイプをセット
                if (sessSenderTalkType === "1") {
                    refPartnerTalkType.current = TALK_TYPE.VOICE;
                } else if (sessSenderTalkType === "2") {
                    refPartnerTalkType.current = TALK_TYPE.VIDEO;
                }
                setTalkTypePartner(refPartnerTalkType.current);

                const partnerId = getPartnerIdFromPathname(props.pathname);
                Utility.log("partnerId:" + partnerId);
                Utility.log("refPartner:" + refPartner.current?.id)
                if (refPartner != null && refPartner.current != null &&
                    refPartner.current.id === partnerId
                ) {
                    refTalkStatus.current = TALK_STATUS.UNDER_TALK;
                    setTalkStatus(refTalkStatus.current);
                    // video要素の初期化のための時間をもうける
                    // window.setTimeout(async () => {
                    //     const success = await startTalk();
                    //     if (!success) {
                    //         terminateRtc(true);
                    //     }
                    // }, 5000);
                    refIntervalId.current =  window.setInterval(() => {
                        if (isElementsPrepared()) {
                            window.clearInterval(refIntervalId.current);
                            (async () => {
                                const success = await startTalk();
                                if (!success) {
                                    terminateRtc(true);
                                }
                            })();
                        }
                    }, 1000);
                }
            })();
            return () => {
                if (refTimerId != null && refTimerId.current != null && refTimerId.current !== 0) {
                    clearTimeout(refTimerId.current);
                }
                const partnerIdPathname = getPartnerIdFromPathname(props.pathname);
                const senderIdSession = window.sessionStorage.getItem("SENDER_ID");
                const receiverIdSession = window.sessionStorage.getItem("RECEIVER_ID");
                if (props.loginUser.id === senderIdSession) {
                    if (receiverIdSession === String(partnerIdPathname)) {
                        Utility.log("removeSessionStorage 2 1")
                        removeSessionStorage();
                    }
                } else if (props.loginUser.id === receiverIdSession) {
                    if (senderIdSession === String(partnerIdPathname)) {
                        Utility.log("removeSessionStorage 2 2")
                        removeSessionStorage();
                    }
                }
            }
        }, [props.pathname]);

        React.useEffect(() => {
            Utility.log(">>>>> UserTalkComponent useEffect 3");
            Utility.log("partner name:" + props.talkUser.partner?.name);
            (async () => {
                if (props.talkUser == null || props.talkUser.partner == null) {
                    return;
                }

                Utility.log(">>> 1")
                if (refPartner !=null && 
                    refPartner.current != null &&
                    refPartner.current.id !== props.talkUser.partnerId &&
                    (refTalkStatus.current === TALK_STATUS.UNDER_TALK ||
                        refTalkStatus.current === TALK_STATUS.PAUSE_TALK)
                ) {
                    refPartner.current = props.talkUser.partner;
                    setPartner(props.talkUser.partner);
                } else {
                    refPartner.current = props.talkUser.partner;
                    setPartner(props.talkUser.partner);
                }
                // ブロックされてるかどうか確認
                const blocked = await checkBlocked();
                if (blocked != null) {
                    refIsBlocked.current = blocked;
                }
                Utility.log(">>> 2")
                Utility.log("sessSenderTalkStarted:" + sessSenderTalkStarted)
                if (sessSenderTalkStarted === "1" &&
                    sessReceiverId === String(props.loginUser.id) &&
                    (sessReceiverTalkType !== null &&
                    sessReceiverTalkType !== "0") &&
                    (sessSenderTalkType !== null &&
                    sessSenderTalkType !== "0")
                ) {
                    Utility.log(">>> 3")
                    refIsSender.current = false;
                    // 自分のトークタイプをセット
                    if (sessReceiverTalkType === "1") {
                        refUserTalkType.current = TALK_TYPE.VOICE;
                    } else if (sessReceiverTalkType === "2") {
                        refUserTalkType.current = TALK_TYPE.VIDEO;
                    }
                    setTalkTypeMe(refUserTalkType.current);

                    // パートナーのトークタイプをセット
                    if (sessSenderTalkType === "1") {
                        refPartnerTalkType.current = TALK_TYPE.VOICE;
                    } else if (sessSenderTalkType === "2") {
                        refPartnerTalkType.current = TALK_TYPE.VIDEO;
                    }
                    setTalkTypePartner(refPartnerTalkType.current);

                    const partnerId = getPartnerIdFromPathname(props.pathname);
                    refTalkStatus.current = TALK_STATUS.UNDER_TALK;
                    setTalkStatus(refTalkStatus.current);
                    // video要素の初期化のための時間をもうける
                    // window.setTimeout(async () => {
                    //     const success = await startTalk();
                    //     if (!success) {
                    //         terminateRtc(true);
                    //     }
                    // }, 5000);
                    refIntervalId.current =  window.setInterval(() => {
                        if (isElementsPrepared()) {
                            window.clearInterval(refIntervalId.current);
                            (async () => {
                                const success = await startTalk();
                                if (!success) {
                                    terminateRtc(true);
                                }
                            })();
                        }
                    }, 1000);

                    return;
                }
            })();
            return () => {
                // if (refTalkStatus != null && 
                //     refTalkStatus.current != null &&
                //     (
                //         refTalkStatus.current === TALK_STATUS.UNDER_TALK ||
                //         refTalkStatus.current === TALK_STATUS.PAUSE_TALK
                //     )
                // ) {
                //     terminateRtc(true);
                // }
                terminateRtc(true);
            }
        }, [props.talkUser]);

        /**
         * EventEmitterのイベント登録
         * @returns
         */
        function registerEmitterListener() {
            if (emitter == null || emitter.current == null) {
                return;
            }
            const s1 = emitter.current.addListener(
                "RESPONSE_FOR_CONFIRM_CONNECT_STATUS",
                onReceivedResponseForConfirmConnectStatus
            );
            const s2 = emitter.current.addListener(
                "ON_RECEIVE_RESPONSE_OF_TALK_REQUEST",
                onReceivedResponseOfTalkRequest
            );
            // const s3 = emitter.current.addListener(
            //     "RECEIVED_TALK_STATUS",
            //     onReceivedTalkStatus
            // );
            const s3 = emitter.current.addListener(
                "START_TALK",
                onReceivedStartTalk
            );
            const s4 = emitter.current.addListener(
                "TALK_FAILURE_NOTIFICATION",
                onReceivedTalkFailureNotification
            );
            lstSubscription.current = [s1, s2, s3, s4];
        }

        /**
         * EventEmitterのイベント解除
         */
        function removeEmitterListener() {
            if (lstSubscription != null && lstSubscription.current != null) {
                for (let i = 0; i < lstSubscription.current.length; i++) {
                    lstSubscription.current[i].remove();
                }
            }
            if (emitter != null && emitter.current != null) {
                emitter.current.removeAllListeners(
                    "RESPONSE_FOR_CONFIRM_CONNECT_STATUS"
                );
                emitter.current.removeAllListeners("ON_RECEIVE_RESPONSE_OF_TALK_REQUEST");
                // emitter.current.removeAllListeners("RECEIVED_TALK_STATUS");
                emitter.current.removeAllListeners("START_TALK");
                emitter.current.removeAllListeners("TALK_FAILURE_NOTIFICATION");
                // emitter.current.removeAllListeners("PARTNER_LEFT_ROOM");
            }
        }

        /**
         * 通話終了処理
         */
        async function terminateRtc(needToSendTalkFailureNotification: boolean) {
            Utility.log("terminateRtc IN")
            if (refTimerId != null && refTimerId.current != null && refTimerId.current !== 0) {
                clearTimeout(refTimerId.current);
            }
            if (refTalkHistory != null && 
                refTalkHistory.current != null && 
                refTalkHistory.current.id != null &&
                refTalkHistory.current.startTime != null &&
                refIsSender.current === true
            ) {
                refTalkHistory.current.endTime = Math.floor(new Date().getTime()/1000);
                refTalkHistory.current.talkTime = refTalkHistory.current.endTime - refTalkHistory.current.startTime;
                updateTalkHistory();
            }
            // stream解放
            if (refAudioStream != null && refAudioStream.current != null) {
                Utility.log("terminateRtc audio 1")
                refAudioStream.current.detach();
                refAudioStream.current.release();
            }
            if (refVideoStream != null && refVideoStream.current != null) {
                Utility.log("terminateRtc video 1")
                refVideoStream.current.detach();
                refVideoStream.current.release();
            }
            // 離脱
            Utility.log("terminateRtc 1")
            if (refRoom != null && refRoom.current != null) {
                if (refRoomMemberMe != null && refRoomMemberMe.current != null) {
                    Utility.log("leave")
                    await refRoom.current.leave(refRoomMemberMe.current);
                }
            }
            Utility.log("terminateRtc 2")
            if (refRoomMemberMe != null && refRoomMemberMe.current != null) {
                Utility.log("terminateRtc 3")
                if (refPublicationAudio != null && refPublicationAudio.current != null) {
                    Utility.log("terminateRtc audio 2")
                    try {
                        const subscriptions = refPublicationAudio.current.subscriptions;
                        for (let i=0; i<subscriptions.length; i++) {
                            const subscription = subscriptions[i];
                            subscription.cancel();
                        }
                        // await refRoomMemberMe.current.unpublish(refPublicationAudio.current);
                    } catch (error) {
                        Utility.log(error);
                    }
                }
                Utility.log("terminateRtc 4")
                if (refPublicationVideo != null && refPublicationVideo.current != null) {
                    Utility.log("terminateRtc video 2")
                    try {
                        const subscriptions = refPublicationVideo.current.subscriptions;
                        for (let i=0; i<subscriptions.length; i++) {
                            const subscription = subscriptions[i];
                            subscription.cancel();
                        }
                        // await refRoomMemberMe.current.unpublish(refPublicationVideo.current);
                    } catch (error) {
                        Utility.log(error);
                    }
                }
                Utility.log("terminateRtc 5")
                if (refRoomMemberMe.current.subscriptions != null) {
                    for (let i=0; i<refRoomMemberMe.current.subscriptions.length; i++) {
                        Utility.log("unsubscribe")
                        const subscription = refRoomMemberMe.current.subscriptions[i];
                        try {
                            await refRoomMemberMe.current.unsubscribe(subscription.id);
                        } catch (error) {
                            Utility.log(error);
                        }
                    }
                }
                refRoomMemberMe.current.leave();
            }
            Utility.log("terminateRtc 6")
            if (refMediaStream != null && refMediaStream.current != null) {
                const audioTrack = refMediaStream.current.getAudioTracks()[0];
                const videoTrack = refMediaStream.current.getVideoTracks()[0];
                if (audioTrack != null) {
                    audioTrack.stop();
                    refMediaStream.current.removeTrack(audioTrack);
                    Utility.log("terminateRtc audio 3")
                }
                if (videoTrack != null) {
                    Utility.log("terminateRtc video 3")
                    videoTrack.stop();
                    refMediaStream.current.removeTrack(videoTrack);
                }
            }
            Utility.log("removeSessionStorage 3")
            removeSessionStorage();

            refUserTalkType.current = TALK_TYPE.NONE;
            setTalkTypeMe(refUserTalkType.current);
            refPartnerTalkType.current = TALK_TYPE.NONE;
            setTalkTypePartner(refPartnerTalkType.current);
            refTalkStatus.current = TALK_STATUS.NONE;
            setTalkStatus(refTalkStatus.current);

            if (needToSendTalkFailureNotification) {
                if (props.loginUser.id != null && 
                    refPartner != null && 
                    refPartner.current != null &&
                    refPartner.current.id != null
                ) {
                    appServer.current.sendTalkFailureNotification(
                        props.loginUser.id,
                        refPartner.current.id
                    );
                }
            }
            // dispatch(
            //     RtcInfoAction({
            //         senderId: null,
            //         receiverId: null,
            //         senderTalkType: null,
            //         receiverTalkType: null,
            //     }),
            // );    
            Utility.log("terminateRtc Finished!")
        }

        function removeSessionStorage() {
            Utility.log("removeSessionStorage IN")
            window.sessionStorage.removeItem("rtcSenderId");
            window.sessionStorage.removeItem("rtcReceiverId");
            window.sessionStorage.removeItem("rtcSenderName");
            window.sessionStorage.removeItem("rtcReceiverName");
            window.sessionStorage.removeItem("rtcSenderTalkType");
            window.sessionStorage.removeItem("rtcReceiverTalkType");
            window.sessionStorage.removeItem("rtcTalkStatus");
            window.sessionStorage.removeItem("SENDER_TALK_STARTED");
            window.sessionStorage.removeItem("SENDER_ID");
            window.sessionStorage.removeItem("RECEIVER_ID");
            window.sessionStorage.removeItem("SENDER_TALK_TYPE");
            window.sessionStorage.removeItem("RECEIVER_TALK_TYPE");
        }
        /**
         * 部屋を去った時
         */
        // async function sendLeftRoomNotification() {
        //     if (props.loginUser.id == null ||
        //         refPartner == null ||
        //         refPartner.current == null ||
        //         refPartner.current.id == null
        //     ) {
        //         return;
        //     }
        //     appServer.current.sendLeftRoomNotification(
        //         props.loginUser.id,
        //         refPartner.current.id
        //     );
        // }

        /**
         * 音声通話クリック時
         */
        async function onClickVoiceCall() {
            Utility.log("onClickVoiceCall IN");
            await terminateRtc(true);
            // let success = await fetchSkyWayToken();
            // if (!success) {
            //     return;
            // }
            refUserTalkType.current = TALK_TYPE.VOICE;
            setTalkTypeMe(TALK_TYPE.VOICE);
            checkPartnerStatus();
        }
        /**
         * 映像通話クリック時
         */
        async function onClickVideoCall() {
            Utility.log("onClickVideoCall IN");
            await terminateRtc(true);
            // const success = await fetchSkyWayToken();
            // if (!success) {
            //     return;
            // }
            refUserTalkType.current = TALK_TYPE.VIDEO;
            setTalkTypeMe(TALK_TYPE.VIDEO);
            checkPartnerStatus();
        }
        /**
         * 通話前チェック
         * @returns
         */
        async function checkPartnerStatus(): Promise<boolean> {
            Utility.log("checkPartnerStatus IN");
            if (props.loginUser == null || props.loginUser.id == null || props.talkUser == null) {
                return false;
            }
            if (
                refPartner == null ||
                refPartner.current == null ||
                refPartner.current.id == null ||
                refPartner.current.name == null
            ) {
                return false;
            }
            // ブロックされてるかどうか確認
            const blocked = await checkBlocked();
            if (blocked != null) {
                refIsBlocked.current = blocked;
            }
            if (refIsBlocked.current) {
                const message = intl.formatMessage({ id: "err_blocked" });
                setDialogMessage(message);
                setOpenDialog(true);
                return false;
            }
            // トーク許可チェック
            const talkPermission = await fetchPartnerTalkPermission(refPartner.current);
            if (talkPermission == null || !talkPermission.permitted) {
                let message = intl.formatMessage({ id: "partner_not_permitted_talk" });
                message = message.replace("USER", refPartner.current.name)
                setDialogMessage(message);
                setOpenDialog(true);
                return false;
            }
            // パートナーのAppサーバー接続確認
            appServer.current.sendConnectStatusConfirmationToAppServer(
                props.loginUser.id,
                refPartner.current.id
            );

            return true;
        }

        /**
         * EventEmitterから呼び出されるため、State値は参照できない
         * 接続状況確認の返答受信時
         * @param obj
         */
        async function onReceivedResponseForConfirmConnectStatus(obj: any) {
            Utility.log("onReceivedResponseForConfirmConnectStatus IN");
            Utility.log("talkType:" + refUserTalkType.current);
            const senderId = obj.sender_id;
            const receiverId = obj.receiver_id;
            const connected = obj.connected;
            if (senderId == null || receiverId == null || connected == null) {
                return;
            }
            if (!connected) {
                const message = intl.formatMessage({
                    id: "partner_is_not_online",
                });
                setDialogMessage(message);
                setOpenDialog(true);
                return;
            }
            const result = await UserRequest.getUser(parseInt(receiverId));
            let deviceType: number | null = null;
            if (result != null && result.user != null) {
                deviceType = result.user.deviceType;
            }
            if (deviceType !== DbConstants.DEVICE_TYPE_BROWSER) {
                const message = intl.formatMessage({
                    id: "err_talk_call_device_type",
                });
                setDialogMessage(message);
                setOpenDialog(true);
                return;
            }
            refIsSender.current = true;
            const success = await fetchSkyWayToken();
            if (!success) {
                return;
            }
            sendTalkRequest();
        }

        /**
         * ブロックされてるかどうか
         */
        async function checkBlocked(): Promise<boolean | null> {
            if (refPartner == null || refPartner.current == null) {
                return null;
            }
            const userId = props.loginUser.id;
            const targetId = refPartner.current.id;
            if (userId == null || targetId == null) {
                return null;
            }
            const result = await UserRequest.isBlocked(
                props.loginUser,
                targetId
            );
            if (result == null || result.rtnCd == null || result.rtnCd < 0) {
                return null;
            }
            return result.blocked;
        }

        /**
         * EventEmitterから呼び出される関数から呼び出されるため、State値は参照できない
         * トーク要求の発信
         * @returns
         */
        async function sendTalkRequest() {
            if (
                props.loginUser.id == null ||
                refPartner == null ||
                refPartner.current == null ||
                refPartner.current.id == null
            ) {
                return;
            }
            setTalkStatus(TALK_STATUS.SENT_REQUEST_CALL);
            refTalkStatus.current = TALK_STATUS.SENT_REQUEST_CALL;
            appServer.current.sendTalkCallRequest(
                props.loginUser.id,
                refPartner.current.id,
                refUserTalkType.current
            );
            refTimerId.current = window.setTimeout(checkResponse, MAX_WAITING_SECONDS * 1000);
        }

        /**
         * EventEmitterから呼び出される関数から呼び出されるため、State値は参照できない
         * トーク要求後の応答有無チェック
         */
        function checkResponse() {
            Utility.log("checkResponse IN");
            if (refTalkStatus.current === TALK_STATUS.SENT_REQUEST_CALL) {
                const message = intl.formatMessage({
                    id: "msg_no_response",
                });
                refTalkStatus.current = TALK_STATUS.NONE;
                setTalkStatus(TALK_STATUS.NONE);
                showRequestCallResponseMessage(message);
                sendCancelTalkRequest();
            }
        }

        /**
         * トーク要求をキャンセル
         */
        function sendCancelTalkRequest() {
            if (refPartner == null || refPartner.current == null) {
                return;
            }
            if (props.loginUser.id != null && refPartner.current.id != null) {
                appServer.current.sendCancelTalkRequest(
                    props.loginUser.id,
                    refPartner.current.id
                );
            }
        }

        /**
         * EventEmitterから呼び出されるため、State値は参照できない
         * トーク要求に対する返信の受信
         * @param obj
         * @returns
         */
        async function onReceivedResponseOfTalkRequest(obj: any) {
            Utility.log("onReceivedResponseOfTalkRequest IN");
            Utility.log("talkType:" + refUserTalkType.current);
            if (refPartner == null || refPartner.current == null) {
                return;
            }
            Utility.log("onReceivedResponseOfTalkRequest 1");

            const senderId = obj.sender_id;
            const receiverId = obj.receiver_id;
            const partnerTalkType = obj.talk_type;
            const accepted = obj.accepted;
            const onTalking = obj.on_talking;
            if (
                props.loginUser == null ||
                senderId == null ||
                receiverId == null ||
                partnerTalkType == null ||
                accepted == null
            ) {
                return;
            }
            Utility.log("onReceivedResponseOfTalkRequest 2");
            refPartnerTalkType.current = partnerTalkType
            setTalkTypePartner(partnerTalkType);
            if (props.loginUser.id !== senderId) {
                return;
            }
            if (refPartner.current.id !== receiverId) {
                return;
            }
            Utility.log("onReceivedResponseOfTalkRequest 3");
            if (onTalking) {
                let message = intl.formatMessage({id: "msg_on_talking"});
                if (refPartner != null && refPartner.current != null && refPartner.current.name != null) {
                    message = intl.formatMessage({id: "msg_partner_talking"});
                    message = message.replace("USER", refPartner.current.name);
                }
    
                setDialogMessage(message);
                setOpenDialog(true);
                return;
            }
            if (!accepted) {
                if (
                    obj.hasOwnProperty("blocked") &&
                    obj.hasOwnProperty("blocked") == true
                ) {
                    const message = intl.formatMessage({
                        id: "msg_you_are_blocked",
                    });
                    showRequestCallResponseMessage(message);
                } else {
                    const message = intl.formatMessage({
                        id: "msg_rejected_calling_request",
                    });
                    showRequestCallResponseMessage(message);
                }
                refTalkStatus.current = TALK_STATUS.NONE;
                setTalkStatus(refTalkStatus.current);
            } else {
                Utility.log("onReceivedResponseOfTalkRequest 4");
                refTalkStatus.current = TALK_STATUS.UNDER_TALK;
                setTalkStatus(refTalkStatus.current);
                // video要素の初期化のための時間をもうける
                // const success = await startTalk();
                // if (!success) {
                //     terminateRtc(true);
                // }
                refIntervalId.current =  window.setInterval(() => {
                    Utility.log("onReceivedResponseOfTalkRequest 5");
                    if (isElementsPrepared()) {
                        window.clearInterval(refIntervalId.current);
                        (async () => {
                            const success = await startTalk();
                            if (!success) {
                                terminateRtc(true);
                            }
                        })();
                    }
                }, 1000);
        }
            return;
        }

        /**
         * EventEmitterから呼び出されるため、State値は参照できない
         * トーク要求発信者がトーク開始した通知の受信時
         * @param obj
         * @returns
         */
        async function onReceivedStartTalk(obj: any) {
            Utility.log("UserTalkComponent onReceivedStartTalk");
            if (Object.keys(obj).indexOf("sender_id") === -1) {
                return;
            }
            const senderId = obj.sender_id;

            if (Object.keys(obj).indexOf("receiver_id") === -1) {
                return;
            }
            const receiverId = obj.receiver_id;            
            if (props.loginUser.id !== receiverId) {
                return;
            }

            if (Object.keys(obj).indexOf("sender_talk_type") === -1) {
                return;
            }
            const senderTalkType = obj.sender_talk_type;

            if (Object.keys(obj).indexOf("receiver_talk_type") === -1) {
                return;
            }
            const receiverTalkType = obj.receiver_talk_type;

            refIsSender.current = false;
            refUserTalkType.current = receiverTalkType;
            refPartnerTalkType.current = senderTalkType;

            setTalkTypePartner(refPartnerTalkType.current);
            setTalkTypeMe(refUserTalkType.current);

            // Skywayトークン取得
            const success = await fetchSkyWayToken();
            if (!success) {
                return;
            }
            
            refTalkStatus.current = TALK_STATUS.UNDER_TALK;
            setTalkStatus(refTalkStatus.current);
            // video要素の初期化のための時間をもうける
            // window.setTimeout(async () => {
            //     const success = await startTalk();
            //     if (!success) {
            //         terminateRtc(true);
            //     }
            // }, 5000);
            refIntervalId.current =  window.setInterval(() => {
                if (isElementsPrepared()) {
                    window.clearInterval(refIntervalId.current);
                    (async () => {
                        const success = await startTalk();
                        if (!success) {
                            terminateRtc(true);
                        }
                    })();
                }
            }, 1000);
        }

        /**
         * パートナーがトーク開始失敗した通知の受信時
         */
        function onReceivedTalkFailureNotification(obj: any) {
            if (refPartner == null || refPartner.current == null) {
                return;
            }
            Utility.log("onReceivedTalkFailureNotification IN");
            if (Object.keys(obj).indexOf("sender_id") === -1) {
                return;
            }
            Utility.log("onReceivedTalkFailureNotification 1");
            const senderId = obj.sender_id;
            if (Object.keys(obj).indexOf("receiver_id") === -1) {
                return;
            }
            if (senderId !== refPartner.current.id) {
                return;
            }
            Utility.log("onReceivedTalkFailureNotification 2");
            const receiverId = obj.receiver_id;
            if (receiverId !== props.loginUser.id) {
                return;
            }

            terminateRtc(false);
        }

        /**
         * EventEmitterから呼び出される関数から呼び出されるため、State値は参照できない
         * ダイアログメッセージ表示
         * @param message
         */
        function showRequestCallResponseMessage(message: string) {
            setDialogMessage(message);
            setOpenDialog(true);
        }

        function getPartnerIdFromPathname(pathname: string): number {
            let strPartnerId = pathname.replace("/conversation/talk/", "");
            strPartnerId = strPartnerId.replace("/conversation/chat/", "");
            try {
                return parseInt(strPartnerId);
            } catch (error) {
                return 0;
            }
        }

        function isElementsPrepared(): boolean {
            Utility.log("isElementsPrepared IN")
            if (refUserTalkType.current === TALK_TYPE.VIDEO) {
                if (refVideoElementMe == null || refVideoElementMe.current == null) {
                    Utility.log("isElementsPrepared false 1")
                    return false;
                }

                if (refPartnerTalkType.current === TALK_TYPE.VIDEO) {
                    if (refVideoElementPartner == null || refVideoElementPartner.current == null) {
                        Utility.log("isElementsPrepared false 2")
                        return false;
                    }
                } else if (refPartnerTalkType.current === TALK_TYPE.VOICE) {
                    if (refAudioElementPartner == null || refAudioElementPartner.current == null) {
                        Utility.log("isElementsPrepared false 3")
                        return false;
                    }
                } else {
                    Utility.log("isElementsPrepared false 4")
                    return false;
                }
            } else if (refUserTalkType.current === TALK_TYPE.VOICE) {
                if (refAudioElementMe == null || refAudioElementMe.current == null) {
                    Utility.log("isElementsPrepared false 5")
                    return false;
                }

                if (refPartnerTalkType.current === TALK_TYPE.VIDEO) {
                    if (refVideoElementPartner == null || refVideoElementPartner.current == null) {
                        Utility.log("isElementsPrepared false 6")
                        return false;
                    }
                } else if (refPartnerTalkType.current === TALK_TYPE.VOICE) {
                    if (refAudioElementPartner == null || refAudioElementPartner.current == null) {
                        Utility.log("isElementsPrepared false 7")
                        return false;
                    }
                } else {
                    Utility.log("isElementsPrepared false 8")
                    return false;
                }
            } else {
                Utility.log("isElementsPrepared false 9")
                return false;
            }
            return true;
        }

        /**
         * トーク開始通知送信
         * @returns 
         */
        function sendTalkStartNotification(): boolean {
            if (props.loginUser == null || props.loginUser.id == null) {
                return false;
            }
            if (refPartner == null || refPartner.current == null || refPartner.current.id == null) {
                return false;
            }
            // トーク開始通知を相手に送る
            appServer.current.sendTalkStartNotification(
                props.loginUser.id,
                refPartner.current.id,
                refUserTalkType.current,
                refPartnerTalkType.current
            );
            setTalkStatus(TALK_STATUS.UNDER_TALK);
            return true;
        }

        /**
         * 性別によるクラス名取得
         * @param user
         * @returns
         */
        function getGenderClassName(user: UserModel): string {
            const gender = user.gender;
            if (gender === DbConstants.GENDER_MALE) {
                return "male";
            } else if (gender === DbConstants.GENDER_FEMALE) {
                return "female";
            } else if (gender === DbConstants.GENDER_OTHER) {
                return "other";
            } else {
                return "";
            }
        }

        /**
         * 選択パートナーのトーク許可情報取得
         */
        async function fetchPartnerTalkPermission(target: UserModel): Promise<TalkPermissionModel | null> {
            Utility.log("------------------------------------------")
            Utility.log("------fetchPartnerTalkPermission----------")
            Utility.log("------------------------------------------")
            const result = await TalkPermissionRequest.getPermission(
                props.loginUser,
                target
            )
            if (result == null || result.rtnCd == null || result.rtnCd < 0) {
                return null;
            }
            Utility.log("result:" + JSON.stringify(result.talkPermission))
            return result.talkPermission;
        }

        /**
         * EventEmitterから呼び出される関数から呼び出されるため、State値は参照できない
         * トーク開始
         */
        async function startTalk(): Promise<boolean> {
            Utility.log("startTalk IN")
            if (refPartner == null || refPartner.current == null) {
                return false;
            }
            Utility.log("startTalk 1")
            Utility.log("talk type me:" + refUserTalkType.current)
            Utility.log("talk type partner:" + refPartnerTalkType.current)
            refTalkHistory.current = new TalkHistoryModel();
            if (refIsSender) {
                refTalkHistory.current.senderId = props.loginUser.id;
                refTalkHistory.current.receiverId = refPartner.current.id;
                refTalkHistory.current.senderTalkType = refUserTalkType.current;
                refTalkHistory.current.receiverTalkType = refPartnerTalkType.current;
            } else {
                refTalkHistory.current.senderId = refPartner.current.id;
                refTalkHistory.current.receiverId = props.loginUser.id;
                refTalkHistory.current.senderTalkType = refPartnerTalkType.current;
                refTalkHistory.current.receiverTalkType = refUserTalkType.current;
            }

            let success = false;
            if (refSkyWayToken == null || refSkyWayToken.current == null) {
                success = await fetchSkyWayToken();
                if (!success) {
                    setTalkStatus(TALK_STATUS.NONE);
                    refTalkStatus.current = TALK_STATUS.NONE;
                    if (refIsSender.current === true) {
                        createTalkHistory();
                    }
                    return false;
                }
            }
            refTalkHistory.current.fetchSkywayToken = 1;
            Utility.log("startTalk 2")
            success = await createAndAttachTalkStream();
            if (!success) {
                setTalkStatus(TALK_STATUS.NONE);
                refTalkStatus.current = TALK_STATUS.NONE;
                if (refIsSender.current === true) {
                    createTalkHistory();
                }
            return false;
            }
            refTalkHistory.current.createAndAttachTalkStream = 1;
            Utility.log("startTalk 3")

            success = await joinSkyWayRoom();
            if (!success) {
                setTalkStatus(TALK_STATUS.NONE);
                refTalkStatus.current = TALK_STATUS.NONE;
                if (refIsSender.current === true) {
                    createTalkHistory();
                }
            return false;
            }
            refTalkHistory.current.joinSkywayRoom = 1;
            Utility.log("startTalk 4")

            success = await startCasting();
            if (!success) {
                setTalkStatus(TALK_STATUS.NONE);
                refTalkStatus.current = TALK_STATUS.NONE;
                if (refIsSender.current === true) {
                    createTalkHistory();
                }
            return false;
            }
            refTalkHistory.current.startCasting = 1;
            Utility.log("startTalk 5")

            success = await subscribeTalk();
            if (!success) {
                setTalkStatus(TALK_STATUS.NONE);
                refTalkStatus.current = TALK_STATUS.NONE;
                if (refIsSender.current === true) {
                    createTalkHistory();
                }
            return false;
            }
            refTalkHistory.current.subscribeTalk = 1;
            if (refIsSender.current === true) {
                createTalkHistory();
            }
            Utility.log("startTalk 6")

            if (refIsSender.current) {
                window.sessionStorage.setItem("rtcSenderId", String(props.loginUser.id));
                window.sessionStorage.setItem("rtcReceiverId", String(refPartner.current.id));
                window.sessionStorage.setItem("rtcSenderName", props.loginUser.name == null ? "" : props.loginUser.name);
                window.sessionStorage.setItem("rtcReceiverName", refPartner.current.name == null ? "" : refPartner.current.name);
                window.sessionStorage.setItem("rtcSenderTalkType", String(refUserTalkType.current));
                window.sessionStorage.setItem("rtcReceiverTalkType", String(refPartnerTalkType.current));
                sendTalkStartNotification();
            } else {
                window.sessionStorage.setItem("rtcSenderId", String(refPartner.current.id));
                window.sessionStorage.setItem("rtcReceiverId", String(props.loginUser.id));
                window.sessionStorage.setItem("rtcSenderName", refPartner.current.name == null ? "" : refPartner.current.name);
                window.sessionStorage.setItem("rtcReceiverName", props.loginUser.name == null ? "" : props.loginUser.name);
                window.sessionStorage.setItem("rtcSenderTalkType", String(refUserTalkType.current));
                window.sessionStorage.setItem("rtcReceiverTalkType", String(refPartnerTalkType.current));
            }
            window.sessionStorage.setItem("rtcTalkStatus", String(refTalkStatus.current));

            return true;
        }

        /**
         * EventEmitterから呼び出される関数から呼び出されるため、State値は参照できない
         * SkyWayトークン取得
         * @returns
         */
        async function fetchSkyWayToken(): Promise<boolean> {
            Utility.log("fetchSkyWayToken IN");
            if (
                props.loginUser == null ||
                props.loginUser.id == null ||
                props.loginUser.bearerToken == null ||
                refPartner == null ||
                refPartner.current == null ||
                refPartner.current.id == null
            ) {
                return false;
            }
            let channelName =
                String(props.loginUser.id) +
                "_" +
                String(refPartner.current.id);
            if (!refIsSender.current) {
                channelName =
                String(refPartner.current.id) +
                "_" +
                String(props.loginUser.id);
            }
            const result = await UserRequest.getSkyWayToken(
                props.loginUser,
                refPartner.current.id,
                channelName
            );
            if (result == null || result.skywayToken == null) {
                return false;
            }
            refSkyWayToken.current = result.skywayToken;

            return true;
        }

        /**
         * トークストリーム作成
         * @returns 
         */
        async function createAndAttachTalkStream(): Promise<boolean> {
            Utility.log("createAndAttachTalkStream IN")
            if (refPartnerTalkType.current === TALK_TYPE.VIDEO) {
                if (refVideoElementPartner == null || refVideoElementPartner.current == null) {
                    return false;
                }
            } else if (refPartnerTalkType.current === TALK_TYPE.VOICE) {
                if (refAudioElementPartner == null || refAudioElementPartner.current == null) {
                    return false;
                }
            }
            Utility.log("createAndAttachTalkStream 1")

            Utility.log("createAndAttachTalkStream 2")
            try {
                // 自分のカメラ・マイクに接続
                if (refUserTalkType.current === TALK_TYPE.VIDEO) {
                    const { audio, video } = await SkyWayStreamFactory.createMicrophoneAudioAndCameraStream({
                        video: { height: 640, width: 360, frameRate: 15 },
                    });
                    refAudioStream.current = audio;
                    refVideoStream.current = video;
                    Utility.log("createAndAttachTalkStream 3")
                    if (refVideoElementMe == null || refVideoElementMe.current == null) {
                        return false;
                    }
                    Utility.log("createAndAttachTalkStream 4")
                    video.attach(refVideoElementMe.current);
                    await refVideoElementMe.current.play();
                } else {
                    const audio = await SkyWayStreamFactory.createMicrophoneAudioStream();
                    if (audio == null) {
                        alert();
                        return false;
                    }
                    refAudioStream.current = audio;
                    Utility.log("createAndAttachTalkStream 5")
                    if (refAudioElementMe == null || refAudioElementMe.current == null) {
                        return false;
                    }
                    Utility.log("createAndAttachTalkStream 6")
                    audio.attach(refAudioElementMe.current as HTMLAudioElement);
                    // await refAudioElementMe.current.play();
                }
                if (props.loginUser.id == null) {
                    return false;
                }
                Utility.log("createAndAttachTalkStream 7")
                if (refPartner == null || refPartner.current == null || refPartner.current.id == null) {
                    return false;
                }
                Utility.log("createAndAttachTalkStream 8")
                if (refSkyWayToken == null || refSkyWayToken.current == null) {
                    return false;
                }
                Utility.log("createAndAttachTalkStream 9")

                return true;
            } catch (error) {
                const message = intl.formatMessage({id: "msg_devices_not_allowed"});
                alert(message);
                Utility.log(error)
                return false;
            }
        }

        /**
         * トーク部屋にJOIN
         * @returns 
         */
        async function joinSkyWayRoom(): Promise<boolean> {
            try {
                if (props.loginUser.name == null) {
                    return false;
                }
                if (refPartner == null || refPartner.current == null || refPartner.current.id == null) {
                    return false;
                }
                if (refSkyWayToken == null || refSkyWayToken.current == null) {
                    return false;
                }
                let roomName =
                    String(props.loginUser.id) +
                    "_" +
                    String(refPartner.current.id);
                if (!refIsSender.current) {
                    roomName =
                    String(refPartner.current.id) +
                    "_" +
                    String(props.loginUser.id);
                }
    
                const context = await SkyWayContext.Create(refSkyWayToken.current);
                refRoom.current = await SkyWayRoom.FindOrCreate(context, {
                    type: "p2p",
                    name: roomName,
                });
                // const person: PersonInit = {
                //     name: props.loginUser.name
                // }
                // refRoomMemberMe.current = await refRoom.current.join(person);
                refRoomMemberMe.current = await refRoom.current.join();
                refRoomMemberMe.current.onPublicationSubscribed.add((e) => {
                    const subscription = e.subscription;
                    const subscriber = subscription.subscriber
                    const publisher = subscription.publication.publisher;
                    if (refIsSender.current === true &&
                        refTalkHistory != null &&
                        refTalkHistory.current != null
                    ) {
                        refTalkHistory.current.startTime = Math.floor(new Date().getTime()/1000);
                        updateTalkHistory();
                    }
                    Utility.log("subscriber:" + subscriber.name + " publisher:" + publisher.name);
                });

                return true;
            } catch (error) {
                return false;
            }
        }
        
        /**
         * 配信開始
         * @returns 
         */
        async function startCasting(): Promise<boolean> {
            Utility.log("startCasting IN")
            if (refPartner == null || refPartner.current == null) {
                return false;
            }
            Utility.log("startCasting 1")
            if (refRoomMemberMe == null || refRoomMemberMe.current == null) {
                return false;
            }
            Utility.log("startCasting 2")
            try {
                if (refUserTalkType.current === TALK_TYPE.VIDEO) {
                    Utility.log("startCasting 3")
                    if ((!audioDisabled.current && (refAudioStream == null || refAudioStream.current == null)) ||
                        refVideoStream == null || refVideoStream.current == null ||
                        refVideoElementMe == null || refVideoElementMe.current == null) {
                            return false;
                    }
                    Utility.log("startCasting 4")
                    refPublicationVideo.current = await refRoomMemberMe.current.publish(refVideoStream.current);
                    refPublicationVideo.current.onSubscribed.add((e1) => {
                        const subscription = e1.subscription;
                        const {removeListener, disposer} = subscription.subscriber.onLeft.add(async () => {
                            Utility.log(">>>> subscription.subscriber.onLeft IN")
                            await terminateRtc(false);
                            Utility.log(">>>> subscription.subscriber.onLeft OUT")
                        });
                        Utility.log("refPublicationVideo.current.onSubscribed IN")
                        Utility.log("subscriber:" + e1.subscription.subscriber.name);
                        Utility.log("refPublicationVideo.current.onSubscribed OUT")
                    })
                    if (!audioDisabled.current) {
                        if (refAudioStream == null || refAudioStream.current == null) {
                            return false;
                        }
                        refPublicationAudio.current = await refRoomMemberMe.current.publish(refAudioStream.current);
                    }
                    Utility.log("startCasting 5")
                    await refVideoElementMe.current.play();
                } else {
                    Utility.log("startCasting 6")
                    if (refAudioStream == null || refAudioStream.current == null ||
                        refAudioElementMe == null || refAudioElementMe.current == null)
                    {
                        if (refAudioStream == null || refAudioStream.current == null)
                        {
                            Utility.log("refAudioStream is null")
                        }
                        if (refAudioElementMe == null || refAudioElementMe.current == null)
                        {
                            Utility.log("refAudioElementMe is null")
                        }
                        return false;
                    }
                    Utility.log("startCasting 7")
                    refPublicationAudio.current = await refRoomMemberMe.current.publish(refAudioStream.current);
                    refPublicationAudio.current.onSubscribed.add((e1) => {
                        const subscription = e1.subscription;
                        const {removeListener, disposer} = subscription.subscriber.onLeft.add(async () => {
                            Utility.log(">>>> subscription.subscriber.onLeft IN")
                            await terminateRtc(false);
                            Utility.log(">>>> subscription.subscriber.onLeft OUT")
                        });
                        if (refTalkHistory != null && refTalkHistory.current != null) {
                            refTalkHistory.current.startTime = Math.floor(new Date().getTime()/1000);
                        }
                        Utility.log("refPublicationVideo.current.onSubscribed IN")
                        Utility.log("subscriber:" + e1.subscription.subscriber.name);
                        Utility.log("refPublicationVideo.current.onSubscribed OUT")
                    })
                    Utility.log("startCasting 8")
                    // await refAudioElementMe.current.play();
                }
                refTalkStatus.current = TALK_STATUS.UNDER_TALK;
                setTalkStatus(refTalkStatus.current);

                if (refIsSender.current) {
                    window.sessionStorage.setItem("rtcSenderId", String(props.loginUser.id));
                    window.sessionStorage.setItem("rtcReceiverId", String(refPartner.current.id));
                    window.sessionStorage.setItem("rtcSenderName", props.loginUser.name == null ? "" : props.loginUser.name);
                    window.sessionStorage.setItem("rtcReceiverName", refPartner.current.name == null ? "" : refPartner.current.name);
                    window.sessionStorage.setItem("rtcSenderTalkType", String(refUserTalkType.current));
                    window.sessionStorage.setItem("rtcReceiverTalkType", String(refPartnerTalkType.current));
                } else {
                    window.sessionStorage.setItem("rtcSenderId", String(refPartner.current.id));
                    window.sessionStorage.setItem("rtcReceiverId", String(props.loginUser.id));
                    window.sessionStorage.setItem("rtcSenderName", refPartner.current.name == null ? "" : refPartner.current.name);
                    window.sessionStorage.setItem("rtcReceiverName", props.loginUser.name == null ? "" : props.loginUser.name);
                    window.sessionStorage.setItem("rtcSenderTalkType", String(refUserTalkType.current));
                    window.sessionStorage.setItem("rtcReceiverTalkType", String(refPartnerTalkType.current));
                }
                window.sessionStorage.setItem("rtcTalkStatus", String(refTalkStatus.current));
    
                return true;
            } catch (error) {
                Utility.log(error);
                finishCasting();
                return false;
            }
        }
        /**
         * 配信停止
         */
        async function stopCasting(): Promise<boolean> {
            Utility.log(">>>>> stopCasting IN")
            if (refRoomMemberMe == null || refRoomMemberMe.current == null) {
                return false;
            }
            try {
                if (refUserTalkType.current === TALK_TYPE.VIDEO) {
                    if (refVideoElementMe == null || refVideoElementMe.current == null ||
                        refPublicationVideo == null || refPublicationVideo.current == null ||
                        (!audioDisabled.current && (refPublicationAudio == null || refPublicationAudio.current == null))
                    ) {
                        return false;
                    }
                    await refRoomMemberMe.current.unpublish(refPublicationVideo.current);
                    if (!audioDisabled.current) {
                        if (refPublicationAudio == null || refPublicationAudio.current == null) {
                            return false;
                        }
                        await refRoomMemberMe.current.unpublish(refPublicationAudio.current);
                    }
                    await refVideoElementMe.current.pause();
                } else {
                    if (refPublicationAudio == null || refPublicationAudio.current == null ||
                        refAudioElementMe == null || refAudioElementMe.current == null) {
                            return false;
                    }
                    await refRoomMemberMe.current.unpublish(refPublicationAudio.current);
                    // await refAudioElementMe.current.pause();
                }
                refTalkStatus.current = TALK_STATUS.PAUSE_TALK;
                setTalkStatus(refTalkStatus.current);

                Utility.log("removeSessionStorage 4")
                removeSessionStorage();
                // window.sessionStorage.removeItem("rtcSenderId");
                // window.sessionStorage.removeItem("rtcReceiverId");
                // window.sessionStorage.removeItem("rtcSenderName");
                // window.sessionStorage.removeItem("rtcReceiverName");
                // window.sessionStorage.removeItem("rtcSenderTalkType");
                // window.sessionStorage.removeItem("rtcReceiverTalkType");
                // window.sessionStorage.removeItem("rtcTalkStatus");

                return true;
            } catch (error) {
                Utility.log(error);
                finishCasting();
                return false;
            }
        }
        /**
         * 配信終了
         */
        async function finishCasting() {
            await terminateRtc(true);
        }

        /**
         * トーク購読
         * @returns 
         */
        async function subscribeTalk(): Promise<boolean> {
            Utility.log(">>>>> subscribeTalk IN")
            if (refRoom == null || refRoom.current == null) {
                return false;
            }
            Utility.log(">>>>> subscribeTalk 1")
            const subscribeAndAttach = async (publication: RoomPublication<LocalStream>) => {
                if (refRoomMemberMe == null || refRoomMemberMe.current == null) {
                    return;
                }
                if (publication.publisher.id === refRoomMemberMe.current.id) {
                    return;
                }
                const { stream } = await refRoomMemberMe.current.subscribe(publication.id);
                if (stream == null) {
                    return;
                }
                if (refPartnerTalkType == null || refPartnerTalkType.current == null) {
                    return;
                }
                if (refPartnerTalkType.current === TALK_TYPE.VIDEO) {
                    if ((stream as RemoteVideoStream).track.kind === "video") {
                        if (refVideoElementPartner == null || refVideoElementPartner.current == null) {
                            return;
                        }
                        try {
                            (stream as RemoteVideoStream).attach(refVideoElementPartner.current);
                        } catch (error) {
                            return;
                        }
                    }
                    else if ((stream as RemoteVideoStream).track.kind === "audio") {
                        if (audioDisabled.current) {
                            return;
                        }
                        if (refVideoElementPartner == null || refVideoElementPartner.current == null) {
                            return;
                        }
                        (stream as RemoteVideoStream).attach(refVideoElementPartner.current);
                        await refVideoElementPartner.current.play();
                    }
                } else
                if (refPartnerTalkType.current === TALK_TYPE.VOICE) {
                    if ((stream as RemoteAudioStream).track.kind === "audio") {
                        if (refAudioElementPartner == null || refAudioElementPartner.current == null) {
                            return;
                        }
                        (stream as RemoteVideoStream).attach(refAudioElementPartner.current);
                        await refAudioElementPartner.current.play();
                    }
                }
            } 

            Utility.log(">>>>> subscribeTalk 2")
            refRoom.current.publications.forEach(subscribeAndAttach);
            Utility.log(">>>>> subscribeTalk 3")
            refRoom.current.onStreamPublished.add((e) => subscribeAndAttach(e.publication));
            Utility.log(">>>>> subscribeTalk 4")

            return true;
        }

        /**
         * トーク履歴作成
         */
        async function createTalkHistory() {
            if (refTalkHistory == null || refTalkHistory.current == null) {
                return;
            }
            const result = await TalkHistoryRequest.create(props.loginUser, refTalkHistory.current);
            Utility.log("result:" + JSON.stringify(result))
            if (result != null && result.talkHistory != null && result.talkHistory.id != null) {
                refTalkHistory.current.id = result.talkHistory.id;
            }
        }
        /**
         * トーク履歴更新
         */
        async function updateTalkHistory() {
            if (refTalkHistory == null || refTalkHistory.current == null || refTalkHistory.current.id == null) {
                return;
            }
            const result = await TalkHistoryRequest.update(props.loginUser, refTalkHistory.current);
        }

        return (
            <Box
                className="component UserTalkComponent"
                sx={{
                    position: "relative",
                    margin: "auto",
                }}
            >
                {partner != null && 
                talkStatus !== TALK_STATUS.UNDER_TALK &&
                talkStatus !== TALK_STATUS.PAUSE_TALK && (
                    <>
                        <div className="calling-area">
                            <div className="partner-thumbnail-area">
                                <img
                                    className="partner-thumbnail"
                                    src={
                                        partner.iconName != null &&
                                        partner.iconName.length > 0
                                            ? UrlConstants.URL_S3_USER_ICON +
                                              partner.iconName
                                            : "/images/no_image.png"
                                    }
                                />
                                <div
                                    className={`${getGenderClassName(
                                        partner
                                    )} partner-name`}
                                >
                                    {partner.name}
                                </div>
                            </div>
                            <div className="connecting-image-area">
                                <img
                                    className="calling-image"
                                    src={
                                        talkStatus ==
                                        TALK_STATUS.SENT_REQUEST_CALL
                                            ? CallingGif
                                            : CallingImage
                                    }
                                />
                            </div>
                            <div
                                className={`${getGenderClassName(
                                    props.loginUser
                                )} user-name`}
                            >
                                {props.loginUser.name}
                            </div>
                        </div>
                        <div className="button-area">
                            <Button
                                className="app-button calling-button voice"
                                variant="text"
                                startIcon={<img src={VoiceCalling} />}
                                onClick={onClickVoiceCall}
                            >
                                <FormattedMessage id="talk_voice" />
                            </Button>
                            <Button
                                className="app-button calling-button video"
                                variant="text"
                                startIcon={<img src={VideoCalling} />}
                                onClick={onClickVideoCall}
                            >
                                <FormattedMessage id="talk_video" />
                            </Button>
                        </div>
                        <OkDialog
                            open={openDialog}
                            title={intl.formatMessage({
                                id: "dlg_title_message",
                            })}
                            message={dialogMessage}
                            onClose={() => {
                                setOpenDialog(false);
                            }}
                            onOk={() => {
                                setOpenDialog(false);
                            }}
                        />
                    </>
                )}
                {partner != null && (talkStatus === TALK_STATUS.UNDER_TALK || talkStatus === TALK_STATUS.PAUSE_TALK) && (
                    <div ref={refTalkScreenElement} className="talk-screen">
                        <div className="operation-area">
                            {talkStatus === TALK_STATUS.UNDER_TALK && (
                                <>
                                    <label className="talk-label">
                                        <FormattedMessage id={"talking"} />
                                    </label>
                                    <Button
                                        className="app-button btn-stop-casting"
                                        onClick={stopCasting}
                                        color="primary"
                                    >
                                        <FormattedMessage id={"btn_stop_casting"} />
                                    </Button>
                                </>
                            )}
                            {talkStatus === TALK_STATUS.PAUSE_TALK && (
                                <Button
                                    className="app-button btn-start-casting"
                                    onClick={startCasting}
                                    color="primary"
                                >
                                    <FormattedMessage id={"btn_start_casting"} />
                                </Button>
                            )}
                            <Button
                                className="app-button btn-finish-casting"
                                onClick={finishCasting}
                                color="primary"
                            >
                                <FormattedMessage id={"btn_exit"} />
                            </Button>
                        </div>
                        <div className="screen-area">
                            <Draggable>
                                <div className="my-area">
                                    {talkTypeMe === TALK_TYPE.VIDEO && (
                                        <video 
                                            ref={refVideoElementMe} 
                                            id="local-video-me" 
                                            muted={false} 
                                            autoPlay 
                                            playsInline
                                        ></video>
                                    )}
                                    {talkTypeMe === TALK_TYPE.VOICE && (
                                        <>
                                            <audio 
                                                ref={refAudioElementMe} 
                                                id="local-audio-me" 
                                                autoPlay={false}
                                                playsInline
                                            ></audio>
                                            <img
                                                className="my-thumbnail"
                                                src={
                                                    props.loginUser.iconName != null &&
                                                    props.loginUser.iconName.length > 0
                                                        ? UrlConstants.URL_S3_USER_ICON +
                                                        props.loginUser.iconName
                                                        : "/images/no_image.png"
                                                }
                                            />
                                        </>
                                    )}
                                </div>
                            </Draggable>
                            <div className={talkTypePartner === TALK_TYPE.VIDEO ? "video partner-area" : "voice partner-area"}>
                                {talkTypePartner === TALK_TYPE.VIDEO && (
                                    <video 
                                        ref={refVideoElementPartner} 
                                        id="local-video-partner" 
                                        controls 
                                        muted={false} 
                                        autoPlay={false}
                                    ></video>
                                )}
                                {talkTypePartner === TALK_TYPE.VOICE && (
                                    <>
                                        <audio 
                                            ref={refAudioElementPartner} 
                                            id="local-audio-partner" 
                                            controls 
                                            muted={false} 
                                            autoPlay={true}
                                        ></audio>
                                        <img
                                            className="partner-thumbnail"
                                            src={
                                                partner.iconName != null &&
                                                partner.iconName.length > 0
                                                    ? UrlConstants.URL_S3_USER_ICON +
                                                    partner.iconName
                                                    : "/images/no_image.png"
                                            }
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.talkUser !== nextProps.talkUser) {
            return false;
        }
        if (prevProps.talkUser != null && nextProps.talkUser != null) {
            if (prevProps.talkUser.userId !== nextProps.talkUser.userId) {
                return false;
            }
            if (prevProps.talkUser.partnerId !== nextProps.talkUser.partnerId) {
                return false;
            }
            if (prevProps.talkUser.partner !== nextProps.talkUser.partner) {
                return false;
            }
            if (prevProps.talkUser.partner != null && nextProps.talkUser.partner != null) {
                const prevPartner = prevProps.talkUser.partner;
                const nextPartner = nextProps.talkUser.partner;
                if (prevPartner.id !== nextPartner.id) {
                    return false;
                }
            }
        }
        if (prevProps.pathname !== nextProps.pathname) {
            return false;
        }
        return true;
    }
);
