import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { SelectedItemAction } from "redux/Actions";
import { TalkPermissionModel, TalkUserModel, UserModel } from "models/Models";
import {
    UserCommunicationComponent,
    TalkUserListComponent,
} from "components/Components";
import { getTimeDifference, getLanguageCode } from "redux/Selectors";
import { AppConstants, USER_LIST_TYPE } from "constants/Constants";
import { TalkUserRequest } from "api/requests/Requests";
import { TalkUserListHandler } from "handlers/handler";
import Utility from "utils/Utility";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/user_communication/UserCommunication.scss";

type Props = {
    loginUser: UserModel;
};

const UserCommunication: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ UserCommunication");
        /***** 定数、変数 */
        const selector = useSelector((state) => state);
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const theme = useTheme();
        // リクエストパラメータ
        // const requestParams = window.location.search;
        // const urlFragment = window.location.hash;
        const href = window.location.href;
        // デバイスサイズ
        const isSizeXS = useMediaQuery(theme.breakpoints.down("sm"));
        const langCd = getLanguageCode(selector);

        /***** useRef */
        const refTimeDifference = React.useRef<number>(
            getTimeDifference(selector)
        );
        // DOMエレメント
        const refTalkUserListComponent =
            React.useRef<TalkUserListHandler | null>(null);
        const refPageWrapper = React.useRef<HTMLDivElement>();
        // selectedTalkUserの参照
        const refSelectedTalkUser = React.useRef<TalkUserModel>();

        /***** useState */
        // 時差
        const [timeDifference, setTimeDifference] = React.useState<number>();
        // ユーザ一覧エリア表示フラグ
        const [showUserListArea, setShowUserListArea] = React.useState<boolean>(true);
        // 会話エリア(チャット、トーク)表示フラグ
        const [showConversationArea, setShowConversationArea] = React.useState<boolean>(true);
        // ユーザ一覧エリアのwidth
        const [userListAreaWidth, setUserListAreaWidth] =
            React.useState<number>(400);
        // 選択されたトークユーザ
        const [selectedTalkUser, setSelectedTalkUser] =
            React.useState<TalkUserModel>();

        /***** useEffect */
        React.useEffect(() => {
            // Utility.log("UserCommunication useEffect")
            pushDataLayer({
                event: 'page_view',
                screen: "会話",
                path: window.location.pathname,
            });

            if (isSizeXS) {
                setUserListAreaWidth(0);
            }
        }, []);
        React.useEffect(() => {
            init();
        }, [href]);

        /**
         * 初期処理
         */
        function init() {
            const pathname = window.location.pathname;
            // スマホの場合
            if (isSizeXS) {
                // 会話トップの場合
                if (pathname === "/conversation") {
                    setSelectedTalkUser(undefined);
                    setShowUserListArea(true);
                    setShowConversationArea(false);
                }
                // チャットの場合
                else if (pathname.indexOf("/conversation/chat/") > -1) {
                    setShowUserListArea(false);
                    setShowConversationArea(true);
                }
                // トークの場合
                else if (pathname.indexOf("/conversation/talk/") > -1) {
                    setShowUserListArea(false);
                    setShowConversationArea(true);
                }
            }
            // PC、タブレットの場合
            else {
                // 会話トップの場合
                if (pathname === "/conversation") {
                    setSelectedTalkUser(undefined);
                    setShowUserListArea(true);
                    setShowConversationArea(true);
                }
                // チャットの場合
                else if (pathname.indexOf("/conversation/chat/") > -1) {
                    setShowUserListArea(true);
                    setShowConversationArea(true);
                }
                // トークの場合
                else if (pathname.indexOf("/conversation/talk/") > -1) {
                    setShowUserListArea(true);
                    setShowConversationArea(true);
                }
            }
        }

        /**
         * マウス押下時
         * @param ev
         * @returns
         */
        function onMouseDown(ev: React.DragEvent<HTMLDivElement>): void {
            if (refPageWrapper == null || refPageWrapper.current == null) {
                return;
            }
            refPageWrapper.current.addEventListener("mouseup", onMouseUp);
            refPageWrapper.current.addEventListener("mousemove", onMouseMove);
        }
        /**
         * マウス移動時
         * @param ev
         * @returns
         */
        function onMouseMove(ev: MouseEvent): void {
            if (refPageWrapper == null || refPageWrapper.current == null) {
                return;
            }
            const innerWidth = window.innerWidth;
            if (innerWidth >= 1536) {
                setUserListAreaWidth(ev.pageX - AppConstants.AD_AREA_WIDTH);
            } else {
                setUserListAreaWidth(ev.pageX);
            }
        }
        /**
         * マウスアップ時
         * @param ev
         * @returns
         */
        function onMouseUp(ev: MouseEvent): void {
            if (refPageWrapper == null || refPageWrapper.current == null) {
                return;
            }
            refPageWrapper.current.removeEventListener("mouseup", onMouseUp);
            refPageWrapper.current.removeEventListener(
                "mousemove",
                onMouseMove
            );
        }

        /**
         * トークユーザ選択時
         * @param user
         */
        async function onSelectTalkUser(talkUser: TalkUserModel | undefined, transfer: boolean = true) {
            if (talkUser == null || talkUser.partner == null) {
                setSelectedTalkUser(undefined);
                return;
            }
            if (selectedTalkUser != null && selectedTalkUser.userId == talkUser.userId && selectedTalkUser.partnerId == talkUser.partnerId) {
                return
            }
            setSelectedTalkUser(JSON.parse(JSON.stringify(talkUser)));
            if (transfer) {
                const search = window.location.search;
                navigate(`/conversation/chat/${talkUser.partnerId}${search}`);
            }
        }

        /**
         * トークユーザ取得
         * @returns
         */
        async function fetchTalkUser(
            targetId: number
        ): Promise<TalkUserModel | null> {
            if (props.loginUser == null) {
                return null;
            }
            // リクエスト実行
            const result = await TalkUserRequest.getTalkUser(
                props.loginUser,
                targetId
            );
            if (result == null || result.rtnCd == null || result.rtnCd < 0) {
                return null;
            }
            return result.talkUser;
        }

        /**
         * レンダリング
         */
        return (
            <Box ref={refPageWrapper} className="pageWrapper UserCommunication">
                {showUserListArea && (
                    <>
                        {
                            // トークユーザ一覧エリア
                        }
                        <Box
                            className="talk-user-list-area-wrapper"
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: userListAreaWidth + "px",
                                },
                            }}
                        >
                            {
                                // トークユーザ一覧エリア
                            }
                            <TalkUserListComponent
                                ref={refTalkUserListComponent}
                                loginUser={props.loginUser}
                                selectedTalkUser={selectedTalkUser}
                                langCd={langCd == null ? "en" : langCd}
                                timeDifference={
                                    timeDifference == null ? 0 : timeDifference
                                }
                                onSelectTalkUser={onSelectTalkUser}
                            />
                            {
                                // ディバイダー
                            }
                            <Box
                                className="divider"
                                onMouseDown={onMouseDown}
                            ></Box>
                        </Box>
                    </>
                )}

                {showConversationArea && (
                    <>
                        {
                            // 会話エリア
                        }
                        <Box className="conversation-area-wrapper">
                            <UserCommunicationComponent
                                loginUser={props.loginUser}
                                talkUser={selectedTalkUser}
                                userListAreaWidth={userListAreaWidth}
                            />
                        </Box>
                    </>
                )}
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default UserCommunication;
