import React from "react";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Utility from "utils/Utility";
import { AppConstants } from "constants/AppConstants";
import "styles/components/AdContentsBottomComponent.scss";

export const AdContentsBottomComponent: React.FC<any> = React.memo(
    (props) => {
        Utility.log("@@@@@ AdContentsBottomComponent IN");
        /***** 定数、変数 */

        /**
         * レンダリング
         */
        return (
            <div className="ad-area bottom">
                <Splide
                    className="slider-container"
                    options={ {
                        type: 'loop',
                        autoplay: true,
                        interval: AppConstants.AD_INTERVAL_BOTTOM,
                        perPage: 1,
                        perMove: 1,
                        direction: 'ttb',
                        height: '50px',
                        fixedHeight: '50px',
                        arrows: false,
                        pagination: false,
                        padding: 0,
                    } }
                >
                    {
                        // 大阪王将
                    }
                    <SplideSlide>
                        <div className="ad-item">
                            <a href="https://px.a8.net/svt/ejp?a8mat=3Z0IWZ+28YZTM+598S+5ZEMP" 
                                rel="nofollow">
                                <img 
                                    width="320" 
                                    height="50" 
                                    alt="" 
                                    src="https://www20.a8.net/svt/bgt?aid=240209603136&wid=002&eno=01&mid=s00000024526001005000&mc=1" />
                            </a>
                            <img 
                                width="1" 
                                height="1" 
                                src="https://www19.a8.net/0.gif?a8mat=3Z0IWZ+28YZTM+598S+5ZEMP" alt="" />
                        </div>
                    </SplideSlide>
                    {
                        // 毎日“考える・買う・作る"を時短に 【食材宅配「ショクブン」】
                    }
                    <SplideSlide>
                        <div className="ad-item">
                            <a href="https://px.a8.net/svt/ejp?a8mat=3Z0JPC+6P4LJU+4X5I+5ZU29" rel="nofollow">
                                <img width="320" height="50" alt="" src="https://www23.a8.net/svt/bgt?aid=240210624405&wid=002&eno=01&mid=s00000022959001007000&mc=1" />
                            </a>
                            <img width="1" height="1" src="https://www15.a8.net/0.gif?a8mat=3Z0JPC+6P4LJU+4X5I+5ZU29" alt="" />
                        </div>
                    </SplideSlide>
                    {
                        // プロポリス・マヌカハニーなど蜂産品の真実が分る専門サイト【TCN ONLINE SHOP】
                    }
                    <SplideSlide>
                        <div className="ad-item">
                            <a href="https://px.a8.net/svt/ejp?a8mat=3Z0JPC+6LJZX6+2G2U+6GRMP" rel="nofollow">
                                <img width="320" height="50" alt="" src="https://www28.a8.net/svt/bgt?aid=240210624399&wid=002&eno=01&mid=s00000011415001086000&mc=1"/>
                            </a>
                            <img width="1" height="1" src="https://www17.a8.net/0.gif?a8mat=3Z0JPC+6LJZX6+2G2U+6GRMP" alt=""/>
                        </div>
                    </SplideSlide>
                    {
                        // ★YOSHIKEI★４つの選べるミールキットお試し5days　＼全国で好評販売中／
                    }
                    <SplideSlide>
                        <div className="ad-item">
                            <a href="https://px.a8.net/svt/ejp?a8mat=3Z0JPC+6KD4PM+1QM6+HY7W1" rel="nofollow">
                                <img width="320" height="50" alt="" src="https://www26.a8.net/svt/bgt?aid=240210624397&wid=002&eno=01&mid=s00000008115003015000&mc=1"/>
                            </a>
                            <img width="1" height="1" src="https://www11.a8.net/0.gif?a8mat=3Z0JPC+6KD4PM+1QM6+HY7W1" alt=""/>
                        </div>
                    </SplideSlide>
                    {
                        // ファンケルから待望のプログラム登場！お得な増量セット！【ファンケルの発芽米】
                    }
                    <SplideSlide>
                        <div className="ad-item">
                            <a href="https://px.a8.net/svt/ejp?a8mat=3Z0JPC+6A8RFE+3GHY+NY1Y9" rel="nofollow">
                                <img width="320" height="50" alt="" src="https://www27.a8.net/svt/bgt?aid=240210624380&wid=002&eno=01&mid=s00000016135004022000&mc=1"/>
                            </a>
                            <img width="1" height="1" src="https://www15.a8.net/0.gif?a8mat=3Z0JPC+6A8RFE+3GHY+NY1Y9" alt=""/>
                        </div>
                    </SplideSlide>
                    {
                        // 年間来店者26万人超！有名アスリート、著名人が通う【筋肉食堂】が提供する宅食サービス
                    }
                    <SplideSlide>
                        <div className="ad-item">
                            <a href="https://px.a8.net/svt/ejp?a8mat=3Z0JPC+5YXIXM+4R6I+5ZEMP" rel="nofollow">
                                <img width="320" height="50" alt="" src="https://www23.a8.net/svt/bgt?aid=240210624361&wid=002&eno=01&mid=s00000022185001005000&mc=1"/>
                            </a>
                            <img width="1" height="1" src="https://www19.a8.net/0.gif?a8mat=3Z0JPC+5YXIXM+4R6I+5ZEMP" alt=""/>
                        </div>
                    </SplideSlide>
                    {
                        // 全国90院以上展開の美容クリニック【美容整形するなら、TCB 東京中央美容外科】
                    }
                    <SplideSlide>
                        <div className="ad-item">
                            <a href="https://px.a8.net/svt/ejp?a8mat=3Z0IX1+740FOA+46J6+BZGEP" rel="nofollow">
                                <img width="320" height="50" alt="" src="https://www29.a8.net/svt/bgt?aid=240209605430&wid=002&eno=01&mid=s00000019509002013000&mc=1"/>
                            </a>
                            <img width="1" height="1" src="https://www17.a8.net/0.gif?a8mat=3Z0IX1+740FOA+46J6+BZGEP" alt=""/>
                        </div>
                    </SplideSlide>
                    {
                        // 元祖ジェルネイルキットと言えばシャイニージェル【シャイニージェル公式ショッ プ本店】
                    }
                    <SplideSlide>
                        <div className="ad-item">
                            <a href="https://px.a8.net/svt/ejp?a8mat=3Z0IX1+6F089M+2SNC+61Z81" rel="nofollow">
                                <img width="320" height="50" alt="" src="https://www29.a8.net/svt/bgt?aid=240209605388&wid=002&eno=01&mid=s00000013044001017000&mc=1"/>
                            </a>
                            <img width="1" height="1" src="https://www16.a8.net/0.gif?a8mat=3Z0IX1+6F089M+2SNC+61Z81" alt=""/>
                        </div>
                    </SplideSlide>
                    {
                        // 【プロネイリストが制作する！】ネイルチップ専門店ミチネイル
                    }
                    <SplideSlide>
                        <div className="ad-item">
                            <a href="https://px.a8.net/svt/ejp?a8mat=3Z0IX1+679LEI+34VM+25GA35" rel="nofollow">
                                <img width="320" height="50" alt="" src="https://www24.a8.net/svt/bgt?aid=240209605375&wid=002&eno=01&mid=s00000014629013009000&mc=1"/>
                            </a>
                            <img width="1" height="1" src="https://www12.a8.net/0.gif?a8mat=3Z0IX1+679LEI+34VM+25GA35" alt=""/>
                        </div>
                    </SplideSlide>
                    {
                        // 【パナソニック公式】最高峰モデル炊飯器と銘柄米の定期購入サービス(23-1020)
                    }
                    <SplideSlide>
                        <div className="ad-item">
                            <a href="https://px.a8.net/svt/ejp?a8mat=3Z0JPC+5ZIYJE+5DQQ+BYDTT" rel="nofollow">
                                <img width="320" height="50" alt="" src="https://www23.a8.net/svt/bgt?aid=240210624362&wid=002&eno=01&mid=s00000025109002008000&mc=1"/>
                            </a>
                            <img width="1" height="1" src="https://www18.a8.net/0.gif?a8mat=3Z0JPC+5ZIYJE+5DQQ+BYDTT" alt=""/>
                        </div>
                    </SplideSlide>
                </Splide>
            </div>
        );
    },
    () => {
        return true;
    }
);
