import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, CardContent, Button } from "@mui/material";
import { GroupModel, JoinGroupModel } from "models/Models";
import { DbConstants, GROUP_JOIN_STATUS } from "constants/Constants";
import ArrowForward from "assets/images/arrow_forward.svg";
import "styles/components/listItems/JoinGroupListItemGroup.scss";

type Props = {
    joinGroup: JoinGroupModel;
    group: GroupModel;
    groupJoinStatus: GROUP_JOIN_STATUS;
    onClick: () => void;
    onClickJoin: () => void;
    onClickDecline: () => void;
    onClickWithdraw: () => void;
};

export const JoinGroupListItemGroup: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("===== JoinGroupListItemGroup");

        /**
         * レンダー
         */
        return (
            <Card className="JoinGroupListItemGroup" onClick={props.onClick}>
                <CardContent className="item-content">
                    <div className="info-wrapper">
                        {
                            // ステータス
                        }
                        <div className="status">
                            {props.group.isOwner && (
                                // オーナー
                                <span className="owner">
                                    <FormattedMessage id={"group_owner"} />
                                </span>
                            )}
                            {props.group.applying && (
                                // 加入申請中
                                <span className="applying">
                                    <FormattedMessage
                                        id={"under_application"}
                                    />
                                </span>
                            )}
                            {props.group.underInvitation && (
                                // 被招待中
                                <span className="under-invited">
                                    <FormattedMessage
                                        id={"group_under_invitation"}
                                    />
                                </span>
                            )}
                            {!props.group.isOwner && props.group.join && (
                                // 加入中
                                <span className="member">
                                    <FormattedMessage id={"group_member"} />
                                </span>
                            )}
                            {props.group.open !== 1 && (
                                // プライベート
                                <span className="private">
                                    <FormattedMessage id={"group_private"} />
                                </span>
                            )}
                        </div>
                        {
                            // グループ情報
                        }
                        <div className="group-info">
                            {
                                // オーナー名
                            }
                            {props.group.owner != null &&
                                props.group.ownerId !== 1 &&
                                props.group.owner.name != null &&
                                props.group.owner.name.length > 0 && (
                                    <div className="owner-name-area">
                                        <span className="owner-name-label">
                                            <FormattedMessage
                                                id={"group_owner2"}
                                            />
                                        </span>
                                        {props.group.owner.gender ===
                                            DbConstants.GENDER_MALE && (
                                            <span className="male owner-name">
                                                {props.group.owner.name}
                                            </span>
                                        )}
                                        {props.group.owner.gender ===
                                            DbConstants.GENDER_FEMALE && (
                                            <span className="female owner-name">
                                                {props.group.owner.name}
                                            </span>
                                        )}
                                        {props.group.owner.gender ===
                                            DbConstants.GENDER_OTHER && (
                                            <span className="other owner-name">
                                                {props.group.owner.name}
                                            </span>
                                        )}
                                    </div>
                                )}
                            {
                                // グループ名
                            }
                            <div className="group-name">{props.group.name}</div>
                        </div>
                    </div>
                    <div className="right-area">
                        <div className="button-area">
                            {/* 招待を受けている */}
                            {props.groupJoinStatus ===
                                GROUP_JOIN_STATUS.INVITED && (
                                <>
                                    {/* 加入ボタン */}
                                    <Button
                                        className="app-button btn-join"
                                        variant="text"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            props.onClickJoin();
                                        }}
                                    >
                                        <FormattedMessage id={"btn_join"} />
                                    </Button>
                                    {/* 拒否ボタン */}
                                    <Button
                                        className="app-button btn-decline"
                                        variant="text"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            props.onClickDecline();
                                        }}
                                    >
                                        <FormattedMessage id={"btn_decline"} />
                                    </Button>
                                </>
                            )}
                            {/* 加入申請をしている */}
                            {props.groupJoinStatus ===
                                GROUP_JOIN_STATUS.APPLYING && (
                                <>
                                    {/* 加入申請の取り消しボタン */}
                                    <Button
                                        className="app-button btn-withdraw"
                                        variant="text"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            props.onClickWithdraw();
                                        }}
                                    >
                                        <FormattedMessage
                                            id={"btn_withdraw_application"}
                                        />
                                    </Button>
                                </>
                            )}
                        </div>
                        <img
                            className="img-arrow-forward"
                            src={ArrowForward}
                            alt=""
                        />
                    </div>
                </CardContent>
            </Card>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.joinGroup !== nextProps.joinGroup) {
            return false;
        }
        if (prevProps.joinGroup != null && nextProps.joinGroup != null) {
            if (prevProps.joinGroup.id !== nextProps.joinGroup.id) {
                return false;
            }
        }
        if (prevProps.group !== nextProps.group) {
            return false;
        }
        if (prevProps.group != null && nextProps.group != null) {
            if (prevProps.group.id !== nextProps.group.id) {
                return false;
            }
        }
        if (prevProps.groupJoinStatus !== nextProps.groupJoinStatus) {
            return false;
        }
        if (prevProps.onClick !== nextProps.onClick) {
            return false;
        }
        if (prevProps.onClickJoin !== nextProps.onClickJoin) {
            return false;
        }
        if (prevProps.onClickDecline !== nextProps.onClickDecline) {
            return false;
        }
        if (prevProps.onClickWithdraw !== nextProps.onClickWithdraw) {
            return false;
        }

        return true;
    }
);
