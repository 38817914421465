/***** import */
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Alert,
    Box,
    Button,
    OutlinedInput,
    RadioGroup,
    FormControlLabel,
    Radio,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AppConstants, UrlConstants } from "constants/Constants";
import { AlbumRequest } from "api/requests/Requests";
import {
    UserModel,
    AlbumTemplateModel,
    AlbumModel,
    GroupModel,
} from "models/Models";
import {
    SelectGroupDialog,
    SelectUserDialog,
    NavBar,
} from "components/Components";
import {
    AlbumTemplateAction,
    SelectedItemAction,
    WorkingItemAction,
} from "redux/Actions";
import {
    getAlbumTemplateList,
    getSelectedGroupList,
    getSelectedUserList,
    getWorkingAlbum,
} from "redux/Selectors";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/mypage/AlbumCreate.scss";

/***** type */
type Props = {
    loginUser: UserModel;
};

/**
 * アルバム作成画面
 * @param props
 * @returns
 */
const AlbumCreate: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ AlbumCreate IN");
        /***** 定数 */
        const navigate = useNavigate();
        const intl = useIntl();
        const dispatch = useDispatch();
        const selector = useSelector((state) => state);
        const theme = useTheme();
        const isSizeXS = useMediaQuery(theme.breakpoints.down("sm"));
        const ALBUM_SETTING_PUBLIC = "1";
        const ALBUM_SETTING_FOLLOWER = "2";
        const ALBUM_SETTING_GROUP = "3";
        const ALBUM_SETTING_USER = "4";
        const ALBUM_SETTING_PRIVATE = "5";
        let wkSelectedGroupList = getSelectedGroupList(selector);
        let wkSelectedUserList = getSelectedUserList(selector);

        /***** useRef */
        const refPageWrapper = React.useRef<HTMLDivElement>();
        const refInUnderProcess = React.useRef<boolean>(false);
        const retainEdittedData = React.useRef<boolean>(false);

        /***** useState */
        // アルバムテンプレートリスト
        const [lstAlbumTemplate, setAlbumTemplateList] =
            React.useState<AlbumTemplateModel[]>();
        // アルバム名
        const [albumName, setAlbumName] = React.useState<string>("");
        // アルバム公開設定
        const [selectedAlbumSetting, setSelectedAlbumSetting] =
            React.useState<string>("1");
        // 選択テンプレートID
        const [selectedTemplateId, setSelectedTemplateId] =
            React.useState<number>();
        // 選択グループリスト
        const [lstSelectedGroup, setSelectedGroupList] =
            React.useState<GroupModel[]>();
        // 選択ユーザリスト
        const [lstSelectedUser, setSelectedUserList] =
            React.useState<UserModel[]>();
        // エラー表示フラグ
        const [showErrorMessage, setShowErrorMessage] =
            React.useState<boolean>();
        // エラーメッセージ
        const [errorMessage, setErrorMessage] = React.useState<string>();
        // グループ選択ダイアログ表示フラグ
        const [openDialogSelectGroup, setOpenDialogSelectGroup] =
            React.useState<boolean>(false);
        // ユーザ選択ダイアログ表示フラグ
        const [openDialogSelectUser, setOpenDialogSelectUser] =
            React.useState<boolean>(false);

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "アルバム作成",
                path: window.location.pathname,
            });

            (async () => {
                retainEdittedData.current = false;
                let lstAlbumTemplate = getAlbumTemplateList(selector);
                if (lstAlbumTemplate == null) {
                    lstAlbumTemplate = await fetchAlbumTemplateList();
                    if (lstAlbumTemplate == null) {
                        return;
                    }
                }
                setAlbumTemplateList(lstAlbumTemplate);
                dispatch(
                    AlbumTemplateAction({
                        list: lstAlbumTemplate,
                    })
                );

                let wkAlbum = getWorkingAlbum(selector);
                if (wkAlbum != null) {
                    setWorkingState(wkAlbum, lstAlbumTemplate);
                }
            })();
            return () => {
                if (
                    retainEdittedData == null ||
                    retainEdittedData.current == null ||
                    !retainEdittedData.current
                ) {
                    dispatch(
                        SelectedItemAction({
                            lstGroup: null,
                            lstUser: null,
                        })
                    );
                    dispatch(
                        WorkingItemAction({
                            album: null,
                        })
                    );
                }
            };
        }, []);
        // 選択グループリスト変更時
        React.useEffect(() => {
            setSelectedGroupList(wkSelectedGroupList);
        }, [wkSelectedGroupList]);
        // 選択ユーザリスト変更時
        React.useEffect(() => {
            setSelectedUserList(wkSelectedUserList);
        }, [wkSelectedUserList]);

        /**
         * アルバム公開設定変更時
         * @param e
         * @returns
         */
        function onChangePublicSetting(e: React.ChangeEvent<HTMLInputElement>) {
            if (e.target == null || e.target.value == null) {
                return;
            }
            const value = e.target.value;
            setSelectedAlbumSetting(value);
            dispatch(SelectedItemAction({ lstGroup: null, lstUser: null }));
        }
        /**
         * グループ選択押下時
         */
        async function onClickSelectGroup() {
            // スマホの場合
            if (isSizeXS) {
                retainEdittedData.current = true;
                dispatch(SelectedItemAction({ lstGroup: lstSelectedGroup }));
                const wkAlbum = getWorkingState();
                dispatch(
                    WorkingItemAction({
                        album: wkAlbum,
                    })
                );
                window.setTimeout(function () {
                    navigate(`/select/group`);
                }, 300);
            } else {
                setOpenDialogSelectGroup(true);
            }
        }
        /**
         * グループ選択ダイアログOK時
         * @returns
         */
        function onOkSelectedGroupDialog() {
            const lstSelectedGroup = getSelectedGroupList(selector);
            setSelectedGroupList(lstSelectedGroup);
            setOpenDialogSelectGroup(false);
        }
        /**
         * グループ選択ダイアログキャンセル時
         * @returns
         */
        function onCancelSelectedGroupDialog() {
            if (lstSelectedGroup == null) {
                setOpenDialogSelectGroup(false);
                return;
            }
            setOpenDialogSelectGroup(false);
        }

        /**
         * ユーザ選択押下時
         */
        async function onClickSelectUser() {
            // スマホの場合
            if (isSizeXS) {
                retainEdittedData.current = true;
                dispatch(SelectedItemAction({ lstUser: lstSelectedUser }));
                const wkAlbum = getWorkingState();
                dispatch(
                    WorkingItemAction({
                        album: wkAlbum,
                    })
                );
                window.setTimeout(function () {
                    navigate(`/select/user`);
                }, 300);
            } else {
                setOpenDialogSelectUser(true);
            }
        }
        /**
         * ユーザ選択ダイアログOK時
         * @returns
         */
        function onOkSelectedUserDialog(_lstSelectedUser: UserModel[] | null) {
            if (_lstSelectedUser == null) {
                setSelectedUserList(undefined);
            } else {
                setSelectedUserList(_lstSelectedUser);
            }
            setOpenDialogSelectUser(false);
            dispatch(SelectedItemAction({ lstUser: _lstSelectedUser }));
        }
        /**
         * ユーザ選択ダイアログキャンセル時
         * @returns
         */
        function onCancelSelectedUserDialog() {
            if (lstSelectedUser == null) {
                setOpenDialogSelectUser(false);
                return;
            }
            setOpenDialogSelectUser(false);
        }

        /**
         * アルバムテンプレート選択時
         * @param _template
         */
        function onSelectTemplate(_template: AlbumTemplateModel) {
            // if (lstAlbumTemplate == null) {
            //     return;
            // }
            // const newAlbumTemplateList = lstAlbumTemplate.map((template) => {
            //     if (_template.id === template.id) {
            //         template.selected = !template.selected;
            //     } else {
            //         template.selected = false;
            //     }
            //     return template;
            // });
            if (_template.id != null) {
                setSelectedTemplateId(_template.id);
                // setAlbumTemplateList(newAlbumTemplateList);
            }
        }
        /**
         * 作業中状態を復元
         * @param _album
         * @param _lstAlbumTemplate
         */
        function setWorkingState(
            _album: AlbumModel,
            _lstAlbumTemplate: AlbumTemplateModel[]
        ) {
            if (_album.name != null) {
                setAlbumName(_album.name);
            }

            if (_album.open === 1) {
                setSelectedAlbumSetting(ALBUM_SETTING_PUBLIC);
            } else if (_album.openFollower === 1) {
                setSelectedAlbumSetting(ALBUM_SETTING_FOLLOWER);
            } else if (_album.openGroup === 1) {
                setSelectedAlbumSetting(ALBUM_SETTING_GROUP);
            } else if (_album.openIndividual === 1) {
                setSelectedAlbumSetting(ALBUM_SETTING_USER);
            } else {
                setSelectedAlbumSetting(ALBUM_SETTING_PRIVATE);
            }

            if (_album.albumTemplateId != null) {
                setSelectedTemplateId(_album.albumTemplateId);
            }

            if (_album.openGroup === 1 && _album.lstAlbumGroup != null) {
                const lstAlbumGroup = _album.lstAlbumGroup;
                const lstGroup: GroupModel[] = [];
                for (let i = 0; i < lstAlbumGroup.length; i++) {
                    const albumGroup = lstAlbumGroup[i];
                    if (albumGroup.group != null) {
                        lstGroup.push(albumGroup.group);
                    }
                }
                setSelectedGroupList(lstGroup);
            }
            if (_album.openIndividual === 1 && _album.lstAlbumUser != null) {
                const lstAlbumUser = _album.lstAlbumUser;
                const lstUser: UserModel[] = [];
                for (let i = 0; i < lstAlbumUser.length; i++) {
                    const albumUser = lstAlbumUser[i];
                    if (albumUser.user != null) {
                        lstUser.push(albumUser.user);
                    }
                }
                setSelectedUserList(lstUser);
            }

            if (_album.openGroup === 1) {
                setSelectedUserList(undefined);
                dispatch(
                    SelectedItemAction({
                        lstUser: null,
                    })
                );
            } else if (_album.openIndividual === 1) {
                setSelectedGroupList(undefined);
                dispatch(
                    SelectedItemAction({
                        lstGroup: null,
                    })
                );
            } else {
                setSelectedGroupList(undefined);
                setSelectedUserList(undefined);
                dispatch(
                    SelectedItemAction({
                        lstUser: null,
                        lstGroup: null,
                    })
                );
            }
        }
        /**
         * 画面遷移前に現在の状態を一時保存するためのアルバムデータ作成
         */
        function getWorkingState(): AlbumModel {
            const album = new AlbumModel();
            album.name = albumName;
            switch (selectedAlbumSetting) {
                case ALBUM_SETTING_PUBLIC:
                    album.open = 1;
                    album.openGroup = 0;
                    album.openFollower = 0;
                    album.openIndividual = 0;
                    break;
                case ALBUM_SETTING_FOLLOWER:
                    album.open = 0;
                    album.openGroup = 0;
                    album.openFollower = 1;
                    album.openIndividual = 0;
                    break;
                case ALBUM_SETTING_GROUP:
                    album.open = 0;
                    album.openGroup = 1;
                    album.openFollower = 0;
                    album.openIndividual = 0;
                    break;
                case ALBUM_SETTING_USER:
                    album.open = 0;
                    album.openGroup = 0;
                    album.openFollower = 0;
                    album.openIndividual = 1;
                    break;
                case ALBUM_SETTING_PRIVATE:
                    album.open = 0;
                    album.openGroup = 0;
                    album.openFollower = 0;
                    album.openIndividual = 0;
                    break;
            }
            if (selectedTemplateId != null) {
                album.albumTemplateId = selectedTemplateId;
            }
            return album;
        }
        /**
         * アルバム作成
         */
        async function createAlbum() {
            if (!validate()) {
                window.setTimeout(() => {
                    if (
                        refPageWrapper != null &&
                        refPageWrapper.current != null
                    ) {
                        refPageWrapper.current.scrollTo({ top: 0 });
                    }
                });
                return null;
            }
            if (selectedTemplateId == null) {
                return null;
            }
            if (refInUnderProcess.current) {
                return;
            }
            refInUnderProcess.current = true;
            const album = new AlbumModel();
            album.name = albumName;
            album.albumTemplateId = selectedTemplateId;
            switch (selectedAlbumSetting) {
                case ALBUM_SETTING_PUBLIC:
                    album.open = 1;
                    album.openGroup = 0;
                    album.openFollower = 0;
                    album.openIndividual = 0;
                    break;
                case ALBUM_SETTING_FOLLOWER:
                    album.open = 0;
                    album.openGroup = 0;
                    album.openFollower = 1;
                    album.openIndividual = 0;
                    break;
                case ALBUM_SETTING_GROUP:
                    album.open = 0;
                    album.openGroup = 1;
                    album.openFollower = 0;
                    album.openIndividual = 0;
                    if (lstSelectedGroup != null) {
                        const lstGroupId: number[] = [];
                        lstSelectedGroup.forEach((group) => {
                            if (group.id != null) {
                                lstGroupId.push(group.id);
                            }
                        });
                        album.lstOpenGroupIdList = lstGroupId;
                    }
                    break;
                case ALBUM_SETTING_USER:
                    album.open = 0;
                    album.openGroup = 0;
                    album.openFollower = 0;
                    album.openIndividual = 1;
                    if (lstSelectedUser != null) {
                        const lstUserId: number[] = [];
                        lstSelectedUser.forEach((user) => {
                            if (user.id != null) {
                                lstUserId.push(user.id);
                            }
                        });
                        album.lstOpenUserIdList = lstUserId;
                    }
                    break;
                case ALBUM_SETTING_PRIVATE:
                    album.open = 0;
                    album.openGroup = 0;
                    album.openFollower = 0;
                    album.openIndividual = 0;
                    break;
            }
            try {
                const result = await AlbumRequest.createAlbum(
                    props.loginUser,
                    album
                );
                if (
                    result == null ||
                    result.rtnCd == null ||
                    result.rtnCd < 0
                ) {
                    return;
                }
                navigate(-1);
            } catch (error) {
                console.error(error);
            } finally {
                refInUnderProcess.current = false;
            }
        }

        /**
         * バリデーション
         */
        function validate(): boolean {
            // アルバム名
            if (albumName == null || albumName.trim().length === 0) {
                const errorMessage = intl.formatMessage({
                    id: "err_empty_album_name",
                });
                if (errorMessage != null && errorMessage.length > 0) {
                    setErrorMessage(errorMessage);
                    setShowErrorMessage(true);
                }
                return false;
            }
            // グループ公開の場合
            if (selectedAlbumSetting === ALBUM_SETTING_GROUP) {
                if (lstSelectedGroup == null || lstSelectedGroup.length === 0) {
                    const errorMessage = intl.formatMessage({
                        id: "err_no_group_selected",
                    });
                    if (errorMessage != null && errorMessage.length > 0) {
                        setErrorMessage(errorMessage);
                        setShowErrorMessage(true);
                    }
                    return false;
                }
            }
            // ユーザ公開の場合
            if (selectedAlbumSetting === ALBUM_SETTING_USER) {
                if (lstSelectedUser == null || lstSelectedUser.length === 0) {
                    const errorMessage = intl.formatMessage({
                        id: "err_no_user_selected",
                    });
                    if (errorMessage != null && errorMessage.length > 0) {
                        setErrorMessage(errorMessage);
                        setShowErrorMessage(true);
                    }
                    return false;
                }
            }
            // テンプレート
            if (selectedTemplateId == null) {
                const errorMessage = intl.formatMessage({
                    id: "err_empty_template",
                });
                if (errorMessage != null && errorMessage.length > 0) {
                    setErrorMessage(errorMessage);
                    setShowErrorMessage(true);
                }
                return false;
            }
            return true;
        }
        /**
         * アルバムテンプレートリスト取得
         */
        async function fetchAlbumTemplateList(): Promise<
            AlbumTemplateModel[] | null
        > {
            if (props.loginUser == null) {
                return null;
            }
            let res = await AlbumRequest.getAlbumTemplateList();
            if (
                res == null ||
                res.rtnCd == null ||
                res.rtnCd < 0 ||
                res.lstAlbumTemplate == null
            ) {
                return null;
            }
            return res.lstAlbumTemplate;
        }

        /**
         * レンダリング
         */
        return (
            <Box className="pageWrapper AlbumCreate" ref={refPageWrapper}>
                <NavBar
                    showBack={true}
                    title={intl.formatMessage({ id: "title_album_create" })}
                />
                {lstAlbumTemplate !== undefined &&
                    lstAlbumTemplate.length > 0 && (
                        <>
                            {showErrorMessage && errorMessage != null && (
                                <div className="error-message-area">
                                    <Alert
                                        className="error-message"
                                        severity="error"
                                        onClose={() => {
                                            setShowErrorMessage(false);
                                            setErrorMessage("");
                                        }}
                                    >
                                        {errorMessage}
                                    </Alert>
                                </div>
                            )}
                            <div className="input-area">
                                {
                                    // アルバム名
                                }
                                <div className="album-name-area">
                                    <label className="label" htmlFor="txtEmail">
                                        <FormattedMessage id={"album_name"} />
                                    </label>
                                    <OutlinedInput
                                        className="album-name-value"
                                        fullWidth
                                        id="txtAlbumName"
                                        label=""
                                        inputProps={{
                                            maxLength: 255,
                                        }}
                                        value={albumName}
                                        onChange={(e) => {
                                            setAlbumName(e.target.value);
                                        }}
                                    />
                                </div>
                                {
                                    // 公開設定
                                }
                                <div className="album-public-setting-area">
                                    <label className="label">
                                        <FormattedMessage
                                            id={"album_public_setting"}
                                        />
                                    </label>
                                    <RadioGroup
                                        className="radio-group"
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue={intl.formatMessage({
                                            id: "album_public",
                                        })}
                                        name="radio-buttons-group"
                                        value={selectedAlbumSetting}
                                        onChange={onChangePublicSetting}
                                    >
                                        {
                                            // 一般公開
                                        }
                                        <FormControlLabel
                                            className="radio-item"
                                            control={
                                                <Radio
                                                    value={ALBUM_SETTING_PUBLIC}
                                                />
                                            }
                                            label={intl.formatMessage({
                                                id: "album_public",
                                            })}
                                        />
                                        {
                                            // フォロワー公開
                                        }
                                        <FormControlLabel
                                            className="radio-item"
                                            control={
                                                <Radio
                                                    value={
                                                        ALBUM_SETTING_FOLLOWER
                                                    }
                                                />
                                            }
                                            label={intl.formatMessage({
                                                id: "album_open_follower",
                                            })}
                                        />
                                        {
                                            // 特定グループ公開
                                        }
                                        <div className="radio-item select-group">
                                            <div className="select-area">
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            value={
                                                                ALBUM_SETTING_GROUP
                                                            }
                                                        />
                                                    }
                                                    label={intl.formatMessage({
                                                        id: "album_open_group",
                                                    })}
                                                />
                                                <Button
                                                    className="select-group-button"
                                                    variant="text"
                                                    sx={{
                                                        backgroundColor:
                                                            selectedAlbumSetting ===
                                                            ALBUM_SETTING_GROUP
                                                                ? "#000000"
                                                                : AppConstants.COLOR_DISABLED_BUTTON,
                                                        color:
                                                            selectedAlbumSetting ===
                                                            ALBUM_SETTING_GROUP
                                                                ? "#ffffff"
                                                                : "#000000",
                                                    }}
                                                    disabled={
                                                        selectedAlbumSetting !==
                                                        ALBUM_SETTING_GROUP
                                                    }
                                                    onClick={onClickSelectGroup}
                                                >
                                                    <FormattedMessage
                                                        id={"btn_select"}
                                                    />
                                                </Button>
                                            </div>
                                            <div className="group-name-list-area">
                                                {lstSelectedGroup != null &&
                                                    lstSelectedGroup.map(
                                                        (
                                                            group: GroupModel,
                                                            index: number
                                                        ) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="group-name-wrapper"
                                                                >
                                                                    {group.name}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                        {
                                            // 特定ユーザ公開
                                        }
                                        <div className="radio-item select-user">
                                            <div className="select-area">
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            value={
                                                                ALBUM_SETTING_USER
                                                            }
                                                        />
                                                    }
                                                    label={intl.formatMessage({
                                                        id: "album_open_individual",
                                                    })}
                                                />
                                                <Button
                                                    className="select-user-button"
                                                    variant="text"
                                                    sx={{
                                                        backgroundColor:
                                                            selectedAlbumSetting ===
                                                            ALBUM_SETTING_USER
                                                                ? "#000000"
                                                                : AppConstants.COLOR_DISABLED_BUTTON,
                                                        color:
                                                            selectedAlbumSetting ===
                                                            ALBUM_SETTING_USER
                                                                ? "#ffffff"
                                                                : "#000000",
                                                    }}
                                                    disabled={
                                                        selectedAlbumSetting !==
                                                        ALBUM_SETTING_USER
                                                    }
                                                    onClick={onClickSelectUser}
                                                >
                                                    <FormattedMessage
                                                        id={"btn_select"}
                                                    />
                                                </Button>
                                            </div>
                                            <div className="user-name-list-area">
                                                {lstSelectedUser != null &&
                                                    lstSelectedUser.map(
                                                        (
                                                            user: UserModel,
                                                            index: number
                                                        ) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="user-name-wrapper"
                                                                >
                                                                    {user.name}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                        {
                                            // 非公開
                                        }
                                        <FormControlLabel
                                            className="radio-item"
                                            control={
                                                <Radio
                                                    value={
                                                        ALBUM_SETTING_PRIVATE
                                                    }
                                                />
                                            }
                                            label={intl.formatMessage({
                                                id: "album_private",
                                            })}
                                        />
                                    </RadioGroup>
                                </div>
                                {
                                    // テンプレート選択
                                }
                                <div className="album-template-select-area">
                                    <label className="label">
                                        <FormattedMessage
                                            id={"select_album_template"}
                                        />
                                    </label>
                                    <div className="value-area">
                                        {lstAlbumTemplate.map((template) => {
                                            return (
                                                <React.Fragment
                                                    key={template.id}
                                                >
                                                    {template.name != null && (
                                                        <Box
                                                            className="img"
                                                            component="img"
                                                            sx={{
                                                                backgroundColor:
                                                                    template.id ===
                                                                    selectedTemplateId
                                                                        ? // template.selected
                                                                          "#ff88ff"
                                                                        : "none",
                                                            }}
                                                            src={
                                                                UrlConstants.URL_S3_ALBUM_ICON +
                                                                template.name
                                                            }
                                                            onClick={() => {
                                                                onSelectTemplate(
                                                                    template
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            {
                                // ボタンエリア
                            }
                            <div className="btn-area">
                                <Button
                                    className="app-button button"
                                    variant="text"
                                    onClick={createAlbum}
                                >
                                    <FormattedMessage id={"btn_create2"} />
                                </Button>
                            </div>
                        </>
                    )}
                <SelectGroupDialog
                    loginUser={props.loginUser}
                    openDialog={openDialogSelectGroup}
                    onClose={() => {
                        onCancelSelectedGroupDialog();
                    }}
                    onCancel={() => {
                        onCancelSelectedGroupDialog();
                    }}
                    onOk={() => {
                        onOkSelectedGroupDialog();
                    }}
                />
                <SelectUserDialog
                    loginUser={props.loginUser}
                    openDialog={openDialogSelectUser}
                    onClose={onCancelSelectedUserDialog}
                    onCancel={onCancelSelectedUserDialog}
                    onOk={onOkSelectedUserDialog}
                />
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default AlbumCreate;
