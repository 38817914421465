import React, { ChangeEvent, MouseEvent } from "react";
import {
    Alert,
    FormControl,
    OutlinedInput,
    Button,
    TextField,
    useMediaQuery,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { MailRequest } from "api/requests/Requests";
import {
    UserModel,
    ParentMailModel,
    MailWorkModel,
    SelectedFile,
} from "models/Models";
import { useDispatch, useSelector } from "react-redux";
import {
    ImageComponent,
    VideoComponent,
    SelectUserDialog,
    AttachedFileNameComponent,
} from "components/Components";
import {
    ProcessAction,
    SelectedItemAction,
    WorkingItemAction,
} from "redux/Actions";
import {
    getSelectedParentMail,
    getSelectedUserList,
    getWorkingMail,
} from "redux/Selectors";
import { AppConstants } from "constants/AppConstants";
import Utility from "utils/Utility";
import AttachFile from "@mui/icons-material/AttachFile";
import { pushDataLayer } from "gtm/gtm"
import "styles/components/SendMessageComponent.scss";
import { DbConstants } from "constants/DbConstants";

type Props = {
    loginUser: UserModel | null;
    target?: UserModel | null;
    onSendCompleted: () => void;
};

export const SendMessageComponent: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("===== SendMessageComponent");
        /***** 定数、変数 */
        const intl = useIntl();
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const selector = useSelector((state) => state);
        const theme = useTheme();
        const isSizeXS = useMediaQuery(theme.breakpoints.down("sm"));
        let wkSelectedUserList = getSelectedUserList(selector);

        /***** useRef */
        const refAttachFile = React.useRef<HTMLInputElement>(null);
        const isUnderProcessing = React.useRef<boolean>(false);

        /***** useState */
        // 親メール
        const [parentMail, setParentMail] = React.useState<ParentMailModel>();
        // 元子メール
        // const [childMail, setChildMail] = React.useState<MailModel>();
        // タイトル
        const [title, setTitle] = React.useState<string>("");
        // 本文
        const [body, setBody] = React.useState<string>("");
        // 添付ファイル
        const [attachedFileList, setAttachedFileList] =
            React.useState<SelectedFile[]>();
        const [isReply, setIsReply] = React.useState<boolean>(false);
        // エラーメッセージ
        const [errMessage, setErrMessage] = React.useState<string>();
        // ボタンラベル
        const [btnSendLabel, setBtnSendLabel] = React.useState<string>("");
        // ボタン活性化フラグ
        const [btnSendDisabled, setBtnSendDisabled] =
            React.useState<boolean>(false);
        // 全画面表示用に選択された添付ファイル
        const [selectedAttachedFile, setSelectedAttachedFile] =
            React.useState<File>();
        // 添付ファイル(画像)全画面表示フラグ
        const [showImage, setShowImage] = React.useState<boolean>(false);
        // 添付ファイル(動画)全画面表示フラグ
        const [showVideo, setShowVideo] = React.useState<boolean>(false);
        // 選択されたユーザリスト
        const [lstSelectedUser, setSelectedUserList] =
            React.useState<UserModel[]>();
        // ユーザ選択ダイアログ表示フラグ
        const [openDialogSelectUser, setOpenDialogSelectUser] =
            React.useState<boolean>(false);
        const [underSending, setUnderSending] = React.useState<boolean>(false);

        /**
         * useEffect
         */
        /***** useEffect */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "メッセージ送信",
                path: window.location.pathname,
            });
        }, []);

        React.useEffect(() => {
            if (props.loginUser == null) {
                return;
            }
            const wkParentMail = getSelectedParentMail(selector);
            if (wkParentMail != null) {
                setParentMail(wkParentMail);

                const sender = wkParentMail.sender;
                const receiver = wkParentMail.receiver;
                const parentTitle = wkParentMail.title;
                if (sender != null && receiver != null && parentTitle != null) {
                    if (sender.id !== props.loginUser.id) {
                        setIsReply(true);
                        setSendButtonLabel("btn_reply_message");
                        sender.selected = true;
                        setSelectedUserList([sender]);
                    } else if (receiver.id !== props.loginUser.id) {
                        setIsReply(true);
                        setSendButtonLabel("btn_reply_message");
                        receiver.selected = true;
                        setSelectedUserList([receiver]);
                    }
                    if (parentTitle != null) {
                        if (parentTitle.indexOf(AppConstants.MAIL_REPLY) > -1) {
                            setTitle(parentTitle);
                        } else {
                            setTitle(AppConstants.MAIL_REPLY + parentTitle);
                        }
                    }
                }
            } else {
                setIsReply(false);
                setSendButtonLabel("btn_send");
            }
            return () => {};
        }, [props.loginUser]);
        React.useEffect(() => {
            if (props.target != null) {
                const selectedUser = props.target;
                selectedUser.selected = true;
                setSelectedUserList([selectedUser]);
            }
        }, [props.target]);

        // 選択ユーザリスト変更時
        React.useEffect(() => {
            if (wkSelectedUserList != null) {
                setSelectedUserList(wkSelectedUserList);
            }
            const workingMail: MailWorkModel | null = getWorkingMail(selector);
            if (workingMail != null) {
                if (workingMail.title != null) {
                    setTitle(workingMail.title);
                }
                if (workingMail.message != null) {
                    setBody(workingMail.message);
                }
                if (workingMail.lstFile != null) {
                    const workingFileList: SelectedFile[] = workingMail.lstFile;
                    setAttachedFileList(workingFileList);
                }
            }
        }, [wkSelectedUserList]);

        /**
         * 送信ボタンのラベル設定
         * @param labelId
         */
        const setSendButtonLabel = (labelId: string) => {
            const btnLabel = intl.formatMessage({
                id: labelId,
            });
            setBtnSendLabel(btnLabel);
        };

        /**
         * 宛先エリアクリック時
         */
        function onClickDestination() {
            if (!isReply && props.target == null) {
                // スマホの場合
                if (isSizeXS) {
                    const workingMail: MailWorkModel = new MailWorkModel();
                    workingMail.title = title;
                    workingMail.message = body;
                    if (attachedFileList == null) {
                        workingMail.lstFile = null;
                    } else {
                        workingMail.lstFile = attachedFileList;
                    }
                    dispatch(
                        WorkingItemAction({
                            mail: workingMail,
                        })
                    );

                    dispatch(SelectedItemAction({ lstUser: lstSelectedUser }));
                    window.setTimeout(function () {
                        navigate(`/select/user`);
                    }, 300);
                } else {
                    setOpenDialogSelectUser(true);
                }
            }
        }
        /**
         * ユーザ選択ダイアログOK時
         * @returns
         */
        function onOkSelectedUserDialog(_lstSelectedUser: UserModel[] | null) {
            if (_lstSelectedUser == null) {
                setSelectedUserList(undefined);
            } else {
                setSelectedUserList(_lstSelectedUser);
            }
            const workingMail: MailWorkModel = new MailWorkModel();
            workingMail.title = title;
            workingMail.message = body;
            if (attachedFileList == null) {
                workingMail.lstFile = null;
            } else {
                workingMail.lstFile = attachedFileList;
            }
            dispatch(
                WorkingItemAction({
                    mail: workingMail,
                })
            );
            setOpenDialogSelectUser(false);
            dispatch(SelectedItemAction({ lstUser: _lstSelectedUser }));
        }
        /**
         * ユーザ選択ダイアログキャンセル時
         * @returns
         */
        function onCancelSelectedUserDialog() {
            const workingMail: MailWorkModel = new MailWorkModel();
            workingMail.title = title;
            workingMail.message = body;
            if (attachedFileList == null) {
                workingMail.lstFile = null;
            } else {
                workingMail.lstFile = attachedFileList;
            }
            dispatch(
                WorkingItemAction({
                    mail: workingMail,
                })
            );
            setOpenDialogSelectUser(false);
        }

        /**
         * ファイル選択時
         * @param e
         * @returns
         */
        async function onFileSelected(e: ChangeEvent<HTMLInputElement>) {
            const target = e.currentTarget as HTMLInputElement;
            const lstFile = target.files as FileList;
            if (lstFile == null) {
                return;
            }
            let wkAttachedFileList: SelectedFile[] = [];
            for (let i = 0; i < lstFile.length; i++) {
                const file = lstFile[i];
                const selectedFile: SelectedFile = {
                    name: file.name,
                    file: file,
                };
                wkAttachedFileList.push(selectedFile);
            }
            if (attachedFileList == null) {
                setAttachedFileList(wkAttachedFileList);
            } else {
                setAttachedFileList((prevState) => {
                    if (prevState == null) {
                        return wkAttachedFileList;
                    } else {
                        return [...prevState, ...wkAttachedFileList];
                    }
                });
            }
            target.value = "";
        }

        /**
         * 選択した添付ファイルの全画面表示
         * @param _selectedFile
         */
        function onClickAttachedFile(_selectedFile: SelectedFile) {
            if (_selectedFile.file == null) {
                return;
            }
            const fileName = _selectedFile.file.name;
            const file = _selectedFile.file;
            if (fileName != null) {
                // 画像の場合
                if (Utility.isImage(fileName)) {
                    setShowImage(true);
                }
                // 動画の場合
                else if (!Utility.isImage(fileName)) {
                    setShowVideo(true);
                }
            }
            if (file != null) {
                setSelectedAttachedFile(file);
            }
        }
        /**
         * 添付ファイル削除
         * @param selectedFile
         */
        function onClickDeleteAttachedFile(_selectedFile: SelectedFile) {
            if (attachedFileList == null) {
                return;
            }
            let wkAttachedFileList = [...attachedFileList];
            wkAttachedFileList = wkAttachedFileList.filter((selectedFile) => {
                return selectedFile.name !== _selectedFile.name;
            });
            setAttachedFileList(wkAttachedFileList);
        }

        /**
         * 送信ボタンクリック時
         * @returns
         */
        const onClickSend = async () => {
            if (validate()) {
                setUnderSending(true);
                await send();
                setUnderSending(false);
            }
        };
        /**
         * 返信ボタンクリック時
         * @returns
         */
        const onClickReply = async () => {
            if (validate()) {
                setUnderSending(true);
                await reply();
                setUnderSending(false);
            }
        };

        /**
         * バリデーション
         * @returns
         */
        function validate(): boolean {
            if (lstSelectedUser == null || lstSelectedUser.length === 0) {
                const errMessage = intl.formatMessage({
                    id: "err_empty_to",
                });
                setErrMessage(errMessage);
                return false;
            }
            if (title.trim().length === 0) {
                const errMessage = intl.formatMessage({
                    id: "err_empty_title",
                });
                setErrMessage(errMessage);
                return false;
            }
            if (body.trim().length === 0) {
                const errMessage = intl.formatMessage({
                    id: "err_empty_body",
                });
                setErrMessage(errMessage);
                return false;
            }
            return true;
        }
        /**
         * 送信
         * @returns
         */
        async function send() {
            if (lstSelectedUser == null) {
                return;
            }
            const targetUserList = lstSelectedUser.filter((selectedUser) => {
                return selectedUser.selected;
            });

            if (
                props.loginUser == null ||
                targetUserList == null ||
                targetUserList.length === 0
            ) {
                return;
            }
            if (isUnderProcessing.current) {
                return;
            }
            isUnderProcessing.current = true;
            setBtnSendDisabled(true);
            setSendButtonLabel("msg_processing");
            // dispatch(
            //     ProcessAction({ processing: true, body: "msg_processing" })
            // );
            try {
                for (let i = 0; i < targetUserList.length; i++) {
                    const targetUser = targetUserList[i];
                    let lstFile: File[] | undefined;
                    if (
                        attachedFileList != null &&
                        attachedFileList.length > 0
                    ) {
                        lstFile = [];
                        attachedFileList.forEach((selectedFile) => {
                            if (selectedFile.file != null) {
                                lstFile?.push(selectedFile.file);
                            }
                        });
                    }
                    const res = await MailRequest.send(
                        0,
                        0,
                        props.loginUser,
                        targetUser,
                        title,
                        body,
                        lstFile
                    );
                    if (res == null) {
                        throw new Error();
                    }
                    if (res.rtnCd == null || res.rtnCd < 0) {
                        throw new Error();
                    }
                }
                dispatch(
                    SelectedItemAction({
                        lstUser: null,
                    })
                );
                props.onSendCompleted();
            } catch (e) {
                const errMessage = intl.formatMessage({
                    id: "err_failed_to_send",
                });
                setErrMessage(errMessage);
            } finally {
                isUnderProcessing.current = false;
                setBtnSendDisabled(false);
                if (isReply) {
                    setSendButtonLabel("btn_reply_message");
                } else {
                    setSendButtonLabel("btn_send");
                }
                // dispatch(ProcessAction({ processing: false, body: "" }));
            }
        }
        /**
         * 返信
         * @returns
         */
        async function reply() {
            if (lstSelectedUser == null) {
                return;
            }
            const targetUserList = lstSelectedUser.filter((selectedUser) => {
                return selectedUser.selected;
            });

            if (
                props.loginUser == null ||
                targetUserList == null ||
                targetUserList.length === 0
            ) {
                return;
            }
            if (parentMail == null || parentMail.id == null) {
                return;
            }
            setBtnSendDisabled(true);
            setSendButtonLabel("msg_processing");
            dispatch(
                ProcessAction({ processing: true, message: "msg_processing" })
            );
            try {
                for (let i = 0; i < targetUserList.length; i++) {
                    const targetUser = targetUserList[i];
                    let lstFile: File[] | undefined;
                    if (
                        attachedFileList != null &&
                        attachedFileList.length > 0
                    ) {
                        lstFile = [];
                        attachedFileList.forEach((selectedFile) => {
                            if (selectedFile.file != null) {
                                lstFile?.push(selectedFile.file);
                            }
                        });
                    }
                    // 返信リクエスト実行
                    const res = await MailRequest.reply(
                        parentMail.id,
                        props.loginUser,
                        targetUser,
                        title,
                        body,
                        lstFile
                    );
                    if (res == null) {
                        throw new Error();
                    }
                    if (res.rtnCd == null || res.rtnCd < 0) {
                        throw new Error();
                    }
                }
                props.onSendCompleted();
            } catch (e) {
                const errMessage = intl.formatMessage({
                    id: "err_failed_to_send",
                });
                setErrMessage(errMessage);
            } finally {
                isUnderProcessing.current = false;
                setBtnSendDisabled(false);
                if (isReply) {
                    setSendButtonLabel("btn_reply_message");
                } else {
                    setSendButtonLabel("btn_send");
                }
                dispatch(ProcessAction({ processing: false, message: "" }));
            }
        }

        function getDestinationClassName(user: UserModel): string {
            if (user.gender === DbConstants.GENDER_FEMALE) {
                return "destination-item female"
            } else if (user.gender === DbConstants.GENDER_MALE) {
                return "destination-item male"
            } else {
                return "destination-item"
            }
        }
        return (
            <div className="component SendMessageComponent">
                {
                    // エラーメッセージエリア
                }
                {errMessage != null && errMessage.length > 0 && (
                    <Alert
                        onClose={() => {
                            setErrMessage("");
                        }}
                        severity="error"
                    >
                        {errMessage}
                    </Alert>
                )}
                {
                    // 入力エリア
                }
                <form
                    className={
                        errMessage != null && errMessage.length > 0
                            ? "entry-area error"
                            : "entry-area"
                    }
                >
                    <div className="entry-wrapper">
                        {
                            // 宛先
                        }
                        <FormControl
                            className="item-area destination-area"
                            variant="standard"
                        >
                            <label>
                                <FormattedMessage id={"message_destination"} />
                            </label>
                            <div
                                className="input-wrapper destination"
                                onClick={onClickDestination}
                            >
                                <div className="destination-items-wrapper">
                                    {lstSelectedUser != null &&
                                        lstSelectedUser.map((user, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={getDestinationClassName(user)}
                                                >
                                                    {user.name}
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </FormControl>
                        {
                            // タイトル
                        }
                        <FormControl
                            className="item-area title-area"
                            variant="standard"
                        >
                            <label htmlFor="txtTitle">
                                <FormattedMessage id={"title"} />
                            </label>
                            <OutlinedInput
                                className="input-title"
                                fullWidth
                                id="txtTitle"
                                label=""
                                type="text"
                                inputProps={{
                                    maxLength: 100,
                                }}
                                value={title}
                                disabled={isReply}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />
                        </FormControl>
                        {
                            // 本文
                        }
                        <FormControl
                            className="item-area body-area"
                            variant="standard"
                        >
                            <div className="label-area-wrapper">
                                <label htmlFor="txtBody">
                                    <FormattedMessage id={"body"} />
                                </label>
                                <AttachFile
                                    className="icon-attached-file"
                                    onClick={() => {
                                        if (
                                            refAttachFile != null &&
                                            refAttachFile.current != null
                                        ) {
                                            refAttachFile.current.click();
                                        }
                                    }}
                                />
                                <input
                                    ref={refAttachFile}
                                    type="file"
                                    style={{ display: "none" }}
                                    accept="image/png,image/jpg,video/*"
                                    multiple
                                    onChange={onFileSelected}
                                    onClick={(
                                        e: MouseEvent<HTMLInputElement>
                                    ) => {}}
                                />
                            </div>
                            <TextField
                                className="input-body"
                                fullWidth
                                variant="outlined"
                                id="txtBody"
                                label=""
                                type="text"
                                multiline={true}
                                maxRows={30}
                                minRows={8}
                                inputProps={{
                                    maxLength: 2000,
                                }}
                                value={body}
                                onChange={(e) => {
                                    setBody(e.target.value);
                                }}
                            />
                        </FormControl>
                        {attachedFileList != null &&
                            attachedFileList.length > 0 && (
                                <div className="attached-file-area">
                                    {attachedFileList.map(
                                        (
                                            selectedFile: SelectedFile,
                                            index: number
                                        ) => {
                                            return (
                                                <AttachedFileNameComponent
                                                    key={index}
                                                    fileName={selectedFile.name}
                                                    onClick={() => {
                                                        onClickAttachedFile(
                                                            selectedFile
                                                        );
                                                    }}
                                                    onDelete={() => {
                                                        onClickDeleteAttachedFile(
                                                            selectedFile
                                                        );
                                                    }}
                                                />
                                            );
                                        }
                                    )}
                                </div>
                            )}
                    </div>
                    {btnSendLabel != null && btnSendLabel.length > 0 && (
                        <Button
                            className={
                                btnSendDisabled ? "disabled app-button button" : "app-button button"
                            }
                            disabled={btnSendDisabled}
                            onClick={() => {
                                if (isReply) {
                                    onClickReply();
                                } else {
                                    onClickSend();
                                }
                            }}
                        >
                            {btnSendLabel}
                        </Button>
                    )}
                </form>
                {props.loginUser != null && (
                    <SelectUserDialog
                        loginUser={props.loginUser}
                        openDialog={openDialogSelectUser}
                        onClose={() => {
                            onCancelSelectedUserDialog();
                        }}
                        onCancel={() => {
                            onCancelSelectedUserDialog();
                        }}
                        onOk={(_lstSelectedUser: UserModel[] | null) => {
                            onOkSelectedUserDialog(_lstSelectedUser);
                        }}
                    />
                )}

                {props.loginUser != null && selectedAttachedFile != null && (
                    <>
                        <ImageComponent
                            user={props.loginUser}
                            file={selectedAttachedFile}
                            onClose={() => {
                                setShowImage(false);
                            }}
                            open={showImage}
                        />
                        <VideoComponent
                            user={props.loginUser}
                            file={selectedAttachedFile}
                            onClose={() => {
                                setShowVideo(false);
                            }}
                            open={showVideo}
                        />
                    </>
                )}
                <Backdrop
                    className="backdrop"
                    sx={{
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={underSending}
                >
                    <div className="backdrop-content-wapper">
                        <CircularProgress color="inherit" />
                        <div className="backdrop-content">
                            <FormattedMessage id="msg_sending" />
                        </div>
                    </div>
                </Backdrop>
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.target !== nextProps.target) {
            return false;
        }
        if (prevProps.target != null && nextProps.target != null) {
            if (prevProps.target.id !== nextProps.target.id) {
                return false;
            }
        }
        if (prevProps.onSendCompleted !== nextProps.onSendCompleted) {
            return false;
        }

        return true;
    }
);
