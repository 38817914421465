import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Button, Zoom, Fab, useScrollTrigger } from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { UserModel, GroupModel, JoinGroupModel } from "models/Models";
import { GroupListItem } from "components/Components";
import { JoinGroupRequest } from "api/requests/Requests";
import { SelectedItemAction } from "redux/Actions";
import { getSelectedGroupList } from "redux/Selectors";
import "styles/components/SelectGroupComponent.scss";

interface Props {
    loginUser: UserModel | undefined;
    onCancel: () => void;
    onOk: () => void;
}
export const SelectGroupComponent: React.FC<Props> = (props) => {
    /***** 定数、変数 */
    // const intl = useIntl();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    /***** useRef */
    const container = React.useRef<HTMLDivElement>();
    // 選択グループリスト参照
    const refSelectedGroupList = React.useRef<GroupModel[]>();

    /***** useState */
    // グループリスト
    const [lstGroup, setGroupList] = React.useState<GroupModel[]>();
    // 選択グループリスト
    const [lstSelectedGroup, setSelectedGroupList] =
        React.useState<GroupModel[]>();
    // ロード完了フラグ
    const [loaded, setLoaded] = React.useState<boolean>(false);

    const trigger = useScrollTrigger({
        target: container.current,
        disableHysteresis: true,
        threshold: 300,
    });

    const scrollToTop = React.useCallback(() => {
        if (container.current != null) {
            container.current.scrollTo({ top: 0 });
        }
    }, []);

    /**
     * useEffect
     */
    React.useEffect(() => {
        if (props.loginUser == null) {
            return;
        }
        fetchJoinGroupList();
    }, [props.loginUser]);

    /**
     * グループ選択時、選択解除時
     * @param group
     */
    async function onClickGroup(group: GroupModel) {
        if (group.id == null) {
            return;
        }
        group.selected = !group.selected;
        let wkSelectedGroupList: GroupModel[] = [];
        if (lstSelectedGroup != null) {
            wkSelectedGroupList = [...lstSelectedGroup];
        }
        if (wkSelectedGroupList == null || wkSelectedGroupList.length === 0) {
            if (group.selected) {
                wkSelectedGroupList = [group];
            }
        } else {
            if (group.selected) {
                wkSelectedGroupList.push(group);
            } else {
                wkSelectedGroupList = wkSelectedGroupList.filter(
                    (wkGroup: GroupModel) => {
                        return group.id !== wkGroup.id;
                    }
                );
            }
        }
        setSelectedGroupList(wkSelectedGroupList);
        refSelectedGroupList.current = wkSelectedGroupList;
        reflectSelectedStatus();
    }
    /**
     * 選択ステータス反映
     */
    function reflectSelectedStatus() {
        if (lstGroup == null) {
            return;
        }
        if (
            refSelectedGroupList == null ||
            refSelectedGroupList.current == null
        ) {
            return;
        }
        const wkGroupList = lstGroup.map((group) => {
            let selected = false;
            if (
                refSelectedGroupList != null &&
                refSelectedGroupList.current != null
            ) {
                for (let i = 0; i < refSelectedGroupList.current.length; i++) {
                    const selectedGroup = refSelectedGroupList.current[i];
                    if (group.id === selectedGroup.id) {
                        selected = true;
                        break;
                    }
                }
            }
            group.selected = selected;
            return group;
        });
        if (wkGroupList != null) {
            setGroupList(wkGroupList);
        }
    }

    /**
     * OK時
     */
    async function onClickOk() {
        let lstSelectedGroupCommitted: GroupModel[] | null = null;
        if (lstSelectedGroup != null) {
            lstSelectedGroupCommitted = lstSelectedGroup.filter((group) => {
                return group.selected;
            });
        }
        dispatch(SelectedItemAction({ lstGroup: lstSelectedGroupCommitted }));
        props.onOk();
    }
    /**
     * キャンセル時
     */
    function onClickCancel() {
        props.onCancel();
    }

    /**
     * 所属グループ取得
     * @returns
     */
    async function fetchJoinGroupList() {
        if (props.loginUser == null || props.loginUser.id == null) {
            return;
        }
        const result = await JoinGroupRequest.getJoinGroupList(
            props.loginUser,
            props.loginUser.id
        );
        if (result == null || result.rtnCd == null || result.rtnCd < 0) {
            return;
        }
        if (result.lstJoinGroup == null) {
            return;
        }
        let wkSelectedGroupList = getSelectedGroupList(selector);

        const wkGroupList: GroupModel[] = [];
        const wkOwnGroupList: GroupModel[] = [];
        for (let i = 0; i < result.lstJoinGroup.length; i++) {
            const joinGroup = result.lstJoinGroup[i];
            const group = joinGroup.group;
            if (group == null) {
                continue;
            }
            if (wkSelectedGroupList != null) {
                wkSelectedGroupList = [...wkSelectedGroupList];
                for (let j = 0; j < wkSelectedGroupList.length; j++) {
                    const selectedGroup = wkSelectedGroupList[j];
                    if (group.id === selectedGroup.id) {
                        group.selected = true;
                        break;
                    }
                }
            }
            // 自分がグループオーナーの場合
            if (group.ownerId === props.loginUser.id) {
                wkOwnGroupList.push(group);
            } else if (
                joinGroup.underApplication !== 1 &&
                joinGroup.underInvitation !== 1
            ) {
                wkGroupList.push(group);
            }
        }
        if (wkSelectedGroupList != null) {
            for (let i = 0; i < wkSelectedGroupList.length; i++) {
                let exist = false;
                const selectedGroup: GroupModel = wkSelectedGroupList[i];
                for (let j = 0; j < result.lstJoinGroup.length; j++) {
                    const joinGroup: JoinGroupModel = result.lstJoinGroup[j];
                    if (selectedGroup.id === joinGroup.groupId) {
                        if (
                            joinGroup.underApplication !== 1 &&
                            joinGroup.underInvitation !== 1
                        ) {
                            exist = true;
                            break;
                        }
                    }
                }
                if (!exist) {
                    selectedGroup.selected = true;
                    wkGroupList.push(selectedGroup);
                }
            }
        }
        setGroupList([...wkOwnGroupList, ...wkGroupList]);
        if (wkSelectedGroupList != null) {
            refSelectedGroupList.current = wkSelectedGroupList;
            setSelectedGroupList(wkSelectedGroupList);
        }
        setLoaded(true);
    }

    return (
        <div className="component SelectGroupComponent">
            <div className="search-area">
                {/* グループリスト */}
                <Box ref={container} className="result-area">
                    {/* グループリストが０件の場合 */}
                    {loaded && (lstGroup == null || lstGroup.length === 0) && (
                        <div className="no-group">
                            <FormattedMessage id="no_join_group" />
                        </div>
                    )}
                    {loaded &&
                        lstGroup != null &&
                        lstGroup.map((group: GroupModel, index: number) => {
                            return (
                                <div key={index} className="item-wrapper">
                                    <GroupListItem
                                        key={index}
                                        group={group}
                                        selected={group.selected}
                                        onClick={() => {
                                            onClickGroup(group);
                                        }}
                                    />
                                </div>
                            );
                        })}
                    {/* {trigger != null && (
                        <Zoom in={trigger}>
                            <Box
                                className="scroll-trigger"
                                role="presentation"
                                sx={{
                                    position: "fixed",
                                    bottom: {
                                        xs: 100,
                                        sm: 32,
                                    },
                                    right: 10,
                                    zIndex: 100,
                                }}
                            >
                                <Fab
                                    onClick={scrollToTop}
                                    color="primary"
                                    size="small"
                                    aria-label="scroll back to top"
                                >
                                    <KeyboardArrowUp />
                                </Fab>
                            </Box>
                        </Zoom>
                    )} */}
                </Box>
            </div>
            {
                // ボタンエリア
            }
            {loaded && (
                <div className="button-area">
                    <Button
                        className="app-button button btn-cancel"
                        variant="text"
                        onClick={onClickCancel}
                    >
                        <FormattedMessage id={"btn_cancel"} />
                    </Button>
                    {lstGroup != null && lstGroup.length > 0 && (
                        <Button
                            className="app-button button btn-ok"
                            variant="text"
                            onClick={onClickOk}
                        >
                            <FormattedMessage id={"btn_ok"} />
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};
