import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Button,
    OutlinedInput,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";

import { UserModel, GroupModel, JoinGroupModel } from "models/Models";
import {
    JoinGroupListItemGroup,
    JoinGroupListItemUser,
    NavBar,
} from "components/Components";
import { getTimeDifference, getLanguageCode } from "redux/Selectors";
import {
    GROUP_LIST_TYPE,
    GROUP_JOIN_STATUS,
    JOIN_GROUP_ACTION,
} from "constants/Constants";
import { JoinGroupRequest } from "api/requests/Requests";
import Utility from "utils/Utility";
import { pushDataLayer } from "gtm/gtm"

import "styles/pages/mypage/ManageGroupList.scss";

/***** type */
type Props = {
    loginUser: UserModel;
};

const ManageGroupList: React.FC<Props> = React.memo(
    (props) => {
        /***** 定数 */
        const navigate = useNavigate();
        const intl = useIntl();
        const selector = useSelector((state) => state);
        const container = React.useRef<HTMLDivElement>(null);
        const langCd = getLanguageCode(selector);

        /***** useRef */
        const refTimeDifference = React.useRef<number>(
            getTimeDifference(selector)
        );
        // 処理タイプ
        const joinGroupAction = React.useRef<JOIN_GROUP_ACTION>();
        // 処理中フラグ
        const processing = React.useRef<boolean>(false);

        /***** useState */
        // 時差
        const [timeDifference, setTimeDifference] = React.useState<number>();
        // 加入中グループリスト
        const [lstJoinGroup, setJoinGroupList] =
            React.useState<JoinGroupModel[]>();
        // 被招待中グループ
        const [lstUnderInvited, setUnderInvitedList] =
            React.useState<JoinGroupModel[]>();
        // 招待中グループ
        const [lstInviting, setInvitingList] =
            React.useState<JoinGroupModel[]>();
        // 被加入申請中グループ
        const [lstUnderApplied, setUnderAppliedList] =
            React.useState<JoinGroupModel[]>();
        // 加入申請中グループ
        const [lstApplying, setApplyingList] =
            React.useState<JoinGroupModel[]>();
        const [selectedJoinGroup, setSelectedJoinGroup] =
            React.useState<JoinGroupModel>();
        // データロード完了フラグ
        const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

        // 確認ダイアログ表示フラグ
        const [openConfirmDialog, setOpenConfirmDialog] =
            React.useState<boolean>(false);
        // ダイアログメッセージ
        const [dialogMessage, setDialogMessage] = React.useState<string>();
        // ダイアログコメント
        const [dialogComment, setDialogComment] = React.useState<string>();

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "グループ管理",
                path: window.location.pathname,
            });
        }, []);        
        React.useEffect(() => {
            if (refTimeDifference.current != null) {
                setTimeDifference(refTimeDifference.current);
            }
        }, [refTimeDifference.current]);
        React.useEffect(() => {
            if (props.loginUser != null) {
                fetchJoinGroupList();
            }
        }, [props.loginUser]);

        /**
         * グループクリック時
         * @param joinGroup
         */
        async function onClickGroup(joinGroup: JoinGroupModel) {
            const groupId = joinGroup.groupId;
            if (groupId != null) {
                navigate("/groups/" + groupId);
            }
        }
        /**
         * ユーザクリック時
         * @param target
         */
        async function onClickUser(target: UserModel) {
            const mark = Utility.getUserKey(target);
            navigate("/users/" + mark + "/profile");
        }
        /**
         * 招待受諾
         * @param joinGroup
         */
        function onClickAcceptInvitation(joinGroup: JoinGroupModel) {
            const group = joinGroup.group;
            if (group == null || group.name == null) {
                return;
            }
            let message = intl.formatMessage({
                id: "confirm_accept_invitation",
            });
            if (message == null) {
                return;
            }
            message = message.replace("[GROUPNAME]", "[" + group.name + "]");
            showConfirmDialog(
                JOIN_GROUP_ACTION.ACCEPT_INVITATION,
                joinGroup,
                message
            );
        }
        async function acceptInvitation(joinGroup: JoinGroupModel) {
            if (
                props.loginUser == null ||
                joinGroup == null ||
                joinGroup.groupId == null
            ) {
                return;
            }
            if (processing.current) {
                return;
            }
            processing.current = true;
            const res = await JoinGroupRequest.acceptInvitation(
                props.loginUser,
                joinGroup.groupId,
                dialogComment
            );
            if (res == null || res.rtnCd == null || res.rtnCd < 0) {
                const errMessage = intl.formatMessage({
                    id: "err_common",
                });
                setDialogMessage(errMessage);
                processing.current = false;
                return;
            }
            processing.current = false;
            fetchJoinGroupList();
        }
        /**
         * 招待を拒否
         * @param joinGroup
         */
        function onClickDeclineInvitation(joinGroup: JoinGroupModel) {
            const group = joinGroup.group;
            if (group == null || group.name == null) {
                return;
            }
            let message = intl.formatMessage({
                id: "confirm_decline_invitation",
            });
            if (message == null) {
                return;
            }
            message = message.replace("[GROUPNAME]", "[" + group.name + "]");
            showConfirmDialog(
                JOIN_GROUP_ACTION.DECLINE_INVITATION,
                joinGroup,
                message
            );
        }
        async function declineInvitation(joinGroup: JoinGroupModel) {
            if (
                props.loginUser == null ||
                joinGroup == null ||
                joinGroup.groupId == null
            ) {
                return;
            }
            if (processing.current) {
                return;
            }
            processing.current = true;
            const res = await JoinGroupRequest.declineInvitation(
                props.loginUser,
                joinGroup.groupId,
                dialogComment
            );
            if (res == null || res.rtnCd == null || res.rtnCd < 0) {
                const errMessage = intl.formatMessage({
                    id: "err_common",
                });
                setDialogMessage(errMessage);
                processing.current = false;
                return;
            }
            processing.current = false;
            fetchJoinGroupList();
        }
        /**
         * 加入申請を取り下げる
         * @param joinGroup
         */
        function onClickWithdrawApplying(joinGroup: JoinGroupModel) {
            const group = joinGroup.group;
            if (group == null || group.name == null) {
                return;
            }
            let message = intl.formatMessage({
                id: "confirm_withdraw_application",
            });
            if (message == null) {
                return;
            }
            showConfirmDialog(
                JOIN_GROUP_ACTION.WITHDRAW_APPLYING,
                joinGroup,
                message
            );
        }
        async function withdrawApplying(joinGroup: JoinGroupModel) {
            if (
                props.loginUser == null ||
                joinGroup == null ||
                joinGroup.groupId == null
            ) {
                return;
            }
            if (processing.current) {
                return;
            }
            processing.current = true;
            const res = await JoinGroupRequest.withdrawApplying(
                props.loginUser,
                joinGroup.groupId,
                dialogComment
            );
            if (res == null || res.rtnCd == null || res.rtnCd < 0) {
                const errMessage = intl.formatMessage({
                    id: "err_common",
                });
                setDialogMessage(errMessage);
                processing.current = false;
                return;
            }
            processing.current = false;
            fetchJoinGroupList();
        }
        /**
         * 加入申請を承認
         * @param joinGroup
         */
        function onClickApproveApplication(joinGroup: JoinGroupModel) {
            const group = joinGroup.group;
            const user = joinGroup.user;
            if (
                group == null ||
                group.name == null ||
                user == null ||
                user.name == null
            ) {
                return;
            }
            let message = intl.formatMessage({
                id: "confirm_approve_application",
            });
            if (message == null) {
                return;
            }
            message = message.replace("[GROUPNAME]", "[" + group.name + "]");
            message = message.replace("[USERNAME]", "[" + user.name + "]");
            showConfirmDialog(
                JOIN_GROUP_ACTION.APPROVE_APPLICATION,
                joinGroup,
                message
            );
        }
        async function approveApplication(joinGroup: JoinGroupModel) {
            if (
                props.loginUser == null ||
                joinGroup == null ||
                joinGroup.id == null ||
                joinGroup.groupId == null ||
                joinGroup.userId == null
            ) {
                return;
            }
            if (processing.current) {
                return;
            }
            processing.current = true;
            const res = await JoinGroupRequest.approveApplication(
                props.loginUser,
                joinGroup.userId,
                joinGroup.groupId,
                joinGroup.id,
                dialogComment
            );
            if (res == null || res.rtnCd == null || res.rtnCd < 0) {
                const errMessage = intl.formatMessage({
                    id: "err_common",
                });
                setDialogMessage(errMessage);
                processing.current = false;
                return;
            }
            processing.current = false;
            fetchJoinGroupList();
        }
        /**
         * 加入申請を拒否
         * @param joinGroup
         */
        function onClickDeclineApplication(joinGroup: JoinGroupModel) {
            const group = joinGroup.group;
            const user = joinGroup.user;
            if (
                group == null ||
                group.name == null ||
                user == null ||
                user.name == null
            ) {
                return;
            }
            let message = intl.formatMessage({
                id: "confirm_reject_application",
            });
            if (message == null) {
                return;
            }
            message = message.replace("[GROUPNAME]", "[" + group.name + "]");
            message = message.replace("[USERNAME]", "[" + user.name + "]");
            showConfirmDialog(
                JOIN_GROUP_ACTION.DECLINE_APPLICATION,
                joinGroup,
                message
            );
        }
        async function declineApplication(joinGroup: JoinGroupModel) {
            if (
                props.loginUser == null ||
                joinGroup == null ||
                joinGroup.id == null ||
                joinGroup.groupId == null ||
                joinGroup.userId == null
            ) {
                return;
            }
            if (processing.current) {
                return;
            }
            processing.current = true;
            const res = await JoinGroupRequest.declineApplication(
                props.loginUser,
                joinGroup.userId,
                joinGroup.groupId,
                joinGroup.id,
                dialogComment
            );
            if (res == null || res.rtnCd == null || res.rtnCd < 0) {
                const errMessage = intl.formatMessage({
                    id: "err_common",
                });
                setDialogMessage(errMessage);
                processing.current = false;
                return;
            }
            processing.current = false;
            fetchJoinGroupList();
        }
        /**
         * 招待を取り下げる
         * @param joinGroup
         */
        async function onClickWithdrawInviting(joinGroup: JoinGroupModel) {
            const group = joinGroup.group;
            const user = joinGroup.user;
            if (
                group == null ||
                group.name == null ||
                user == null ||
                user.name == null
            ) {
                return;
            }
            let message = intl.formatMessage({
                id: "confirm_withdraw_inviting",
            });
            if (message == null) {
                return;
            }
            showConfirmDialog(
                JOIN_GROUP_ACTION.WITHDRAW_INVITING,
                joinGroup,
                message
            );
        }
        async function withdrawInviting(joinGroup: JoinGroupModel) {
            if (
                props.loginUser == null ||
                joinGroup == null ||
                joinGroup.groupId == null ||
                joinGroup.userId == null
            ) {
                return;
            }
            if (processing.current) {
                return;
            }
            processing.current = true;
            const res = await JoinGroupRequest.withdrawInviting(
                props.loginUser,
                joinGroup.groupId,
                joinGroup.userId,
                dialogComment
            );
            if (res == null || res.rtnCd == null || res.rtnCd < 0) {
                const errMessage = intl.formatMessage({
                    id: "err_common",
                });
                setDialogMessage(errMessage);
                processing.current = false;
                return;
            }
            processing.current = false;
            fetchJoinGroupList();
        }
        /**
         * 確認ダイアログ表示
         * @param _joinGroupAction
         * @param joinGroup
         * @param message
         */
        function showConfirmDialog(
            _joinGroupAction: JOIN_GROUP_ACTION,
            joinGroup: JoinGroupModel,
            message: string
        ) {
            joinGroupAction.current = _joinGroupAction;
            setSelectedJoinGroup(joinGroup);
            setDialogMessage(message);
            setOpenConfirmDialog(true);
        }
        /**
         * 確認ダイアログで「YES」押下時
         */
        async function onClickDialogYes() {
            if (
                selectedJoinGroup == null ||
                joinGroupAction == null ||
                joinGroupAction.current == null
            ) {
                return;
            }
            switch (joinGroupAction.current) {
                case JOIN_GROUP_ACTION.ACCEPT_INVITATION:
                    await acceptInvitation(selectedJoinGroup);
                    break;
                case JOIN_GROUP_ACTION.DECLINE_INVITATION:
                    await declineInvitation(selectedJoinGroup);
                    break;
                case JOIN_GROUP_ACTION.DECLINE_APPLICATION:
                    await declineApplication(selectedJoinGroup);
                    break;
                case JOIN_GROUP_ACTION.APPROVE_APPLICATION:
                    await approveApplication(selectedJoinGroup);
                    break;
                case JOIN_GROUP_ACTION.WITHDRAW_INVITING:
                    await withdrawInviting(selectedJoinGroup);
                    break;
                case JOIN_GROUP_ACTION.WITHDRAW_APPLYING:
                    await withdrawApplying(selectedJoinGroup);
                    break;
            }
            setOpenConfirmDialog(false);
            setDialogComment(undefined);
        }
        /**
         * 加入中グループ取得
         */
        async function fetchJoinGroupList() {
            if (props.loginUser.id == null) {
                return;
            }
            const result = await JoinGroupRequest.getJoinGroupList(
                props.loginUser,
                props.loginUser.id
            );
            if (result == null || result.rtnCd == null || result.rtnCd < 0) {
                return;
            }
            if (result.lstJoinGroup != null) {
                const wkJoinGroupList: JoinGroupModel[] = [];
                const wkApplyingList: JoinGroupModel[] = [];
                const wkUnderInvitedList: JoinGroupModel[] = [];
                for (let i = 0; i < result.lstJoinGroup.length; i++) {
                    const joinGroup = result.lstJoinGroup[i];
                    const group = joinGroup.group;
                    if (group == null) {
                        continue;
                    }
                    // 自分がグループオーナーの場合
                    if (group.ownerId === props.loginUser.id) {
                        group.isOwner = true;
                        group.join = true;
                    }
                    // 自分が加入申請中の場合
                    if (joinGroup.underApplication === 1) {
                        group.applying = true;
                        wkApplyingList.push(joinGroup);
                    }
                    // 自分が招待を受けている場合
                    else if (joinGroup.underInvitation === 1) {
                        group.underInvitation = true;
                        wkUnderInvitedList.push(joinGroup);
                    }
                    // 加入中
                    else {
                        group.join = true;
                        wkJoinGroupList.push(joinGroup);
                    }
                }
                setJoinGroupList(wkJoinGroupList);
                setApplyingList(wkApplyingList);
                setUnderInvitedList(wkUnderInvitedList);
            }
            // 自分が招待してる場合
            if (result.lstUnderInvitation != null) {
                const wkInvitingList: JoinGroupModel[] = [];
                for (let i = 0; i < result.lstUnderInvitation.length; i++) {
                    const joinGroup = result.lstUnderInvitation[i];
                    const group = joinGroup.group;
                    if (group == null) {
                        continue;
                    }
                    if (group.ownerId === props.loginUser.id) {
                        group.inviting = true;
                        wkInvitingList.push(joinGroup);
                    }
                }
                setInvitingList(wkInvitingList);
            }
            // 加入申請を受けている場合
            if (result.lstUnderApplication != null) {
                const wkUnderAppliedList: JoinGroupModel[] = [];
                for (let i = 0; i < result.lstUnderApplication.length; i++) {
                    const joinGroup = result.lstUnderApplication[i];
                    const group = joinGroup.group;
                    if (group == null) {
                        continue;
                    }
                    if (group.ownerId === props.loginUser.id) {
                        group.underApplication = true;
                        wkUnderAppliedList.push(joinGroup);
                    }
                }
                setUnderAppliedList(wkUnderAppliedList);
            }
            setDataLoaded(true);
        }

        type GroupListHeaderProps = {
            type: GROUP_LIST_TYPE;
        };
        /**
         * グループリストヘッダー
         * @param props
         * @returns
         */
        const GroupListHeader = (props: GroupListHeaderProps) => {
            let title = "";
            let className = "group-list-title";
            switch (props.type) {
                // 加入申請を受けているリスト
                case GROUP_LIST_TYPE.UNDER_APPLIED:
                    title = intl.formatMessage({ id: "join_group_applied" });
                    className = className + " under-applied";
                    break;
                // 招待を受けているリスト
                case GROUP_LIST_TYPE.UNDER_INVITED:
                    title = intl.formatMessage({ id: "join_group_invited" });
                    className = className + " under-invited";
                    break;
                // 自分が加入申請をしているリスト
                case GROUP_LIST_TYPE.APPLYING:
                    title = intl.formatMessage({
                        id: "join_group_under_application",
                    });
                    className = className + " applying";
                    break;
                // 自分が招待をしているリスト
                case GROUP_LIST_TYPE.INVITING:
                    title = intl.formatMessage({
                        id: "join_group_under_invitation",
                    });
                    className = className + " inviting";
                    break;
                // 加入中グループリスト
                case GROUP_LIST_TYPE.JOIN:
                    title = intl.formatMessage({ id: "join_group_member" });
                    className = className + " joined";
                    break;
            }
            if (title === "") {
                return <></>;
            }
            return <h2 className={className}>{title}</h2>;
        };

        type GroupListContentsProps = {
            lstJoinGroup: JoinGroupModel[];
            groupJoinStatus: GROUP_JOIN_STATUS;
        };
        /**
         * グループリスト
         * @param props
         * @returns
         */
        const GroupListContents = (props: GroupListContentsProps) => {
            return (
                <div className="group-list-wrapper">
                    {props.lstJoinGroup.map(
                        (joinGroup: JoinGroupModel, index: number) => {
                            const group = joinGroup.group;
                            const groupId = joinGroup.groupId;
                            if (group != null && groupId != null) {
                                return (
                                    <React.Fragment key={index}>
                                        <JoinGroupListItemGroup
                                            joinGroup={joinGroup}
                                            group={group}
                                            groupJoinStatus={
                                                props.groupJoinStatus
                                            }
                                            onClick={() => {
                                                onClickGroup(joinGroup);
                                            }}
                                            onClickJoin={() => {
                                                onClickAcceptInvitation(
                                                    joinGroup
                                                );
                                            }}
                                            onClickDecline={() => {
                                                onClickDeclineInvitation(
                                                    joinGroup
                                                );
                                            }}
                                            onClickWithdraw={() => {
                                                onClickWithdrawApplying(
                                                    joinGroup
                                                );
                                            }}
                                        />
                                    </React.Fragment>
                                );
                            } else {
                                return (
                                    <React.Fragment
                                        key={index}
                                    ></React.Fragment>
                                );
                            }
                        }
                    )}
                </div>
            );
        };

        type UserListSubHeaderProps = {
            group: GroupModel;
        };
        /**
         * ユーザリストサブヘッダー
         * @param props
         */
        const UserListSubHeader = (props: UserListSubHeaderProps) => {
            const groupName = props.group.name;
            return (
                <>
                    {groupName != null && (
                        <h2 className="sub-header-user-list">{groupName}</h2>
                    )}
                </>
            );
        };

        type UserListContentsProps = {
            lstJoinGroup: JoinGroupModel[];
            groupJoinStatus: GROUP_JOIN_STATUS;
        };
        /**
         * ユーザリスト
         * @param props
         * @returns
         */
        const UserListContents = (props: UserListContentsProps) => {
            let prevGroupId: number = -1;
            return (
                <div className="user-list-wrapper">
                    {props.lstJoinGroup.map(
                        (joinGroup: JoinGroupModel, index: number) => {
                            const group = joinGroup.group;
                            const groupId = joinGroup.groupId;
                            const user = joinGroup.user;
                            if (
                                langCd != null &&
                                timeDifference != null &&
                                group != null &&
                                groupId != null &&
                                user != null
                            ) {
                                let showSubHeader = false;
                                if (
                                    props.groupJoinStatus ===
                                        GROUP_JOIN_STATUS.APPLIED ||
                                    props.groupJoinStatus ===
                                        GROUP_JOIN_STATUS.INVITING
                                ) {
                                    if (
                                        prevGroupId === -1 ||
                                        prevGroupId !== groupId
                                    ) {
                                        showSubHeader = true;
                                    }
                                }
                                prevGroupId = groupId;

                                return (
                                    <React.Fragment key={index}>
                                        {showSubHeader && (
                                            <UserListSubHeader group={group} />
                                        )}
                                        <JoinGroupListItemUser
                                            joinGroup={joinGroup}
                                            group={group}
                                            groupJoinStatus={
                                                props.groupJoinStatus
                                            }
                                            user={user}
                                            timeDifference={timeDifference}
                                            langCd={langCd}
                                            onClick={() => {
                                                onClickUser(user);
                                            }}
                                            onClickApprove={() => {
                                                onClickApproveApplication(
                                                    joinGroup
                                                );
                                            }}
                                            onClickDecline={() => {
                                                onClickDeclineApplication(
                                                    joinGroup
                                                );
                                            }}
                                            onClickWithdraw={() => {
                                                onClickWithdrawInviting(
                                                    joinGroup
                                                );
                                            }}
                                        />
                                    </React.Fragment>
                                );
                            } else {
                                return (
                                    <React.Fragment
                                        key={index}
                                    ></React.Fragment>
                                );
                            }
                        }
                    )}
                </div>
            );
        };

        /**
         * レンダリング
         */
        return (
            <div className="pageWrapper ManageGroupList">
                <NavBar
                    showBack={true}
                    title={intl.formatMessage({ id: "title_item_list" })}
                />
                {dataLoaded &&
                    (lstJoinGroup == null || lstJoinGroup.length === 0) &&
                    (lstApplying == null || lstApplying.length === 0) &&
                    (lstInviting == null || lstInviting.length === 0) &&
                    (lstUnderApplied == null || lstUnderApplied.length === 0) &&
                    (lstUnderInvited == null ||
                        lstUnderInvited.length === 0) && (
                        <div className="no-joined-group-page">
                            <FormattedMessage id="no_join_group" />
                        </div>
                    )}
                {dataLoaded && (
                    <div ref={container} className="contents-wrapper">
                        {
                            /* 加入申請を受けているリスト */
                            lstUnderApplied != null &&
                                lstUnderApplied.length > 0 && (
                                    <div className="list-wrapper under-applied">
                                        <GroupListHeader
                                            type={GROUP_LIST_TYPE.UNDER_APPLIED}
                                        />
                                        <UserListContents
                                            lstJoinGroup={lstUnderApplied}
                                            groupJoinStatus={
                                                GROUP_JOIN_STATUS.APPLIED
                                            }
                                        />
                                    </div>
                                )
                        }
                        {
                            /* 招待を受けているリスト */
                            lstUnderInvited != null &&
                                lstUnderInvited.length > 0 && (
                                    <div className="list-wrapper under-invited">
                                        <GroupListHeader
                                            type={GROUP_LIST_TYPE.UNDER_INVITED}
                                        />
                                        <GroupListContents
                                            lstJoinGroup={lstUnderInvited}
                                            groupJoinStatus={
                                                GROUP_JOIN_STATUS.INVITED
                                            }
                                        />
                                    </div>
                                )
                        }
                        {
                            /* 自分が加入申請をしているリスト */
                            lstApplying != null && lstApplying.length > 0 && (
                                <div className="list-wrapper applying">
                                    <GroupListHeader
                                        type={GROUP_LIST_TYPE.APPLYING}
                                    />
                                    <GroupListContents
                                        lstJoinGroup={lstApplying}
                                        groupJoinStatus={
                                            GROUP_JOIN_STATUS.APPLYING
                                        }
                                    />
                                </div>
                            )
                        }
                        {
                            /* 自分が招待をしているリスト */
                            lstInviting != null && lstInviting.length > 0 && (
                                <div className="list-wrapper inviting">
                                    <GroupListHeader
                                        type={GROUP_LIST_TYPE.INVITING}
                                    />
                                    <UserListContents
                                        lstJoinGroup={lstInviting}
                                        groupJoinStatus={
                                            GROUP_JOIN_STATUS.INVITING
                                        }
                                    />
                                </div>
                            )
                        }
                        {
                            /* 加入中グループリスト */
                            lstJoinGroup != null && lstJoinGroup.length > 0 && (
                                <div className="list-wrapper join">
                                    <GroupListHeader
                                        type={GROUP_LIST_TYPE.JOIN}
                                    />
                                    <GroupListContents
                                        lstJoinGroup={lstJoinGroup}
                                        groupJoinStatus={
                                            GROUP_JOIN_STATUS.JOINED
                                        }
                                    />
                                </div>
                            )
                        }
                    </div>
                )}
                <Dialog
                    className="dialog"
                    open={openConfirmDialog}
                    onClose={() => {
                        setOpenConfirmDialog(false);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        className="dialog-title"
                        id="alert-dialog-title"
                    >
                        <FormattedMessage id={"dlg_title_message"} />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMessage}
                        </DialogContentText>
                        <div className="message-label">
                            <FormattedMessage id="message" />
                        </div>
                        <OutlinedInput
                            className="comment"
                            fullWidth
                            label=""
                            type="text"
                            multiline={true}
                            inputProps={{
                                maxLength: 1000,
                            }}
                            rows={5}
                            value={dialogComment}
                            onChange={(e) => {
                                setDialogComment(e.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            className="dialog-button"
                            onClick={onClickDialogYes} 
                            color="primary">
                            <FormattedMessage id={"btn_yes2"} />
                        </Button>
                        <Button
                            className="dialog-button"
                            onClick={() => {
                                setOpenConfirmDialog(false);
                            }}
                            color="primary"
                        >
                            <FormattedMessage id={"btn_cancel"} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default ManageGroupList;
