import {
    createStore as reduxCreateStore,
    combineReducers,
    applyMiddleware,
    compose,
    // applyMiddleware,
} from "redux";
import thunk from "redux-thunk";
// import { connectRouter, routerMiddleware } from "connected-react-router";
import { ProcessReducer } from "../process/reducers";
import { LanguageReducer } from "../language/reducers";
import { TimeDifferenceReducer } from "../timeDifference/reducers";
import { MaintenanceReducer } from "../maintenance/reducers";
import { SelectedItemReducer } from "../selectedItem/reducers";
import { WorkingItemReducer } from "../workingItem/reducers";
import { FooterMenuReducer } from "../footerMenu/reducers";
import { UserReducer } from "../user/reducers";
import { ReadSizeReducer } from "../readSize/reducers";
import { HistoryReducer } from "../history/reducers";
import { UnreadCountReducer } from "../unreadCount/reducers";
import { EmitterReducer } from "../emitter/reducers";
import { AlbumTemplateReducer } from "../albumTemplate/reducers";
import { TagListReducer } from "../tagList/reducers";
import { CountryListReducer } from "../countryList/reducers";
import { CategoryListReducer } from "../categoryList/reducers";
import { RtcInfoReducer } from "../rtcInfo/reducers";
import { ShootReducer } from "../shoot/reducers";
// import { CustomTagListReducer } from "../customTagList.back/reducers";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function createStore() {
    return reduxCreateStore(
        combineReducers({
            // router: connectRouter(history),
            timeDifference: TimeDifferenceReducer,
            maintenance: MaintenanceReducer,
            language: LanguageReducer,
            process: ProcessReducer,
            selectedItem: SelectedItemReducer,
            workingItem: WorkingItemReducer,
            user: UserReducer,
            readSize: ReadSizeReducer,
            footerMenu: FooterMenuReducer,
            history: HistoryReducer,
            unreadCount: UnreadCountReducer,
            emitter: EmitterReducer,
            albumTemplate: AlbumTemplateReducer,
            tagList: TagListReducer,
            countryList: CountryListReducer,
            categoryList: CategoryListReducer,
            rtcInfo: RtcInfoReducer,
            shoot: ShootReducer,
            // customTagList: CustomTagListReducer,
        }),
        composeEnhancers(applyMiddleware(thunk))
        // applyMiddleware(thunk)
        // applyMiddleware(routerMiddleware(history))
    );
}
