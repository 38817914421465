import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Box, IconButton, TextField, Button } from "@mui/material";
import SendButton from "@mui/icons-material/Send";
import { useNavigate, useParams } from "react-router-dom";
import {
    UserModel,
    GroupModel,
    GroupChatMemberData,
    GroupChatMessageData,
} from "models/Models";
import {
    getLanguageCode,
    getTimeDifference,
    getSelectedGroup,
} from "redux/Selectors";

import { GroupRequest } from "api/requests/GroupRequest";
import { GroupChatListItem, NavBar } from "components/Components";
import { GroupChatServer } from "socket/GroupChatServer";
import { AppConstants } from "constants/AppConstants";
import Utility from "utils/Utility";
import { DbConstants } from "constants/Constants";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/group/GroupChat.scss";

type Props = {
    loginUser: UserModel | null;
};

const GroupChat: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ GroupChat IN");
        /***** 定数、変数 */
        const intl = useIntl();
        const navigate = useNavigate();
        const selector = useSelector((state) => state);
        const { group_id } = useParams<{ group_id: string }>();
        const langCd = getLanguageCode(selector);

        /***** useRef */
        const refTimeDifference = React.useRef<number>(
            getTimeDifference(selector)
        );
        // チャットサーバー
        const chatServer = React.useRef<GroupChatServer>(new GroupChatServer());
        // チャット表示エリア
        const refChatArea = React.useRef<HTMLDivElement>(null);
        // 処理中フラグ
        const isUnderProcess = React.useRef(false);
        // 次レコード有無
        const hasNext = React.useRef<boolean>(true);
        // 読み込みインデックス日付
        const limitUnixTime = React.useRef<number>(0);
        const prevLimitUnixTime = React.useRef<number>(0);

        /***** useState */
        // 時差
        const [timeDifference, setTimeDifference] = React.useState<number>();
        // 本グループ
        const [group, setGroup] = React.useState<GroupModel>(
            getSelectedGroup(selector)
        );
        // 入力チャットメッセージ
        const [message, setMessage] = React.useState<string>("");
        // チャット履歴
        const [lstHistory, setHistoryList] = React.useState<
            GroupChatMessageData[]
        >([]);
        // 入室者リスト
        const [lstMember, setMemberList] = React.useState<
            GroupChatMemberData[]
        >([]);
        // ボタンラベル
        const [btnSendLabel, setBtnSendLabel] = React.useState<string>("");
        // ボタン活性化フラグ
        const [btnSendDisabled, setBtnSendDisabled] =
            React.useState<boolean>(false);

        /***** useEffect */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "グループチャット",
                path: window.location.pathname,
            })
        }, []);
        React.useEffect(() => {
            setSendButtonLabel("btn_send");
        }, [langCd]);
        React.useEffect(() => {
            if (refTimeDifference.current != null) {
                setTimeDifference(refTimeDifference.current);
            }
        }, [refTimeDifference.current]);
        React.useEffect(() => {
            if (props.loginUser == null) {
                return;
            }
            if (group != null && group.id != null) {
                const prevLimit = limitUnixTime.current;
                const limit = getNextLimitUnixTime(prevLimit);
                (async () => {
                    if (prevLimit === 0) {
                        await getGroupChatHistory(limit, prevLimit);
                    }
                    window.setTimeout(() => {
                        connectToChatSerer();
                    });
                })();
            } else if (group_id != null && group_id.length > 0) {
                (async () => {
                    if (props.loginUser == null) {
                        return;
                    }
                    const p1 = fetchGroup();
                    const p2 = fetchGroupMemberList(parseInt(group_id));
                    const [wkGroup, wkGroupMemberList] = await Promise.all([
                        p1,
                        p2,
                    ]);
                    if (wkGroup == null || wkGroupMemberList == null) {
                        navigate("/");
                        return;
                    }
                    let isMember = false;
                    for (let i = 0; i < wkGroupMemberList.length; i++) {
                        const member = wkGroupMemberList[i];
                        if (props.loginUser.id === member.id) {
                            isMember = true;
                            break;
                        }
                    }
                    if (!isMember) {
                        navigate("/");
                        return;
                    }
                    setGroup(wkGroup);
                })();
            }
            return () => {
                disconnectFromChatServer();
            };
        }, [props.loginUser, group]);

        /**
         * 接続コールバック
         * @returns
         */
        function onConnect() {
            Utility.log("GroupChat onConnect");
            if (
                chatServer == null ||
                chatServer.current == null ||
                chatServer.current.socket == null ||
                !chatServer.current.socket.connected
            ) {
                window.setTimeout(onConnect, 3000);
                return;
            }
            sendEnterInfo();
        }
        /**
         * データ受信コールバック
         * @param obj
         */
        function onReceive(obj: any) {
            // Utility.log("onReceive obj:" + JSON.stringify(obj));
            if (
                Object.keys(obj).indexOf("isMessage") > -1 &&
                obj["isMessage"]
            ) {
                onReceiveMessage(obj);
            } else if (
                Object.keys(obj).indexOf("isEnterInfo") > -1 &&
                obj["isEnterInfo"]
            ) {
                onReceiveEnterInfo(obj);
            }
            if (
                Object.keys(obj).indexOf("isLeaveInfo") > -1 &&
                obj["isLeaveInfo"]
            ) {
                onReceiveLeaveInfo(obj);
            }
            window.setTimeout(() => {
                if (refChatArea != null && refChatArea.current != null) {
                    refChatArea.current.scrollTo({
                        top: refChatArea.current?.scrollHeight,
                    });
                }
            }, 300);
        }
        /**
         * チャットメッセージ受信コールバック
         * @param obj
         */
        function onReceiveMessage(obj: any) {
            let wkTimeDifference = timeDifference;
            let wkLangCd = langCd;
            if (wkTimeDifference == null) {
                wkTimeDifference = getTimeDifference(selector);
            }
            if (wkLangCd == null) {
                wkLangCd = getLanguageCode(selector);
            }

            const history = new GroupChatMessageData();
            if (Object.keys(obj).indexOf("Id") > -1) {
                history.id = obj["Id"];
            }
            if (Object.keys(obj).indexOf("GroupId") > -1) {
                history.groupId = obj["GroupId"];
            }
            if (Object.keys(obj).indexOf("Message") > -1) {
                history.message = obj["Message"];
            }
            if (Object.keys(obj).indexOf("SenderId") > -1) {
                history.senderId = obj["SenderId"];
            }
            if (Object.keys(obj).indexOf("Sender") > -1) {
                history.sender = new GroupChatMemberData(obj["Sender"]);
            }
            if (Object.keys(obj).indexOf("CreatedAt") > -1) {
                history.createdAt = obj["CreatedAt"];
                const createdAt = new Date(obj["CreatedAt"]);
                const fomattedCreatedAt = Utility.getFormattedTime(
                    createdAt.getTime() / 1000,
                    wkTimeDifference == null ? 0 : wkTimeDifference,
                    wkLangCd == null ? "en" : wkLangCd
                );
                history.displayTime = fomattedCreatedAt;
            }
            setHistoryList((prevHistoryList: GroupChatMessageData[]) =>
                prevHistoryList.concat([history])
            );

            if (
                props.loginUser != null &&
                props.loginUser.id != null &&
                history.senderId === props.loginUser.id
            ) {
                isUnderProcess.current = false;
                setBtnSendDisabled(false);
                setSendButtonLabel("btn_send");
                setMessage("");
            }
        }
        /**
         * 入室情報受信コールバック
         * @param obj
         */
        function onReceiveEnterInfo(obj: any) {
            let wkTimeDifference = timeDifference;
            let wkLangCd = langCd;
            if (wkTimeDifference == null) {
                wkTimeDifference = getTimeDifference(selector);
            }
            if (wkLangCd == null) {
                wkLangCd = getLanguageCode(selector);
            }

            if (Object.keys(obj).indexOf("lstMember") > -1) {
                let wkMemberList: GroupChatMemberData[] = [];
                for (let i in obj["lstMember"]) {
                    const member = new GroupChatMemberData(obj["lstMember"][i]);
                    wkMemberList.push(member);
                }
                setMemberList(wkMemberList);
            }
            if (
                Object.keys(obj).indexOf("userInfo") > -1 &&
                Object.keys(obj).indexOf("enteredAt") > -1
            ) {
                const member = new GroupChatMemberData(obj["userInfo"]);
                const strEnteredAt = obj["enteredAt"] as string;
                const enteredAt = new Date(strEnteredAt);

                if (member.name != null) {
                    const history = new GroupChatMessageData();
                    history.enter = true;
                    if (langCd === "ja") {
                        history.message = member.name + " さんが入室しました。";
                    } else {
                        history.message = member.name + " has entered.";
                    }
                    const fomattedEnteredAt = Utility.getFormattedTime(
                        enteredAt.getTime() / 1000,
                        wkTimeDifference == null ? 0 : wkTimeDifference,
                        wkLangCd == null ? "en" : wkLangCd
                    );
                    history.displayTime = fomattedEnteredAt;
                    setHistoryList((prevHistoryList: GroupChatMessageData[]) =>
                        prevHistoryList.concat([history])
                    );
                }
            }
        }
        /**
         * 退室情報受信コールバック
         * @param obj
         */
        function onReceiveLeaveInfo(obj: any) {
            let wkTimeDifference = timeDifference;
            let wkLangCd = langCd;
            if (wkTimeDifference == null) {
                wkTimeDifference = getTimeDifference(selector);
            }
            if (wkLangCd == null) {
                wkLangCd = getLanguageCode(selector);
            }

            if (Object.keys(obj).indexOf("lstMember") > -1) {
                let wkMemberList: GroupChatMemberData[] = [];
                for (let i in obj["lstMember"]) {
                    const member = new GroupChatMemberData(obj["lstMember"][i]);
                    wkMemberList.push(member);
                }
                setMemberList(wkMemberList);
            }
            if (
                Object.keys(obj).indexOf("userInfo") > -1 &&
                Object.keys(obj).indexOf("leftAt") > -1
            ) {
                const member = new GroupChatMemberData(obj["userInfo"]);
                const strLeftAt = obj["leftAt"] as string;
                const leftAt = new Date(strLeftAt);

                if (member.name != null) {
                    const history = new GroupChatMessageData();
                    history.enter = true;
                    if (langCd === "ja") {
                        history.message = member.name + " さんが退室しました。";
                    } else {
                        history.message = member.name + " has left.";
                    }
                    const fomattedLeftAt = Utility.getFormattedTime(
                        leftAt.getTime() / 1000,
                        wkTimeDifference == null ? 0 : wkTimeDifference,
                        wkLangCd == null ? "en" : wkLangCd
                    );
                    history.displayTime = fomattedLeftAt;
                    setHistoryList((prevHistoryList: GroupChatMessageData[]) =>
                        prevHistoryList.concat([history])
                    );
                }
            }
        }
        /**
         * 切断コールバック
         */
        function onDisconnect() {
            // Utility.log("onDisconnect");
        }
        /**
         * チャットサーバへ接続
         * @returns
         */
        function connectToChatSerer() {
            if (props.loginUser == null || group == null || group.id == null) {
                return;
            }
            chatServer.current.connect(
                props.loginUser,
                group.id,
                onConnect,
                onReceive,
                onDisconnect
            );
        }
        /**
         * 入室通知を送信
         * @returns
         */
        function sendEnterInfo() {
            if (
                chatServer == null ||
                chatServer.current == null ||
                chatServer.current.socket == null ||
                !chatServer.current.socket.connected
            ) {
                return null;
            }
            const data = createChatMemberData();
            if (data != null) {
                chatServer.current.sendEnterInfo(data);
            }
        }
        /**
         * メッセージを送信
         * @returns
         */
        function sendMessage() {
            if (
                chatServer == null ||
                chatServer.current == null ||
                chatServer.current.socket == null ||
                !chatServer.current.socket.connected
            ) {
                return null;
            }
            const data = createChatMessageData();
            if (data != null) {
                if (isUnderProcess.current) {
                    return;
                }
                isUnderProcess.current = true;
                setBtnSendDisabled(true);
                setSendButtonLabel("msg_processing");
                chatServer.current.sendMessage(data);
            }
        }
        /**
         * 送信メッセージ生成
         * @returns
         */
        function createChatMessageData(): GroupChatMessageData | null {
            if (
                message.length === 0 ||
                props.loginUser == null ||
                props.loginUser.id == null ||
                props.loginUser.name == null ||
                props.loginUser.gender == null ||
                props.loginUser.attribute == null ||
                group == null ||
                group.id == null
            ) {
                return null;
            }
            const memberData = createChatMemberData();
            if (memberData == null) {
                return null;
            }
            let data = new GroupChatMessageData();
            data.groupId = group.id;
            data.message = message;
            data.senderId = props.loginUser.id;
            data.sender = memberData;
            return data;
        }
        /**
         * 入室ユーザ情報生成
         * @returns
         */
        function createChatMemberData(): GroupChatMemberData | null {
            if (
                props.loginUser == null ||
                props.loginUser.id == null ||
                props.loginUser.name == null ||
                props.loginUser.gender == null ||
                props.loginUser.attribute == null ||
                group == null ||
                group.id == null
            ) {
                return null;
            }
            let data = new GroupChatMemberData();
            data.id = props.loginUser.id;
            data.name = props.loginUser.name;
            data.gender = props.loginUser.gender;
            data.attribute = props.loginUser.attribute;
            data.groupId = group.id;
            return data;
        }
        /**
         * チャットサーバから切断
         * @returns
         */
        function disconnectFromChatServer() {
            if (chatServer == null || chatServer.current == null) {
                return null;
            }
            chatServer.current.disconnect();
        }
        /**
         * 送信ボタン押下時
         * @returns
         */
        async function onClickSend() {
            if (message.trim().length === 0) {
                return;
            }
            sendMessage();
        }
        /**
         * 次レコード取得のためのLimitUnixTimeを取得
         * @returns
         */
        function getNextLimitUnixTime(limitTime: number) {
            let nextUnixTime = limitTime;
            if (nextUnixTime === 0) {
                nextUnixTime = Math.floor(new Date().getTime() / 1000);
            }
            // 6ヶ月前(60秒x60分x24時間x30日x6)のunixtime取得
            nextUnixTime = nextUnixTime - 15552000;
            return nextUnixTime;
        }

        /**
         * グループ詳細取得
         */
        async function fetchGroup(): Promise<GroupModel | null | undefined> {
            if (group_id != null) {
                const result = await GroupRequest.getDetail(Number(group_id));
                if (
                    result == null ||
                    result.rtnCd == null ||
                    result.rtnCd < 0 ||
                    result.group == null
                ) {
                    return null;
                } else {
                    return result.group;
                }
            }
        }
        /**
         * グループメンバーリスト取得
         * @param groupId
         * @returns
         */
        async function fetchGroupMemberList(
            groupId: number
        ): Promise<UserModel[] | null | undefined> {
            const result = await GroupRequest.getGroupMemberListAll(groupId);
            if (
                result == null ||
                result.rtnCd == null ||
                result.rtnCd < 0 ||
                result.lstJoinGroup == null
            ) {
                return null;
            } else {
                const lstGroupMember: UserModel[] = [];
                const lstJoinGroup = result.lstJoinGroup;
                for (let i = 0; i < lstJoinGroup.length; i++) {
                    const joinGroup = lstJoinGroup[i];
                    if (joinGroup.underApplication === 1) {
                        continue;
                    } else if (joinGroup.underInvitation === 1) {
                        continue;
                    }
                    const member = joinGroup.user;
                    if (member != null) {
                        lstGroupMember.push(member);
                    }
                }
                return lstGroupMember;
            }
        }

        /**
         * チャット履歴取得
         */
        async function getGroupChatHistory(
            limit_unixtime: number,
            prev_limit_unixtime: number
        ) {
            if (props.loginUser == null || group == null || group.id == null) {
                return;
            }
            if (!hasNext.current) {
                return null;
            }
            if (isUnderProcess.current) {
                return null;
            }
            isUnderProcess.current = true;
            const result = await GroupRequest.getChatHistory(
                props.loginUser,
                group.id,
                limit_unixtime,
                prev_limit_unixtime
            );
            try {
                if (
                    result == null ||
                    result.rtnCd == null ||
                    result.rtnCd < 0
                ) {
                    throw new Error();
                }
                // hasNext
                if (result.hasNext != null) {
                    hasNext.current = result.hasNext;
                }
                // limitUnixTime
                if (result.limitUnixTime != null) {
                    limitUnixTime.current = result.limitUnixTime;
                }
                // prevLimitUnixTime
                if (result.prevLimitUnixTime != null) {
                    prevLimitUnixTime.current = result.prevLimitUnixTime;
                }
                // lstHistory
                if (result.lstHistory != null) {
                    let wkTimeDifference = timeDifference;
                    let wkLangCd = langCd;
                    if (wkTimeDifference == null) {
                        wkTimeDifference = getTimeDifference(selector);
                    }
                    if (wkLangCd == null) {
                        wkLangCd = getLanguageCode(selector);
                    }

                    const wkHistoryList = result.lstHistory.map((history) => {
                        const strCreatedAt = history.createdAt;
                        if (strCreatedAt != null) {
                            const createdAt = new Date(strCreatedAt);
                            const displayTime = Utility.getFormattedTime(
                                createdAt.getTime(),
                                wkTimeDifference == null ? 0 : wkTimeDifference,
                                wkLangCd == null ? "en" : wkLangCd
                            );
                            history.displayTime = displayTime;
                        }
                        return history;
                    });
                    if (lstHistory == null) {
                        setHistoryList(wkHistoryList);
                    } else {
                        setHistoryList((prevList: GroupChatMessageData[]) => {
                            if (prevList == null) {
                                return wkHistoryList;
                            } else {
                                return wkHistoryList.concat(prevList);
                            }
                        });
                    }
                    window.setTimeout(() => {
                        if (
                            refChatArea != null &&
                            refChatArea.current != null
                        ) {
                            refChatArea.current.scrollTo({
                                top: refChatArea.current.scrollHeight,
                            });
                        }
                    }, 300);
                }
            } catch (error) {
            } finally {
                isUnderProcess.current = false;
            }
        }

        /**
         * 送信ボタンのラベル設定
         * @param labelId
         */
        const setSendButtonLabel = (labelId: string) => {
            const btnLabel = intl.formatMessage({
                id: labelId,
            });
            setBtnSendLabel(btnLabel);
        };

        type MemberItemProps = {
            member: GroupChatMemberData;
        };
        const MemberItem: React.FC<MemberItemProps> = ({ member }) => {
            if (
                member.gender == null ||
                member.name == null ||
                member.attribute == null
            ) {
                return <></>;
            }
            return (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        cursor: "default",
                    }}
                >
                    {member.gender > 0 && member.attribute > 0 && (
                        <Box
                            sx={{
                                color: "#ffffff",
                                backgroundColor:
                                    member.gender === DbConstants.GENDER_MALE
                                        ? AppConstants.BG_COLOR_GENDER_MALE
                                        : AppConstants.BG_COLOR_GENDER_FEMALE,
                                padding: "0 10px",
                                fontSize: "0.8rem",
                                borderRadius: "9999px",
                            }}
                        >
                            {intl.formatMessage({
                                id: Utility.getAttributeLabelKey2(
                                    member.gender,
                                    member.attribute
                                ),
                            })}
                        </Box>
                    )}
                    <Box
                        sx={{
                            paddingLeft: "5px",
                            color:
                                member.gender === DbConstants.GENDER_MALE
                                    ? AppConstants.BG_COLOR_GENDER_MALE
                                    : AppConstants.BG_COLOR_GENDER_FEMALE,
                        }}
                    >
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`/users/${Utility.getUserKey2(
                                member
                            )}/profile`}
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            {member.name}
                        </a>
                    </Box>
                </Box>
            );
        };
        const ChatUserList = () => {
            return (
                <Box
                    className="ChatUserList"
                    sx={{
                        position: "fixed",
                        top: `calc(${AppConstants.HEADER_HEIGHT} + ${AppConstants.NAVBAR_HEIGHT})`,
                        bottom: {
                            xs: `calc(${AppConstants.FOOTER_HEIGHT} + 50px)`,
                            sm: "60px",
                        },
                        right: 0,
                        width: "35%",
                        minWidth: "150px",
                        maxWidth: "300px",
                        backgroundColor: "rgba(0,0,0,0.2)",
                        overflowY: "auto",
                        display: "flex",
                        padding: "10px",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                    }}
                >
                    {lstMember.map((member, index) => {
                        return <MemberItem key={index} member={member} />;
                    })}
                </Box>
            );
        };

        /***** レンダリング */
        return (
            <div className="pageWrapper GroupChat">
                {group != null &&
                    group.name != null &&
                    props.loginUser != null &&
                    props.loginUser.id != null && (
                        <>
                            {
                                // ナビゲーションバー
                            }
                            <NavBar
                                showBack={true}
                                title={group.name}
                                showPC={true}
                            />

                            {
                                // チャット表示エリア
                            }
                            <div className="chat-area" ref={refChatArea}>
                                {lstHistory != null &&
                                    lstHistory.map(
                                        (
                                            history: GroupChatMessageData,
                                            index: number
                                        ) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    {props.loginUser != null &&
                                                        langCd != null &&
                                                        timeDifference !=
                                                            null &&
                                                        group != null && (
                                                            <GroupChatListItem
                                                                loginUser={
                                                                    props.loginUser
                                                                }
                                                                group={group}
                                                                chatMessage={
                                                                    history
                                                                }
                                                                timeDifference={
                                                                    timeDifference
                                                                }
                                                                langCd={langCd}
                                                            />
                                                        )}
                                                </React.Fragment>
                                            );
                                        }
                                    )}
                                <ChatUserList />
                            </div>
                            {
                                // チャット入力エリア
                            }
                            <div className="chat-input-area">
                                <div className="input-area">
                                    <TextField
                                        className="text-field"
                                        multiline={true}
                                        maxRows={5}
                                        sx={{
                                            flexGrow: 1,
                                            flexShrink: 1,
                                            backgroundColor: "#ffffff",
                                            height: "100%",
                                            borderRadius: "4px",
                                        }}
                                        InputProps={{
                                            style: {
                                                padding: "2px 10px",
                                                minHeight: "40px",
                                            },
                                        }}
                                        type="text"
                                        value={message}
                                        onChange={(event) =>
                                            setMessage(event.target.value)
                                        }
                                    />
                                    <IconButton
                                        className="button-area"
                                        disabled={btnSendDisabled}
                                        onClick={onClickSend}
                                    >
                                        <SendButton className="send-button" />
                                    </IconButton>
                                </div>
                            </div>
                        </>
                    )}
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default GroupChat;
