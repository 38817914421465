import React from "react";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Utility from "utils/Utility";
import "styles/components/AdContentsRightComponent.scss";
import { AppConstants } from "constants/AppConstants";

export const AdContentsRightComponent: React.FC<any> = React.memo(
    (props) => {
        Utility.log("@@@@@ AdContentsRightComponent IN");
        /***** 定数、変数 */

        /**
         * レンダリング
         */
        return (
            <div className="ad-area right">
                <div className="dummy-area" />
                <div className="ad-contents right">
                    <div className="slider-wrapper">
                        <Splide
                            className="slider-container"
                            options={ {
                                type: 'loop',
                                autoplay: true,
                                interval: AppConstants.AD_INTERVAL_RIGHT,
                                width: '320px',
                                fixedWidth: '320px',
                                height: '100%',
                                arrows: false,
                                pagination: false,
                                padding: 0,
                            } }
                        >
                            <SplideSlide>
                                <div className="item-container">
                                    {
                                        // 全国90院以上展開の美容クリニック【美容整形するなら、TCB 東京中央美容外科】(19-0527)
                                    }
                                    <div className="ad-item">
                                        <a href="https://px.a8.net/svt/ejp?a8mat=3Z0IX1+740FOA+46J6+BZ8OX" rel="nofollow">
                                            <img width="300" height="250" alt="" src="https://www24.a8.net/svt/bgt?aid=240209605430&wid=002&eno=01&mid=s00000019509002012000&mc=1"/>
                                        </a>
                                        <img width="1" height="1" src="https://www16.a8.net/0.gif?a8mat=3Z0IX1+740FOA+46J6+BZ8OX" alt=""/>
                                        <a href="https://px.a8.net/svt/ejp?a8mat=3Z0IX1+740FOA+46J6+CB12Q" rel="nofollow">二重埋没法なら、TCB 東京中央美容外科</a>
                                        <img width="1" height="1" src="https://www11.a8.net/0.gif?a8mat=3Z0IX1+740FOA+46J6+CB12Q" alt=""/>
                                    </div>
                                    <div className="ad-item">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div style={{
                                                            border:"1px solid #95A5A6",
                                                            borderRadius:".75rem",
                                                            backgroundColor:"#FFFFFF",
                                                            width:"310px",
                                                            margin:"0px",
                                                            padding:"5px 0",
                                                            textAlign:"center",
                                                            overflow:"hidden"
                                                        }}>
                                                            <a href="https://hb.afl.rakuten.co.jp/ichiba/395e0d61.eff5d975.395e0d62.f9543618/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fsk-ii%2Fgift-festive2023_deluxe%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                target="_blank" 
                                                                rel="nofollow sponsored noopener" 
                                                                style={{wordWrap:"break-word"}}
                                                            >
                                                                <img src="https://hbb.afl.rakuten.co.jp/hgb/395e0d61.eff5d975.395e0d62.f9543618/?me_id=1330923&item_id=10003659&pc=https%3A%2F%2Fthumbnail.image.rakuten.co.jp%2F%400_mall%2Fsk-ii%2Fcabinet%2Fitem%2Fthumb%2Fgift-festive2023_del%2Fgift-festive2023d.jpg%3F_ex%3D300x300&s=300x300&t=picttext" 
                                                                    style={{margin:"2px"}} 
                                                                    alt="[商品価格に関しましては、リンクが作成された時点と現時点で情報が変更されている場合がございます。]" 
                                                                    title="[商品価格に関しましては、リンクが作成された時点と現時点で情報が変更されている場合がございます。]"/>
                                                            </a>
                                                            <p style={{
                                                                fontSize:"12px",
                                                                lineHeight:"1.4em",
                                                                textAlign:"left",
                                                                margin:"0px",
                                                                padding:"2px 6px",
                                                                wordWrap:"break-word"
                                                            }}>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/395e0d61.eff5d975.395e0d62.f9543618/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fsk-ii%2Fgift-festive2023_deluxe%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                    target="_blank" 
                                                                    rel="nofollow sponsored noopener" 
                                                                    style={{wordWrap:"break-word"}}>フェイシャルトリートメント エッセンス 230mLx MAISON KITSUNÉ ホリデーリミテッドエディションレッドデラックスコフレ
                                                                </a>
                                                                <br/>
                                                                <span >価格：52,250円～（税込、送料無料)</span> 
                                                                <span style={{color:"#BBB"}}>(2024/2/13時点)</span>
                                                            </p>
                                                            <div style={{margin:"15px"}}>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/395e0d61.eff5d975.395e0d62.f9543618/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fsk-ii%2Fgift-festive2023_deluxe%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                    target="_blank" 
                                                                    rel="nofollow sponsored noopener" 
                                                                    style={{wordWrap:"break-word"}}
                                                                >
                                                                    <img src="https://static.affiliate.rakuten.co.jp/makelink/rl.svg" 
                                                                        style={{
                                                                            float:"left",
                                                                            maxHeight:"27px",
                                                                            width:"auto",
                                                                            marginTop:"5px"
                                                                        }}
                                                                    />
                                                                </a>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/395e0d61.eff5d975.395e0d62.f9543618/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Fsk-ii%2Fgift-festive2023_deluxe%2F%3Fscid%3Daf_pc_bbtn&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                    target="_blank" 
                                                                    rel="nofollow sponsored noopener" 
                                                                    style={{wordWrap:"break-word"}}>
                                                                    <div style={{
                                                                        float:"right",
                                                                        width:"50%",
                                                                        height:"32px",
                                                                        backgroundColor:"#bf0000",
                                                                        color:"#fff",
                                                                        fontSize:"14px",
                                                                        fontWeight:"500",
                                                                        lineHeight:"32px",
                                                                        marginLeft:"1px",
                                                                        padding: "0 12px",
                                                                        borderRadius:"16px",
                                                                        cursor:"pointer",
                                                                        textAlign:"center"
                                                                    }}>
                                                                        楽天で購入 
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <p style={{
                                                            color:"#000000",
                                                            fontSize:"12px",
                                                            lineHeight:"1.4em",
                                                            margin:"5px",
                                                            wordWrap:"break-word"
                                                        }}></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className="item-container">
                                    {
                                        // 元祖ジェルネイルキットと言えばシャイニージェル【シャイニージェル公式ショッ プ本店】（13-0619）
                                    }
                                    <div className="ad-item">
                                        <a href="https://px.a8.net/svt/ejp?a8mat=3Z0IX1+6F089M+2SNC+64Z8X" rel="nofollow">
                                            <img width="300" height="250" alt="" src="https://www24.a8.net/svt/bgt?aid=240209605388&wid=002&eno=01&mid=s00000013044001031000&mc=1"/>
                                        </a>
                                        <img width="1" height="1" src="https://www10.a8.net/0.gif?a8mat=3Z0IX1+6F089M+2SNC+64Z8X" alt=""/>
                                        <a href="https://px.a8.net/svt/ejp?a8mat=3Z0IX1+6F089M+2SNC+5YJRM" rel="nofollow">シャイニージェル公式ショップ</a>
                                        <img width="1" height="1" src="https://www13.a8.net/0.gif?a8mat=3Z0IX1+6F089M+2SNC+5YJRM" alt=""/>
                                    </div>
                                    {
                                        // ロレアル パリ 公式店
                                    }
                                    <div className="ad-item">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div style={{
                                                            border:"1px solid #95A5A6",
                                                            borderRadius:".75rem",
                                                            backgroundColor:"#FFFFFF",
                                                            width:"310px",
                                                            margin:"0px",
                                                            padding:"5px 0",
                                                            textAlign:"center",
                                                            overflow:"hidden"
                                                        }}>
                                                            <a href="https://hb.afl.rakuten.co.jp/ichiba/395e4adf.3c7485f9.395e4ae0.5679a75b/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Florealparis%2F404691%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                target="_blank" 
                                                                rel="nofollow sponsored noopener" 
                                                                style={{wordWrap:"break-word"}}>
                                                                <img src="https://hbb.afl.rakuten.co.jp/hgb/395e4adf.3c7485f9.395e4ae0.5679a75b/?me_id=1380642&item_id=10000501&pc=https%3A%2F%2Fthumbnail.image.rakuten.co.jp%2F%400_mall%2Florealparis%2Fcabinet%2F91%2F404691_n.jpg%3F_ex%3D300x300&s=300x300&t=picttext" 
                                                                    style={{margin:"2px"}} 
                                                                    alt="[商品価格に関しましては、リンクが作成された時点と現時点で情報が変更されている場合がございます。]" 
                                                                    title="[商品価格に関しましては、リンクが作成された時点と現時点で情報が変更されている場合がございます。]"/>
                                                            </a>
                                                            <p style={{
                                                                fontSize:"12px",
                                                                lineHeight:"1.4em",
                                                                textAlign:"left",
                                                                margin:"0px",
                                                                padding:"2px 6px",
                                                                wordWrap:"break-word"
                                                            }}>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/395e4adf.3c7485f9.395e4ae0.5679a75b/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Florealparis%2F404691%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                    target="_blank" 
                                                                    rel="nofollow sponsored noopener" 
                                                                    style={{wordWrap:"break-word"}}
                                                                >
                                                                    ロレアル パリ エルセーヴ エクストラオーディナリー ヘアオイル リフィル 詰め替え 洗い流さない アウトバス トリートメント スタイリング ヘアケア 90ml
                                                                </a>
                                                                <br/>
                                                                <span >価格：1,749円（税込、送料無料)</span> 
                                                                <span style={{color:"#BBB"}}>(2024/2/13時点)</span>
                                                            </p>
                                                            <div style={{margin:"15px"}}>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/395e4adf.3c7485f9.395e4ae0.5679a75b/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Florealparis%2F404691%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                    target="_blank" 
                                                                    rel="nofollow sponsored noopener" 
                                                                    style={{wordWrap:"break-word"}}>
                                                                    <img src="https://static.affiliate.rakuten.co.jp/makelink/rl.svg" 
                                                                        style={{
                                                                            float:"left",
                                                                            maxHeight:"27px",
                                                                            width:"auto",
                                                                            marginTop:"5px"
                                                                        }}
                                                                    />
                                                                </a>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/395e4adf.3c7485f9.395e4ae0.5679a75b/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Florealparis%2F404691%2F%3Fscid%3Daf_pc_bbtn&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                    target="_blank" 
                                                                    rel="nofollow sponsored noopener" 
                                                                    style={{wordWrap:"break-word"}}>
                                                                    <div style={{
                                                                        float:"right",
                                                                        width:"50%",
                                                                        height:"32px",
                                                                        backgroundColor:"#bf0000",
                                                                        color:"#fff",
                                                                        fontSize:"14px",
                                                                        fontWeight:"500",
                                                                        lineHeight:"32px",
                                                                        marginLeft:"1px",
                                                                        padding: "0 12px",
                                                                        borderRadius:"16px",
                                                                        cursor:"pointer",
                                                                        textAlign:"center"
                                                                    }}>
                                                                        楽天で購入 
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <p style={{
                                                            color:"#000000",
                                                            fontSize:"12px",
                                                            lineHeight:"1.4em",
                                                            margin:"5px",
                                                            wordWrap:"break-word"
                                                        }}></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide>
                                <div className="item-container">
                                    {
                                        // 【プロネイリストが制作する！】ネイルチップ専門店ミチネイル(20-0318)
                                    }
                                    <div className="ad-item">
                                        <a href="https://px.a8.net/svt/ejp?a8mat=3Z0IX1+679LEI+34VM+25G2DD" rel="nofollow">
                                            <img width="300" height="250" alt="" src="https://www23.a8.net/svt/bgt?aid=240209605375&wid=002&eno=01&mid=s00000014629013008000&mc=1"/>
                                        </a>
                                        <img width="1" height="1" src="https://www16.a8.net/0.gif?a8mat=3Z0IX1+679LEI+34VM+25G2DD" alt=""/>
                                        <a href="https://px.a8.net/svt/ejp?a8mat=3Z0IX1+679LEI+34VM+25GPIQ" rel="nofollow">ネイルチップでおしゃれをしよう</a>
                                        <img width="1" height="1" src="https://www12.a8.net/0.gif?a8mat=3Z0IX1+679LEI+34VM+25GPIQ" alt=""/>
                                    </div>
                                    {
                                        // 【エスティ ローダー（Estee Lauder）公式】【正規品】
                                    }
                                    <div className="ad-item">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div style={{
                                                            border:"1px solid #95A5A6",
                                                            borderRadius:".75rem",
                                                            backgroundColor:"#FFFFFF",
                                                            width:"310px",
                                                            margin:"0px",
                                                            padding:"5px 0",
                                                            textAlign:"center",
                                                            overflow:"hidden"
                                                        }}>
                                                            <a href="https://hb.afl.rakuten.co.jp/ichiba/395e6d9c.4f08f6fa.395e6d9d.b19e3535/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Festeelauder%2F123127%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                target="_blank" 
                                                                rel="nofollow sponsored noopener" 
                                                                style={{wordWrap:"break-word"}}
                                                            >
                                                                <img src="https://hbb.afl.rakuten.co.jp/hgb/395e6d9c.4f08f6fa.395e6d9d.b19e3535/?me_id=1348110&item_id=10001017&pc=https%3A%2F%2Fthumbnail.image.rakuten.co.jp%2F%400_mall%2Festeelauder%2Fcabinet%2Fitem%2F123127_bgb.jpg%3F_ex%3D300x300&s=300x300&t=picttext" 
                                                                    style={{margin:"2px"}} 
                                                                    alt="[商品価格に関しましては、リンクが作成された時点と現時点で情報が変更されている場合がございます。]" 
                                                                    title="[商品価格に関しましては、リンクが作成された時点と現時点で情報が変更されている場合がございます。]"/>
                                                            </a>
                                                            <p style={{
                                                                fontSize:"12px",
                                                                lineHeight:"1.4em",
                                                                textAlign:"left",
                                                                margin:"0px",
                                                                padding:"2px 6px",
                                                                wordWrap:"break-word"
                                                            }}>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/395e6d9c.4f08f6fa.395e6d9d.b19e3535/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Festeelauder%2F123127%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                    target="_blank" 
                                                                    rel="nofollow sponsored noopener" 
                                                                    style={{wordWrap:"break-word"}}
                                                                >
                                                                    【送料無料】エスティ ローダー アドバンス ナイト リペア スペシャル セット B / 美容液 限定セット ギフト
                                                                </a>
                                                                <br/>
                                                                <span >価格：17,050円（税込、送料無料)</span> 
                                                                <span style={{color:"#BBB"}}>(2024/2/13時点)</span>
                                                            </p>
                                                            <div style={{margin:"15px"}}>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/395e6d9c.4f08f6fa.395e6d9d.b19e3535/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Festeelauder%2F123127%2F&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                    target="_blank" 
                                                                    rel="nofollow sponsored noopener" 
                                                                    style={{wordWrap:"break-word"}}>
                                                                    <img src="https://static.affiliate.rakuten.co.jp/makelink/rl.svg" 
                                                                        style={{
                                                                            float:"left",
                                                                            maxHeight:"27px",
                                                                            width:"auto",
                                                                            marginTop:"5px"
                                                                        }}
                                                                    />
                                                                </a>
                                                                <a href="https://hb.afl.rakuten.co.jp/ichiba/395e6d9c.4f08f6fa.395e6d9d.b19e3535/?pc=https%3A%2F%2Fitem.rakuten.co.jp%2Festeelauder%2F123127%2F%3Fscid%3Daf_pc_bbtn&link_type=picttext&ut=eyJwYWdlIjoiaXRlbSIsInR5cGUiOiJwaWN0dGV4dCIsInNpemUiOiIzMDB4MzAwIiwibmFtIjoxLCJuYW1wIjoiZG93biIsImNvbSI6MSwiY29tcCI6ImRvd24iLCJwcmljZSI6MSwiYm9yIjoxLCJjb2wiOjEsImJidG4iOjEsInByb2QiOjAsImFtcCI6ZmFsc2V9" 
                                                                    target="_blank" 
                                                                    rel="nofollow sponsored noopener" 
                                                                    style={{wordWrap:"break-word"}}
                                                                >
                                                                    <div style={{
                                                                        float:"right",
                                                                        width:"50%",
                                                                        height:"32px",
                                                                        backgroundColor:"#bf0000",
                                                                        color:"#fff",
                                                                        fontSize:"14px",
                                                                        fontWeight:"500",
                                                                        lineHeight:"32px",
                                                                        marginLeft:"1px",
                                                                        padding: "0 12px",
                                                                        borderRadius:"16px",
                                                                        cursor:"pointer",
                                                                        textAlign:"center"
                                                                    }}>
                                                                        楽天で購入 
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <p style={{
                                                            color:"#000000",
                                                            fontSize:"12px",
                                                            lineHeight:"1.4em",
                                                            margin:"5px",
                                                            wordWrap:"break-word"
                                                        }}></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </SplideSlide>
                        </Splide>
                    </div>
                </div>
            </div>
        );
    },
    () => {
        return true;
    }
);
