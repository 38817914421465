import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
    Backdrop,
    CircularProgress,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControlLabel,
    RadioGroup,
    Radio,
} from "@mui/material";
import Router from "./Router";
import {
    isProcessing,
    getProcessingMessage,
    isMaintenance,
    getLoginUser,
    getRtcInfo,
    getRtcSenderId,
    getRtcReceiverId,
    getRtcSenderTalkType,
    getRtcReceiverTalkType,
} from "redux/Selectors";
import {
    ProcessAction,
    UserAction,
    UnreadCountAction,
    TimeDifferenceAction,
    MaintenanceAction,
    EmitterAction,
    IncrementTalkUnreadCountAction,
    DecrementTalkUnreadCountAction,
    LanguageAction,
} from "redux/Actions";
import { EventEmitter } from "fbemitter";
import { SystemInfoRequest, UserRequest } from "api/requests/Requests";
import { UserModel, TalkMessageModel } from "models/Models";
import { AppConstants, UrlConstants } from "constants/Constants";
import { MODE } from "constants/AppConstants";
import Utility from "utils/Utility";
// import AuthenticateScreen from "AuthenticateScreen";
import { AppServer } from "socket/AppServer";
import { TALK_STATUS, TALK_TYPE } from "constants/Enum";
import { TalkCallingDialog } from "components/Components";
import "styles/App.scss";
import IndexedDb from "IndexedDb";

const App: React.FC = () => {
    // function App() {
    // Utility.log("@@@@@ App");
    // Utility.log("mode is " + process.env.NODE_ENV);
    /***** 定数、変数 */
    const intl = useIntl();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const wkProcessing = isProcessing(selector);
    const wkProcessingMessage = getProcessingMessage(selector);
    const maintenance = isMaintenance(selector);
    // ログインユーザ
    const tmpLoginUser = getLoginUser(selector);
    // const loginUser: UserModel | null = getLoginUser(selector);
    // if (getLoginUser(selector) != null) {
    //     const tmp = getLoginUser(selector).lstCustomTag;
    //     Utility.log("lstCustomTag:" + JSON.stringify(tmp));
    // }

    /***** useRef */
    // ログインユーザ参照
    const refLoginUser = React.useRef<UserModel>();
    // Appサーバー
    const appServer = React.useRef<AppServer>(AppServer.instance);
    // EventEmitter
    const emitter = React.useRef<EventEmitter>();
    // 初期処理完了フラグ
    const initializeCompleted = React.useRef<boolean>(false);

    /***** useState */
    // ログインユーザ
    const [loginUser, setLoginUser] = React.useState<UserModel | null>(null);
    const [processing, setProcessing] = React.useState<boolean>(
        isProcessing(selector)
    );
    const [processingMessage, setProcessingMessage] = React.useState<string>();
    // アクセストークン取得結果
    const [completedAccessTokenResult, setCompletedAccessTokenResult] =
        React.useState<boolean>(false);
    const [underMaintenance, setUnderMaintenance] =
        React.useState<boolean>(false);
    // const [released, setReleased] = React.useState<boolean>(false);
    // トークリクエストユーザ
    const [talkRequestUser, setTalkRequestUser] = React.useState<UserModel>();
    // トークタイプ(リクエスト側)
    const [talkTypeRequest, setTalkTypeRequest] =
        React.useState<TALK_TYPE>();
    // トークタイプ(返信側)
    const [talkTypeResponse, setTalkTypeResponse] =
        React.useState<TALK_TYPE>();
    // トークリクエスト確認ダイアログ表示フラグ
    const [showConfirmTalkRequest, setShowConfirmTalkRequest] =
        React.useState<boolean>(false);
    // ダイアログメッセージ
    // ダイアログ表示フラグ
    const [dialogMessage, setDialogMessage] =
        React.useState<string>();
    // ダイアログ表示フラグ
    const [openDialog, setOpenDialog] =
        React.useState<boolean>(false);

    const [open, setOpen] = React.useState<boolean>(false);

    /**
     * useEffect
     */
    React.useEffect(() => {
        // const langCode = Utility.getLanguageCode();
        // dispatch(
        //     LanguageAction({
        //         code: langCode,
        //     })
        // );
        if (AppConstants.mode === MODE.PRODUCTION) {
            const protocol = window.location.protocol;
            const port = window.location.port;
            const hostname = window.location.hostname;
            if (
                AppConstants.mode == MODE.PRODUCTION &&
                protocol === "http:" &&
                port !== "8082"
            ) {
                window.location.href = UrlConstants.URL_HOST;
                return;
            }
        }
        (async () => {
            if (
                initializeCompleted == null ||
                initializeCompleted.current == null ||
                !initializeCompleted.current
            ) {
                initialize();
            }
            const timezoneOffset = new Date().getTimezoneOffset() + 540;
            dispatch(
                await TimeDifferenceAction({ difference: timezoneOffset * 60 })
            );
            await getSystemInfo();
            await fetchAccessToken();
            setCompletedAccessTokenResult(true);
            setOpen(true);
        })();
        window.onfocus = () => {
            Utility.log("onfocus");
        };
        window.onblur = () => {
            Utility.log("onblur");
        };
    }, []);
    React.useEffect(() => {
        Utility.log("App useEffect refLoginUser IN")
        window.sessionStorage.clear();
        if (tmpLoginUser != null) {
            if (refLoginUser == null ||
                refLoginUser.current == null ||
                refLoginUser.current.id !== tmpLoginUser.id
            ) {
                setLoginUser(tmpLoginUser);
                refLoginUser.current = tmpLoginUser;
                connectToAppServer(tmpLoginUser);
                // if (AppConstants.mode === MODE.PRODUCTION) {
                //     connectToAppServer(tmpLoginUser);
                // }
            } else {
                setLoginUser(tmpLoginUser);
                refLoginUser.current = tmpLoginUser;
            }
        } else {
            setLoginUser(null);
            appServer.current.disconnect();
        }
        return () => {
            // alert("disconnect tmpLoginUser id:" + tmpLoginUser.id + " refLoginUser.id:" + refLoginUser.current?.id)

            // appServer.current.disconnect();
        };
    }, [tmpLoginUser]);
    React.useEffect(() => {
        (async () => {
            if (emitter == null || emitter.current == null) {
                emitter.current = new EventEmitter();

                dispatch(await EmitterAction({ instance: emitter.current }));
            }
        })();
    }, [emitter]);
    React.useEffect(() => {
        setProcessingMessage(wkProcessingMessage);
        setProcessing(wkProcessing);
    }, [wkProcessing, wkProcessingMessage]);
    React.useEffect(() => {
        if (maintenance != null) {
            setUnderMaintenance(maintenance);
        }
    }, [maintenance]);

    function initialize() {
        IndexedDb.initialize();
        if (AppConstants.mode !== MODE.PRODUCTION) {
        }
    }

    /**
     * 処理中バックドロップを閉じる
     */
    const handleClose = async () => {
        dispatch(ProcessAction({ processing: false, message: "" }));
    };

    /**
     * システム情報取得
     */
    async function getSystemInfo() {
        const result = await SystemInfoRequest.getSystemInfo();
        if (result == null) {
            dispatch(await MaintenanceAction({ isMaintenance: true }));
            return;
        }
        if (result.systemInfo != null) {
            if (result.systemInfo.maintenance === 1) {
                dispatch(await MaintenanceAction({ isMaintenance: true }));
            } else {
                dispatch(await MaintenanceAction({ isMaintenance: false }));
            }
        }
    }

    /**
     * Appサーバーへ接続
     */
    function connectToAppServer(_loginUser: UserModel) {
        if (_loginUser != null && _loginUser.id != null) {
            appServer.current.connect(
                _loginUser,
                appServerConnectCallback,
                appServerEventCallback,
                appServerDisconnectCallback
            );
        }
    }
    /**
     * Appサーバー接続コールバック
     */
    function appServerConnectCallback() {
        // Utility.log("===== App connect callback");
        appServer.current.sendStartupInfoToAppServer();
    }
    /**
     * Appサーバーイベントコールバック
     * @param obj
     */
    function appServerEventCallback(obj: any) {
        Utility.log("appServerEventCallback")
        if (Object.keys(obj).indexOf("type") === -1) {
            return;
        }
        switch (obj["type"]) {
            case "TALK_MESSAGE":
                const talkMessage1 = new TalkMessageModel(obj);
                onReceiveTalkMessage(talkMessage1);
                break;
            case "DELETE_TALK_MESSAGE":
                const talkMessage2 = new TalkMessageModel(obj);
                onReceiveDeleteTalkMessage(talkMessage2);
                break;
            case "READ_MESSAGE":
                const talkMessage3 = new TalkMessageModel(obj);
                onReceiveReadMessage(talkMessage3);
                break;
            case "MAINTENANCE_NOTIFICATION":
                onReceiveMaintenanceNotification();
                break;
            case "MAINTENANCE_STARTED_NOTIFICATION":
                onReceiveMaintenanceStartedNotification();
                break;
            case "MAINTENANCE_FINISHED_NOTIFICATION":
                onReceiveMaintenanceFinishedNotification();
                break;
            case "RESPONSE_FOR_CONFIRM_CONNECT_STATUS":
                Utility.log("App RESPONSE_FOR_CONFIRM_CONNECT_STATUS");
                onReceivedResponseForConnectStatusConfirmation(obj);
                break;
            case "RECEIVED_CALL_REQUEST":
                onReceivedTalkRequest(obj);
                break;
            case "RECEIVED_CANCEL_CALL_REQUEST":
                onReceiveCancelRequestCall(obj);
                break;
            // case "RECEIVED_REPLY_TALK_STATUS":
            //     onReceivedTalkStatus(obj);
            //     break;
            case "RECEIVED_CALL_RESPONSE":
                onReceivedCallResponse(obj);
                break;
            case "TALK_STARTED":
                onReceivedTalkStartedNotification(obj);
                break;
            case "CHANGED_TALK_PERMISSION":
                onReceivedTalkPermissionChange(obj);
                break;
            case "TALK_FAILURE_NOTIFICATION":
                onReceivedTalkFailureNotification(obj);
                break;
            }
    }
    /**
     * トークメッセージ受信時
     * @param talkMessage
     */
    async function onReceiveTalkMessage(talkMessage: TalkMessageModel) {
        if (emitter == null || emitter.current == null) {
            return;
        }

        if (
            talkMessage.senderId == null ||
            refLoginUser == null ||
            refLoginUser.current == null ||
            refLoginUser.current.id == null
        ) {
            return;
        }

        // イベント発行
        emitter.current.emit("RECEIVED_NEW_MESSAGE", talkMessage);

        // 自分が発信したトークメッセージの場合
        if (talkMessage.senderId === refLoginUser.current.id) {
            return;
        }

        // バッジカウントのインクリメント
        dispatch(await IncrementTalkUnreadCountAction());
    }
    /**
     * トークメッセージ削除通知受信時
     * @param talkMessage
     */
    async function onReceiveDeleteTalkMessage(talkMessage: TalkMessageModel) {
        if (emitter == null || emitter.current == null) {
            return;
        }

        if (
            talkMessage.senderId == null ||
            refLoginUser == null ||
            refLoginUser.current == null ||
            refLoginUser.current.id == null
        ) {
            return;
        }

        // イベント発行
        emitter.current.emit("DELETE_TALK_MESSAGE", talkMessage);

        // 自分が発信したトークメッセージの場合
        if (talkMessage.senderId === refLoginUser.current.id) {
            return;
        }

        // バッジカウントのデクリメント
        if (talkMessage.read !== 1) {
            dispatch(await DecrementTalkUnreadCountAction());
        }
    }
    /**
     * トークメッセージ既読通知受信時
     * @param talkMessage
     */
    function onReceiveReadMessage(talkMessage: TalkMessageModel) {
        if (emitter != null && emitter.current != null) {
            emitter.current.emit("READ_TALK_MESSAGE", talkMessage);
        }
    }
    /**
     * 接続状況の確認の結果受信時
     * @param obj
     */
    function onReceivedResponseForConnectStatusConfirmation(obj: any) {
        Utility.log("onReceivedResponseForConnectStatusConfirmation IN")
        if (refLoginUser == null || refLoginUser.current == null) {
            return;
        }
        if (Object.keys(obj).indexOf("sender_id") === -1) {
            return;
        }
        const senderId = obj.sender_id;
        if (Object.keys(obj).indexOf("receiver_id") === -1) {
            return;
        }
        const receiverId = obj.receiver_id;

        if (refLoginUser.current.id !== senderId) {
            return;
        }
        if (emitter != null && emitter.current != null) {
            emitter.current.emit("RESPONSE_FOR_CONFIRM_CONNECT_STATUS", obj);
        }
    }
    /**
     * トーク要求の受信時
     * @param obj
     */
    async function onReceivedTalkRequest(obj: any) {
        Utility.log("***** App onReceivedTalkRequest IN");
        if (refLoginUser == null || refLoginUser.current == null || refLoginUser.current.id == null) {
            return;
        }
        Utility.log("***** App onReceivedTalkRequest 1");
        if (Object.keys(obj).indexOf("sender_id") === -1) {
            return;
        }
        Utility.log("***** App onReceivedTalkRequest 2");
        const senderId = obj.sender_id;
        if (Object.keys(obj).indexOf("receiver_id") === -1) {
            return;
        }
        Utility.log("***** App onReceivedTalkRequest 3");
        const receiverId = obj.receiver_id;
        if (refLoginUser.current.id !== receiverId) {
            return;
        }
        Utility.log("***** App onReceivedTalkRequest 4");
        if (Object.keys(obj).indexOf("talk_type") === -1) {
            return;
        }
        Utility.log("***** App onReceivedTalkRequest 5");

        let isTalkScreen = false;
        let partnerId = 0;
        const pathname = window.location.pathname;
        if (pathname.includes("/conversation/talk/")) {
            isTalkScreen = true;
            const strPartnerId = pathname.replace("/conversation/talk/", "");
            if (strPartnerId != null && strPartnerId.length > 0) {
                partnerId = parseInt(strPartnerId);
            }
        }
        // 通話状況取得
        const strRtcSenderId = window.sessionStorage.getItem("rtcSenderId");
        const strRtcSenderName = window.sessionStorage.getItem("rtcSenderName");
        const strRtcReceiverId = window.sessionStorage.getItem("rtcReceiverId");
        const strRtcReceiverName = window.sessionStorage.getItem("rtcReceiverName");
        const strRtcSenderTalkType = window.sessionStorage.getItem("rtcSenderTalkType");
        const strRtcReceiverTalkType = window.sessionStorage.getItem("rtcReceiverTalkType");
        const strTalkStatus = window.sessionStorage.getItem("rtcTalkStatus");
        // 通話中の場合
        if (strRtcSenderId != null && 
            strRtcReceiverId != null && 
            strRtcSenderTalkType != null && 
            strRtcReceiverTalkType != null &&
            partnerId > 0 &&
            strTalkStatus != null &&
            (parseInt(strTalkStatus) === TALK_STATUS.UNDER_TALK ||
            parseInt(strTalkStatus) === TALK_STATUS.PAUSE_TALK)
        ) {
            const rtcSenderId = parseInt(strRtcSenderId);
            const rtcReceiverId = parseInt(strRtcReceiverId);
            const rtcSenderTalkType = parseInt(strRtcSenderTalkType);
            const rtcReceiverTalkType = parseInt(strRtcReceiverTalkType);
            Utility.log("rtcSenderId:" + rtcSenderId);
            Utility.log("rtcReceiverId:" + rtcReceiverId);
            Utility.log("rtcSenderTalkType:" + rtcSenderTalkType);
            Utility.log("rtcReceiverTalkType:" + rtcReceiverTalkType);

            appServer.current.sendTalkCallResponse(
                senderId,
                refLoginUser.current.id,
                false,
                TALK_TYPE.NONE,
                true
            );
    
            return;
        }
        Utility.log("***** App onReceivedTalkRequest 6");

        setShowConfirmTalkRequest(false);
        const talkType = obj.talk_type;

        const result = await UserRequest.getUser(senderId);
        if (
            result == null ||
            result.rtnCd == null ||
            result.rtnCd < 0 ||
            result.user == null
        ) {
            return;
        }
        Utility.log("***** App onReceivedTalkRequest 7");
        setTalkRequestUser(result.user);
        setTalkTypeRequest(talkType);
        setShowConfirmTalkRequest(true);
    }
    /**
     * 受信したトーク要求のキャンセル通知の受信時
     * @param obj
     */
    async function onReceiveCancelRequestCall(obj: any) {
        Utility.log("App onReceiveCancelRequestCall IN");
        setShowConfirmTalkRequest(false);
    }
    /**
     * トーク要求に対するトーク状況の受信時
     * @param obj
     */
    // async function onReceivedTalkStatus(obj: any) {
    //     Utility.log("App onReceivedTalkStatus IN");
    //     if (emitter != null && emitter.current != null) {
    //         Utility.log("App onReceivedTalkStatus before emitter");
    //         emitter.current.emit("RECEIVED_TALK_STATUS", obj);
    //     }
    // }
    /**
     * 発信したトーク要求に対する返信の受信時
     * @param obj
     * @returns
     */
    async function onReceivedCallResponse(obj: any) {
        Utility.log("App onReceivedCallResponse IN");
        if (refLoginUser == null || refLoginUser.current == null) {
            return;
        }
        if (Object.keys(obj).indexOf("sender_id") === -1) {
            return;
        }
        const senderId = obj.sender_id;
        if (Object.keys(obj).indexOf("receiver_id") === -1) {
            return;
        }
        const receiverId = obj.receiver_id;
        if (refLoginUser.current.id !== senderId) {
            return;
        }
        if (Object.keys(obj).indexOf("talk_type") === -1) {
            return;
        }
        const talkType = obj.talk_type;
        if (emitter != null && emitter.current != null) {
            emitter.current.emit("ON_RECEIVE_RESPONSE_OF_TALK_REQUEST", obj);
        }
    }
    /**
     * トーク要求発信者がトーク開始した通知の受信時
     * @param obj
     * @returns
     */
    async function onReceivedTalkStartedNotification(obj: any) {
        Utility.log("App onReceivedTalkStartedNotification IN");
        if (refLoginUser == null || refLoginUser.current == null) {
            return;
        }
        Utility.log("App onReceivedTalkStartedNotification 1");
        if (Object.keys(obj).indexOf("sender_id") === -1) {
            return;
        }
        Utility.log("App onReceivedTalkStartedNotification 2");
        const senderId = obj.sender_id;
        if (Object.keys(obj).indexOf("receiver_id") === -1) {
            return;
        }
        Utility.log("App onReceivedTalkStartedNotification 3");
        const receiverId = obj.receiver_id;
        if (refLoginUser.current.id !== receiverId) {
            return;
        }
        Utility.log("App onReceivedTalkStartedNotification 4");
        if (Object.keys(obj).indexOf("sender_talk_type") === -1) {
            return;
        }
        Utility.log("App onReceivedTalkStartedNotification 5");
        const senderTalkType = obj.sender_talk_type;
        if (Object.keys(obj).indexOf("receiver_talk_type") === -1) {
            return;
        }
        Utility.log("App onReceivedTalkStartedNotification 6");

        const pathname = `/conversation/talk/${senderId}`;
        if (window.location.pathname === pathname) {
            // イベント発行
            Utility.log("App onReceivedTalkStartedNotification 7");
            if (emitter != null && emitter.current != null) {
                emitter.current.emit("START_TALK", obj);
            }
        } else {
            Utility.log("App onReceivedTalkStartedNotification 8");
            const receiverTalkType = obj.receiver_talk_type;
            // window.sessionStorage.setItem("sender_talk_type", String(senderId));
            // window.sessionStorage.setItem("receiver_talk_type", String(receiverTalkType));
            // window.sessionStorage.setItem("talk_started", "1");
            
            // セッションストレージにトーク開始情報をセット
            window.sessionStorage.setItem("SENDER_ID", String(senderId));
            window.sessionStorage.setItem("RECEIVER_ID", String(receiverId));
            window.sessionStorage.setItem("SENDER_TALK_TYPE", String(senderTalkType));
            window.sessionStorage.setItem("RECEIVER_TALK_TYPE", String(receiverTalkType));
            window.sessionStorage.setItem("SENDER_TALK_STARTED", "1");
            // イベント発行
            if (emitter != null && emitter.current != null) {
                emitter.current.emit("GO_TO_TALK_SCREEN", senderId);
            }
        }
    }
    /**
     * トーク許可変更通知の受信時
     * @param obj
     * @returns
     */
    async function onReceivedTalkPermissionChange(obj: any) {
        Utility.log("App onReceivedTalkPermissionChange IN");
        if (refLoginUser == null || refLoginUser.current == null) {
            return;
        }
        if (Object.keys(obj).indexOf("sender_id") === -1) {
            return;
        }
        const senderId = obj.sender_id;
        if (Object.keys(obj).indexOf("receiver_id") === -1) {
            return;
        }
        const receiverId = obj.receiver_id;
        if (refLoginUser.current.id !== receiverId) {
            return;
        }
        if (Object.keys(obj).indexOf("permitted") === -1) {
            return;
        }
        const permitted = obj.permitted;

        // イベント発行
        if (emitter != null && emitter.current != null) {
            emitter.current.emit("CHANGED_TALK_PERMISSION", obj);
        }
    }
    // /**
    //  * トーク開始失敗の通知の受信時
    //  * @param obj
    //  * @returns
    //  */
    async function onReceivedTalkFailureNotification(obj: any) {
        Utility.log("App onReceivedTalkFailureNotification IN");
        setShowConfirmTalkRequest(false);
        if (refLoginUser == null || refLoginUser.current == null) {
            return;
        }
        if (Object.keys(obj).indexOf("sender_id") === -1) {
            return;
        }
        const senderId = obj.sender_id;
        if (Object.keys(obj).indexOf("receiver_id") === -1) {
            return;
        }
        const receiverId = obj.receiver_id;
        if (receiverId !== refLoginUser.current.id) {
            return;
        }

        // イベント発行
        if (emitter != null && emitter.current != null) {
            emitter.current.emit("TALK_FAILURE_NOTIFICATION", obj);
        }
    }
    /**
     * メンテナンス予告通知受信時
     */
    function onReceiveMaintenanceNotification() {
        const message = intl.formatMessage({id: "msg_maintenance_notification"})
        setDialogMessage(message);
        setOpenDialog(true);
    }
    /**
     * メンテナンス通知受信時
     */
    async function onReceiveMaintenanceStartedNotification() {
        dispatch(await MaintenanceAction({ isMaintenance: true }));
        setUnderMaintenance(true);
    }
    /**
     * メンテナンス終了通知受信時
     */
    async function onReceiveMaintenanceFinishedNotification() {
        dispatch(await MaintenanceAction({ isMaintenance: false }));
        setUnderMaintenance(false);
    }
    /**
     * Appサーバー切断コールバック
     */
    function appServerDisconnectCallback() {}
    /**
     * アクセストークン取得
     */
    async function fetchAccessToken() {
        const resAccessToken = await UserRequest.getAccessToken();
        if (resAccessToken?.rtnCd === 0) {
            let user = resAccessToken?.user;
            if (user != null) {
                const accessToken = resAccessToken?.accessToken;
                if (accessToken != null) {
                    user.bearerToken = accessToken;
                    const resMyData = await UserRequest.getMyData(user);
                    if (
                        resMyData == null ||
                        resMyData.rtnCd == null ||
                        resMyData.rtnCd < 0
                    ) {
                        user = null;
                    } else {
                        const langCd = Utility.getLanguageCode();
                        if (user.languageCd != null && user.languageCd !== langCd) {
                            dispatch(
                                LanguageAction({
                                    code: user.languageCd,
                                })
                            );                    
                        }
                        dispatch(
                            UserAction({
                                loginUser: user,
                                lstOwnGroup: resMyData.lstOwnGroup,
                                lstBlockUser: resMyData.lstBlockUser,
                                lstFollowUser: resMyData.lstFollow,
                                lstTag: resMyData.lstTag,
                                lstCustomTag: resMyData.lstCustomTag,
                                lstInvite: resMyData.lstInvite,
                            })
                        );
                        // メール未読件数取得
                        const p1 = Utility.getUnreadMailCount(user);
                        // トーク未読件数取得
                        const p2 = Utility.getUnreadTalkCount(user);
                        const [unreadMailCount, unreadTalkCount] =
                            await Promise.all([p1, p2]);
                        dispatch(
                            UnreadCountAction({
                                mail:
                                    unreadMailCount != null
                                        ? unreadMailCount
                                        : 0,
                                talk:
                                    unreadTalkCount != null
                                        ? unreadTalkCount
                                        : 0,
                            })
                        );
                    }
                }
            }
        }
    }

    /**
     * 受信したトーク要求を拒否する
     * @returns
     */
    async function onRejectTalkRequest() {
        setShowConfirmTalkRequest(false);
        if (
            loginUser == null ||
            loginUser.id == null ||
            talkRequestUser == null ||
            talkRequestUser.id == null
        ) {
            return;
        }
        appServer.current.sendTalkCallResponse(
            talkRequestUser.id,
            loginUser.id,
            false,
            TALK_TYPE.NONE,
            false
        );
    }
    /**
     * 受信したトーク要求を承諾
     * @returns
     */
    async function onAcceptTalkRequest(talkType: TALK_TYPE) {
        setShowConfirmTalkRequest(false);
        if (
            loginUser == null ||
            loginUser.id == null ||
            talkRequestUser == null ||
            talkRequestUser.id == null
        ) {
            return;
        }
        if (emitter == null || emitter.current == null) {
            return;
        }
        if (talkType == null) {
            talkType = TALK_TYPE.VOICE;
        }
        appServer.current.sendTalkCallResponse(
            talkRequestUser.id,
            loginUser.id,
            true,
            talkType,
            false
        );
    }    

    return (
        <div id="App" className="App">
            {open && maintenance != null && completedAccessTokenResult && (
                <>
                    <>
                        <Backdrop
                            className="backdrop"
                            sx={{
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={processing}
                            onClick={handleClose}
                        >
                            <div className="backdrop-content-wrapper">
                                <CircularProgress color="inherit" />
                                {processingMessage != null &&
                                    processingMessage.length > 0 && (
                                        <div className="backdrop-content">
                                            <FormattedMessage
                                                id={processingMessage}
                                            />
                                        </div>
                                    )}
                            </div>
                        </Backdrop>
                        <div
                            className={
                                completedAccessTokenResult
                                    ? "router-wrapper"
                                    : "d-none"
                            }
                        >
                            <Router
                                loginUser={loginUser}
                                maintenance={underMaintenance}
                            />
                        </div>
                        <Dialog
                            className="dialog"
                            open={openDialog}
                            onClose={() => {
                                setOpenDialog(false);
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle
                                id="alert-dialog-title"
                                className="dialog-title"
                            >
                                <FormattedMessage id={"dlg_title_message"} />
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {dialogMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    className="dialog-button"
                                    color="primary"
                                    onClick={() => {
                                        setOpenDialog(false);
                                    }}
                                >
                                    <FormattedMessage id={"btn_ok"} />
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {loginUser != null && 
                         showConfirmTalkRequest && 
                         talkRequestUser != null && 
                         talkTypeRequest != null && (
                            <TalkCallingDialog
                                loginUser={loginUser}
                                show={showConfirmTalkRequest}
                                talkRequestUser={talkRequestUser}
                                talkTypeRequest={talkTypeRequest}
                                onRejectTalkRequest={onRejectTalkRequest}
                                onAcceptTalkRequest={(talkType: TALK_TYPE) => {
                                    onAcceptTalkRequest(talkType)
                                }
                                }
                            />
                        )}
                    </>
                </>
            )}
        </div>
    );
};

export default App;
