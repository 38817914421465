import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, FormControl, OutlinedInput, Button, Alert } from "@mui/material";
import { NavBar } from "components/Components";
import { UserRequest } from "api/requests/Requests";
import { useDispatch } from "react-redux";
import { ProcessAction } from "redux/Actions";
import "styles/pages/login/RequestResetPassword.scss";
import { AppConstants } from "constants/AppConstants";
import { pushDataLayer } from "gtm/gtm"

const RequestResetPassword: React.FC = () => {
    // Utility.log("@@@@@ Login");
    /***** 定数、変数 */
    const intl = useIntl();
    const dispatch = useDispatch();

    /***** useRef */
    const isUnderProcessing = React.useRef<boolean>(false);

    /***** useState */
    const [email, setEmail] = React.useState<string>("");
    const [errMessage, setErrMessage] = React.useState<string>("");
    const [completeMessage, setCompleteMessage] = React.useState<string>("");
    const [btnSendDisabled, setBtnSendDisabled] =
        React.useState<boolean>(false);

    /***** useEffect */
    React.useEffect(() => {
        pushDataLayer({
            event: 'page_view',
            screen: "リセットパスワード",
            path: window.location.pathname,
        });        
    }, []);

    /**
     * 送信ボタンクリック時
     * @returns
     */
    const onClickSend = async () => {
        if (validate()) {
            send();
        }
    };

    function validate() {
        if (email.length === 0) {
            const errMessage = intl.formatMessage({
                id: "err_empty_email",
            });
            setErrMessage(errMessage);
            return false;
        }
        return true;
    }

    /**
     * 送信
     */
    async function send() {
        if (isUnderProcessing.current) {
            return;
        }
        isUnderProcessing.current = true;

        setBtnSendDisabled(true);
        dispatch(
            ProcessAction({ processing: true, message: "msg_processing" })
        );
        let res = await UserRequest.requestResetPassword(email);
        try {
            if (res == null || res.rtnCd == null) {
                throw new Error();
            }
            if (res.rtnCd < 0) {
                if (res.rtnCd === AppConstants.ERROR_EMAIL_NOT_FOUND) {
                    const message = intl.formatMessage({
                        id: "err_email_not_found",
                    });
                    if (message != null) {
                        throw new Error(message);
                    } else {
                        throw new Error();
                    }
                } else if (
                    res.rtnCd ==
                    AppConstants.ERROR_PASSWORD_RESET_REQUEST_GOOGLE
                ) {
                    const message = intl.formatMessage({
                        id: "err_request_reset_password_google",
                    });
                    if (message != null) {
                        throw new Error(message);
                    } else {
                        throw new Error();
                    }
                } else {
                    throw new Error();
                }
            }
            setErrMessage("");
            const message = intl.formatMessage({
                id: "msg_complete_request_reset_password",
            });
            if (message != null) {
                setCompleteMessage(message);
            }
            setEmail("");
        } catch (e) {
            setCompleteMessage("");
            if (
                e instanceof Error &&
                e.message != null &&
                e.message.length > 0
            ) {
                setErrMessage(e.message);
            } else {
                const errMessage = intl.formatMessage({
                    id: "err_common",
                });
                setErrMessage(errMessage);
            }
        } finally {
            setBtnSendDisabled(false);
            dispatch(ProcessAction({ processing: false, message: "" }));
            isUnderProcessing.current = false;
        }
    }
    return (
        <div className="pageWrapper RequestResetPassword">
            {/* ページタイトル */}
            <NavBar
                showBack={true}
                title={intl.formatMessage({ id: "title_password_reset" })}
            />

            {/* エラーメッセージエリア */}
            {errMessage.length > 0 && (
                <Alert
                    className="error-message"
                    severity="error"
                    onClose={() => {
                        setErrMessage("");
                    }}
                >
                    {errMessage}
                </Alert>
            )}
            {/* 完了メッセージエリア */}
            {completeMessage.length > 0 && (
                <Alert
                    className="complete-message"
                    severity="success"
                    onClose={() => {
                        setCompleteMessage("");
                    }}
                >
                    {completeMessage}
                </Alert>
            )}
            <Box
                component="h1"
                className="page-title"
                display={{
                    xs: "none",
                    sm: "block",
                }}
            >
                <FormattedMessage id={"title_password_reset"} />
            </Box>
            {
                // 入力エリア
            }
            <form>
                {
                    // Email
                }
                <FormControl className="email-area" variant="standard">
                    <label className="email-label" htmlFor="txtEmail">
                        <FormattedMessage id={"email_address"} />
                    </label>
                    <OutlinedInput
                        className="email-value"
                        fullWidth
                        id="txtEmail"
                        label=""
                        type="email"
                        inputProps={{
                            maxLength: 50,
                        }}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                window.setTimeout(() => {
                                    onClickSend();
                                });
                            }
                        }}
                    />
                </FormControl>
                <div className="button-area">
                    <Button
                        className={
                            btnSendDisabled ? "app-button button disabled" : "app-button button"
                        }
                        variant="text"
                        disabled={btnSendDisabled}
                        onClick={onClickSend}
                    >
                        <FormattedMessage id="btn_send" />
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default RequestResetPassword;
