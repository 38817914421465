import React from "react";
import {
    Box,
    Zoom,
    Fab,
    useScrollTrigger,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { ThreadRequest } from "api/requests/Requests";
import { UserModel, ThreadModel, CategoryModel } from "models/Models";
import { NavBar } from "components/Components";
import { KeyboardArrowUp } from "@mui/icons-material";
import { useNavigate, useParams, Link } from "react-router-dom";
import WriteIcon from "assets/images/write.svg";
import { pushDataLayer } from "gtm/gtm"
import "styles/components/ThreadListComponent.scss";

type Props = {
    loginUser: UserModel | null;
    category: CategoryModel;
    langCd: string;
};

export const ThreadListComponent: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ ThreadListComponent IN");
        /***** 定数、変数 */
        const intl = useIntl();
        const navigate = useNavigate();
        const { categoryId } = useParams<{ categoryId: string }>();

        /***** useState */
        // ナブバータイトル
        const [navBarTitle, setNavBarTitle] = React.useState<string>("");
        // スレッドリスト
        const [lstThread, setThreadList] = React.useState<ThreadModel[]>([]);
        const [loadCompleted, setLoadCompleted] =
            React.useState<boolean>(false);
        // ダイアログ表示フラグ
        const [openDialog, setOpenDialog] = React.useState<boolean>(false);

        const trigger = useScrollTrigger({
            target: window,
            disableHysteresis: true,
            threshold: 300,
        });

        const scrollToTop = React.useCallback(() => {
            window.scrollTo({ top: 0 });
        }, []);

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "スレッド一覧(掲示板)",
                path: window.location.pathname,
            });
        }, []);
        React.useEffect(() => {
            if (categoryId != null) {
                try {
                    const iCategoryId = parseInt(categoryId);
                    getThreadList(iCategoryId);
                } catch (error) {
                    console.error(error);
                }
            } else {
                setLoadCompleted(true);
            }
        }, [categoryId]);
        React.useEffect(() => {
            let navBarTitle: string | null = null;
            switch (props.langCd) {
                case "ja":
                    navBarTitle = props.category.name;
                    break;
                case "cn":
                    navBarTitle = props.category.nameCn;
                    break;
                case "tw":
                    navBarTitle = props.category.nameTw;
                    break;
                case "es":
                    navBarTitle = props.category.nameEs;
                    break;
                case "de":
                    navBarTitle = props.category.nameDe;
                    break;
                case "it":
                    navBarTitle = props.category.nameIt;
                    break;
                case "ru":
                    navBarTitle = props.category.nameRu;
                    break;
                default:                
                    navBarTitle = props.category.nameEn;
            }
            if (navBarTitle == null) {
                navBarTitle = intl.formatMessage({
                    id: "title_message_board",
                });
            }
            setNavBarTitle(navBarTitle);
        }, [props.langCd]);

        /**
         * ダイアログ表示
         */
        function handleDialogOpen() {
            setOpenDialog(true);
        }

        /**
         * ダイアログ閉じる
         */
        function handleDialogClose() {
            setOpenDialog(false);
        }

        /**
         * スレッド作成
         */
        function write() {
            if (props.loginUser == null) {
                handleDialogOpen();
                return;
            }
            if (categoryId == null) {
                return;
            }
            navigate(`/board/${categoryId}/thread/new`);
        }
        /**
         * スレッドリスト取得
         * @param index
         */
        async function getThreadList(categoryId: number) {
            let res = await ThreadRequest.getList(categoryId);
            if (res == null) {
                return;
            }
            // スレッドリスト
            if (res.lstThread != null) {
                setThreadList(res.lstThread);
            }
            setLoadCompleted(true);
        }

        return (
            <div className="component ThreadListComponent">
                {
                    // ページタイトル
                }
                <NavBar showBack={true} title={navBarTitle} />

                {loadCompleted && lstThread.length === 0 && (
                    <div className="no-thread">
                        <FormattedMessage id={"no_thread"} />
                    </div>
                )}
                {loadCompleted && lstThread.length > 0 && (
                    <div className="contents-wrapper">
                        {lstThread.map((thread: ThreadModel, index) => {
                            return (
                                <React.Fragment key={`3_${index + 1}`}>
                                    {thread.title != null && (
                                        <Link
                                            className="thread-title"
                                            to={`/board/${categoryId}/thread/${thread.id}/list`}
                                        >
                                            {`${index + 1}.${thread.title}(${
                                                thread.numberOfResponse
                                            })`}
                                        </Link>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                )}
                <div className="icon-write" onClick={write}>
                    <OverlayTrigger
                        overlay={
                            <Popover id="tooltip-disabled" body>
                                <FormattedMessage
                                    id={"tooltip_create_thread"}
                                />
                            </Popover>
                        }
                    >
                        <img src={WriteIcon} alt="Create Thread Button" />
                    </OverlayTrigger>
                </div>
                <Dialog
                    className="dialog"
                    open={openDialog}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        className="dialog-title"
                    >
                        <FormattedMessage id={"dlg_title_message"} />
                    </DialogTitle>
                    <DialogContent
                        sx={{
                            padding: {
                                xs: "5px",
                                sm: "10px",
                            },
                            paddingTop: {
                                xs: "5px !important",
                                sm: "10px !important",
                            },
                        }}
                    >
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage id={"msg_login_needed"} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            className="dialog-button"
                            onClick={handleDialogClose} 
                            color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* <Zoom in={trigger}>
                    <Box
                        role="presentation"
                        sx={{
                            position: "fixed",
                            bottom: {
                                xs: 100,
                                sm: 32,
                            },
                            right: 10,
                            zIndex: 100,
                        }}
                    >
                        <Fab
                            onClick={scrollToTop}
                            color="primary"
                            size="small"
                            aria-label="scroll back to top"
                        >
                            <KeyboardArrowUp />
                        </Fab>
                    </Box>
                </Zoom> */}
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.category !== nextProps.category) {
            return false;
        }
        if (prevProps.category != null && nextProps.category != null) {
            if (prevProps.category.id !== nextProps.category.id) {
                return false;
            }
        }
        if (prevProps.langCd !== nextProps.langCd) {
            return false;
        }

        return true;
    }
);
