import { GalleryData, TagModel } from "models/Models";

export class UserProfileData {
    id: number | null = null;
    name: string | null = null;
    gender: number | null = null;
    region: string | null = null;
    age: string | null = null;
    profile: string | null = null;
    iconName: string | null = null;
    publishFlag: number | null = null;
    attribute: number | null = null;
    tagList: string[] | null = null;
    tagList2: TagModel[] | null = null;
    createdAt: string | null = null;
    updatedAt: string | null = null;
    isUnderFollow: boolean | null = null;
    countryCd: string | null = null;
    countryName: string | null = null;
    galleryDataList: GalleryData[] | null = null;
    s3BaseUrl: string | null = null;
    amari: number | null = null;
    existTweet: boolean | null = null;
    hasGroup: boolean | null = null;
    isMyGallery: boolean | null = null;
    showTalkButton: boolean | null = null;
    showFollowButton: boolean | null = null;
    showInviteButton: boolean | null = null;
    deviceType: number | null = null;

    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // id
        if (obj.hasOwnProperty("id")) {
            this.id = obj.id;
        } else if (obj.hasOwnProperty("Id")) {
            this.id = obj.Id;
        }
        // name
        if (obj.hasOwnProperty("name")) {
            this.name = obj.name;
        } else if (obj.hasOwnProperty("Name")) {
            this.name = obj.Name;
        }
        // gender
        if (obj.hasOwnProperty("gender")) {
            this.gender = obj.gender;
        } else if (obj.hasOwnProperty("Gender")) {
            this.gender = obj.Gender;
        }
        // region
        if (obj.hasOwnProperty("region")) {
            this.region = obj.region;
        } else if (obj.hasOwnProperty("Region")) {
            this.region = obj.Region;
        }
        // age
        if (obj.hasOwnProperty("age")) {
            this.age = obj.age;
        } else if (obj.hasOwnProperty("Age")) {
            this.age = obj.Age;
        }
        // profile
        if (obj.hasOwnProperty("profile")) {
            this.profile = obj.profile;
        } else if (obj.hasOwnProperty("Profile")) {
            this.profile = obj.Profile;
        }
        // iconName
        if (obj.hasOwnProperty("iconName")) {
            this.iconName = obj.iconName;
        } else if (obj.hasOwnProperty("IconName")) {
            this.iconName = obj.IconName;
        }
        // publishFlag
        if (obj.hasOwnProperty("publishFlag")) {
            this.publishFlag = obj.publishFlag;
        } else if (obj.hasOwnProperty("PublishFlag")) {
            this.publishFlag = obj.PublishFlag;
        }
        // attribute
        if (obj.hasOwnProperty("attribute")) {
            this.attribute = obj.attribute;
        } else if (obj.hasOwnProperty("Attribute")) {
            this.attribute = obj.Attribute;
        }
        // tagList
        if (obj.hasOwnProperty("tagList")) {
            this.tagList = obj.tagList;
        } else if (obj.hasOwnProperty("TagList")) {
            this.tagList = obj.TagList;
        }
        // tagList2
        if (obj.hasOwnProperty("tagList2")) {
            this.tagList2 = [];
            for (let i in obj.tagList2) {
                let tag = new TagModel(obj.tagList2[i]);
                this.tagList2.push(tag);
            }
        } else if (obj.hasOwnProperty("TagList2")) {
            this.tagList2 = [];
            for (let i in obj.TagList2) {
                let tag = new TagModel(obj.TagList2[i]);
                this.tagList2.push(tag);
            }
        }
        // createdAt
        if (obj.hasOwnProperty("createdAt")) {
            this.createdAt = obj.createdAt;
        } else if (obj.hasOwnProperty("CreatedAt")) {
            this.createdAt = obj.CreatedAt;
        }
        // updatedAt
        if (obj.hasOwnProperty("updatedAt")) {
            this.updatedAt = obj.updatedAt;
        } else if (obj.hasOwnProperty("UpdatedAt")) {
            this.updatedAt = obj.UpdatedAt;
        }
        // isUnderFollow
        if (obj.hasOwnProperty("isUnderFollow")) {
            this.isUnderFollow = obj.isUnderFollow;
        } else if (obj.hasOwnProperty("IsUnderFollow")) {
            this.isUnderFollow = obj.IsUnderFollow;
        }
        // countryCd
        if (obj.hasOwnProperty("countryCd")) {
            this.countryCd = obj.countryCd;
        } else if (obj.hasOwnProperty("CountryCd")) {
            this.countryCd = obj.CountryCd;
        }
        // countryName
        if (obj.hasOwnProperty("countryName")) {
            this.countryName = obj.countryName;
        } else if (obj.hasOwnProperty("CountryName")) {
            this.countryName = obj.CountryName;
        }
        // galleryDataList
        if (obj.hasOwnProperty("galleryDataList")) {
            this.galleryDataList = [];
            for (let i in obj.galleryDataList) {
                let galleryData = new GalleryData(obj.galleryDataList[i]);
                this.galleryDataList.push(galleryData);
            }
        } else if (obj.hasOwnProperty("GalleryDataList")) {
            this.galleryDataList = [];
            for (let i in obj.GalleryDataList) {
                let galleryData = new GalleryData(obj.GalleryDataList[i]);
                this.galleryDataList.push(galleryData);
            }
        }
        // s3BaseUrl
        if (obj.hasOwnProperty("s3BaseUrl")) {
            this.s3BaseUrl = obj.s3BaseUrl;
        } else if (obj.hasOwnProperty("S3BaseUrl")) {
            this.s3BaseUrl = obj.S3BaseUrl;
        }
        // amari
        if (obj.hasOwnProperty("amari")) {
            this.amari = obj.amari;
        } else if (obj.hasOwnProperty("Amari")) {
            this.amari = obj.Amari;
        }
        // existTweet
        if (obj.hasOwnProperty("existTweet")) {
            this.existTweet = obj.existTweet;
        } else if (obj.hasOwnProperty("ExistTweet")) {
            this.existTweet = obj.ExistTweet;
        }
        // hasGroup
        if (obj.hasOwnProperty("hasGroup")) {
            this.hasGroup = obj.hasGroup;
        } else if (obj.hasOwnProperty("HasGroup")) {
            this.hasGroup = obj.HasGroup;
        }
        // isMyGallery
        if (obj.hasOwnProperty("isMyGallery")) {
            this.isMyGallery = obj.isMyGallery;
        } else if (obj.hasOwnProperty("IsMyGallery")) {
            this.isMyGallery = obj.IsMyGallery;
        }
        // showTalkButton
        if (obj.hasOwnProperty("showTalkButton")) {
            this.showTalkButton = obj.showTalkButton;
        } else if (obj.hasOwnProperty("ShowTalkButton")) {
            this.showTalkButton = obj.ShowTalkButton;
        }
        // showFollowButton
        if (obj.hasOwnProperty("showFollowButton")) {
            this.showFollowButton = obj.showFollowButton;
        } else if (obj.hasOwnProperty("ShowFollowButton")) {
            this.showFollowButton = obj.ShowFollowButton;
        }
        // showInviteButton
        if (obj.hasOwnProperty("showInviteButton")) {
            this.showInviteButton = obj.showInviteButton;
        } else if (obj.hasOwnProperty("ShowInviteButton")) {
            this.showInviteButton = obj.ShowInviteButton;
        }
        // deviceType
        if (obj.hasOwnProperty("deviceType")) {
            this.deviceType = obj.deviceType;
        } else if (obj.hasOwnProperty("DeviceType")) {
            this.deviceType = obj.DeviceType;
        }
    }
}
