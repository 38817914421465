import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Tabs, Tab } from "@mui/material";
import { UserModel, TalkUserModel } from "models/Models";
import { SelectedItemAction } from "redux/Actions";
import { NavBar, UserChatComponent, UserTalkComponent } from "./Components";
import { AppConstants, DbConstants } from "constants/Constants";
import Utility from "utils/Utility";

import "styles/components/UserCommunicationComponent.scss";

type Props = {
    loginUser: UserModel;
    talkUser: TalkUserModel | null | undefined;
    userListAreaWidth?: number;
};

export const UserCommunicationComponent: React.FC<Props> = React.memo(
    (props) => {
        Utility.log("@@@@@ UserCommunicationComponent width:" + props.userListAreaWidth);
        /***** 定数、変数 */
        const TAB_CHAT = 0;
        const TAB_TALK = 1;
        const intl = useIntl();
        const navigate = useNavigate();
        const dispatch = useDispatch();

        /***** useRef */
        const refTabArea = React.useRef<HTMLDivElement>();

        /***** useState */
        const [selectedTab, setSelectedTab] = React.useState(TAB_CHAT);
        const [activateTab, setActivateTab] = React.useState(false);
        const [partner, setPartner] = React.useState<UserModel>();

        /***** useEffect */
        React.useEffect(() => {
        }, []);
        React.useEffect(() => {
            // Utility.log("UserCommunicationComponent useEffect 1");
            const pathname = window.location.pathname;
            if (pathname === "/conversation") {
                setActivateTab(false);
            } else {
                setActivateTab(true);
                if (pathname.includes("/conversation/talk")) {
                    setSelectedTab(TAB_TALK)
                } else if (pathname.includes("/conversation/chat")) {
                    setSelectedTab(TAB_CHAT)
                }
            }
        }, [window.location.href]);
        React.useEffect(() => {
            // Utility.log("UserCommunicationComponent useEffect 2");
            if (props.talkUser != null && props.talkUser.partner != null) {
                setPartner(props.talkUser.partner);
            } else {
                setPartner(undefined);
            }

            const pathname = window.location.pathname;
            if (pathname === "/conversation") {
                setActivateTab(false);
            } else {
                setActivateTab(true);
                if (pathname.includes("/conversation/talk")) {
                    setSelectedTab(TAB_TALK)
                } else if (pathname.includes("/conversation/chat")) {
                    setSelectedTab(TAB_CHAT)
                }
            }

        }, [props.talkUser])

        function onTabClicked(tab: number) {
            if (selectedTab === tab) {
                return
            }
            if (props.talkUser == null || props.talkUser.partnerId == null) {
                return
            }
            const partnerId = props.talkUser.partnerId;
            const search = window.location.search;

            if (tab === TAB_CHAT) {
                navigate(`/conversation/chat/${partnerId}${search}`);
            } else if (tab === TAB_TALK) {
                navigate(`/conversation/talk/${partnerId}${search}`);
            }
        }
        function handleTabChange(
            event: React.SyntheticEvent,
            newValue: number
        ) {
            if (newValue === TAB_CHAT) {
                window.location.hash = window.location.hash;
            } else if (newValue === TAB_TALK) {
                window.location.hash = window.location.hash;
            }
            setSelectedTab(newValue);
        }

        function getPartnerNameClassName(partner: UserModel): string {
            if (partner.gender === DbConstants.GENDER_MALE) {
                return "partner-name male";
            } else if (partner.gender === DbConstants.GENDER_FEMALE) {
                return "partner-name female";
            } else {
                return "partner-name other";
            }
        }

        function getTabAreaLeftMargin() {
            const width = window.innerWidth;
            let margin = 0;
            if (props.userListAreaWidth != null) {
                margin = props.userListAreaWidth;
            }
            if (width >= 1536) {
                margin = margin + AppConstants.AD_AREA_WIDTH;
            }
            return margin + "px"
        }
        function getTabAreaWidth() {
            const innerWidth = window.innerWidth;
            if (props.userListAreaWidth != null) {
                Utility.log(1)
                if (innerWidth >= 1536) {
                    Utility.log(2)
                    return `calc(100% - ${props.userListAreaWidth}px - ${AppConstants.AD_AREA_WIDTH * 2}px) !important`;
                } else {
                    Utility.log(3)
                    return `calc(100% - ${props.userListAreaWidth}px) !important`;
                }
            } else {
                Utility.log(4)
                if (innerWidth >= 1536) {
                    Utility.log(5)
                    return `calc(100% - ${AppConstants.AD_AREA_WIDTH * 2}px) !important`;
                } else {
                    Utility.log(6)
                    return "100% !important";
                }
            }
        }

        /**
         * レンダリング
         */
        return (
            <Box className="component UserCommunicationComponent">
                {props.loginUser != null &&
                 props.loginUser.id != null &&
                partner != null &&
                partner.name != null &&
                partner.name.length > 0 &&
                partner.gender != null && (
                    <>
                        {
                            // ナビゲーションバー
                        }
                        <NavBar showBack={true} title={partner.name} />

                        <Box
                            ref={refTabArea}
                            component="div"
                            className={props.talkUser == null ? "tab-area no-user" : "tab-area"}
                            sx={{
                                width: getTabAreaWidth(),
                                left: getTabAreaLeftMargin()
                            }}
                        >
                            <div className="tab-area-inner-wrapper">
                                {partner != null && partner.name != null && (
                                    <div
                                        className={getPartnerNameClassName(
                                            partner
                                        )}
                                    >
                                        {partner.name}
                                    </div>
                                )}
                                {activateTab && (
                                    <Tabs
                                        className="tab-wrapper"
                                        value={selectedTab}
                                        // onChange={handleTabChange}
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "transparent",
                                            },
                                        }}
                                    >
                                        <Tab
                                            className={
                                                selectedTab === TAB_CHAT
                                                    ? "tab selected"
                                                    : "tab"
                                            }
                                            id="simple-tab-0"
                                            aria-controls="simple-tabpanel-0"
                                            label={intl.formatMessage({
                                                id: "MENU_CHAT",
                                            })}
                                            value={TAB_CHAT}
                                            onClick={() => {
                                                onTabClicked(TAB_CHAT);
                                            }}
                                            />
                                        <Tab
                                            className={
                                                selectedTab === TAB_TALK
                                                    ? "tab selected"
                                                    : "tab"
                                            }
                                            id="simple-tab-1"
                                            aria-controls="simple-tabpanel-1"
                                            label={intl.formatMessage({
                                                id: "MENU_TALK_UNDER_TEST_OPERATION",
                                            })}
                                            value={TAB_TALK}
                                            onClick={() => {
                                                onTabClicked(TAB_TALK);
                                            }}
                                        />
                                    </Tabs>
                                )}
                            </div>
                        </Box>
                        <div
                            className={
                                selectedTab === TAB_CHAT
                                    ? props.talkUser == null ? "tab-panel chat no-user" : "tab-panel chat"
                                    : "d-none"
                            }
                            role="tabpanel"
                            id={"simple-tabpanel-0"}
                            aria-labelledby={"simple-tab-0"}
                        >
                            <UserChatComponent
                                loginUser={props.loginUser}
                                talkUser={props.talkUser}
                            />
                        </div>
                        <div
                            className={
                                selectedTab === TAB_TALK
                                ? props.talkUser == null ? "tab-panel talk no-user" : "tab-panel talk"
                                : "d-none"
                            }
                            role="tabpanel"
                            id={"simple-tabpanel-1"}
                            aria-labelledby={"simple-tab-1"}
                        >
                            {props.talkUser != null && (
                                <UserTalkComponent
                                    loginUser={props.loginUser}
                                    talkUser={props.talkUser}
                                    pathname={window.location.pathname}
                                />
                            )}
                        </div>
                    </>
                )}
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.talkUser !== nextProps.talkUser) {
            return false;
        }
        if (prevProps.talkUser != null && nextProps.talkUser != null) {
            if (prevProps.talkUser.userId !== nextProps.talkUser.userId) {
                return false;
            }
            if (prevProps.talkUser.partnerId !== nextProps.talkUser.partnerId) {
                return false;
            }
            if (prevProps.talkUser.partner !== nextProps.talkUser.partner) {
                return false;
            }
            if (prevProps.talkUser.partner != null && nextProps.talkUser.partner != null) {
                const prevPartner = prevProps.talkUser.partner;
                const nextPartner = nextProps.talkUser.partner;
                if (prevPartner.id !== nextPartner.id) {
                    return false;
                }
            }
        }
        if (
            prevProps.userListAreaWidth !== nextProps.userListAreaWidth
        ) {
            return false;
        }

        return true;
    }
);
