import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TextField, Button, FormControlLabel, Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { UserModel, TagModel, CustomTagModel } from "models/Models";
import { AppConstants } from "constants/AppConstants";
import { UserAction, TagListAction } from "redux/Actions";
import {
    getTagList,
    getUserTagList,
    getUserCustomTagList,
    getLanguageCode,
} from "redux/Selectors";
import { UserRequest, TagRequest } from "api/requests/Requests";
import { pushDataLayer } from "gtm/gtm"

import "styles/components/SelectTagComponent.scss";
import Utility from "utils/Utility";

interface Props {
    loginUser: UserModel | null;
    onCancel: () => void;
    onOk: () => void;
}
export const SelectTagComponent: React.FC<Props> = (props) => {
    // Utility.log("===== SelectTagComponent");
    /***** 定数、変数 */
    const intl = useIntl();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const langCd = getLanguageCode(selector);

    /***** useRef */

    /***** useState */
    // カスタムタグ名
    const [customTagName, setCustomTagName] = React.useState<string>("");
    // タグリスト
    const [lstTag, setTagList] = React.useState<TagModel[]>();
    // カスタムタグリスト
    const [lstCustomTag, setCustomTagList] = React.useState<CustomTagModel[]>();
    // 選択タグリスト
    const [lstSelectedTag, setSelectedTagList] = React.useState<TagModel[]>();

    /**
     * useEffect
     */
    React.useEffect(() => {
        pushDataLayer({
            event: 'page_view',
            screen: "タグ選択",
            path: window.location.pathname,
        });
        
        (async () => {
            // タグリスト
            let wkTagList: TagModel[] | null = getTagList(selector);
            // カスタムタグリスト
            let wkCustomTagList: CustomTagModel[] | null =
                getUserCustomTagList(selector);
            // 選択タグリスト
            let wkSelectedTagList: TagModel[] | null = getUserTagList(selector);

            if (wkTagList == null) {
                const array = await fetchTagList();
                wkTagList = array[0];
                wkCustomTagList = array[1];
            }
            if (wkTagList != null) {
                const newTagList = structuredClone(wkTagList);
                const newCustomTagList = structuredClone(wkCustomTagList);
                const newSelectedTagList = structuredClone(wkSelectedTagList);
                setInitialTag(newTagList, newSelectedTagList, newCustomTagList);
                setTagList(newTagList);
                if (newSelectedTagList != null) {
                    setSelectedTagList(newSelectedTagList);
                }
                if (newCustomTagList != null) {
                    setCustomTagList(newCustomTagList);
                }
            }
        })();
    }, [langCd]);

    /**
     * 初期表示時の選択タグをセット
     * @param _lstTag
     * @param _lstCustomTag
     */
    function setInitialTag(
        _lstTag: TagModel[],
        _lstSelectedTag: TagModel[] | null,
        _lstCustomTag: CustomTagModel[] | null
    ) {
        if (_lstSelectedTag != null) {
            _lstTag.forEach((tag: TagModel) => {
                _lstSelectedTag.forEach((selectedTag: TagModel) => {
                    if (tag.id === selectedTag.id) {
                        tag.selected = true;
                    }
                });
            });
        }
        if (_lstCustomTag != null) {
            _lstCustomTag.forEach((customTag: CustomTagModel) => {
                customTag.selected = true;
            });
        }
    }

    /**
     * タグ選択変更時
     * @param e
     * @param _tag
     * @returns
     */
    function onChangeTag(
        e: React.ChangeEvent<HTMLInputElement>,
        _tag: TagModel
    ) {
        if (lstTag == null) {
            return;
        }
        const selected = e.target.checked;
        const newSelectedTagList: TagModel[] = [];
        const newTagList = lstTag.map((tag: TagModel) => {
            if (tag.id === _tag.id) {
                tag.selected = selected;
            }
            if (tag.selected) {
                newSelectedTagList.push(tag);
            }
            return tag;
        });
        setTagList(newTagList);
        setSelectedTagList(newSelectedTagList);

        // _tag.selected = e.target.checked;
        // setSelectedTagList((prevList) => {
        //     if (prevList == null) {
        //         // 選択 => 選択解除
        //         if (!_tag.selected) {
        //             return prevList;
        //         }
        //         // 非選択 => 選択
        //         else {
        //             return [_tag];
        //         }
        //     } else {
        //         let exist = false;
        //         const newList = prevList.filter((selectedTag: TagModel) => {
        //             if (_tag.id === selectedTag.id) {
        //                 exist = true;
        //                 selectedTag.selected = _tag.selected;
        //                 return _tag.selected;
        //             } else {
        //                 return true;
        //             }
        //         });
        //         if (_tag.selected && !exist) {
        //             newList.push(_tag);
        //         }
        //         refSelectedTagList.current = newList;
        //         return newList;
        //     }
        // });
        // reflectSelectedTagStatus();
    }
    /**
     * カスタムタグ選択変更時
     * @param e
     * @param _customTag
     * @returns
     */
    function onChangeCustomTag(
        e: React.ChangeEvent<HTMLInputElement>,
        _customTag: CustomTagModel
    ) {
        if (lstTag == null) {
            return;
        }
        const selected = e.target.checked;
        if (lstCustomTag == null || lstCustomTag.length === 0) {
            _customTag.selected = selected;
            setCustomTagList([_customTag]);
        } else {
            const newCustomTagList = lstCustomTag.map(
                (customTag: CustomTagModel) => {
                    if (customTag.id === _customTag.id) {
                        customTag.selected = selected;
                    }
                    return customTag;
                }
            );
            setCustomTagList(newCustomTagList);
        }

        // _customTag.selected = e.target.checked;
        // setCustomTagList((prevList) => {
        //     if (prevList == null) {
        //         let newList: CustomTagModel[] | undefined;
        //         // 選択 => 選択解除
        //         if (!_customTag.selected) {
        //             newList = [];
        //         }
        //         // 非選択 => 選択
        //         else {
        //             newList = [_customTag];
        //         }
        //         refCustomTagList.current = newList;
        //         return newList;
        //     } else {
        //         let exist = false;
        //         const newList = prevList.map((customTag: CustomTagModel) => {
        //             if (_customTag.id === customTag.id) {
        //                 exist = true;
        //                 customTag.selected = _customTag.selected;
        //             }
        //             return customTag;
        //         });
        //         if (_customTag.selected && !exist) {
        //             newList.push(_customTag);
        //         }
        //         refCustomTagList.current = newList;
        //         return newList;
        //     }
        // });
        // reflectSelectedCustomTagStatus();
    }

    /**
     * カスタムタグ追加時
     * @returns
     */
    function onClickAdd() {
        if (customTagName.length === 0) {
            return;
        }
        let customTag = new CustomTagModel();
        customTag.name = customTagName;
        customTag.selected = true;
        // lstCustomTagに追加
        let wkCustomTagList = lstCustomTag;
        if (wkCustomTagList == null) {
            customTag.id = 1;
            wkCustomTagList = [customTag];
        } else {
            let tagId = 0;
            wkCustomTagList.forEach((customTag) => {
                if (customTag.id != null) {
                    if (tagId < customTag.id) {
                        tagId = customTag.id;
                    }
                }
            });
            customTag.id = tagId + 1;
            wkCustomTagList.push(customTag);
        }
        setCustomTagList(wkCustomTagList);
        // refCustomTagList.current = wkCustomTagList;
        setCustomTagName("");
    }

    /**
     * OK時
     */
    async function onClickOk() {
        // タグ
        let lstSelectedTagCommitted: TagModel[] | null = null;
        if (lstSelectedTag != null) {
            lstSelectedTagCommitted = lstSelectedTag;
        }
        // カスタムタグ
        let lstSelectedCustomTagCommitted = null;
        if (lstCustomTag != null) {
            lstSelectedCustomTagCommitted = lstCustomTag.filter((customTag) => {
                return customTag.selected;
            });
        }

        // 保存リクエスト
        if (props.loginUser != null) {
            await save(lstSelectedTagCommitted, lstSelectedCustomTagCommitted);
        }

        // ディスパッチ
        dispatch(
            UserAction({
                lstTag: lstSelectedTagCommitted,
                lstCustomTag: lstSelectedCustomTagCommitted,
            })
        );

        props.onOk();
    }
    /**
     * キャンセル時
     */
    function onClickCancel() {
        props.onCancel();
    }

    /**
     * タグ変更保存
     */
    async function save(
        _lstSelectedTag: TagModel[] | null,
        _lstSelectedCustomTag: CustomTagModel[] | null
    ) {
        if (props.loginUser == null) {
            return;
        }
        let updateUser = new UserModel();
        updateUser.id = props.loginUser.id;
        updateUser.createdAt = props.loginUser.createdAt;
        updateUser.bearerToken = props.loginUser.bearerToken;
        if (_lstSelectedTag != null) {
            updateUser.lstSelectedTag = _lstSelectedTag;
        }
        if (_lstSelectedCustomTag != null) {
            updateUser.lstCustomTag = _lstSelectedCustomTag;
        }
        const res = await UserRequest.save(updateUser);
        if (res != null) {
            if (res.rtnCd != null && res.rtnCd === 0) {
                if (res.user != null && res.bearerToken != null) {
                    res.user.bearerToken = res.bearerToken;
                    if (_lstSelectedTag != null) {
                        res.user.lstSelectedTag = _lstSelectedTag;
                    }
                    if (_lstSelectedCustomTag != null) {
                        res.user.lstCustomTag = _lstSelectedCustomTag;
                    }
                    dispatch(
                        UserAction({
                            loginUser: res.user,
                            lstTag: _lstSelectedTag,
                            lstCustomTag: _lstSelectedCustomTag,
                        })
                    );
                }
            }
        }
    }
    /**
     * 選択タグステータス反映
     */
    // function reflectSelectedTagStatus() {
    //     if (lstTag == null) {
    //         return;
    //     }
    //     if (refSelectedTagList == null || refSelectedTagList.current == null) {
    //         return;
    //     }
    //     const wkTagList = lstTag.map((tag) => {
    //         let selected = false;
    //         if (
    //             refSelectedTagList != null &&
    //             refSelectedTagList.current != null
    //         ) {
    //             for (let i = 0; i < refSelectedTagList.current.length; i++) {
    //                 const selectedTag = refSelectedTagList.current[i];
    //                 if (tag.id === selectedTag.id) {
    //                     selected = true;
    //                     break;
    //                 }
    //             }
    //         }
    //         tag.selected = selected;
    //         return tag;
    //     });
    //     if (wkTagList != null) {
    //         setTagList(wkTagList);
    //     }
    // }

    /**
     * 選択カスタムタグステータス反映
     */
    // function reflectSelectedCustomTagStatus() {
    //     if (refCustomTagList == null || refCustomTagList.current == null) {
    //         return;
    //     }
    //     const wkCustomTagList = refCustomTagList.current.map((customTag) => {
    //         let selected = false;
    //         if (refCustomTagList != null && refCustomTagList.current != null) {
    //             for (let i = 0; i < refCustomTagList.current.length; i++) {
    //                 const selectedCustomTag = refCustomTagList.current[i];
    //                 if (
    //                     customTag.id === selectedCustomTag.id &&
    //                     selectedCustomTag.selected
    //                 ) {
    //                     selected = true;
    //                     break;
    //                 }
    //             }
    //         }
    //         customTag.selected = selected;
    //         return customTag;
    //     });
    //     if (wkCustomTagList != null) {
    //         setCustomTagList(wkCustomTagList);
    //         refCustomTagList.current = wkCustomTagList;
    //     }
    // }

    /**
     * タグリスト取得
     */
    async function fetchTagList(): Promise<
        [TagModel[] | null, CustomTagModel[] | null]
    > {
        let lstTag = getTagList(selector);
        let lstCustomTag = null;
        if (lstTag == null) {
            const result = await TagRequest.getList(props.loginUser);
            Utility.log("tagList:" + JSON.stringify(result))
            if (result != null && result.rtnCd != null && result.rtnCd === 0) {
                lstTag = result.lstTag;
                dispatch(
                    TagListAction({
                        list: lstTag,
                    })
                );
                if (result.lstCustomTag != null) {
                    lstCustomTag = result.lstCustomTag;
                }
            }
        }
        return [lstTag, lstCustomTag];
    }

    return (
        <div className="component SelectTagComponent">
            <div className="tag-area">
                <div className="operation-area">
                    <TextField
                        className="txt-custom-tag-name"
                        fullWidth
                        placeholder={intl.formatMessage({
                            id: "custom_tag",
                        })}
                        type="text"
                        value={customTagName}
                        onChange={(event) =>
                            setCustomTagName(event.target.value)
                        }
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                onClickAdd();
                            }
                        }}
                    />
                    <Button
                        className="app-button btn-add"
                        variant="text"
                        sx={{
                            backgroundColor: AppConstants.COLOR_PRIMARY,
                            fontSize: AppConstants.FONT_SIZE_BUTTON,
                            color: "#ffffff",
                            padding: "3px 10px",
                            marginLeft: "10px",
                            height: "100%",
                        }}
                        onClick={onClickAdd}
                    >
                        <FormattedMessage id={"btn_add"} />
                    </Button>
                </div>
                <div className="list-area">
                    {lstCustomTag != null &&
                        lstCustomTag.map((customTag, index) => {
                            return (
                                <FormControlLabel
                                    key={customTag.name + "_" + String(index)}
                                    control={
                                        <Checkbox
                                            className="checkbox"
                                            checked={customTag.selected}
                                            onChange={(event) => {
                                                onChangeCustomTag(
                                                    event,
                                                    customTag
                                                );
                                            }}
                                        />
                                    }
                                    label={customTag.name}
                                />
                            );
                        })
                    }
                    {langCd != null &&
                    lstTag != null &&
                    lstTag.map((tag: TagModel) => {
                        let tagName = null;
                        switch (langCd) {
                            case "ja":
                                tagName = tag.name;
                                break;
                            case "cn":
                                tagName = tag.nameCn;
                                break;
                            case "tw":
                                tagName = tag.nameTw;
                                break;
                            case "es":
                                tagName = tag.nameEs;
                                break;
                            case "de":
                                tagName = tag.nameDe;
                                break;
                            case "it":
                                tagName = tag.nameIt;
                                break;
                            case "ru":
                                tagName = tag.nameRu;
                                break;
                        }
                        if (tagName == null) {
                            tagName = tag.nameEn;
                        }

                        return (
                            <FormControlLabel
                                key={tag.id}
                                control={
                                    <Checkbox
                                        className="checkbox"
                                        checked={tag.selected}
                                        onChange={(event) => {
                                            onChangeTag(event, tag);
                                        }}
                                    />
                                }
                                label={tagName}
                            />
                        );
                    })}
                </div>
            </div>
            <div className="button-area">
                <Button
                    className="app-button button btn-cancel"
                    variant="text"
                    onClick={onClickCancel}
                >
                    <FormattedMessage id={"btn_cancel"} />
                </Button>
                <Button
                    className="app-button button btn-ok"
                    variant="text"
                    onClick={onClickOk}
                >
                    <FormattedMessage id={"btn_ok"} />
                </Button>
            </div>
        </div>
    );
};
