import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { UserModel, CountryModel } from "models/Models";
import { CountryListItem } from "components/Components";
import { CountryRequest, UserRequest } from "api/requests/Requests";
import { CountryListAction, UserAction } from "redux/Actions";
import {
    getCountryList,
    getSelectedCountry,
    getLanguageCode,
} from "redux/Selectors";
import { pushDataLayer } from "gtm/gtm"
import "styles/components/SelectCountryComponent.scss";

interface Props {
    loginUser: UserModel | undefined;
    onCancel: () => void;
    onOk: () => void;
}
export const SelectCountryComponent: React.FC<Props> = (props) => {
    // Utility.log("===== SelectCountryComponent");
    /***** 定数、変数 */
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const langCd = getLanguageCode(selector);

    /***** useRef */
    // クリックカウント
    const  refClickCount = React.useRef<number>(0);

    /***** useState */
    // 国リスト
    const [lstCountry, setCountryList] = React.useState<CountryModel[]>();

    /**
     * useEffect
     */
    React.useEffect(() => {
        pushDataLayer({
            event: 'page_view',
            screen: "国選択",
            path: window.location.pathname,
        });
        
        (async () => {
            let wkCountryList = getCountryList(selector);
            if (wkCountryList == null) {
                wkCountryList = await fetchCountryList();
            }
            if (wkCountryList != null) {
                const newList = structuredClone(wkCountryList);
                if (newList != null) {
                    setInitialCountry(newList);
                    setCountryList(newList);
                }
            }
        })();
    }, []);

    /**
     * 初期表示時の選択国をセット
     * @param _lstCountry
     */
    function setInitialCountry(_lstCountry: CountryModel[]) {
        let wkCountryCode: string | null = null;
        if (props.loginUser == null) {
            let wkSelectedCountry = getSelectedCountry(selector);
            if (wkSelectedCountry != null) {
                wkCountryCode = wkSelectedCountry.code;
            }
        } else {
            wkCountryCode = props.loginUser.countryCd;
        }
        if (wkCountryCode != null) {
            _lstCountry.forEach((_country: CountryModel) => {
                if (_country.code === wkCountryCode) {
                    _country.selected = true;
                } else {
                    _country.selected = false;
                }
            });
        }
    }
    /**
     * 国選択時
     * @param _country
     */
    function onSelect(_country: CountryModel) {
        if (lstCountry == null) {
            return;
        }
        refClickCount.current++;
        if (refClickCount.current > 1) {
            window.setTimeout(() => {
                refClickCount.current = 0;
                onClickOk();
            });
            return;
        }
        let newList = lstCountry.map((country: CountryModel) => {
            if (country.code === _country.code) {
                country.selected = true;
            } else {
                country.selected = false;
            }
            return country;
        });
        setCountryList(newList);
        window.setTimeout(() => {
            refClickCount.current = 0;
        }, 300);
    }
    function onDoubleClick(_country: CountryModel) {
        if (lstCountry == null) {
            return;
        }
        let newList = lstCountry.map((country: CountryModel) => {
            if (country.code === _country.code) {
                country.selected = true;
            } else {
                country.selected = false;
            }
            return country;
        });
        setCountryList(newList);
    }

    /**
     * OK時
     */
    async function onClickOk() {
        updateCountry();
        props.onOk();
    }
    /**
     * キャンセル時
     */
    function onClickCancel() {
        props.onCancel();
    }

    /**
     * 国更新
     */
    async function updateCountry(): Promise<number> {
        if (lstCountry == null) {
            return -1;
        }
        if (
            props.loginUser == null ||
            props.loginUser.id == null ||
            props.loginUser.createdAt == null ||
            props.loginUser.bearerToken == null
        ) {
            return -1;
        }
        let selectedCountryCode = null;
        lstCountry.forEach((country: CountryModel) => {
            if (country.selected) {
                selectedCountryCode = country.code;
            }
        });
        if (selectedCountryCode == null) {
            return -1;
        }
        let updateUser = new UserModel();
        updateUser.id = props.loginUser.id;
        updateUser.createdAt = props.loginUser.createdAt;
        updateUser.bearerToken = props.loginUser.bearerToken;
        updateUser.countryCd = selectedCountryCode;

        const res = await UserRequest.save(updateUser);
        if (res == null || res.rtnCd == null || res.rtnCd < 0) {
            return -1;
        }
        if (res.user != null && res.bearerToken != null) {
            res.user.bearerToken = res.bearerToken;
            dispatch(
                UserAction({
                    loginUser: res.user,
                })
            );
        }
        return 0;
    }

    /**
     * 国レコード取得
     * @param index
     * @returns
     */
    async function fetchCountryList(): Promise<CountryModel[] | null> {
        let result = await CountryRequest.getList();
        if (result == null || result.rtnCd == null || result.rtnCd < 0) {
            return null;
        }
        let lstCountry = result.lstCountry;
        if (lstCountry == null) {
            return null;
        }
        dispatch(
            CountryListAction({
                list: lstCountry,
            })
        );
        return lstCountry;
    }

    return (
        <div className="component SelectCountryComponent">
            {/* 国リスト */}
            <div className="list-area">
                {langCd != null &&
                    lstCountry != null &&
                    lstCountry.map((country: CountryModel, index: number) => {
                        return (
                            <div
                                key={index}
                                className="item-wrapper"
                                onClick={() => {
                                    onSelect(country);
                                }}
                            >
                                <CountryListItem
                                    key={index}
                                    country={country}
                                    selected={country.selected}
                                    langCode={langCd}
                                />
                                <hr />
                            </div>
                        );
                    })}
            </div>
            {
                // ボタンエリア
            }
            <div className="button-area">
                <Button
                    className="app-button button btn-cancel"
                    variant="text"
                    onClick={onClickCancel}
                >
                    <FormattedMessage id={"btn_cancel"} />
                </Button>
                <Button
                    className="app-button button btn-ok"
                    variant="text"
                    onClick={onClickOk}
                >
                    <FormattedMessage id={"btn_ok"} />
                </Button>
            </div>
        </div>
    );
};
