import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, CardMedia, CardContent, Button } from "@mui/material";
import { UserModel, JoinGroupModel, GroupModel } from "models/Models";
import {
    DbConstants,
    UrlConstants,
    GROUP_JOIN_STATUS,
} from "constants/Constants";
import Utility from "utils/Utility";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import ArrowForward from "assets/images/arrow_forward.svg";
import "styles/components/listItems/JoinGroupListItemUser.scss";

type Props = {
    joinGroup: JoinGroupModel;
    group: GroupModel;
    groupJoinStatus: GROUP_JOIN_STATUS;
    user: UserModel;
    langCd: string;
    timeDifference: number;
    onClick: () => void;
    onClickApprove: () => void;
    onClickDecline: () => void;
    onClickWithdraw: () => void;
};

export const JoinGroupListItemUser: React.FC<Props> = (props) => {
    // Utility.log("===== JoinGroupListItemUser");
    /***** 定数、変数 */
    let updatedAt = new Date(props.user.updatedAt as string);
    let unixTime = updatedAt.getTime() / 1000;
    let formattedDate = Utility.getFormattedDate(
        unixTime,
        props.timeDifference,
        props.langCd
    );

    // /**
    //  * テーマ
    //  */
    // const theme = createTheme({
    //     components: {
    //         // Name of the component
    //         MuiCard: {
    //             styleOverrides: {
    //                 // Name of the slot
    //                 root: {
    //                     // Some CSS
    //                     fontSize: "1rem",
    //                 },
    //             },
    //         },
    //     },
    // });

    /**
     * フォローのHtml要素生成
     * @returns
     */
    function getFollowHtml() {
        if (props.user.following) {
            return (
                <div className="follow">
                    <FormattedMessage id={"user_following"} />
                </div>
            );
        } else {
            return <></>;
        }
    }
    /**
     * 属性のHtml要素生成
     * @param gender
     * @param attribute
     * @returns
     */
    function getAttributeHtml() {
        let attributeLabel = Utility.getAttributeLabelKey(props.user);
        if (attributeLabel.length > 0) {
            let className = "attribute";
            if (props.user.gender === DbConstants.GENDER_MALE) {
                className = className + " male";
            } else if (props.user.gender === DbConstants.GENDER_FEMALE) {
                className = className + " female";
            } else {
                className = className + " other";
            }
            let label = Utility.getAttributeLabelKey(props.user);
            if (label != null && label.length > 0) {
                return (
                    <div className={className}>
                        <FormattedMessage id={label} />
                    </div>
                );
            } else {
                return <span></span>;
            }
        } else {
            return <span></span>;
        }
    }
    /**
     * 国旗のHtml要素生成
     * @returns
     */
    function getUserFlagHtml() {
        if (props.user.countryCd != null && props.user.countryCd.length > 0) {
            return (
                <img
                    className="flag-image"
                    src={
                        "/images/flag/" +
                        props.user.countryCd?.toUpperCase() +
                        ".png"
                    }
                    alt="Country Flag"
                />
            );
        } else {
            return <span></span>;
        }
    }
    /**
     * 年代のHtml要素生成
     * @returns
     */
    function getAgeHtml() {
        if (props.user.age != null) {
            let label = Utility.getAgeLabelKey(props.user.age!);
            if (label != null && label.length > 0) {
                return (
                    <div className="age">
                        <FormattedMessage id={label} />
                    </div>
                );
            } else {
                return <span></span>;
            }
        } else {
            return <span></span>;
        }
    }
    /**
     * 性別のHtml生成
     * @returns
     */
    function getGenderHtml() {
        if (props.user.gender != null) {
            let label = Utility.getGenderLabelKey(props.user.gender);
            if (label != null && label.length > 0) {
                return (
                    <div className=".gender">
                        (
                        <FormattedMessage id={label} />)
                    </div>
                );
            } else {
                return <span></span>;
            }
        } else {
            return <span></span>;
        }
    }
    /**
     * レンダー
     */
    return (
        // <ThemeProvider theme={theme}>
        <Card className="JoinGroupListItemUser" onClick={props.onClick}>
            {
                // ユーザアイコン
            }
            <CardMedia
                className="card-image"
                component="img"
                image={
                    props.user.iconName != null &&
                    props.user.iconName.length > 0
                        ? UrlConstants.URL_S3_USER_ICON + props.user.iconName
                        : "/images/no_image.png"
                }
            />
            <CardContent className="card-content">
                <div className="info-wrapper">
                    <div className="info-wrapper-1">
                        {
                            // 国旗
                            getUserFlagHtml()
                        }
                        {
                            // 属性
                            getAttributeHtml()
                        }
                    </div>
                    {
                        // 名前
                    }
                    <div className="info-wrapper-2">{props.user.name}</div>
                    <div className="info-wrapper-3">
                        {
                            // 年代
                            getAgeHtml()
                        }
                        {
                            // 性別
                            getGenderHtml()
                        }
                    </div>
                    {
                        // 地域
                    }
                    <div className="info-wrapper-4">
                        {props.user.region == null || props.user.region === ""
                            ? "\u2003"
                            : props.user.region}
                    </div>
                    {
                        // 最終アクセス日
                    }
                    <div className="info-wrapper-5">
                        <CalendarIcon className="card-icon" />
                        {formattedDate}
                    </div>
                </div>
                <div className="right-area">
                    {/* ボタンエリア */}
                    <div className="button-area">
                        {/* 加入申請を受けている */}
                        {props.groupJoinStatus ===
                            GROUP_JOIN_STATUS.APPLIED && (
                            <>
                                {/* 承認ボタン */}
                                <Button
                                    className="app-button btn-approve"
                                    variant="text"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props.onClickApprove();
                                    }}
                                >
                                    <FormattedMessage id={"btn_approve"} />
                                </Button>
                                {/* 拒否ボタン */}
                                <Button
                                    className="app-button btn-decline"
                                    variant="text"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props.onClickDecline();
                                    }}
                                >
                                    <FormattedMessage id={"btn_decline"} />
                                </Button>
                            </>
                        )}
                        {/* 招待をしている */}
                        {props.groupJoinStatus ===
                            GROUP_JOIN_STATUS.INVITING && (
                            <>
                                {/* 招待の取り消しボタン */}
                                <Button
                                    className="app-button btn-withdraw"
                                    variant="text"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props.onClickWithdraw();
                                    }}
                                >
                                    <FormattedMessage
                                        id={"btn_withdraw_invitation"}
                                    />
                                </Button>
                            </>
                        )}
                    </div>
                    <img
                        className="img-arrow-forward"
                        src={ArrowForward}
                        alt=""
                    />
                </div>
            </CardContent>
            <div className="user-label">
                {
                    // フォローラベル
                    getFollowHtml()
                }
            </div>
            {props.user.selected && <div className="selected-layer" />}
        </Card>
        // </ThemeProvider>
    );
};
