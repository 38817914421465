export class UrlConstants {
    /***************************************** 本番用ベースURL *****************************************/
    static URL_PROTOCOL_PRODUCTION = "https";
    static URL_HOST_PRODUCTION: string = UrlConstants.URL_PROTOCOL_PRODUCTION + "://www.sm-mate.net:443";
    // static URL_HOST_PRODUCTION: string = "http://www.sm-mate.net:8082";
    // nginxが8086番ポートで受けて、appInfoサービスの8085番へフォワードする。
    static URL_APP_INFO_HOST_PRODUCTION: string =
    UrlConstants.URL_PROTOCOL_PRODUCTION + "://www.sm-mate.net:8086";
    static SOCKET_APP_CONNECTION_HOST_PRODUCTION: string =
    UrlConstants.URL_PROTOCOL_PRODUCTION + "://app-socket.sm-mate.net";
    static SOCKET_GROUP_CONNECTION_HOST_PRODUCTION: string =
    UrlConstants.URL_PROTOCOL_PRODUCTION + "://group-socket.sm-mate.net";
    static SOCKET_APP_CONNECTION_PORT_PRODUCTION: number = 8900;
    static SOCKET_GROUP_CONNECTION_PORT_PRODUCTION: number = 8890;
    static URL_USER_CONTENTS_URL_PRODUCTION: string =
        "https://s3.us-west-2.amazonaws.com/com.predestination.smmate.product";

    /***************************************** ステージング用ベースURL *****************************************/
    static URL_PROTOCOL_STAGING = "https";
    static URL_HOST_STAGING: string = UrlConstants.URL_PROTOCOL_STAGING + "://staging.sm-mate.net:443";
    // static URL_HOST_PRODUCTION: string = "http://www.sm-mate.net:8082";
    // nginxが8086番ポートで受けて、appInfoサービスの8085番へフォワードする。
    static URL_APP_INFO_HOST_STAGING: string =
    UrlConstants.URL_PROTOCOL_STAGING + "://www.sm-mate.net:8086";
    static SOCKET_APP_CONNECTION_HOST_STAGING: string =
    UrlConstants.URL_PROTOCOL_STAGING + "://app-socket.sm-mate.net";
    static SOCKET_GROUP_CONNECTION_HOST_STAGING: string =
    UrlConstants.URL_PROTOCOL_STAGING + "://group-socket.sm-mate.net";
    static SOCKET_APP_CONNECTION_PORT_STAGING: number = 8900;
    static SOCKET_GROUP_CONNECTION_PORT_STAGING: number = 8890;
    static URL_USER_CONTENTS_URL_STAGING: string =
        "https://s3.us-west-2.amazonaws.com/com.predestination.smmate.product";

    /***************************************** 開発用ベースURL1(https://192.168.50.4:443) *****************************************/
    // static URL_PROTOCOL_DEVELOPMENT = "http";
    // static URL_PROTOCOL_DEVELOPMENT_HTTPS = "https";
    // static URL_HOST_DEVELOPMENT: string = "https://192.168.50.4:443";
    // static URL_APP_INFO_HOST_DEVELOPMENT: string = "https://192.168.50.4:8086";

    // static SOCKET_APP_CONNECTION_HOST_DEVELOPMENT: string =
    //     "https://192.168.50.4";
    // static SOCKET_GROUP_CONNECTION_HOST_DEVELOPMENT: string =
    //     "https://192.168.50.4";
    // static SOCKET_APP_CONNECTION_PORT_DEVELOPMENT: number = 8900;
    // static SOCKET_GROUP_CONNECTION_PORT_DEVELOPMENT: number = 8890;
    // static URL_USER_CONTENTS_URL_DEVELOPMENT: string =
    //     "https://s3.us-west-2.amazonaws.com/com.predestination.smmate.develop";

    /***************************************** 開発用ベースURL1(http://localhost:3000) *****************************************/
    static URL_PROTOCOL_DEVELOPMENT = "http";
    static URL_PROTOCOL_DEVELOPMENT_HTTPS = "https";
    static URL_HOST_DEVELOPMENT: string = "http://localhost";
    static URL_APP_INFO_HOST_DEVELOPMENT: string = "https://localhost:8086";

    static SOCKET_APP_CONNECTION_HOST_DEVELOPMENT: string =
        "https://192.168.50.4";
    static SOCKET_GROUP_CONNECTION_HOST_DEVELOPMENT: string =
        "https://192.168.50.4";
    static SOCKET_APP_CONNECTION_PORT_DEVELOPMENT: number = 8900;
    static SOCKET_GROUP_CONNECTION_PORT_DEVELOPMENT: number = 8890;
    static URL_USER_CONTENTS_URL_DEVELOPMENT: string =
        "https://s3.us-west-2.amazonaws.com/com.predestination.smmate.develop";

    /***************************************** 開発用ベースURL2 *****************************************/
    // static URL_PROTOCOL_DEVELOPMENT = "http";
    // static URL_PROTOCOL_DEVELOPMENT_HTTPS = "https";

    // static URL_HOST_DEVELOPMENT: string = UrlConstants.URL_PROTOCOL_DEVELOPMENT + "://localhost:80";
    // static URL_APP_INFO_HOST_DEVELOPMENT: string = UrlConstants.URL_PROTOCOL_DEVELOPMENT + "://localhost:8086";
    // static SOCKET_APP_CONNECTION_HOST_DEVELOPMENT: string = UrlConstants.URL_PROTOCOL_DEVELOPMENT_HTTPS + "://localhost";
    // static SOCKET_GROUP_CONNECTION_HOST_DEVELOPMENT: string =
    // UrlConstants.URL_PROTOCOL_DEVELOPMENT_HTTPS + "://localhost";

    // static SOCKET_APP_CONNECTION_PORT_DEVELOPMENT: number = 8900;
    // static SOCKET_GROUP_CONNECTION_PORT_DEVELOPMENT: number = 8890;
    // static URL_USER_CONTENTS_URL_DEVELOPMENT: string =
    //     "https://s3.us-west-2.amazonaws.com/com.predestination.smmate.develop";

    /***************************************** ベースURL *****************************************/
    // 本番用 START
    static URL_HOST: string = UrlConstants.URL_HOST_PRODUCTION;
    static URL_APP_INFO_HOST: string =
        UrlConstants.URL_APP_INFO_HOST_PRODUCTION;
    static SOCKET_APP_CONNECTION_HOST: string =
        UrlConstants.SOCKET_APP_CONNECTION_HOST_PRODUCTION;
    static SOCKET_GROUP_CONNECTION_HOST: string =
        UrlConstants.SOCKET_GROUP_CONNECTION_HOST_PRODUCTION;
    static SOCKET_APP_CONNECTION_PORT: number =
        UrlConstants.SOCKET_APP_CONNECTION_PORT_PRODUCTION;
    static SOCKET_GROUP_CONNECTION_PORT: number =
        UrlConstants.SOCKET_GROUP_CONNECTION_PORT_PRODUCTION;
    static URL_USER_CONTENTS_URL: string =
        UrlConstants.URL_USER_CONTENTS_URL_PRODUCTION;
    // 本番用 END

    // ステージング用 START
    // static URL_HOST: string = UrlConstants.URL_HOST_STAGING;
    // static URL_APP_INFO_HOST: string =
    //     UrlConstants.URL_APP_INFO_HOST_STAGING;
    // static SOCKET_APP_CONNECTION_HOST: string =
    //     UrlConstants.SOCKET_APP_CONNECTION_HOST_STAGING;
    // static SOCKET_GROUP_CONNECTION_HOST: string =
    //     UrlConstants.SOCKET_GROUP_CONNECTION_HOST_STAGING;
    // static SOCKET_APP_CONNECTION_PORT: number =
    //     UrlConstants.SOCKET_APP_CONNECTION_PORT_STAGING;
    // static SOCKET_GROUP_CONNECTION_PORT: number =
    //     UrlConstants.SOCKET_GROUP_CONNECTION_PORT_STAGING;
    // static URL_USER_CONTENTS_URL: string =
    //     UrlConstants.URL_USER_CONTENTS_URL_STAGING;
    // ステージング用 END

    // 開発用 START
    // static URL_HOST: string = UrlConstants.URL_HOST_DEVELOPMENT;
    // static URL_APP_INFO_HOST: string =
    //     UrlConstants.URL_APP_INFO_HOST_DEVELOPMENT;
    // static SOCKET_APP_CONNECTION_HOST: string =
    //     UrlConstants.SOCKET_APP_CONNECTION_HOST_DEVELOPMENT;
    // static SOCKET_GROUP_CONNECTION_HOST: string =
    //     UrlConstants.SOCKET_GROUP_CONNECTION_HOST_DEVELOPMENT;
    // static SOCKET_APP_CONNECTION_PORT: number =
    //     UrlConstants.SOCKET_APP_CONNECTION_PORT_DEVELOPMENT;
    // static SOCKET_GROUP_CONNECTION_PORT: number =
    //     UrlConstants.SOCKET_GROUP_CONNECTION_PORT_DEVELOPMENT;
    // static URL_USER_CONTENTS_URL: string =
    //     UrlConstants.URL_USER_CONTENTS_URL_DEVELOPMENT;
    // 開発用 END

    /***************************************** 各URL *****************************************/
    // アプリ
    static URL_GET_SERVER_TIME: string =
        UrlConstants.URL_HOST + "/api/web/v1/get_server_time";
    static V2_URL_GET_SYSTEM_INFO: string =
        UrlConstants.URL_APP_INFO_HOST + "/api/v2/get_system_info";
    static URL_SYSTEM_NEWS: string =
        UrlConstants.URL_HOST + "/api/web/v1/system_news";
    static URL_REPORT_EXCEPTION: string =
        UrlConstants.URL_HOST + "/api/web/v1/report_exception";
    // ユーザ
    static URL_USER_DETAIL: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id";
    static URL_MY_DATA: string =
        UrlConstants.URL_HOST + "/api/web/v2/mydata/:id";
    static V3_URL_MY_DATA: string =
        UrlConstants.URL_HOST + "/api/web/v3/mydata/:id";
    static URL_USER_PROFILE: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/profile";
    static V2_URL_USER_PROFILE: string =
        UrlConstants.URL_HOST + "/api/web/v2/users/profile";
    static URL_POST: string = UrlConstants.URL_HOST + "/api/web/v1/posts/:id";
    static URL_POST_CREATE: string =
        UrlConstants.URL_HOST + "/api/web/v1/posts/create";
    static URL_POST_DELETE: string =
        UrlConstants.URL_HOST + "/api/web/v1/posts/delete";
    static URL_MESSAGE_CREATE: string =
        UrlConstants.URL_HOST + "/api/web/v1/messages/create";
    static URL_POST_LIST: string = UrlConstants.URL_HOST + "/api/web/v1/posts";
    static URL_USER_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/list";
    static V3_URL_USER_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v3/users/list";
    static URL_USER_SEARCH: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/search";
    static V2_URL_USER_SEARCH: string =
        UrlConstants.URL_HOST + "/api/web/v2/users/search";
    static V3_URL_USER_SEARCH: string =
        UrlConstants.URL_HOST + "/api/web/v3/users/search";
    static URL_MESSAGE_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/messages";
    static URL_NEWS_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/newslist";
    static URL_UNREAD_MAIL_COUNT: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/unread_mail_count";
    static URL_READ_NEWS: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/read/news";
    static URL_READ_MESSAGE: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/read/message";
    static URL_MYPAGE: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/mypage";
    static URL_SAVE_USER: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id";
    static V2_URL_SAVE_USER: string =
        UrlConstants.URL_HOST + "/api/web/v2/users/:id";
    static V3_URL_SAVE_USER: string =
        UrlConstants.URL_HOST + "/api/web/v3/users/:id";
    static V4_URL_SAVE_USER: string =
        UrlConstants.URL_HOST + "/api/web/v4/users/:id";
    static V5_URL_SAVE_USER: string =
        UrlConstants.URL_HOST + "/api/web/v5/users/:id";
    static URL_PUBLISH_USER: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/publish";
    static URL_TERMS_OF_USE: string =
        UrlConstants.URL_HOST + "/terms_of_use.html";
    static URL_MESSAGE_SEND: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/send_message";
    static URL_ATTACHED_FILE_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/attached_file_list";
    static URL_REPORT: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/report";
    static URL_INAPPROPRIATE_REPORT: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/inappropriate_report";
    static URL_RETIRE: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/retire";
    static URL_RESTORE: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/restore";
    static URL_USER_FOREGROUND: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/foreground";
    static URL_USER_BACKGROUND: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/background";
    static URL_USER_OFFLINE: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/offline";
    static URL_USER_IS_BLOCKED: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/is_blocked";
    static URL_USER_DELETE: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/delete";
    static URL_USER_ACCOUNT_INFO: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/account_info";
    // フォロー
    static V2_URL_FOLLOW_USER_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v2/users/:id/follow_user_list";
    static V2_URL_FOLLOWER_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v2/users/:id/follower_list";
    static URL_FOLLOW_USER: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/follow";
    static URL_CANCEL_FOLLOW_USER: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/cancelfollow";
    // ブロック
    static URL_BLOCK_USER: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/block";
    static URL_UNBLOCK_USER: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/unblock";
    // グループ
    static URL_PUBLIC_GROUP_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/public_list";
    static URL_GROUP_SEARCH: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/search";
    static URL_GROUP_DETAIL: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/:id";
    static URL_GROUP_APPLY_JOIN_GROUP: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/:id/apply_join_group";
    static URL_GROUP_JOIN_GROUP: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/:id/join_group";
    static URL_GROUP_CANCEL_JOIN_GROUP_APPLICATION: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/groups/:id/cancel_join_group_application";
    static URL_USER_JOIN_GROUP_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/join_group_list";
    static URL_USER_MY_GROUP_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/my_group_list";
    static URL_GROUP_MEMBER_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/group/:groupId/group_member_list";
    static URL_GROUP_MEMBER_LIST_ALL: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/group/:groupId/group_member_list_all";
    static URL_USER_CREATE_GROUP: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/create_group";
    static URL_GROUP_UPDATE: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/:id/update";
    static URL_USER_DISMISS_GROUP: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/:id/dismiss_group";
    static URL_USER_LEAVE_GROUP: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/:id/leave_group";
    static URL_USER_INVITE_GROUP: string =
        UrlConstants.URL_HOST + "/api/web/v2/users/:id/invite_group";
    static URL_GROUP_REJECT_INVITATION: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/:id/reject_invitation";
    static URL_GROUP_ACCEPT_INVITATION: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/:id/accept_invitation";
    static URL_GROUP_CANCEL_INVITATION: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/:id/cancel_invitation";
    static URL_GROUP_REJECT_APPLICATION: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/:id/reject_application";
    static URL_GROUP_ACCEPT_APPLICATION: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/:id/accept_application";
    static URL_GROUP_PURGE_MEMBER: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/:id/purge_member";
    static URL_GROUP_CHAT_MESSAGES: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/:id/chat_message_list";
    static V2_URL_GROUP_CHAT_MESSAGES: string =
        UrlConstants.URL_HOST + "/api/web/v2/groups/:id/chat_message_list";
    static V3_URL_GROUP_CHAT_MESSAGES: string =
        UrlConstants.URL_HOST + "/api/web/v3/groups/:id/chat_message_list";
    static URL_GROUP_CHAT_ATTACHED_FILES: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/:id/chat_attached_files";
    static URL_GROUP_MAIL_SEND: string =
        UrlConstants.URL_HOST + "/api/web/v1/groups/:id/send_group_message";
    // 掲示板
    static URL_MESSAGE_BOARD_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/message_board/list";
    static URL_MESSAGEBOARD_SEARCH: string =
        UrlConstants.URL_HOST + "/api/web/v1/message_board/search";
    static URL_MESSAGE_BOARD_DETAIL: string =
        UrlConstants.URL_HOST + "/api/web/v1/message_board/:id/detail";
    static URL_MESSAGE_BOARD_POST: string =
        UrlConstants.URL_HOST + "/api/web/v1/message_board/post";
    static V2_URL_MESSAGE_BOARD_POST: string =
        UrlConstants.URL_HOST + "/api/web/v2/message_board/post";
    static URL_MESSAGE_BOARD_SAVE: string =
        UrlConstants.URL_HOST + "/api/web/v1/message_board/:id/save";
    static V2_URL_MESSAGE_BOARD_SAVE: string =
        UrlConstants.URL_HOST + "/api/web/v2/message_board/:id/save";
    static URL_MESSAGE_BOARD_DELETE_POST: string =
        UrlConstants.URL_HOST + "/api/web/v1/message_board/:id/delete_message";
    static URL_MESSAGE_BOARD_REPLY: string =
        UrlConstants.URL_HOST + "/api/web/v1/message_board/:id/reply";
    static URL_MESSAGE_BOARD_DELETE_REPLY: string =
        UrlConstants.URL_HOST + "/api/web/v1/message_board/:id/delete_reply";
    // スレッド
    static URL_THREAD_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/category/:category_id/list";
    static URL_THREAD: string =
        UrlConstants.URL_HOST + "/api/web/v1/thread/:id";
    static URL_THREAD_CREATE: string =
        UrlConstants.URL_HOST + "/api/web/v1/thread/create";
    // レスポンス
    static URL_RESPONSE_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/thread/:thread_id/list";
    static URL_RESPONSE_POST: string =
        UrlConstants.URL_HOST + "/api/web/v1/thread/:thread_id/post";
    // トーク
    static URL_TALK_USER: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:user_id/talk/user/:partner_id";
    static URL_TALK_USER_LIST: string =
    UrlConstants.URL_HOST + "/api/web/v1/users/:user_id/talk/user/list";
    static URL_FAVORITE_TALK_USER_LIST: string =
    UrlConstants.URL_HOST + "/api/web/v1/users/:user_id/talk/user/favorite_list";
    static URL_TALK_USER_SEARCH: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:user_id/talk/user/search";
    static URL_TALK_USER_CREATE: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:user_id/talk/user/create";
    static V2_URL_TALK_MESSAGES: string =
        UrlConstants.URL_HOST + "/api/web/v2/users/:user_id/talk/list";
    static URL_TALK_SEND: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:user_id/talk/send";
    static URL_TALK_DELETE_MESSAGE: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:user_id/talk/delete/message";
    static URL_TALK_DELETE_ATTACHED_FILE: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:user_id/talk/delete/attached_file";
    static URL_TALK_UNREAD_MESSAGE_COUNT: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:user_id/talk/unread_talk_message_count";
    static URL_TALK_READ: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:user_id/talk/read";
    static URL_TALK_REMOVE_USER: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:user_id/talk/user/remove";
    static URL_TALK_PUSH_NOTIFICATION: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:user_id/talk/push/:partner_id";
    static URL_TALK_TALK_HISTORY_EXISTENCE: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:user_id/talk/history_existence/:partner_id";
    static URL_UPDATE_TALK_PERMISSION: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:user_id/talk/permission";
    static URL_GET_TALK_PERMISSION: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:user_id/talk/permission";
    static URL_TALK_HISTORY: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:user_id/talk/history";
        // メール
    static V3_URL_RECEIVE_PARENT_MAIL_LIST: string =
        UrlConstants.URL_HOST +
        "/api/web/v3/users/:id/parent_receive_mail_list";
    static V3_URL_SEND_PARENT_MAIL_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v3/users/:id/parent_send_mail_list";
    static URL_CHILD_MAIL_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/child_mail_list";
    static V3_URL_CHILD_MAIL_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v3/users/:id/child_mail_list";
    static URL_USER_MAIL_RECEIVE_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/mail_receive_list";
    static URL_USER_MAIL_SEND_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/mail_send_list";
    static URL_USER_MAIL_SEND: string =
        UrlConstants.URL_HOST + "/api/web/v3/users/:id/mail_send";
    static URL_USER_MAIL_REPLY: string =
        UrlConstants.URL_HOST + "/api/web/v3/users/:id/mail_reply";
    static URL_USER_MAIL_DELETE: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/mail_delete";
    static URL_USER_MAIL_READ: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/mail_read";
    // ツイート
    static URL_TWEET_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/tweet/list";
    static URL_TWEET_POST: string =
        UrlConstants.URL_HOST + "/api/web/v1/tweet/post";
    static URL_TWEET_SAVE: string =
        UrlConstants.URL_HOST + "/api/web/v1/tweet/:id/save";
    static URL_TWEET_DELETE: string =
        UrlConstants.URL_HOST + "/api/web/v1/tweet/:id/delete";
    // アルバム
    static URL_GET_ALBUM_TEMPLATE_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/album_template/list";
    static URL_USER_ALBUM_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/album/list";
    static URL_CREATE_ALBUM: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/album/create";
    static URL_DELETE_ALBUM: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/album/delete";
    static URL_SAVE_ALBUM: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/album/save";
    static V2_URL_SAVE_ALBUM: string =
        UrlConstants.URL_HOST + "/api/web/v2/users/:id/album/save";
    static URL_SAVE_ALBUM_ORDER: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/album/save_order";
    static URL_SAVE_ALBUM_GROUP_ORDER: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/album_group/save_order";
    static URL_SAVE_ALBUM_USER_ORDER: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/album_user/save_order";
    static URL_ALBUM: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/album/:album_id";
    // ギャラリー
    static URL_GALLERY_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/gallery/list";
    static URL_USER_ALBUM_GALLERY_LIST: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:user_id/album/:album_id/gallery/list";
    static URL_USER_OPEN_GALLERY_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/gallery/user_open_list";
    static URL_CREATE_GALLERY: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:user_id/album/:album_id/gallery/create";
    static URL_SAVE_GALLERY: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:user_id/album/:album_id/gallery/save";
    static URL_DELETE_GALLERY: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:user_id/album/:album_id/gallery/delete";
    static URL_SAVE_GALLERY_ORDER: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:user_id/album/:album_id/gallery/save_order";
    static URL_DELETE_SERVER_TEMP_DIRECTORY: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:user_id/delete_temp_directory";
    static URL_USER_GALLERY_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:user_id/gallery/list";

    // 認証
    static V3_URL_REGISTRATION: string =
        UrlConstants.URL_HOST + "/api/web/v3/users/register";
    static V4_URL_REGISTRATION: string =
        UrlConstants.URL_HOST + "/api/web/v4/users/register";
    static V5_URL_REGISTRATION: string =
        UrlConstants.URL_HOST + "/api/web/v5/users/register";
    static URL_LOGIN: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/login";
    static V2_URL_LOGIN: string =
        UrlConstants.URL_HOST + "/api/web/v2/users/login";
    static URL_REGISTER_TOKEN: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/register_token";
    static URL_RESEND_AUTH_KEY: string =
        UrlConstants.URL_HOST + "/resend_auth_key";
    static URL_REQUEST_PASSWORD_RESET: string =
        UrlConstants.URL_HOST + "/api/web/v1/request_reset_password";
    static URL_RESET_PASSWORD: string =
        UrlConstants.URL_HOST + "/api/web/v1/reset_password";
    static URL_GET_ACCESS_TOKEN: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/get_access_token";
    static URL_GET_SKYWAY_TOKEN: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/skyway_token";
    static URL_REFRESH_ACCESS_TOKEN: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/refresh_access_token";
    static URL_CHANGE_LANGUAGE: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/change_language";
    static URL_LOGOUT: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/logout";

    // 課金
    static URL_SUBSCRIPTION_PURCHASED: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:id/purchased_apple_subscription";

    // ポイント
    static URL_POINT_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/point/list";

    // ギフト
    static URL_GIFT_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/gift/list";

    // ユーザーギフト
    static URL_UNCONSUMED_USER_GIFT_LIST: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:id/unconsumed_user_gift_list";
    static URL_CONSUMED_USER_GIFT_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/consumed_user_gift_list";
    static URL_RECEIVED_USER_GIFT_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/gift/list_received";
    static URL_SENT_USER_GIFT_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/gift/list_sent";

    // 請求
    static URL_GET_CLAIM_INFO: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/claim_info";
    static URL_SAVE_PAYPAL_ACCOUNT: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/save_paypal_account";
    static URL_PAYPAL_CLAIM: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/paypal_claim";
    static URL_BILLING_HISTORY: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/billing_history";
    static URL_BILLING_HISTORY_DETAIL: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:user_id/billing_histories/:history_id";
    static URL_CANCEL_PAYPAL_CLAIM: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:user_id/billing_histories/:history_id/cancel";

    // タグリスト
    static URL_TAG_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/tag/list";
    static V2_URL_TAG_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v2/tag/list";

    // 国リスト
    static URL_COUNTRY_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/country/list";

    // カテゴリーリスト
    static URL_CATEGORY_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/category/list";

    // ギフト
    static URL_MATERIAL_PROCESS_IMAGE_LIST: string =
        UrlConstants.URL_HOST + "/api/web/v1/material/process_image/list";

    // S3
    static URL_S3_USER_ICON: string =
        UrlConstants.URL_USER_CONTENTS_URL + "/profile_icon/";
    static URL_S3_ORIGINAL_USER_ICON: string =
        UrlConstants.URL_USER_CONTENTS_URL + "/profile_icon/original/";
    static URL_S3_ALBUM_ICON: string =
        UrlConstants.URL_USER_CONTENTS_URL + "/materials/albums/";
    static URL_S3_ORIGINAL_GALLERY_FILES: string =
        UrlConstants.URL_USER_CONTENTS_URL + "/gallery/";
    static URL_S3_GALLERY_FILES: string =
        UrlConstants.URL_USER_CONTENTS_URL + "/gallery_sample/";
    static URL_S3_ORIGINAL_ATTACHED_FILES: string =
        UrlConstants.URL_USER_CONTENTS_URL + "/attached_file/";
    static URL_S3_ATTACHED_FILE_TBUMBNAIL: string =
        UrlConstants.URL_USER_CONTENTS_URL + "/attached_file/";
    static URL_S3_GALLERY_FILE_TBUMBNAIL: string =
        UrlConstants.URL_USER_CONTENTS_URL + "/gallery/";
    static URL_S3_ATTACHED_FILES: string =
        UrlConstants.URL_USER_CONTENTS_URL + "/attached_file/sample/";
    static URL_S3_GIFT: string = UrlConstants.URL_USER_CONTENTS_URL + "/gift/";
    static URL_S3_POINT: string =
        UrlConstants.URL_USER_CONTENTS_URL + "/point/";
    static URL_S3_MATERIAL: string =
        UrlConstants.URL_USER_CONTENTS_URL + "/materials/";
    static URL_S3_HLS = UrlConstants.URL_USER_CONTENTS_URL + "/hls/";
    static URL_S3_HLS_ATTACHED_FILE_DIRNAME = "attached_file";
    static URL_S3_360HLS_ATTACHED_FILE =
        UrlConstants.URL_S3_HLS + "360/attached_file";
    static URL_S3_HLS_GALLERY_DIRNAME = "gallery";
    static URL_S3_360HLS_GALLERY = UrlConstants.URL_S3_HLS + "360/gallery";

    // セッション作成
    static URL_CREATE_SESSION: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/confirm_token";

    // トークン発行
    static URL_ISSUE_PURCHASE_POINT_TOKEN: string =
        UrlConstants.URL_HOST +
        "/api/web/v1/users/:id/issue_purchase_point_token";
    static URL_ISSUE_GIFT_SEND_TOKEN: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/issue_send_gift_token";

    // アクションキー取得
    static URL_GET_ACTION_KEY: string =
        UrlConstants.URL_HOST + "/api/web/v1/app/get_action_key";

    // App情報取得
    static URL_GET_APP_INFO: string =
        UrlConstants.URL_HOST + "/api/web/v1/app_info";

    // アカウント削除
    static URL_DELETE_ACCOUNT: string =
        UrlConstants.URL_HOST + "/api/web/v1/users/:id/delete";

    // Google Credential 検証
    static URL_VERIFY_GOOGLE_CREDENTIAL: string =
        UrlConstants.URL_HOST + "/api/web/v1/oauth/google/verify";
}
